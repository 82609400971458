import Chip, { ChipColors, ChipSizes } from "aether/Chip";
import { SkillStatus } from "pages/AgentBuilder/types";

export const formatSkillStatus = (
  status: SkillStatus
): { label: string | null; color: ChipColors } => {
  switch (status) {
    case SkillStatus.DRAFT:
      return { label: "Draft", color: ChipColors.default };
    case SkillStatus.ACTIVE:
      return { label: "Active", color: ChipColors.success };
    case SkillStatus.INACTIVE:
      return { label: "Inactive", color: ChipColors.error };
    default:
      return { label: null, color: ChipColors.none };
  }
};

interface Props {
  status: SkillStatus;
}

const SkillStatusChip: React.FC<Props> = ({ status }) => {
  const { label, color } = formatSkillStatus(status);

  if (!label) return null;

  return <Chip label={label} color={color} size={ChipSizes.large} />;
};

export default SkillStatusChip;
