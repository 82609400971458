import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useKnowledgeBaseHeaderStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    width: "calc(100% - 72px)",
    height: "80px",
    position: "fixed",
    display: "flex",
    minWidth: "1200px",
    padding: "0px 32px",
    justifyContent: "space-between",
    alignItems: "stretch",
    background: "#FFF",
    boxShadow: "0px 1px 0px 0px #E6E6E6",
    zIndex: 1000,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  headerIconContainer: {
    "& svg": {
      height: 32,
      width: 32,

      "& path": {
        fill: "#282624",
      },
    },
  },
}));
