import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { type IAgentComposition } from "./types";
import { useCallback, useContext, useRef } from "react";
import AgentBuilderContext from "../AgentBuilderContext";
import { patchAgent } from "api/bot/bot-agents";
import { useSnackbar } from "notistack";
import Typography, { TypographyWeights } from "aether/Typography";
import { isEmpty } from "lodash";

const instructions_placeholder =
  "E.g. Assist customers with tasks like flight bookings, cancellations, and providing real-time flight status updates, ensuring a smooth and efficient customer experience. ";
const Instructions: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { values, handleBlur, handleChange, setFieldValue } =
    useFormikContext<IAgentComposition>();

  const agentBuilderContext = useContext(AgentBuilderContext);
  const { agentId, setAgent, setIsSavingInProgress, setIsEditingInProgress } =
    agentBuilderContext;

  const currentInstructions = useRef<string>(values.general_prompt);

  const handleOnBlur = useCallback(
    async (event: any): Promise<any> => {
      handleBlur(event);
      setIsEditingInProgress(false);

      if (values.general_prompt === currentInstructions.current) {
        return;
      }
      if (isEmpty(values.general_prompt)) {
        void setFieldValue("general_prompt", currentInstructions.current);
        return;
      }

      setIsSavingInProgress(true);
      try {
        const res = await patchAgent({
          _id: agentId,
          general_prompt: values.general_prompt,
        });
        setAgent(res);
        currentInstructions.current = values.general_prompt;
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while saving script ${error}`, {
          variant: "error",
        });
      } finally {
        setIsSavingInProgress(false);
      }
    },
    [values.general_prompt]
  );

  return (
    <div className="flex flex-col row-gap-8">
      <Typography weight={TypographyWeights.semiBold}>
        {`What are the execution steps and instructions for this skill? `}
        <span style={{ color: "red" }}>*</span>
      </Typography>
      <TextField
        name="general_prompt"
        variant="outlined"
        size="small"
        placeholder={instructions_placeholder}
        fullWidth
        value={values.general_prompt}
        onChange={handleChange}
        onBlur={(event) => {
          void handleOnBlur(event);
        }}
        onFocus={() => {
          setIsEditingInProgress(true);
        }}
        minRows={18}
        multiline
      />
    </div>
  );
};

export default Instructions;
