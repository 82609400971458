import { makeStyles } from "@mui/styles";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { ReactComponent as EmtyStateIcon } from "assets/barChartEmptyState.svg";
const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  subtitle: {
    maxWidth: "612px",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "28px",
    marginTop: "8px",
  },
}));

interface Props {
  title?: string;
  description?: string;
  icon?: React.ReactNode;
}

const BrokenCard: React.FC<Props> = ({
  title = "Sorry! there is something wrong at our end.",
  description = "Please check your internet connection, refresh your browser, or tryagain later.",
  icon = <EmtyStateIcon />,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {icon}
      <Typography
        weight={TypographyWeights.bold}
        variant={TypographyVariants.textXL}
      >
        {title}
      </Typography>
      <div className={classes.subtitle}>{description}</div>
    </div>
  );
};

export default BrokenCard;
