import {
  AuthType,
  GLOBAL_INTEGRATION_SESSION_KEY,
} from "pages/Integrations/integrations.types";
import { createConnection } from "pages/Integrations/Services/integrations.service";
import { useEffect } from "react";
import { getModifiedPayload } from "./rightConfigure.util";
import { isEmpty, isEqual } from "lodash";

interface props {
  connectorId: string;
  onSuccess: () => void;
  onFailure: () => void;
  setIsMutationIsInProgress: (state: boolean) => void;
  authType: AuthType;
}
export const useHandleRedirectBackAfterAuthentication = ({
  connectorId,
  onSuccess,
  onFailure,
  setIsMutationIsInProgress,
  authType,
}: props): void => {
  // Handle the redirect back after authentication
  useEffect(() => {
    // Parse the query parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve allquery params into an object
    const queryParams: Record<string, string> = {};
    urlParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    if (!isEmpty(queryParams) && isEqual(authType, AuthType.OAUTH)) {
      const savedStringifiedValues = sessionStorage.getItem(
        GLOBAL_INTEGRATION_SESSION_KEY
      );

      let savedValues = {};
      if (savedStringifiedValues) {
        savedValues = JSON.parse(savedStringifiedValues);
      }
      // Clear the session storage after retrieval
      sessionStorage.removeItem(GLOBAL_INTEGRATION_SESSION_KEY);

      const updatedValues = { ...savedValues, ...queryParams };
      const payload = getModifiedPayload(
        updatedValues,
        connectorId,
        AuthType.OAUTH
      );
      setIsMutationIsInProgress(true);

      createConnection(payload)
        .then(onSuccess)
        .catch(onFailure)
        .finally(() => {
          setIsMutationIsInProgress(false);
        });
    }
  }, []);
};
