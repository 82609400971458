import Typography, {
  TypographyColors,
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { TabNavigationCategory } from "../types";
import { useTabNavigationStyles as useStyles } from "./styles";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

interface Props {
  navigations: TabNavigationCategory[];
}

const TabNavigationBar: React.FC<Props> = ({ navigations }) => {
  const classes = useStyles();
  const location = useLocation();

  const [activePath, setActivePath] = useState("");

  useEffect(() => {
    if (location?.pathname) {
      const _path = location.pathname.split("/")[4];
      setActivePath(_path);
    }
  }, [location]);

  return (
    <div className={classes.tabNavigationContainer}>
      {navigations.map(({ category, key, title, paths }, categoryIndex) => (
        <React.Fragment key={key}>
          <div className={classes.categoryContainer}>
            {title && (
              <div className="category-title-container">
                <Typography
                  variant={TypographyVariants.textLarge}
                  weight={TypographyWeights.bold}
                >
                  {title}
                </Typography>
              </div>
            )}

            <div className={clsx(classes.categoryNavigationsContainer)}>
              {paths.map(
                (
                  { path, title: pathTitle, key: pathKey, enabled },
                  pathIndex
                ) => (
                  <Link
                    key={pathKey}
                    to={enabled && path ? path : ""}
                    className={clsx("navigation-link-container", {
                      active: activePath === path,
                      disabled: !enabled,
                    })}
                  >
                    <Typography
                      weight={TypographyWeights.semiBold}
                      color={TypographyColors.muted}
                    >
                      {pathTitle}
                    </Typography>
                  </Link>
                )
              )}
            </div>
          </div>
          {categoryIndex < navigations.length - 1 && (
            <div className={classes.categorySeparator}></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default TabNavigationBar;
