import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { ReactComponent as DisplayInfoIcon } from "assets/display-info-icon.svg";
import { ReactComponent as TriggerNodeIcon } from "assets/trigger-node-icon.svg";
import { ReactComponent as ChatMessageIcon } from "assets/chat-message-icon.svg";
import { ReactComponent as BotPromptIcon } from "assets/bot-prompt-icon.svg";
// import { ReactComponent as TextInputIcon } from "assets/text-input-icon.svg";
import { ReactComponent as CarouselIcon } from "assets/carousel-icon.svg";
import { ReactComponent as ActionsIcon } from "assets/actions-icon.svg";
import { ReactComponent as TimerIcon } from "assets/timer-icon.svg";
import { ReactComponent as TextInputEnableIcon } from "assets/text-enable-icon.svg";
import { ReactComponent as TextInputDisableIcon } from "assets/text-disable-icon.svg";
import { ReactComponent as ServiceIcon } from "assets/service-icon.svg";
import { ReactComponent as ThumbsUpIcon } from "assets/thumbs-up.svg";
import { ReactComponent as LogicIcon } from "assets/logic-icon.svg";
import { ReactComponent as ListIcon } from "assets/list-icon.svg";
import { ReactComponent as DialogContextIcon } from "assets/dialog-context-icon.svg";
import { ReactComponent as GlobalContextIcon } from "assets/global-context-icon.svg";
import { ReactComponent as UserContextIcon } from "assets/user-context-icon.svg";

// NODES TYPE
export const NodeGroupType = {
  TRIGGER_NODE: "TriggerNode",
  RESPONSE_NODE: "ResponseNode",
  BOT_PROMPT_NODE: "BotPromptNode",
  SERVICE_NODE: "ServiceNode",
  USER_SELECTION_NODE: "UserSelectionNode",
  LOGIC_NODE: "LogicNode",
  CREATE_NODE: "CreationNode",
};

export const CreateNodeType = {
  PLACEHOLDER_NODE: "PlaceholderNode",
  BRANCH_ADD_NODE: "BranchAddNode",
};

export const TriggerNodeType = {
  TRIGGER: "TriggerNode",
};

export const ResponseNodeType = {
  TEXT: "TextResponseNode",
  CAROUSEL: "carousel",
};

export const BotPromptNodeType = {
  TEXT: "TextFormInputNode",
  CAROUSEL: "CarouselResponseNode",
};

export const ServiceNodeType = {
  DELAY_RESPONSE: "delay_response",
  TEXT_INPUT_DISABLED: "text_input_disabled",
  TEXT_INPUT_ENABLED: "text_input_enabled",
  API_SERVICE_NODE: "APIServiceNode",
  USER_FEEDBACK: "user_feedback",
};

export const LogicNodeType = {
  BRANCH: "BranchNode",
};

// NODES CONFIGURATION
export const NodeConfig = {
  [NodeGroupType.RESPONSE_NODE]: {
    ICON: DisplayInfoIcon,
    COLOR: "#8CD1C5",
  },
  [NodeGroupType.BOT_PROMPT_NODE]: {
    ICON: BotPromptIcon,
    COLOR: "#AD79EF",
  },
  [NodeGroupType.SERVICE_NODE]: {
    ICON: ActionsIcon,
    COLOR: "#78C7E7",
  },
  [NodeGroupType.LOGIC_NODE]: {
    ICON: LogicIcon,
    COLOR: "#D8C24F",
  },
};

export const CreateNodeConfig = {
  [CreateNodeType.PLACEHOLDER_NODE]: {
    ICON: PlusIcon,
    COLOR: "#ffff",
  },
  [CreateNodeType.BRANCH_ADD_NODE]: {
    ICON: PlusIcon,
    COLOR: "#ffff",
  },
};

export const TriggerNodeConfig = {
  [TriggerNodeType.TRIGGER]: {
    ICON: TriggerNodeIcon,
    COLOR: "#B2E69F",
  },
};

export const ResponseNodeConfig = {
  [ResponseNodeType.TEXT]: {
    ICON: ChatMessageIcon,
    COLOR: "#B2E69F",
  },
  [ResponseNodeType.CAROUSEL]: {
    ICON: CarouselIcon,
    COLOR: "#B2E69F",
  },
};

export const BotPromptNodeConfig = {
  [BotPromptNodeType.TEXT]: {
    ICON: ListIcon,
    COLOR: "#AD79EF",
  },
  [BotPromptNodeType.CAROUSEL]: {
    ICON: CarouselIcon,
    COLOR: "#AD79EF",
  },
};

export const ServiceNodeConfig = {
  [ServiceNodeType.DELAY_RESPONSE]: {
    ICON: TimerIcon,
    COLOR: "#78C7E7",
  },
  [ServiceNodeType.TEXT_INPUT_DISABLED]: {
    ICON: TextInputDisableIcon,
    COLOR: "#78C7E7",
  },
  [ServiceNodeType.TEXT_INPUT_ENABLED]: {
    ICON: TextInputEnableIcon,
    COLOR: "#78C7E7",
  },
  [ServiceNodeType.API_SERVICE_NODE]: {
    ICON: ServiceIcon,
    COLOR: "#78C7E7",
  },
  [ServiceNodeType.USER_FEEDBACK]: {
    ICON: ThumbsUpIcon,
    COLOR: "#78C7E7",
  },
};

export const LogicNodeConfig = {
  [LogicNodeType.BRANCH]: {
    ICON: LogicIcon,
    COLOR: "#D8C24F",
  },
};

// EDGES TYPE
export const EdgeType = {
  PLACEHOLDER_EDGE: "placeholder",
  WORKFLOW_EDGE: "workflow",
};

// CONTEXT
export const ContextVariableState = {
  DIALOG: "dialog_context",
  USER: "user_context",
  GLOBBAL: "global_context",
  CONVERSATION: "connection_context",
  INTERNAL: "internal_context",
} as const;

// TODO - Need to define key and value typeof for these kind of const state
export const ContextVariableConfig: Record<any, any> = {
  [ContextVariableState.DIALOG]: {
    label: "Local Context",
    ICON: DialogContextIcon,
  },
  [ContextVariableState.USER]: {
    label: "User Context",
    ICON: UserContextIcon,
  },
  [ContextVariableState.GLOBBAL]: {
    label: "Global Context",
    ICON: GlobalContextIcon,
  },
};

export const FieldType = {
  DROPDOWN: "dropdown",
  TEXT: "text",
  TEXTAREA: "textarea",
  TEMPLATE_SELECT: "template-select",
  TEMPLATE_TEXTFIELD: "template-textfield",
  TEMPLATE_TEXTAREA: "template-textarea",
};

//
export const ServiceNodeSteps = {
  API_CONFIGURATION: "API_CONFIGURATION",
  API_INPUT: "API_INPUT",
  API_OUTPUT: "API_OUTPUT",
  API_FALLBACK: "API_FALLBACK",
  API_RESPONSE: "API_RESPONSE",
} as const;

// BRNACH NODE OPERATOR
export const OPERATOR_TYPE: Record<string, string[]> = {
  STRING: [
    "CONTAINS",
    "NOT_CONTAINS",
    "MATCHES",
    "NOT_MATCHES",
    "IS_IN",
    "NOT_IS_IN",
  ],
  INTEGER: ["GT", "LT", "GTE", "LTE", "EQ", "NEQ"],
  NUMBER: ["GT", "LT", "GTE", "LTE", "EQ", "NEQ"],
  BOOLEAN: ["TRUE", "FALSE", "EXISTS", "NOT_EXISTS"],
  ARRAY: [],
  OBJECT: [],
  NULL: [],
};

export const OPERATOR_LABELS: Record<string, string> = {
  CONTAINS: "Contains",
  NOT_CONTAINS: "Does not contain",
  MATCHES: "Matches",
  NOT_MATCHES: "Does not match",
  IS_IN: "Is in",
  NOT_IS_IN: "Is not in",
  GT: "Greater than",
  LT: "Less than",
  GTE: "Greater than or equal to",
  LTE: "Less than or equal to",
  EQ: "Equal to",
  NEQ: "Not equal to",
  TRUE: "True",
  FALSE: "False",
  EXISTS: "Exists",
  NOT_EXISTS: "Does not exist",
} as const;

// ENTITY CONSTANTS
export const ENTITY_DATA_TYPE = [
  {
    value: "String",
    label: "String",
  },
  {
    value: "Number",
    label: "Number",
  },
  {
    value: "Boolean",
    label: "Boolean",
  },
] as const;

//
export const TERMINAL_NODES = [
  TriggerNodeType.TRIGGER,
  ResponseNodeType.TEXT,
  BotPromptNodeType.TEXT,
  ServiceNodeType.API_SERVICE_NODE,
];
