import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
  },
  title: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    letterSpacing: "-0.2px",
    marginRight: "8px",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& svg": {
      height: "16px",
      width: "16px",
      "& path": {
        fill: "#35312D",
      },
    },
  },
  subTitle: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    marginBottom: "24px",
  },
  container: {
    height: "384px",
    display: "flex",
    padding: "16px 24px",
    flexDirection: "column",
    alignItems: "stretch",
    borderRadius: "12px",
    border: "var(--spacing-spacing-none, 1px) solid var(--Gray-200, #EAECF0)",
    background: "var(--Base-White, #FFF)",
  },
  tableContainer: {
    maxHeight: "calc(100% - 60px)",
    overflowY: "auto",
  },
  tableHeaderRow: {
    position: "sticky",
    top: 0,
    background: "#FFF",
    zIndex: 1,
  },
  tableHeader: {
    background: "var(--inactive-surfaces-muted, #FCFCFB)",
    fontWeight: "bold",
    textAlign: "center",
    borderTop:
      "var(--spacing-spacing-none, 1px) solid var(--Gray-200, #EAECF0)",
    borderBottom:
      "var(--spacing-spacing-none, 1px) solid var(--Gray-200, #EAECF0)",
  },
  tableCell: {
    borderTop:
      "var(--spacing-spacing-none, 1px) solid var(--Gray-200, #EAECF0)",
    borderBottom:
      "var(--spacing-spacing-none, 1px) solid var(--Gray-200, #EAECF0)",
    background: "var(--inactive-surfaces-muted, #FCFCFB)",
    textAlign: "center",
    padding: "8px 16px",
  },
}));

export default useStyles;
