import Typography from "components/base/Typography";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { type ICarousel } from "types/Workflow";
import { ReactComponent as SampleImageIcon } from "assets/sample-image-icon.svg";

const useStyles = makeStyles((theme) => ({
  "carousel-container": {
    "& .carousel-sample": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
      borderRadius: "16px",
      border: "1px solid var(--border-default, #E1DEDA)",
      background: "var(--bg-disabled, #F9F8F8)",

      "& .carousel-card": {
        position: "relative",
        display: "flex",
        width: "236px",
        height: "306px",
        paddingBottom: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        borderRadius: "16px",
        border: "1px solid var(--border-default, #E1DEDA)",
        background: "#FFF",

        "& .tag": {
          display: "flex",
          padding: "2px 8px",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          position: "absolute",
          left: "12px",
          top: "12px",
          borderRadius: "4px",
          background: "#FFF",
          boxShadow: "4px 4px 5px 0px rgba(0, 0, 0, 0.15)",
        },

        "& .card-img-container": {
          width: "100%",
          height: "116px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          "& .carousel-img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          },
        },

        "& .carousel-header": {
          width: "236px",
          height: "117px",
          borderRadius:
            "16px 16px var(--spacing-spacing-none, 0px) var(--spacing-spacing-none, 0px)",
          background: "var(--colors-shades-stone-400, #E7E5E1)",
        },

        "& .carousel-content": {
          height: "75px",

          "& .text-body": {
            height: "46px",
            overflow: "hidden",
          },
        },

        "& .carousel-action": {
          height: "72px",
          width: "100%",

          "& .card-action-btn": {
            height: "50%",
            borderTop: "1px solid #E7E5E1",

            "& a": {
              textDecoration: "none",
              color: "#E06F06",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "24px",
            },
          },
        },
      },
    },
  },
}));

interface ICarouselItemProps {
  item: ICarousel;
  onClickAction?: (action: any) => void;
}

const CarouselItem: React.FC<ICarouselItemProps> = ({
  item,
  onClickAction,
}) => {
  const classes = useStyles();

  const textContent =
    item?.body?.length > 64 ? `${item.body?.slice(0, 64)}...` : item.body;

  return (
    <div className={classes["carousel-container"]}>
      <div className="carousel-sample">
        <div className="carousel-card">
          <div className="carousel-header center">
            <div className="tag">
              <Typography>{item?.tag || "Tag"}</Typography>
            </div>
            <div className="card-img-container">
              {item?.image ? (
                <img
                  src={item?.image}
                  alt={item?.heading}
                  className="carousel-img"
                />
              ) : (
                <SampleImageIcon />
              )}
            </div>
          </div>

          <div className="carousel-content flex flex-col row-gap-2 px-12">
            <div className="flex flex-col">
              <Typography
                variant="textTiny"
                sx={{ color: "#7C7972" }}
                className="w-semi-bold"
              >
                {item.subheading || "Subheading"}
              </Typography>
              <Typography className="w-bold word-elipses">
                {item.heading || "Heading"}
              </Typography>
            </div>
            <Typography className="text-body" title={item.body}>
              {textContent || "Body of text"}
            </Typography>
          </div>

          <div className="carousel-action flex flex-col justify-content-end align-items-stretch">
            <div className="card-action-btn center">
              <Link
                to={""}
                onClick={() => {
                  onClickAction?.({ ...item, action: "button_1" });
                }}
              >
                {item.button_1}
              </Link>
            </div>
            {item?.button_2 && (
              <div className="card-action-btn center">
                <Link
                  to={""}
                  onClick={() => {
                    onClickAction?.({ ...item, action: "button_2" });
                  }}
                >
                  {item.button_2}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
