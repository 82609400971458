import { isEmpty } from "lodash";
import Source from "pages/KnowledgeBase/types";
import { botAxiosInstance } from "services/axios";

// LINK
export const getLinkSourceList = async (params?: string): Promise<any> => {
  let url = `/crawler/crawlResources/`;
  if (params) {
    url += `?${params}`;
  }

  return await botAxiosInstance({
    method: "GET",
    url,
  });
};

export const postLinkSource = async (data: {
  url: string;
  name: string;
}): Promise<Source> => {
  return await botAxiosInstance({
    method: "POST",
    url: `/crawler/crawlResources/`,
    data,
  });
};

export const patchLinkSource = async (
  sourceId: string,
  values: Record<string, any>
): Promise<Source> => {
  return await botAxiosInstance({
    method: "PATCH",
    url: `/crawler/crawlResources/byId/${sourceId}/`,
    data: {
      ...values,
    },
  });
};

export const deleteLinkSource = async (sourceId: string): Promise<any> => {
  return await botAxiosInstance({
    method: "DELETE",
    url: `/crawler/crawlResources/byId/${sourceId}/`,
  });
};

// FILE
export const getFileSourceList = async (
  params?: string,
  namespace_id = "GLOBAL"
): Promise<any> => {
  let url = `/customer-resource/uploads/?namespace_id=${namespace_id}`;
  if (params) {
    url += `&${params}`;
  }

  return await botAxiosInstance({
    method: "GET",
    url,
  });
};

export const uploadSource = async (data: any): Promise<any> => {
  return await botAxiosInstance({
    method: "POST",
    url: `/customer-resource/uploads/upload/`,
    data,
  });
};

export const patchFileSource = async (
  sourceId: string,
  values: Record<string, any>
): Promise<any> => {
  return await botAxiosInstance({
    method: "PATCH",
    url: `/customer-resource/uploads/${sourceId}/`,
    data: {
      ...values,
    },
  });
};

export const deleteFileSource = async (sourceId: string): Promise<any> => {
  return await botAxiosInstance({
    method: "DELETE",
    url: `/customer-resource/uploads/${sourceId}`,
  });
};

// KNOWLEDGE BASE SERVICE
export const getKnowledgeSources = async ({
  meta,
  params,
}: {
  meta?: {
    nextFile?: string;
    nextLink?: string;
  };
  params?: string;
}): Promise<any> => {
  let linkSources: any = [];
  let fileSources: any = [];

  if (isEmpty(meta) || (meta?.nextFile && meta?.nextLink)) {
    fileSources = await getFileSourceList(params);
    linkSources = await getLinkSourceList(params);
  } else if (meta?.nextFile) {
    fileSources = await getFileSourceList(params);
  } else if (meta?.nextLink) {
    linkSources = await getLinkSourceList(params);
  }

  const _linkSources = formatSourceResponse(linkSources, "link");
  const _fileSources = formatSourceResponse(fileSources, "file");

  const combinedSources = { link: _linkSources, file: _fileSources };

  return formatSourcePagination(combinedSources, meta);
};

const formatSourceResponse = (data: any, type: "link" | "file"): any => {
  const results = data?.results?.map((item: any) => ({
    ...item,
    resourceType: type,
    resourceId: type === "file" ? item.document_id : item.id,
  }));

  if (!results) {
    return null;
  }

  return {
    ...data,
    results,
  };
};

const formatSourcePagination = (
  combinedSources: {
    link: any;
    file: any;
  },
  meta?: {
    nextFile?: string;
    nextLink?: string;
  }
): any => {
  const { link, file } = combinedSources;

  if (link && file) {
    return {
      count: link.count + file.count,
      next: link.next || file.next,
      previous: link.previous || file.previous,
      meta: {
        nextFile: file.next,
        nextLink: link.next,
      },
      data: {
        results: [...link.results, ...file.results],
      },
    };
  }

  if (link) {
    return {
      count: link.count,
      next: link.next,
      previous: link.previous,
      meta: {
        nextFile: null,
        nextLink: link.next,
      },
      data: {
        results: [...link.results],
      },
    };
  }

  if (file) {
    return {
      count: file.count,
      next: file.next,
      previous: file.previous,
      meta: {
        nextFile: null,
        nextLink: file.next,
      },
      data: {
        results: [...file.results],
      },
    };
  }
};
