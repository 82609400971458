import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  actionContainer: {
    display: "flex",
    gap: "24px",
    alignItems: "center",
  },
  autoSave: {
    color: "var(--Success-700, #027A48)",
    display: "flex",
    // flexDirection: "column",
    gap: "8px",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 600,
  },
  autoSaving: {
    display: "flex",
    gap: "8px",
    textAlign: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: 600,
  },
  deactivateBtn: {
    color: "var(--text-error, #B72D0E)",
    borderRadius: "var(--radius-border-radius, 8px)",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "var(--actionable-surfaces-secondary-default, #FFF)",
  },
  agentBuilderContainer: {
    width: "100%",
    height: "100vh",
  },
  agentBuilderMainContainer: {
    height: "calc(100vh - 72px)",
    width: "100%",
    position: "relative",
    top: "72px",
  },
  agentBuilderOutletContainer: {
    padding: "16px 32px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "auto",
    height: "100%",
    width: "100%",

    [theme.breakpoints.down("lg")]: {
      alignItems: "flex-start",
    },
  },
  chatbotWidgetContainer: {
    backgroundColor: "transparent",
    position: "fixed",
    right: 0,
    bottom: 0,
    zIndex: "1000",
    justifyContent: "flex-end",
  },
}));

export default useStyles;
