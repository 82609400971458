import { MenuItem, Select, FormControl } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Filter } from "../types";
import { isEmpty } from "lodash";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    gap: "12px",
  },
  filter: {
    width: "200px",
    borderRadius: "12px",
    background: "#fff",
  },
  select: {
    "& .MuiSelect-select": {
      fontWeight: 500,
    },
  },
  menuItem: {
    fontWeight: 500,
  },
}));

const isChanged = (
  filters: Filter[],
  filterId: string,
  newValue: string
): boolean => {
  return filters.some((filter) => {
    if (filter.id === filterId) {
      return newValue !== filter?.currentFilterId;
    }
    return false;
  });
};

interface Props {
  filters: Filter[];
  onChangeFilter: (filters: Filter[]) => void;
}

const AnalyticsFilters: React.FC<Props> = ({ filters, onChangeFilter }) => {
  const classes = useStyles();

  const handleFilterChange = (
    updatedFilterId: string,
    newValue: string
  ): void => {
    if (!isChanged(filters, updatedFilterId, newValue)) return;
    const updatedFilterValues = filters.map((filter) =>
      filter.id === updatedFilterId
        ? { ...filter, currentFilterId: newValue }
        : filter
    );
    onChangeFilter(updatedFilterValues);
  };

  if (isEmpty(filters)) return null;

  return (
    <div className={classes.container}>
      {filters.map((filter) => {
        return (
          <FormControl className={classes.filter} key={filter.id}>
            <Select
              value={filter.currentFilterId}
              onChange={(e) => {
                handleFilterChange(filter.id, e.target.value);
              }}
              disabled={filter?.allValues.length <= 2} // In case if we have only one agent present
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "500px",
                    overflow: "auto",
                    width: "200px",
                  },
                },
              }}
              className={classes.select} // Apply custom styles for Select component
            >
              {filter.allValues?.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  className={classes.menuItem} // Apply custom styles for MenuItem
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      })}
    </div>
  );
};

export default AnalyticsFilters;
