import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { formatToHumanStringFromDateTime } from "utils/dateUtils";
import FileTypeIcon from "components/shared/FileTypeIcon";
import {
  ResourceType,
  FileResource,
  LinkResource,
} from "pages/KnowledgeBase/types";
import { ReactComponent as LinkIcon } from "assets/link-icon.svg";
import { Checkbox, CircularProgress } from "@mui/material";
import { ReactComponent as EmptyState } from "assets/empty-state.svg";
import { useSourceTableStyles as useStyles } from "./style";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import SourceStatusChip from "./SourceStatus";

enum ColumnId {
  Name = "name",
  UploadedOn = "uploadedOn",
  Status = "status",
  Actions = "actions",
}

enum Alignment {
  Left = "left",
  Center = "center",
  Right = "right",
}

interface IColumn {
  id: ColumnId;
  label: string;
  maxWidth?: string;
  width?: string;
  alignment?: Alignment;
}

const columns: readonly IColumn[] = [
  {
    id: ColumnId.Name,
    label: "Name",
    maxWidth: "40%",
    width: "40%",
    alignment: Alignment.Left,
  },
  {
    id: ColumnId.UploadedOn,
    label: "Date uploaded",
    maxWidth: "20%",
    width: "20%",
    alignment: Alignment.Center,
  },
  {
    id: ColumnId.Status,
    label: "Status",
    maxWidth: "15%",
    width: "15%",
    alignment: Alignment.Center,
  },

  {
    id: ColumnId.Actions,
    label: "",
    maxWidth: "20%",
    width: "20%",
    alignment: Alignment.Right,
  },
];

export interface TableRowSelectionProps {
  selected: string[];
  onSelect: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface Props {
  data: Array<FileResource | LinkResource>;
  tableState: {
    loading: boolean;
    empty: boolean;
    error: boolean;
  };
  pageLoading: boolean;
  nextUrl: string;
  selectState: TableRowSelectionProps;
  setParentNode: (element: HTMLDivElement | null) => void;
  setLastElement: (element: HTMLDivElement | null) => void;
}

const SourceTable: React.FC<Props> = ({
  data,
  pageLoading,
  nextUrl,
  selectState,
  setParentNode,
  setLastElement,
  tableState,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  if (tableState.loading) {
    return (
      <div className={clsx(classes.sourceTableContainer, "center")}>
        <div className="source-loader-container center">
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (tableState.error) {
    enqueueSnackbar("Something went wrong loading knowledge sources", {
      variant: "error",
    });
  }

  if (tableState.empty) {
    return (
      <div className={clsx(classes.sourceTableContainer, "center")}>
        <div className="source-empty-container">
          <EmptyState />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classes.sourceTableContainer}
      ref={(ref) => {
        setParentNode(ref);
      }}
    >
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead className="table-head">
            <TableRow>
              <TableCell
                style={{
                  maxWidth: "5%",
                  width: "5%",
                }}
              >
                <Checkbox
                  size="small"
                  color="primary"
                  onChange={selectState.onSelectAll}
                  indeterminate={
                    selectState.selected.length > 0 &&
                    selectState.selected.length < data?.length
                  }
                  checked={
                    data?.length > 0 &&
                    selectState.selected.length === data?.length
                  }
                  inputProps={{
                    "aria-labelledby": `select-all`,
                  }}
                  style={{
                    padding: "0px",
                  }}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    maxWidth: column.maxWidth,
                    width: column.width,
                  }}
                  align={column.alignment}
                >
                  <Typography
                    variant={TypographyVariants.textSmall}
                    weight={TypographyWeights.bold}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className="table-body">
            {data?.map((row, index) => {
              const isSelected = selectState?.selected?.includes(
                row?.resourceId
              );

              return (
                <TableRow
                  ref={
                    index === data?.length - 1 && nextUrl
                      ? setLastElement
                      : null
                  }
                  hover
                  tabIndex={-1}
                  key={`${row.id}-${index}`}
                >
                  <TableCell>
                    <Checkbox
                      size="small"
                      color="primary"
                      onChange={(event) => {
                        selectState?.onSelect(event, row?.resourceId);
                      }}
                      checked={isSelected}
                      inputProps={{
                        "aria-labelledby": `${index}`,
                      }}
                      style={{
                        padding: "0px",
                      }}
                    />
                  </TableCell>

                  <TableCell align={columns[0].alignment}>
                    <div className={"agent-name-container"}>
                      <div className="flex align-items-center col-gap-8">
                        {row.resourceType === ResourceType.LINK && (
                          <>
                            <LinkIcon />
                            <Typography
                              className="source-name"
                              weight={TypographyWeights.semiBold}
                            >
                              {(row as LinkResource).name ||
                                (row as FileResource).title}
                            </Typography>
                          </>
                        )}
                        {row.resourceType === ResourceType.FILE && (
                          <>
                            <div className="source-type-icon">
                              <FileTypeIcon
                                type={(row as FileResource).file_type}
                              />
                            </div>
                            <Typography
                              className="source-name"
                              weight={TypographyWeights.semiBold}
                            >
                              {(row as FileResource).title}
                            </Typography>
                          </>
                        )}
                      </div>
                    </div>
                  </TableCell>

                  <TableCell align={columns[1].alignment}>
                    <Typography>
                      {formatToHumanStringFromDateTime(
                        new Date(row?.modified),
                        "MMM d, yyyy"
                      )}
                    </Typography>
                  </TableCell>

                  <TableCell align={columns[2].alignment}>
                    {row.resourceType === ResourceType.LINK && (
                      <SourceStatusChip
                        status={(row as LinkResource).render_status}
                      />
                    )}
                    {row.resourceType === ResourceType.FILE && (
                      <SourceStatusChip
                        status={(row as FileResource).pending_sync}
                      />
                    )}
                  </TableCell>

                  <TableCell align={columns[3].alignment}>
                    <div className="flex align-items-center col-gap-16"></div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {nextUrl && pageLoading && (
          <div className="center mt-2 mb-20">
            <CircularProgress size={28} />
          </div>
        )}
      </TableContainer>
    </div>
  );
};

export default SourceTable;
