import { useNavigate } from "react-router-dom";

const useIntegrationsRouteNavigator = (): {
  gotoIntegrationsPage: () => void;
  gotoConnector: (connectorId: string) => void;
  gotoConnection: (connectionId: string) => void;
} => {
  const navigate = useNavigate();

  const gotoIntegrationsPage = (): void => {
    navigate(`/integrations`);
  };

  const gotoConnection = (connectionId: string): void => {
    navigate(`/integrations/connection/${connectionId}`);
  };

  const gotoConnector = (connectorId: string): void => {
    navigate(`/integrations/connector/${connectorId}`);
  };

  return {
    gotoIntegrationsPage,
    gotoConnector,
    gotoConnection,
  };
};

export default useIntegrationsRouteNavigator;
