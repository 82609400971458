import {
  Typography as MuiTypography,
  type TypographyProps,
  type TypographyVariant,
} from "@mui/material";
import clsx from "clsx";
import type React from "react";
import { makeStyles } from "@mui/styles";

export type Variant =
  | TypographyVariant
  | "textXXXl"
  | "textXXl"
  | "textXl"
  | "textLg"
  | "textMd"
  | "textSm"
  | "textTiny";

export interface Props extends Omit<TypographyProps, "variant"> {
  variant?: Variant | "inherit";
  component?: React.ElementType;
  maxLines?: number;
  breakAll?: boolean;
  preWrap?: boolean;
}

const useStyles = makeStyles((theme) => ({
  textXXXl: {
    fontSize: "2rem",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "40px",
    letterSpacing: "-0.5px",
  },
  textXXl: {
    fontSize: "1.5rem",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "200%",
    letterSpacing: "-0.3px",
  },
  textXl: {
    fontSize: "20px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "175%",
    letterSpacing: "-0.2px",
  },
  textLg: {
    fontSize: "1rem",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "175%",
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "150%",
  },
  textMd: {
    fontSize: "0.875rem",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "150%",
  },
  textSm: {
    fontSize: "0.75rem",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "125%",
  },
  textTiny: {
    fontSize: "0.625rem",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "100%",
    letterSpacing: "0.2px",
  },
  maxLines: (props: { maxLines?: number }) => ({
    display: "-webkit-box",
    lineClamp: props.maxLines ? props.maxLines : 1,
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
  }),
  breakAll: {
    wordBreak: "break-all",
  },
  preWrap: {
    whiteSpace: "pre-wrap",
  },
}));

const Typography: React.FC<Props> = ({
  className,
  variant,
  maxLines,
  breakAll,
  preWrap,
  ...otherProps
}) => {
  const classes = useStyles({ maxLines });

  let newClassName: string;
  let component: React.ElementType;

  switch (variant) {
    case "textXXXl":
      newClassName = classes.textXXXl;
      component = "span";
      break;
    case "textXXl":
      newClassName = classes.textXXl;
      component = "span";
      break;
    case "textXl":
      newClassName = classes.textXl;
      component = "span";
      break;
    case "textLg":
      newClassName = classes.textLg;
      component = "span";
      break;
    case "textMd":
      newClassName = classes.textMd;
      component = "span";
      break;
    case "textSm":
      newClassName = classes.textSm;
      component = "span";
      break;
    case "textTiny":
      newClassName = classes.textTiny;
      component = "span";
      break;
    default:
      newClassName = classes.text;
      component = "span";
  }

  let newVariant: TypographyVariant = variant as TypographyVariant;

  if (newClassName) {
    newVariant = "body1";
  }

  return (
    <MuiTypography
      className={clsx(className, newClassName, {
        [classes.maxLines]: !!maxLines,
        [classes.breakAll]: breakAll,
        [classes.preWrap]: preWrap,
      })}
      component={component}
      variant={newVariant}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );
};

export default Typography;
