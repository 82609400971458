import type React from "react";
import { useEffect, useState } from "react";
import { isEmpty, isNil } from "lodash";
import { useSnackbar } from "notistack";
import useStyles from "./ApiListContainer.styles";
import { IAPI } from "types/APIIntegration";
import { getAPIIntegrationListV2 } from "api/workflow/integration";
import Typography, {
  TypographyColors,
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { useParams } from "react-router-dom";
import Table, { TableColumnProps } from "aether/Table";
import { formatToHumanStringFromDateTime } from "utils/dateUtils";
import Chip, { ChipColors, ChipSizes } from "aether/Chip";
import Button from "components/base/Button";
import clsx from "clsx";
import SelectedAPIAction from "./selectedAPI/SelectedAPIAction";
import useAPIIntegrationsRouteNavigator from "hooks/navigation/useAPIIntegrationRouteNavigator";
import useReducerUtilityActions from "../useReducerUtilityActions/useReducerUtilityActions";
import { IAPIAction } from "store/reducers/ActionBuilder/ActionBuilderReducer";
import { Tool } from "../AgentActions.const";
import { ReactComponent as LinkToAPIPageIcon } from "assets/linkToAPIPage.svg";

interface Props {
  tool: Tool | null;
}

const ApiListContainer: React.FC<Props> = ({ tool }) => {
  const classes = useStyles();
  const { botId } = useParams() || {};
  const { gotoAPI, gotoAPIIntegrationListPage } =
    useAPIIntegrationsRouteNavigator();
  const {
    selectedConfigurableAction,
    onActionSelection,
    onChangeSelectedAction,
    onPreview,
    clearSlice,
  } = useReducerUtilityActions();
  const selectedAction = selectedConfigurableAction as IAPIAction;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [apis, setApis] = useState<IAPI[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleAPIPageRedirection = (): void => {
    if (!isNil(botId) && !isNil(selectedAction?._id)) {
      gotoAPI(botId, selectedAction?._id ?? "");
    }
  };

  const getList = (botId: string): void => {
    setLoading(true);
    getAPIIntegrationListV2(botId)
      .then((response) => {
        const apis = response?.apis;
        if (tool?.config?.api_integration_id) {
          const toolAction = apis.filter(
            (action) => action._id === tool?.config?.api_integration_id
          )?.[0];
          toolAction.extracted_variables = tool?.config?.input_params ?? [];
          setApis(toolAction ? [toolAction] : []);
          onActionSelection({
            ...toolAction,
            actionDisplayName: tool?.name ?? "",
            actionInstructions: tool?.config?.instructions ?? "",
          });
        } else {
          setApis(apis ?? []);
        }
      })
      .catch((error: any) => {
        setError(true);
        enqueueSnackbar(`Something went wrong: ${error}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
        setError(false);
      });
  };

  useEffect(() => {
    if (botId) {
      getList(botId);
    }
    return clearSlice;
  }, []);

  const handleGotoAPIPage = (): void => {
    if (!botId) return;
    gotoAPIIntegrationListPage(botId);
  };

  const handleUpdateDisplayName = (name: string): void => {
    onActionSelection({
      ...selectedAction,
      actionDisplayName: name,
    });
  };

  const handleUpdateInstructions = (instructions: string): void => {
    onActionSelection({
      ...selectedAction,
      actionInstructions: instructions,
    });
  };
  const APITable = Table<IAPI>();
  const APIColumns: Array<TableColumnProps<IAPI>> = [
    {
      id: "name",
      label: "Name and description",
      width: "40%",
      render: (row: IAPI) => (
        <>
          <div className="flex justify-content-start align-items-center col-gap-8">
            <Typography
              weight={TypographyWeights.bold}
              variant={TypographyVariants.text}
              renderInLines={1}
              className="mr-2"
            >
              {row.name}
            </Typography>
            <Chip label={row.http_method} size={ChipSizes.xsmall} />
          </div>
          <Typography
            weight={TypographyWeights.medium}
            variant={TypographyVariants.textSmall}
            color={TypographyColors.subtle}
            renderInLines={1}
          >
            {row.description}
          </Typography>
        </>
      ),
    },
    {
      id: "editedOn",
      label: "Last updated",
      width: "20%",
      render: (row: IAPI) => (
        <Typography weight={TypographyWeights.semiBold}>
          {formatToHumanStringFromDateTime(
            new Date(row?.modified),
            "MMM d yyyy"
          )}
        </Typography>
      ),
    },
    {
      id: "status",
      label: "Run Status",
      width: "20%",
      render: (row: IAPI) => (
        <Chip
          label={"Active"}
          color={ChipColors.success}
          size={ChipSizes.large}
        />
      ),
    },
    {
      id: "actions",
      label: "",
      width: "20%",
      align: "right",
      render: (row: IAPI) => (
        <div className={clsx("row-action-container", classes.actionsContainer)}>
          <Button
            variant="text"
            color="secondary"
            size="x-small"
            onClick={() => {
              onPreview(row);
            }}
          >
            Preview
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="x-small"
            onClick={() => {
              onActionSelection(row);
            }}
          >
            Select
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <Typography
          variant={TypographyVariants.textTiny}
          weight={TypographyWeights.bold}
          color={TypographyColors.subtle}
        >
          Step 2/2
        </Typography>
        <Typography
          weight={TypographyWeights.bold}
          variant={TypographyVariants.textXL}
          className="mb-4"
        >
          Select an API
        </Typography>
        {isNil(selectedAction) ? (
          <>
            <div className={classes.apiTable}>
              <APITable
                columns={APIColumns}
                data={apis}
                loading={loading}
                emptyState={{ empty: isEmpty(apis) }}
                error={error}
              />
            </div>
            <div className={classes.linkToAPIPage}>
              <Typography>Didn’t find what you were looking for</Typography>
              <Typography
                className={classes.linkText}
                weight={TypographyWeights.bold}
                onClick={handleGotoAPIPage}
              >
                Integration a new API
              </Typography>
              <div className="center" onClick={handleGotoAPIPage}>
                <LinkToAPIPageIcon />
              </div>
            </div>
          </>
        ) : (
          <SelectedAPIAction
            initialName={tool?.name ?? ""}
            initialInstructions={tool?.config?.instructions}
            action={selectedAction}
            onChange={onChangeSelectedAction}
            hideChangeButton={!isNil(tool?.config?.api_integration_id)}
            onMoreInfoAndTest={handleAPIPageRedirection}
            updateActionDisplayName={handleUpdateDisplayName}
            updateActionInstructions={handleUpdateInstructions}
          />
        )}
      </div>
    </>
  );
};

export default ApiListContainer;
