import { useContext, useEffect, useState } from "react";
import AssistantContext from "../AssistantContext";
import { TableStateProps } from "aether/TableState";
import Table, { TableColumnProps } from "aether/Table";
import Typography, {
  // TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { formatToHumanStringFromDateTime } from "utils/dateUtils";
// import Chip from "aether/Chip";
import Button, { ButtonSizes } from "aether/Button";
import { ReactComponent as KnowledgeEmptyLogo } from "assets/knowledge-empty-logo.svg";
import { ReactComponent as FilePlusIcon } from "assets/file-plus-icon.svg";
import { ReactComponent as KnowledgeBaseIcon } from "assets/knowledge-base-icon.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/external-link-icon.svg";
import { useAssistantKnowlegdeStyles as useStyles } from "./styles";
import { formatResourcesForTable } from "../utils";
import {
  ResourceType,
  FileResource,
  LinkResource,
  SourcesTableData,
} from "pages/KnowledgeBase/types";
import { ReactComponent as LinkIcon } from "assets/link-icon.svg";
import FileTypeIcon from "components/shared/FileTypeIcon";
import DeleteSourceModal from "./DeleteSourceModal";
import SourceStatusChip from "components/KnowledgeSource/SourceStatus";

interface Props {
  resources: Array<FileResource | LinkResource>;
  handleOpenSelectSource: () => void;
  onUpdateResources: (resources: Array<FileResource | LinkResource>) => void;
}

const KnowledgeSource: React.FC<Props> = ({
  resources,
  handleOpenSelectSource,
  onUpdateResources,
}) => {
  const classes = useStyles();

  const assistantContext = useContext(AssistantContext);
  const { assistantId, assistant } = assistantContext;

  const { kb_metadata } = assistant;
  const { crawl_resources_count, knowledge_units_count } = kb_metadata;

  const [globalSourcesAvailable, setGlobalSourcesAvailable] = useState(false);
  const [tableData, setTableData] = useState<SourcesTableData[]>([]);
  const [tableState, setTableState] = useState<TableStateProps>({
    loading: false,
    empty: true,
    error: false,
  });

  useEffect(() => {
    setTableState({
      loading: true,
      empty: false,
      error: false,
    });

    if (crawl_resources_count > 0 || knowledge_units_count > 0) {
      setGlobalSourcesAvailable(true);
      if (resources && resources?.length > 0) {
        const _tableData = formatResourcesForTable(resources);
        setTableData(_tableData);
        setTableState({
          loading: false,
          empty: false,
          error: false,
        });
      } else {
        setTableState({
          loading: false,
          empty: true,
          error: false,
        });
      }
    } else {
      setGlobalSourcesAvailable(false);
      setTableState({
        loading: false,
        empty: true,
        error: false,
      });
    }
  }, [crawl_resources_count, knowledge_units_count, resources]);

  const handleClickEmptyStateButton = (): void => {
    if (globalSourcesAvailable) {
      handleOpenSelectSource();
      return;
    }

    window.open(`/knowledge-base`, "_blank");
  };

  const handleOnDeleteSource = (resourceId: string): void => {
    const updatedResources = resources.filter(
      (resource) => resource.resourceId !== resourceId
    );

    onUpdateResources(updatedResources);
  };

  const emptyState = globalSourcesAvailable
    ? {
        message: "No sources added to this Agent",
        description:
          "To equip this agent with knowledge, please select and add a source from your core knowledge base",
      }
    : {
        message: "No sources available to add",
        description:
          "To proceed, please add a knowledge source. Once added, you can select it for this agent.",
      };

  const EmptyStateButton = globalSourcesAvailable ? (
    <Button
      size={ButtonSizes.xsmall}
      startIcon={<FilePlusIcon />}
      onClick={handleClickEmptyStateButton}
    >
      Add knowledge
    </Button>
  ) : (
    <Button
      size={ButtonSizes.xsmall}
      startIcon={<KnowledgeBaseIcon />}
      endIcon={<ExternalLinkIcon />}
      onClick={handleClickEmptyStateButton}
    >
      View knowledge base
    </Button>
  );

  const SourcesTable = Table<SourcesTableData>();
  const sourceColumns: Array<TableColumnProps<SourcesTableData>> = [
    {
      id: "name",
      label: "Name",
      width: "40%",
      render: (row: SourcesTableData) => (
        <div className={"agent-name-container"}>
          <div className="flex align-items-center col-gap-8">
            {row.resourceType === ResourceType.LINK && <LinkIcon />}
            {row.resourceType === ResourceType.FILE && (
              <FileTypeIcon type={row.type} />
            )}
            <Typography
              className="source-name"
              weight={TypographyWeights.semiBold}
            >
              {row.name}
            </Typography>
          </div>
        </div>
      ),
    },
    {
      id: "uploadedOn",
      label: "Date uploaded",
      width: "20%",
      render: (row: SourcesTableData) => (
        <Typography>
          {formatToHumanStringFromDateTime(
            new Date(row?.uploadedOn),
            "MMM d, yyyy"
          )}
        </Typography>
      ),
    },
    {
      id: "status",
      label: "Status",
      width: "20%",
      render: (row: SourcesTableData) => (
        <SourceStatusChip status={row?.status} />
      ),
    },
    {
      id: "actions",
      label: "",
      width: "20%",
      align: "right",
      render: (row: SourcesTableData) => (
        <div className={"row-action-container"}>
          <DeleteSourceModal
            assistantId={assistantId}
            resources={resources}
            resourceId={row?.resourceId}
            onDeleteCb={handleOnDeleteSource}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={classes.assistantKnowledgeContainer}>
      <SourcesTable
        columns={sourceColumns}
        data={tableData}
        loading={tableState.loading}
        emptyState={{
          empty: tableState.empty,
          emptyLogo: <KnowledgeEmptyLogo />,
          message: emptyState.message,
          description: emptyState.description,
          button: EmptyStateButton,
        }}
        error={tableState.error}
      />
    </div>
  );
};

export default KnowledgeSource;
