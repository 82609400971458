import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100vh ",
    overflow: "hidden",
  },
  ContentAndRightPanelContainer: {
    height: "calc(100vh - 80px)",
    marginTop: "2px",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  contentContainer: {
    height: "100%",
    overflow: "auto",
    padding: "32px",
    width: "70%",
  },
}));

export default useStyles;
