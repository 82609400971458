import { type DropdownItem } from "components/base/NestedDropdown/NestedDropdown";
import { type APIGroup } from "types/APIIntegration";

export const getDropdownItems = (sampleAPIs: APIGroup[]): DropdownItem[] => {
  return sampleAPIs.map((group, index) => ({
    id: group.groupName, // Using group name as an ID, change if needed for uniqueness
    title: group.groupName + index,
    subtitle: group.groupDescription,
    children: group.apiList.map((api) => ({
      id: api.id,
      title: api.name,
      subtitle: api.description,
    })),
  }));
};
