import devConfig from "./dev.config";
import stagexConfig from "./stagex.config";
import prodConfig from "./prod.config";

export const ENV_CONSTANT = {
  DEV: "DEV",
  STAGE: "STAGE",
  STAGEX: "STAGEX",
  PROD: "PRODUCTION",
};

export const getAppConfig = (): Record<string, any> => {
  const ENV = process.env.REACT_APP_ENVIRONMENT ?? "";

  switch (ENV) {
    case ENV_CONSTANT.DEV:
      return devConfig;
    case ENV_CONSTANT.STAGEX:
      return stagexConfig;
    case ENV_CONSTANT.PROD:
      return prodConfig;
    default:
      return devConfig;
  }
};

export default getAppConfig();
