import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    maxHeight: "300px",
    width: "224px",
    bottom: "72px",
    right: "32px",
    marginBottom: "16px",
    position: "fixed",

    padding: "12px",
    alignItems: "flex-start",
    borderRadius: "8px 8px 0px 8px",
    background: "#FFF",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",

    color: "var(--Full-palette-Neutral-100, #333)",
    fontFamily: '"SF Pro Text"',
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
  },
}));

interface Props {
  isOpen: boolean;
  message: string;
}

const NudgePopup: React.FC<Props> = ({ isOpen, message }) => {
  const classes = useStyles();

  if (!isOpen) return null;
  return <div className={classes.container}>{message}</div>;
};

export default NudgePopup;
