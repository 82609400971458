import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useAssistantBuilderStyles = makeStyles((theme: Theme) => ({
  assistantBuilderContainer: {
    width: "100%",
    height: "100vh",
  },
  assistantBuilderMain: {},
  assistantBuilderContent: {
    // padding: "16px 32px",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // overflowX: "auto",
    // height: "100%",
    // width: "100%",
    // [theme.breakpoints.down("lg")]: {
    //   alignItems: "flex-start",
    // },
  },
  assistantCompositionContainer: {
    width: "100%",
    display: "flex",
    rowGap: "32px",
    maxWidth: "1368px",
    minWidth: "1080px",
    marginTop: "16px",
    flexDirection: "column",
  },
}));

export const useAssistantAgentsStyles = makeStyles((theme: Theme) => ({
  assistantAgentsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",

    "& .agent-name-container": {
      "& .agent-name": {
        alignSelf: "flex-start",
        cursor: "pointer",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
  },
}));

export const useChatboWidgetStyles = makeStyles((theme: Theme) => ({
  WidgetContainer: {
    backgroundColor: "transparent",
    position: "fixed",
    right: 0,
    bottom: 0,
    zIndex: "1000",
    justifyContent: "flex-end",
  },
}));
