import { ReactComponent as BotIcon } from "assets/bot.svg";
import { ReactComponent as BotTreeIcon } from "assets/bot-tree-icon.svg";
import { ReactComponent as FAQSuggestionsIcon } from "assets/faq-suggestions-icon.svg";
import { ReactComponent as PuzzleIcon } from "assets/puzzle-piece-02 (4).svg";
import { ReactComponent as KnowledgeBaseIcon } from "assets/knowledge-base-icon.svg";
import { ReactComponent as AnalyticsIcon } from "assets/analytics.svg";

const NavigationItems = [
  {
    key: "assistant",
    path: "assistant",
    title: "Agents",
    icon: BotIcon,
    enable: true,
  },
  {
    key: "bot-tree",
    path: "bot-tree",
    title: "Bot Tree",
    icon: BotTreeIcon,
    enable: false,
  },
  {
    key: "faq-suggestions",
    path: "faq-suggestions",
    title: "FAQ Suggestions",
    icon: FAQSuggestionsIcon,
    enable: false,
  },
  {
    key: "workflow-builder",
    path: "workflow-builder",
    title: "Workflow Builder",
    icon: BotTreeIcon,
    enable: false,
  },
  {
    key: "connectors-flow",
    path: "integrations",
    title: "Integrations",
    icon: PuzzleIcon,
    enable: true,
  },
  {
    key: "knowledge-base",
    path: "knowledge-base",
    title: "Knowledge",
    icon: KnowledgeBaseIcon,
    enable: true,
  },
  {
    key: "analytics",
    path: "analytics",
    title: "Analytics",
    icon: AnalyticsIcon,
    enable: true,
  },
];

export default NavigationItems;
