import { IconButton } from "@mui/material";
import { useAssistantHeaderStyles as useStyles } from "../styles";
import { ReactComponent as ArrowLeft } from "assets/arrow-left.svg";
import useRouteNavigator from "hooks/navigation/useRouteNavigator";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import AssistantNavigationBar from "./AssistantNavigation";

interface Props {
  children: React.ReactNode;
  title: string;
  description?: string;
  isVoiceAssistant?: boolean;
}

const AssistantHeader: React.FC<Props> = ({
  children,
  title,
  description = "",
  isVoiceAssistant = false,
}) => {
  const classes = useStyles();
  const { gotoBotDashboard } = useRouteNavigator();

  const handleBackClick = (): void => {
    gotoBotDashboard();
  };

  return (
    <div className={classes.assistantHeaderContainer}>
      <div className={classes.assistantDetailsContainer}>
        <div className="center">
          <IconButton className="back-button" onClick={handleBackClick}>
            <ArrowLeft />
          </IconButton>
        </div>

        <div className="assistant-details-container flex flex-col justify-content-center">
          <Typography
            variant={TypographyVariants.textXL}
            weight={TypographyWeights.bold}
            renderInLines={1}
          >
            {title}
          </Typography>
          {description && (
            <Typography weight={TypographyWeights.medium} renderInLines={1}>
              {description}
            </Typography>
          )}
        </div>
      </div>

      <div className={classes.assistantNavigationContainer}>
        <AssistantNavigationBar hideDeploymentTab={isVoiceAssistant} />
      </div>

      <div className={classes.assistantActionContainer}>{children}</div>
    </div>
  );
};

export default AssistantHeader;
