import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { ReactComponent as HeaderIcon } from "assets/analyticsHeader.svg";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    width: "100%",
    padding: "24px 32px",
    gap: "8px",
    borderBottom: "1px solid var(--border-default, #E1DEDA)",
    background: "#FFF",
    flexShrink: 0,
    [theme.breakpoints.down("lg")]: {
      width: "1128px",
      overflowX: "scroll",
    },
  },
}));

const AnalyticsHeader: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <HeaderIcon />
      <Typography
        weight={TypographyWeights.bold}
        variant={TypographyVariants.textXXL}
      >
        Analytics
      </Typography>
    </div>
  );
};

export default AnalyticsHeader;
