import Button, { ButtonColors, ButtonSizes } from "aether/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useState } from "react";
import SelectAssistantModal from "./SelectAssistantModal";
import { useSnackbar } from "notistack";
import useRouteNavigator from "hooks/navigation/useRouteNavigator";
import { createAssistant } from "api/assistant/assistant";
import { AssistantType } from "pages/Assistant/types";
const create_assistant_button_label = "Create agent";

const CreateAssistantModal: React.FC = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { gotoAssistant } = useRouteNavigator();

  const handleCreateAssistant = async (
    fields: {
      name: string;
      description: string;
    },
    type: AssistantType
  ): Promise<void> => {
    setLoading(true);

    try {
      const response = await createAssistant({
        name: fields.name,
        description: fields.description,
        enabled_channels: [type],
      });
      const { data } = response;
      const { assistant } = data;

      enqueueSnackbar("Assistant created", {
        variant: "success",
      });

      gotoAssistant(assistant._id);
    } catch (err: any) {
      enqueueSnackbar(`Some error occurred. Please try again ${err}`, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="pt-32">
      <Button
        size={ButtonSizes.large}
        color={ButtonColors.primary}
        endIcon={<ArrowForwardIcon />}
        onClick={() => {
          setOpenCreateModal(true);
        }}
      >
        {create_assistant_button_label}
      </Button>

      <SelectAssistantModal
        open={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false);
        }}
        onSubmit={handleCreateAssistant}
        loading={loading}
      />
    </div>
  );
};

export default CreateAssistantModal;
