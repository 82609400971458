/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer } from "../util";
import { ReduxBotActions } from "store/reduxActions/ReduxBotActions";

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
};

const botList = createReducer(initialState, {
  [ReduxBotActions.FETCH_BOT_LIST_LOADING]: (state: any, action: any) => {
    return {
      ...state,
      bot_list: {
        ...state.bot_list,
        isLoading: true,
        isError: false,
      },
    };
  },
  [ReduxBotActions.FETCH_BOT_LIST_SUCCESS]: (state: any, action: any) => {
    return {
      ...state,
      bot_list: {
        ...state.bot_list,
        isLoading: false,
        data: action.payload,
      },
    };
  },
  [ReduxBotActions.FETCH_BOT_LIST_ERROR]: (state: any, action: any) => {
    return {
      ...state,
      bot_list: {
        ...state.bot_list,
        isLoading: false,
        isError: true,
      },
    };
  },
  [ReduxBotActions.POST_BOT_SUCCESS]: (state: any, action: any) => {
    return {
      ...state,
      bot_list: {
        ...state.bot_list,
        isLoading: false,
        data: [...state.bot_list.data, action.payload],
      },
    };
  },
  [ReduxBotActions.PUT_BOT_SUCCESS]: (state: any, action: any) => {
    const itemIdToUpdate = action.payload.id;
    const currBotList = [...state.bot_list.data];

    const index = currBotList.findIndex(
      (currBot) => currBot.id === itemIdToUpdate
    );

    return {
      ...state,
      bot_list: {
        ...state.bot_list,
        isLoading: false,
        data: currBotList
          .slice(0, index)
          .concat([action.payload])
          .concat(currBotList.slice(index + 1)),
      },
    };
  },
  [ReduxBotActions.DELETE_BOT_SUCCESS]: (state: any, action: any) => {
    const itemIdToDelete = action.payload.id;
    const currBotList = [...state.bot_list.data];

    const index = currBotList.findIndex(
      (currBot) => currBot.id === itemIdToDelete
    );

    return {
      ...state,
      bot_list: {
        ...state.bot_list,
        isLoading: false,
        data: currBotList.slice(0, index).concat(currBotList.slice(index + 1)),
      },
    };
  },
});

export default botList;
