import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "24px",
    width: "67%",
    minHeight: "250px",
    overflow: "hidden",
    borderRadius: "16px",
    background: "#FFF",
    boxShadow:
      "0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  },
  title: {
    marginBottom: "24px",
  },
  content: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    gap: "24px",
    maxHeight: "430px",
    overflow: "auto",
    height: "calc(100% - 52px)",
  },
  emptyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

export default useStyles;
