import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useAssistantKnowlegdeStyles = makeStyles((theme: Theme) => ({
  assistantKnowledgeContainer: {
    width: "100%",
  },
}));

export const useKnowledgeSourceContainer = makeStyles((theme: Theme) => ({
  knowledgeSourceContainer: {
    width: "100%",

    "& .agent-name-container": {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
  },
}));
