export const countryCodeMap: Record<string, string> = {
  "+1": "us", // United States
  "+44": "gb", // United Kingdom
  "+91": "in", // India
  "+81": "jp", // Japan
  "+61": "au", // Australia
  "+49": "de", // Germany
  "+33": "fr", // France
  "+39": "it", // Italy
  "+34": "es", // Spain
  "+86": "cn", // China
  "+7": "ru", // Russia
  "+55": "br", // Brazil
  "+27": "za", // South Africa
  "+82": "kr", // South Korea
  "+65": "sg", // Singapore
  "+63": "ph", // Philippines
  "+41": "ch", // Switzerland
  "+351": "pt", // Portugal
  "+62": "id", // Indonesia
  "+353": "ie", // Ireland
  "+60": "my", // Malaysia
  "+64": "nz", // New Zealand
  "+421": "sk", // Slovakia
  "+420": "cz", // Czech Republic
  "+30": "gr", // Greece
  "+372": "ee", // Estonia
  "+370": "lt", // Lithuania
  "+371": "lv", // Latvia
  "+95": "mm", // Myanmar
  "+380": "ua", // Ukraine
};

export const countryCodePatterns = {
  "1": /^1/, // USA/Canada
  "7": /^7/, // Russia/Kazakhstan
  "20": /^20/, // Egypt
  "27": /^27/, // South Africa
  "30": /^30/, // Greece
  "31": /^31/, // Netherlands
  "32": /^32/, // Belgium
  "33": /^33/, // France
  "34": /^34/, // Spain
  "36": /^36/, // Hungary
  "39": /^39/, // Italy
  "40": /^40/, // Romania
  "41": /^41/, // Switzerland
  "43": /^43/, // Austria
  "44": /^44/, // United Kingdom
  "45": /^45/, // Denmark
  "46": /^46/, // Sweden
  "47": /^47/, // Norway
  "48": /^48/, // Poland
  "49": /^49/, // Germany
  "51": /^51/, // Peru
  "52": /^52/, // Mexico
  "53": /^53/, // Cuba
  "54": /^54/, // Argentina
  "55": /^55/, // Brazil
  "56": /^56/, // Chile
  "57": /^57/, // Colombia
  "58": /^58/, // Venezuela
  "60": /^60/, // Malaysia
  "61": /^61/, // Australia
  "62": /^62/, // Indonesia
  "63": /^63/, // Philippines
  "64": /^64/, // New Zealand
  "65": /^65/, // Singapore
  "66": /^66/, // Thailand
  "81": /^81/, // Japan
  "82": /^82/, // South Korea
  "84": /^84/, // Vietnam
  "86": /^86/, // China
  "90": /^90/, // Turkey
  "91": /^91/, // India
  "92": /^92/, // Pakistan
  "93": /^93/, // Afghanistan
  "94": /^94/, // Sri Lanka
  "95": /^95/, // Myanmar
  "98": /^98/, // Iran
  "212": /^212/, // Morocco
  "213": /^213/, // Algeria
  "216": /^216/, // Tunisia
  "218": /^218/, // Libya
  "220": /^220/, // Gambia
  "221": /^221/, // Senegal
  "233": /^233/, // Ghana
  "234": /^234/, // Nigeria
  "251": /^251/, // Ethiopia
  "254": /^254/, // Kenya
  "255": /^255/, // Tanzania
  "256": /^256/, // Uganda
  "260": /^260/, // Zambia
  "261": /^261/, // Madagascar
  "263": /^263/, // Zimbabwe
  "264": /^264/, // Namibia
  "265": /^265/, // Malawi
  "266": /^266/, // Lesotho
  "267": /^267/, // Botswana
  "351": /^351/, // Portugal
  "352": /^352/, // Luxembourg
  "353": /^353/, // Ireland
  "354": /^354/, // Iceland
  "355": /^355/, // Albania
  "356": /^356/, // Malta
  "357": /^357/, // Cyprus
  "358": /^358/, // Finland
  "359": /^359/, // Bulgaria
  "370": /^370/, // Lithuania
  "371": /^371/, // Latvia
  "372": /^372/, // Estonia
  "373": /^373/, // Moldova
  "374": /^374/, // Armenia
  "375": /^375/, // Belarus
  "376": /^376/, // Andorra
  "377": /^377/, // Monaco
  "380": /^380/, // Ukraine
  "381": /^381/, // Serbia
  "382": /^382/, // Montenegro
  "385": /^385/, // Croatia
  "386": /^386/, // Slovenia
  "387": /^387/, // Bosnia and Herzegovina
  "420": /^420/, // Czech Republic
  "421": /^421/, // Slovakia
  "423": /^423/, // Liechtenstein
  "500": /^500/, // Falkland Islands
  "501": /^501/, // Belize
  "502": /^502/, // Guatemala
  "503": /^503/, // El Salvador
  "504": /^504/, // Honduras
  "505": /^505/, // Nicaragua
  "506": /^506/, // Costa Rica
  "507": /^507/, // Panama
  "509": /^509/, // Haiti
  "593": /^593/, // Ecuador
  "595": /^595/, // Paraguay
  "598": /^598/, // Uruguay
  "670": /^670/, // East Timor
  "673": /^673/, // Brunei
  "674": /^674/, // Nauru
  "675": /^675/, // Papua New Guinea
  "676": /^676/, // Tonga
  "677": /^677/, // Solomon Islands
  "678": /^678/, // Vanuatu
  "679": /^679/, // Fiji
  "680": /^680/, // Palau
  "681": /^681/, // Wallis and Futuna
  "682": /^682/, // Cook Islands
  "683": /^683/, // Niue
  "685": /^685/, // Samoa
  "686": /^686/, // Kiribati
  "687": /^687/, // New Caledonia
  "688": /^688/, // Tuvalu
  "689": /^689/, // French Polynesia
  "690": /^690/, // Tokelau
  "691": /^691/, // Micronesia
  "692": /^692/, // Marshall Islands
  "850": /^850/, // North Korea
  "852": /^852/, // Hong Kong
  "853": /^853/, // Macau
  "855": /^855/, // Cambodia
  "856": /^856/, // Laos
  "880": /^880/, // Bangladesh
  "886": /^886/, // Taiwan
  "960": /^960/, // Maldives
  "961": /^961/, // Lebanon
  "962": /^962/, // Jordan
  "963": /^963/, // Syria
  "964": /^964/, // Iraq
  "965": /^965/, // Kuwait
  "966": /^966/, // Saudi Arabia
  "967": /^967/, // Yemen
  "968": /^968/, // Oman
  "970": /^970/, // Palestinian Territory
  "971": /^971/, // United Arab Emirates
  "972": /^972/, // Israel
  "973": /^973/, // Bahrain
  "974": /^974/, // Qatar
  "975": /^975/, // Bhutan
  "976": /^976/, // Mongolia
  "977": /^977/, // Nepal
  "992": /^992/, // Tajikistan
  "993": /^993/, // Turkmenistan
  "994": /^994/, // Azerbaijan
  "995": /^995/, // Georgia
  "996": /^996/, // Kyrgyzstan
  "998": /^998/, // Uzbekistan
};
