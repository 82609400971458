import clsx from "clsx";
import { useStyles } from "./style";
import { CircularProgress } from "@mui/material";

const Loading: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.loadingContainer, "center")}>
      <CircularProgress />
    </div>
  );
};

export default Loading;
