import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import Typography from "components/base/Typography";
import useStyles from "./style";
import TableBodyContainer from "./TableBodyContainer";
import { EmptyStateProps } from "aether/TableState";

export interface TableColumnProps<T> {
  id: string;
  label: string;
  width?: string;
  minWidth?: string;
  align?: "left" | "right" | "center";
  format?: (value: number) => string;
  render?: (props: T) => React.ReactNode;
}

export interface TableRowSelectionProps {
  enableSelection: boolean;
  selected: string[];
  onSelect: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface TableProps<RowData> {
  columns: Array<TableColumnProps<RowData>>;
  data: RowData[];
  loading?: boolean;
  emptyState: EmptyStateProps;
  selectState?: TableRowSelectionProps;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  rowClassNames?: string;
  isHeaderHidden?: boolean;
}

// FACTORY GENERATOR COMPONENT
const Table = <RowData,>(): React.FC<TableProps<RowData>> => {
  const TableComponent: React.FC<TableProps<RowData>> = ({
    data,
    columns,
    loading = false,
    emptyState,
    selectState,
    error = false,
    errorMessage = "",
    className = "",
    rowClassNames = "",
    isHeaderHidden = false,
  }) => {
    const classes = useStyles();

    const TableBodyComponent = TableBodyContainer<RowData>();

    return (
      <div className={classes.tableWrapper}>
        <MuiTableContainer className={classes.tableContainer}>
          <MuiTable>
            {!isHeaderHidden && (
              <TableHead className={classes.tableHeadContainer}>
                <TableRow>
                  {selectState?.enableSelection && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        size="small"
                        color="primary"
                        onChange={selectState.onSelectAll}
                        indeterminate={
                          selectState.selected.length > 0 &&
                          selectState.selected.length < data?.length
                        }
                        checked={
                          data?.length > 0 &&
                          selectState.selected.length === data?.length
                        }
                        inputProps={{
                          "aria-labelledby": `select-all`,
                        }}
                      />
                    </TableCell>
                  )}

                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ width: column.width }}
                    >
                      <Typography className="w-bold">{column.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}

            <TableBodyComponent
              data={data}
              columns={columns}
              loading={loading}
              selectState={selectState}
              emptyState={emptyState}
              error={error}
              errorMessage={errorMessage}
              rowClassNames={rowClassNames}
            />
          </MuiTable>
        </MuiTableContainer>
      </div>
    );
  };

  TableComponent.displayName = "Table";

  return TableComponent;
};

export default Table;
