import {
  ActionType,
  type ActionParam,
  type ConnectorActionsConfig,
} from "pages/ActionBuilder/components/AgentActions.const";
import { createReducer } from "../util";
import { ActionBuilderActions } from "store/reduxActions/ActionBuilderActions";
import { type PayloadAction } from "@reduxjs/toolkit";
import { IAPI } from "types/APIIntegration";

interface ConnectorActionsConfigWithConnectionId
  extends ConnectorActionsConfig {
  connectionId: string;
}
export interface IAPIAction extends IAPI {
  actionDisplayName?: string;
  actionInstructions?: string;
}
export interface ActionBuilderState {
  actionType: ActionType | null;
  actionForPreview: ConnectorActionsConfigWithConnectionId | IAPIAction | null;
  selectedConfigurableParam: ActionParam | null;
  selectedConfigurableAction:
    | ConnectorActionsConfigWithConnectionId
    | IAPIAction
    | null;
}

const initialState: ActionBuilderState = {
  actionType: null,
  actionForPreview: null,
  selectedConfigurableParam: null,
  selectedConfigurableAction: null,
};

const ActionBuilderReducer = createReducer(initialState, {
  // Action to set Action type
  [ActionBuilderActions.SET_ACTION_TYPE]: (
    state: ActionBuilderState,
    action: PayloadAction<ActionType>
  ) => {
    return {
      ...state,
      actionType: action.payload,
    };
  },
  // Action to set Action for Preview
  [ActionBuilderActions.SET_PREVIEW_ACTION]: (
    state: ActionBuilderState,
    action: PayloadAction<ConnectorActionsConfigWithConnectionId | IAPIAction>
  ) => {
    return {
      ...state,
      actionForPreview: action.payload,
    };
  },
  // Clearing PreviewAction
  [ActionBuilderActions.CLEAR_PREVIEW_ACTION]: (
    state: ActionBuilderState
  ): ActionBuilderState => {
    return {
      ...state,
      actionForPreview: null,
    };
  },
  // Action to set Param for select and configure
  [ActionBuilderActions.SET_SELECTED_CONFIGURABLE_PARAM]: (
    state: ActionBuilderState,
    action: PayloadAction<ActionParam>
  ) => {
    return {
      ...state,
      selectedConfigurableParam: action.payload,
    };
  },
  // Clearing Selected param to configure
  [ActionBuilderActions.CLEAR_SELECTED_CONFIGURABLE_PARAM]: (
    state: ActionBuilderState
  ): ActionBuilderState => {
    return {
      ...state,
      selectedConfigurableParam: null,
    };
  },
  // Action to set Action for select and configure
  [ActionBuilderActions.SET_SELECTED_CONFIGURABLE_ACTION]: (
    state: ActionBuilderState,
    action: PayloadAction<ConnectorActionsConfigWithConnectionId | IAPIAction>
  ) => {
    return {
      ...state,
      selectedConfigurableAction: action.payload,
    };
  },
  // Clearing Selected action to configure
  [ActionBuilderActions.CLEAR_SELECTED_CONFIGURABLE_ACTION]: (
    state: ActionBuilderState
  ): ActionBuilderState => {
    return {
      ...state,
      selectedConfigurableAction: null,
    };
  },
  [ActionBuilderActions.CLEAR_ALL_ACTIONS_AND_PARAMS]: (
    state: ActionBuilderState
  ): ActionBuilderState => {
    return {
      ...state,
      actionForPreview: null,
      selectedConfigurableParam: null,
      selectedConfigurableAction: null,
    };
  },
  [ActionBuilderActions.CLEAR_SLICE]: (
    state: ActionBuilderState
  ): ActionBuilderState => {
    return initialState;
  },
});

export default ActionBuilderReducer;
