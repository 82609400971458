import type React from "react";
import useStyles from "./configureHeader.styles";
import Chip, { ChipColors, ChipSizes } from "aether/Chip";
import { isEmpty } from "lodash";
import IconButton from "aether/IconButton";
import { ReactComponent as PuzzleIcon } from "assets/puzzle-piece-02-black.svg";
import { ReactComponent as CheckIcon } from "assets/check.svg";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";

interface ConfigureHeaderProps {
  icon: string;
  name: string;
  description: string;
  showTag?: boolean;
}

const ConfigureHeader: React.FC<ConfigureHeaderProps> = ({
  icon,
  name,
  description,
  showTag = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {!isEmpty(icon) ? (
        <img src={icon} alt={name} className={classes.image} />
      ) : (
        <IconButton
          variant="contained"
          color="custom"
          customColors={{ backgroundColor: "#F3F2F0" }}
        >
          <PuzzleIcon />
        </IconButton>
      )}
      <div className={classes.nameDescContainer}>
        <div className={classes.titleTagContainer}>
          <Typography
            variant={TypographyVariants.textXXXL}
            weight={TypographyWeights.bold}
          >
            {name}
          </Typography>
          {showTag && (
            <Chip
              startIcon={<CheckIcon />}
              label={"Integrated"}
              color={ChipColors.success}
              size={ChipSizes.small}
            />
          )}
        </div>
        <Typography
          variant={TypographyVariants.textLarge}
          weight={TypographyWeights.medium}
        >
          {description}
        </Typography>
      </div>
    </div>
  );
};

export default ConfigureHeader;
