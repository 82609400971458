import type React from "react";
import { useState, useRef, useEffect } from "react";
import {
  InputBase,
  Popover,
  MenuItem,
  Divider,
  IconButton,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useStyles } from "./NestedDropdown.styles";
import { ReactComponent as EllipseIcon } from "assets/Ellipse 703.svg";
import StickeyFooter from "./StickeyFooter";

export interface DropdownItem {
  id: string;
  title: string;
  subtitle?: string;
  children?: DropdownItem[];
}

interface NestedDropdownProps {
  data: DropdownItem[];
  value?: string;
  onSelect: (item: DropdownItem) => void;
  placeholder?: string;
  preInputIcon?: React.ReactNode;
  showSearchInputBox?: boolean;
  searchPlaceholder?: string;
  childCountLabel?: string;
  showChildCount?: boolean;
  footerConfig?: {
    icon: React.ReactNode;
    message: string;
    buttonConfig: {
      buttonLabel: string;
      onClickFooterButton: () => void;
      preFixIcon: JSX.Element;
    };
  };
}

const NestedDropdown: React.FC<NestedDropdownProps> = ({
  data,
  value,
  onSelect,
  placeholder = "Choose Option",
  preInputIcon,
  showSearchInputBox = false,
  searchPlaceholder = "Search",
  childCountLabel = "Values",
  showChildCount = true,
  footerConfig,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openChild, setOpenChild] = useState<string | null>(null);
  const [popoverHeight, setPopoverHeight] = useState<number>(0);
  const inputRef = useRef<HTMLElement | null>(null);
  const classes = useStyles({ width: anchorEl?.clientWidth });
  const { buttonConfig } = footerConfig ?? {};
  // Calculate the popover height based on screen height and position of the input box
  useEffect(() => {
    if (inputRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      const remainingHeight = screenHeight - inputRect.bottom - 20; // 20px padding from bottom
      setPopoverHeight(Math.max(remainingHeight, 100)); // Ensure it has a minimum height
    }
  }, [anchorEl]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setOpenChild(null);
  };

  const handleParentClick = (parentId: string): void => {
    setOpenChild(openChild === parentId ? null : parentId);
  };

  const handleChildSelect = (child: DropdownItem): void => {
    onSelect(child);
    handleClose();
  };

  return (
    <div>
      <InputBase
        ref={inputRef}
        onClick={handleOpen}
        value={value ?? ""}
        placeholder={placeholder}
        className={classes.inputBase}
        readOnly
        startAdornment={
          preInputIcon && (
            <div className={classes.preInputIcon}>{preInputIcon}</div>
          )
        }
        endAdornment={anchorEl ? <ExpandLess /> : <ExpandMore />}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom", // Position it below the input
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.popover }}
        PaperProps={{
          style: {
            maxHeight: popoverHeight,
            height: popoverHeight,
            overflowY: "auto", // Allow scrolling of content
          },
        }}
      >
        <div className={classes.dropdownContainer}>
          {showSearchInputBox && (
            <div className={classes.searchInputContainer}>
              <InputBase
                placeholder={searchPlaceholder}
                className={classes.inputBase}
                startAdornment={
                  preInputIcon && (
                    <div className={classes.preInputIcon}>{preInputIcon}</div>
                  )
                }
              />
            </div>
          )}
          <div className={classes.scrollableContent}>
            {data.map((parent) => (
              <div key={parent.id}>
                <div className={classes.itemContainer}>
                  <MenuItem
                    onClick={() => {
                      handleParentClick(parent.id);
                    }}
                    className={classes.parentItem}
                  >
                    <div className={classes.contentAndIconContainer}>
                      <div className={classes.contentContainer}>
                        <div className={classes.titleAndCountContainer}>
                          <div className={classes.title}>{parent.title}</div>
                          {showChildCount && (
                            <div className={classes.ellipsisAndCount}>
                              <IconButton>
                                <EllipseIcon />
                              </IconButton>

                              <div className={classes.childCount}>
                                {parent.children
                                  ? `${parent.children.length} ${childCountLabel}`
                                  : ""}
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          {parent?.subtitle && (
                            <div className={classes.subtitle}>
                              {parent.subtitle}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={classes.expandIconContainer}>
                        {openChild === parent.id ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </div>
                    </div>
                  </MenuItem>

                  {openChild === parent.id &&
                    parent.children?.map((child) => (
                      <MenuItem
                        key={child.id}
                        onClick={() => {
                          handleChildSelect(child);
                        }}
                        className={classes.childItem}
                      >
                        <div className={classes.childContent}>
                          <div className={classes.title}>{child.title}</div>
                          <div className={classes.subtitle}>
                            {child.subtitle}
                          </div>
                        </div>
                      </MenuItem>
                    ))}
                </div>
                <Divider />
              </div>
            ))}
          </div>
          {footerConfig && (
            <StickeyFooter
              icon={footerConfig?.icon}
              message={footerConfig?.message}
              buttonConfig={{
                buttonLabel: buttonConfig?.buttonLabel,
                onClickFooterButton: () => {
                  handleClose();
                  buttonConfig?.onClickFooterButton();
                },
                preFixIcon: buttonConfig?.preFixIcon,
              }}
            />
          )}
        </div>
      </Popover>
    </div>
  );
};

export default NestedDropdown;
