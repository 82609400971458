import React from "react";
import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Custom styles for the tooltip
const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    backgroundColor: "var(--Base-White, #FFF)",
    borderRadius: "4px",
    boxShadow: "0px 0px 8px rgba(16, 24, 40, 0.1)",
    padding: "4px 8px",
    color: "#333",
    "& .MuiTooltip-arrow": {
      color: "var(--Base-White, #FFF)",
    },
  },
}));

// Define a custom type for the placement
export type TooltipPlacementType =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";

interface TooltipPropsExtended extends TooltipProps {
  title: string;
  placement?: TooltipPlacementType; // Use the custom TooltipPlacementType
}

const Tooltip: React.FC<TooltipPropsExtended> = ({
  title,
  placement = "bottom",
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <MuiTooltip
      title={title}
      arrow
      placement={placement}
      classes={{ tooltip: classes.tooltip }}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
