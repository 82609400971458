import { makeStyles } from "@mui/styles";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    gap: "38px",
    marginTop: "16px",
  },
  leftSection: {
    display: "flex",
    flexDirection: "column",
    gap: "28px",
  },
  bottomLeft: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  rightSection: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
}));

const BarAndDonutTileVisualizationinSyncShimmer: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {/* Left section - Donut Chart Placeholder */}
      <div className={classes.leftSection}>
        <Skeleton
          variant="circular"
          animation="wave"
          width={188}
          height={188}
          key={"inSyncShimmer"}
        />
        <div className={classes.bottomLeft}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={190}
            height={25}
            key={"inSyncShimmer"}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            width={156}
            height={25}
            key={"inSyncShimmer"}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            width={174}
            height={25}
            key={"inSyncShimmer"}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            width={129}
            height={25}
            key={"inSyncShimmer"}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            width={226}
            height={25}
            key={"inSyncShimmer"}
          />
        </div>
      </div>

      {/* Right section - Bar Chart Placeholder */}
      <div className={classes.rightSection}>
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={406}
          key={"inSyncShimmer"}
        />
      </div>
    </div>
  );
};

export default BarAndDonutTileVisualizationinSyncShimmer;
