import type React from "react";
import { Dialog, DialogProps, IconButton } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/x-close.svg";
import Typography, { TypographyVariants } from "aether/Typography";
import Button, {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
} from "aether/Button";
import { useMemo } from "react";
import { useStyles } from "./Modal.styles";

type Variants = "primary" | "secondary" | "tertiary" | "error";

interface ModalProps extends DialogProps {
  variant?: Variants;
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  title: string;
  children: React.ReactNode;
  confirmStartIcon?: React.ReactElement;
  confirmText?: string;
  confirmEndIcon?: React.ReactElement;
  confirmDisabled?: boolean;
  showConfirmLoading?: boolean;
  // showCancelButton?: boolean;
  cancelStartIcon?: React.ReactElement;
  cancelText?: string;
  cancelEndIcon?: React.ReactElement;
  cancelDisabled?: boolean;
  showCancelLoading?: boolean;
  borderColor?: string;
  footer?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  variant = "primary",
  open,
  onClose,
  onConfirm,
  onCancel,
  title,
  children,
  confirmStartIcon = null,
  confirmText = "Proceed",
  confirmEndIcon = null,
  confirmDisabled = false,
  showConfirmLoading = false,
  // showCancelButton = false,
  cancelStartIcon = null,
  cancelText = null,
  cancelEndIcon = null,
  cancelDisabled = false,
  showCancelLoading = false,
  borderColor = null,
  maxWidth = "sm",
  footer = null,
  ...props
}) => {
  const classes = useStyles({ borderColor });

  const buttonColor = useMemo(() => {
    switch (variant) {
      case "primary":
        return ButtonColors.primary;
      case "secondary":
        return ButtonColors.secondary;
      case "tertiary":
        return ButtonColors.tertiary;
      case "error":
        return ButtonColors.error;
      default:
        return ButtonColors.primary;
    }
  }, [variant]);

  return (
    <Dialog
      className={classes.modalContainer}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={maxWidth}
      {...props}
    >
      <div className="dialog-container flex flex-col">
        <div className="dialog-header flex justify-content-between">
          <Typography
            variant={TypographyVariants.textXL}
            className="w-semi-bold"
          >
            {title}
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        {children}

        <div className="form-action flex col-gap-16">
          <Button
            size={ButtonSizes.small}
            onClick={onConfirm}
            type="submit"
            color={buttonColor}
            disabled={confirmDisabled}
            loading={showConfirmLoading}
            startIcon={confirmStartIcon}
            endIcon={confirmEndIcon}
          >
            {confirmText}
          </Button>
          {cancelText && (
            <Button
              variant={ButtonVariants.outlined}
              size={ButtonSizes.small}
              onClick={onCancel}
              type="submit"
              color={ButtonColors.secondary}
              disabled={cancelDisabled}
              loading={showCancelLoading}
              startIcon={cancelStartIcon}
              endIcon={cancelEndIcon}
            >
              {cancelText}
            </Button>
          )}
          {footer}
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;
