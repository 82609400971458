import { ENV_CONSTANT } from "config";

export const BASE_URL = (): string => {
  const ENV: string = process.env.REACT_APP_ENVIRONMENT ?? "";
  let baseApiUrl = "";

  switch (ENV) {
    case ENV_CONSTANT.STAGE:
      baseApiUrl = "stage";
      break;

    case ENV_CONSTANT.STAGEX:
      baseApiUrl = "stagex";
      break;

    case ENV_CONSTANT.PROD:
      baseApiUrl = "prod";
      break;

    default:
      baseApiUrl = "stagex";
      break;
  }
  return `https://${baseApiUrl}-api.thelevel.ai`;
};

export const BOT_BE_BASE_URL = (): string => {
  const env: string = process.env.REACT_APP_ENVIRONMENT ?? "";
  let baseApiUrl = "";

  switch (env) {
    case ENV_CONSTANT.PROD:
      baseApiUrl = "-bot";
      break;

    default:
      // preprod for stagex, dev, local, preprod envs
      baseApiUrl = "-preprod";
      break;
  }
  return `https://knowledge${baseApiUrl}.thelevel.ai`;
};

export const BOT_BE_SERVER = (): string => {
  const ENV: string = process.env.REACT_APP_ENVIRONMENT ?? "";
  let baseApiUrl = "";

  switch (ENV) {
    case ENV_CONSTANT.PROD:
      baseApiUrl = "prod";
      break;

    default:
      baseApiUrl = "staging";
      break;
  }

  return `https://bot-${baseApiUrl}.thelevel.ai/`;
};
