import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { SectionConfig } from "../types";
import isEmpty from "lodash/isEmpty";
import { makeStyles } from "@mui/styles";
import DashboardSectionGridLayout from "./Section/DashboardSectionGridLayout";
import BrokenCard from "../util/BrokenCard";
import { ReactComponent as InsufficientDataIcon } from "assets/insufficientData.svg";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    padding: "32px",
    height: "calc(100vh - 80px)",
    overflow: "scroll",
  },
  sectionContainer: {
    width: "100%",
    marginTop: "24px",
  },
  sectionTitleDesc: {
    display: "flex",
    gap: "12px",
    marginBottom: "16px",
  },
  image: {
    width: "28px",
    height: "28px",
    flexShrink: 0,
  },
}));

interface Props {
  sections: SectionConfig[];
  insuffecientFilteredData?: boolean;
}

const Dashboard: React.FC<Props> = ({
  sections,
  insuffecientFilteredData = false,
}) => {
  const classes = useStyles();
  const getAllSections = (): JSX.Element[] => {
    return sections.map((section) => {
      return (
        <div key={section.id} className={classes.sectionContainer}>
          <div className={classes.sectionTitleDesc}>
            {!isEmpty(section.icon) && (
              <img
                src={section.icon}
                alt={section.label}
                className={classes.image}
              />
            )}
            <Typography
              weight={TypographyWeights.bold}
              variant={TypographyVariants.textXL}
            >
              {section.label}
            </Typography>
          </div>
          <DashboardSectionGridLayout layouts={section?.layouts} />
        </div>
      );
    });
  };
  if (insuffecientFilteredData) {
    return (
      <BrokenCard
        title={"Not enough data available to generate insights."}
        description={
          "Try adjusting the time range or selecting a different agent to view insights. Alternatively, please check back later as we gather more conversations to generate meaningful insights."
        }
        icon={<InsufficientDataIcon />}
      />
    );
  }
  return <div>{getAllSections()}</div>;
};

export default Dashboard;
