import { LoginActions } from "store/reduxActions/authActions";
import { createReducer } from "../util";
import { type ILoginSlice } from "types/auth.types";
import { type PayloadAction } from "@reduxjs/toolkit";

const initialState: ILoginSlice = {
  loading: true,
  loggedIn: false,
  token: null,
};

const loginReducer = createReducer(initialState, {
  [LoginActions.START_LOGIN]: (state: ILoginSlice, action: PayloadAction) => {
    return {
      ...state,
      loading: true,
      loggedIn: false,
      token: null,
    };
  },
  [LoginActions.LOGIN_SUCCESS]: (
    state: ILoginSlice,
    action: PayloadAction<{ token: string }>
  ) => {
    return {
      ...state,
      loading: false,
      loggedIn: true,
      token: action.payload.token,
    };
  },
  [LoginActions.SET_LOGGED_IN]: (
    state: ILoginSlice,
    action: PayloadAction<{ loggedIn: boolean }>
  ) => {
    return {
      ...state,
      loggedIn: action.payload.loggedIn,
    };
  },
});

export default loginReducer;
