import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    padding: "32px",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    gap: "32px",
    overflow: "auto",
    height: "calc(100% - 80px)",
  },
}));

export default useStyles;
