import React, { useContext } from "react";
import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Dashboard from "./Dashboard";
import AnalyticsFilters from "./AnalyticsFilters";
import { AnalyticsDashboardData, Filter } from "../types";
import { AnalyticsContext } from "../Context/AnalyticsContext";
import Loading from "aether/Loading";
import { getAnalyticsDashboard } from "../Service/Analytics.service";
import { useSnackbar } from "notistack";
import BrokenCard from "../util/BrokenCard";
import { ReactComponent as InsufficientDataIcon } from "assets/insufficientData.svg";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    padding: "32px",
    height: "calc(100vh - 80px)",
    overflowY: "scroll",
    [theme.breakpoints.down("lg")]: {
      width: "1128px",
      overflowX: "scroll",
    },
  },
  contentContainer: {
    width: "70%",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
  },
}));

const AnalyticsContentContainer: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    dashboard,
    setLoading,
    setDashboard,
    dashboardLayout,
    errorinLoadingData,
  } = useContext(AnalyticsContext);

  const handleChangeFilter = (filters: Filter[]): void => {
    setLoading(true);

    if (dashboard) {
      setDashboard({
        id: dashboard.id,
        filters,
        visualizations: [], // as soon as filter changes we set visualizations to be empty, so that it start showing shimmer
      });
    }
    getAnalyticsDashboard(filters)
      .then((res) => {
        setDashboard(res);
      })
      .catch((err) => {
        enqueueSnackbar(`Something went wrong: ${err}`, {
          variant: "error",
        });
        // fallback to set to the previous dashboard data.
        setDashboard(dashboard as AnalyticsDashboardData);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!dashboard || !dashboardLayout) {
    return <Loading />;
  }

  if (errorinLoadingData) {
    return <BrokenCard />;
  }

  if (dashboard?.insufficientData) {
    return (
      <BrokenCard
        title={"Not enough data available to generate insights."}
        description={
          "Please wait and check after some time while we collect a sufficient number of conversations to provide meaningful insights."
        }
        icon={<InsufficientDataIcon />}
      />
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <AnalyticsFilters
          filters={dashboard?.filters ?? []}
          onChangeFilter={handleChangeFilter}
        />
        <Dashboard
          sections={dashboardLayout?.sections ?? []}
          insuffecientFilteredData={dashboard?.insuffecientFilteredData}
        />
      </div>
    </div>
  );
};

export default AnalyticsContentContainer;
