import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const useStyles = makeStyles((theme: Theme) => ({
  tableLoadingContainer: {
    height: "40vh",
  },
}));

interface Props {
  colSpan: number;
}

const TableLoading: React.FC<Props> = ({ colSpan }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.tableLoadingContainer}>
      <TableCell colSpan={colSpan}>
        <div className="table-loading-logo center">
          <CircularProgress />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TableLoading;
