import { InputAdornment, TextField } from "@mui/material";
import clsx from "clsx";
import { useCallback, useState } from "react";
import useStyles from "./voiceBotContainer.styles";
import { ReactComponent as VoiceIcon } from "assets/voice-widget-icon.svg";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import IconButton from "aether/IconButton";
import { ReactComponent as CrossIcon } from "assets/x-close.svg";
import CountryFlagIcon from "components/base/CountryFlagIcon";
import { formatAssociatedPhoneNumber } from "../utils";

interface Props {
  phoneNumber?: string;
}

const VoiceBotContainer: React.FC<Props> = ({ phoneNumber = "" }) => {
  const classes = useStyles();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const togglePopup = useCallback((): void => {
    setIsPopupVisible(!isPopupVisible);
  }, [isPopupVisible]);

  const { countryCode, localNumber } = formatAssociatedPhoneNumber(phoneNumber);
  const displayPhoneNumber = phoneNumber ? `${countryCode} ${localNumber}` : "";

  return (
    <>
      <div
        onClick={togglePopup}
        className={clsx(classes.iconWrapper, {
          hide: isPopupVisible,
        })}
      >
        <VoiceIcon />
      </div>

      <div
        className={clsx(classes.popUpContainer, {
          show: isPopupVisible,
          hide: !isPopupVisible,
        })}
      >
        <div className="flex mb-3">
          <div className="mr-2">
            <Typography
              variant={TypographyVariants.textXL}
              weight={TypographyWeights.bold}
            >
              Test your assistant
            </Typography>
            <Typography
              variant={TypographyVariants.text}
              weight={TypographyWeights.medium}
            >
              Simulate real customer interactions to ensure your assistant meets
              performance expectations. Please dial the no. below
            </Typography>
          </div>
          <IconButton
            color="custom"
            variant="icon"
            onClick={togglePopup}
            size="xsmall"
            customColors={{ color: "black" }}
          >
            <CrossIcon />
          </IconButton>
        </div>
        <TextField
          size="small"
          autoComplete="off"
          fullWidth
          value={displayPhoneNumber}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CountryFlagIcon countryCode={countryCode} />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
};

export default VoiceBotContainer;
