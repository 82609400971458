import { UserActions } from "store/reduxActions/authActions";
import { createReducer } from "../util";
import { type IUser, type IUserSlice } from "types/auth.types";
import { type PayloadAction } from "@reduxjs/toolkit";

const initialState: IUserSlice = {
  loading: true,
  first_name: "",
  last_name: "",
  groups: [],
  id: "",
  email: "",
  channel: "",
  is_active: null,
  is_superuser: null,
  is_super_admin: null,
  is_agent: null,
  is_qa_auditor: null,
  is_admin: null,
  schema_name: "",
  notificationCount: 0,
  business_type: null,
  workspace: [],
  selectedWorkspace: "",
  redirectUrl: "/organizations",
  userSettings: {
    emailDigestSubscription: {
      isEnabled: false,
      isEnabledForRole: false,
      isDataFetched: false,
    },
  },
};

const userReducer = createReducer(initialState, {
  [UserActions.SET_APP_USER]: (
    state: any,
    action: PayloadAction<{ user: IUser }>
  ) => {
    return {
      ...state,
      ...action.payload.user,
    };
  },
});

export default userReducer;
