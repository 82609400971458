import { makeStyles } from "@mui/styles";

interface StyleProps {
  color: "red" | "blue" | "grey";
}

export const useStyles = makeStyles(() => ({
  badge: {
    display: "flex",
    alignItems: "center",
    padding: "2px 8px",
    borderRadius: "16px",
    background: (props: StyleProps) => {
      switch (props.color) {
        case "red":
          return "var(--colors-primary-red-100, #FDE8E8)";
        case "blue":
          return "var(--colors-primary-sky-100, #EAF9FE)";
        case "grey":
        default:
          return "var(--Gray-100, #F2F4F7)";
      }
    },
    mixBlendMode: "multiply",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "12px",
    height: "12px",
    marginRight: "4px",
  },
  title: {
    fontFamily: "Manrope, sans-serif",
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: (props: StyleProps) => {
      switch (props.color) {
        case "red":
          return "var(--text-Red-notes, #D14343)";
        case "blue":
          return "var(--text-Blue-notes, #3A91B4)";
        case "grey":
        default:
          return "var(--text-default, #282624)";
      }
    },
    textAlign: "center",
  },
}));
