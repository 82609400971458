import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  togglerContainer: {
    marginTop: "16px",
    marginBottom: "16px",
  },

  selectAPIContainer: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
  },

  descriptionContainer: {
    marginBottom: "24px",
  },

  descriptionLabel: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    marginBottom: "8px",
  },

  namelabel: {
    marginBottom: "24px",
  },

  zapIcon: {
    "& path": {
      fill: "#ff8000",
    },
  },
  plusIcon: {
    height: "16px",
    width: "16px",
  },
}));

export default useStyles;
