import React from "react";
import {
  type IconButtonProps as MuiIconButtonProps,
  IconButton as MuiIconButton,
} from "@mui/material";
import clsx from "clsx";
import useStyles from "./style";

export interface CustomColorProps {
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
}

// Define variant types
type IconButtonVariant = "icon" | "contained" | "outlined";
type IconButtonSize = "xsmall" | "small" | "medium" | "large";
type IconButtonColor =
  | "primary"
  | "secondary"
  | "tertiary"
  | "warning"
  | "error"
  | "custom";

// Interface
export interface IconButtonProps
  extends Omit<MuiIconButtonProps, "variant" | "size" | "color"> {
  variant?: IconButtonVariant;
  size?: IconButtonSize;
  color?: IconButtonColor;
  customColors?: CustomColorProps;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      variant = "icon",
      size = "small",
      color = "primary",
      children,
      className,
      customColors = { color: "#000000", backgroundColor: "#ffffff" },
      ...props
    },
    ref
  ) => {
    const classes = useStyles({
      color,
      customColors,
    });

    const buttonClasses = clsx(
      classes.root,
      classes[variant],
      classes[size],
      className
    );

    return (
      <MuiIconButton ref={ref} className={buttonClasses} {...props}>
        {children}
      </MuiIconButton>
    );
  }
);

IconButton.displayName = "IconButton";

export default IconButton;
