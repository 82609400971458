import { botBEAxiosInstance as botBEAxios } from "services/axios";
import {
  type AuthType,
  type Connection,
  type Connector,
} from "../integrations.types";

export interface CreateConnectionInput {
  name: string;
  config_values: Record<string, string>;
  connector_id: string;
  auth_type: AuthType;
}

export const getAllConnectors = async (): Promise<Connector[]> => {
  const res = await botBEAxios({
    method: "GET",
    url: `/connectors`,
  });
  return res?.data?.connectors;
};

export const getAllConnections = async (): Promise<Connection[]> => {
  const res = await botBEAxios({
    method: "GET",
    url: `/connections`,
  });
  return res?.data?.connections;
};

export const getConnectorById = async (
  connectorId: string
): Promise<Connector> => {
  const res = await botBEAxios({
    method: "GET",
    url: `/connectors/${connectorId}`,
  });
  return res?.data?.connector;
};

export const getConnectionById = async (
  connectionId: string
): Promise<Connection> => {
  const res = await botBEAxios({
    method: "GET",
    url: `/connections/${connectionId}`,
  });
  return res?.data?.connection;
};

export const createConnection = async (
  data: CreateConnectionInput
): Promise<any> => {
  return await botBEAxios({
    method: "POST",
    url: `/connections`,
    data,
  });
};

export const deleteConnection = async (connectionId: string): Promise<any> => {
  return await botBEAxios({
    method: "DELETE",
    url: `/connections/${connectionId}`,
  });
};
