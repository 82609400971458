/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { format, parse, isValid, parseISO } from "date-fns";

const DATE_VALUE_FORMAT = "dd MMM yy";

export const baseFormater = (
  date: Date,
  formatTemplate: string
): string | null => {
  if (!date) return null;

  if (!isValid(date)) {
    return null;
  }

  try {
    return format(date, formatTemplate);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Failed to format date", error);
    return null;
  }
};

export const formatToStringFromDate = (date: Date): string | null =>
  baseFormater(date, DATE_VALUE_FORMAT);

export const formatToStringFromDateString = (date: string): string | null => {
  const dateStrng = new Date(date);
  return formatToStringFromDate(dateStrng);
};

export const formatToHumanStringFromDate = (date: Date): string | null =>
  baseFormater(date, "MMMM dd, yyyy");

export const parseDateFromDateValue = (dateStr: string): Date | null => {
  if (!dateStr) return null;

  try {
    const d = parse(dateStr, DATE_VALUE_FORMAT, new Date());

    if (isValid(d)) {
      return d;
    }
    throw Error("Invalid date");
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Failed to parse date string", error);
    return null;
  }
};

export const formateToTimeStringFromDate = (date: Date): string | undefined =>
  baseFormater(date, "h:mmaa")?.toLocaleLowerCase();

export const baseParser = (
  dateStr: string,
  formatTemplate: string,
  date = new Date()
): Date | null => {
  try {
    return parse(dateStr, formatTemplate, date);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("baseParser: Failed to parse date", error);
    return null;
  }
};

export const formatToHumanStringFromDateTime = (
  date: Date,
  formatStr = "dd-MMM-yyyy hh:mm aa"
): string | null => {
  return baseFormater(date, formatStr);
};

export const formatDateRange = (dateRange: number): any => {
  const today = new Date();
  const startDate = new Date();
  startDate.setDate(today.getDate() - dateRange + 1); // Ensure n days are included

  const endDate = new Date(today);
  endDate.setHours(0, 0, 0, 0); // Set end time to midnight for range accuracy

  const dateFormat = "yyyy-MM-dd"; // Adjust format as needed

  const formattedRange = {
    startDate: format(startDate, dateFormat),
    endDate: format(endDate, dateFormat),
  };

  return formattedRange;
};

export { parse, format, parseISO };
