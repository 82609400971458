import { IconButton } from "@mui/material";
import useStyles from "./style";
import { ReactComponent as ArrowLeft } from "assets/arrow-left.svg";
import { type ITabSwitchOption } from "components/base/TabSwitch";
import useRouteNavigator from "hooks/navigation/useRouteNavigator";
import { useParams } from "react-router-dom";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";

interface Props {
  title: string;
  description?: string;
  navigations?: ITabSwitchOption[];
  children?: React.ReactNode;
}

const BotHeader: React.FC<Props> = ({
  title,
  description = "",
  navigations,
  children,
}) => {
  const classes = useStyles();
  const { gotoAssistant } = useRouteNavigator();
  const { assistantId } = useParams() || {};
  // const [selectedTab, setSelectedTab] = useState(navigations?.[0].key);

  const handleBackClick = (id: string | undefined): void => {
    if (!id) return;
    gotoAssistant(id);
  };

  return (
    <div className={classes.botHeaderContainer}>
      <div className={classes.botDetailsContainer}>
        <IconButton
          className="back-button"
          onClick={() => {
            handleBackClick(assistantId);
          }}
        >
          <ArrowLeft />
        </IconButton>

        <div className="bot-details-container flex flex-col row-gap-4">
          <Typography
            variant={TypographyVariants.textXL}
            weight={TypographyWeights.bold}
            renderInLines={1}
          >
            {title}
          </Typography>
          <Typography
            renderInLines={1}
            variant={TypographyVariants.text}
            weight={TypographyWeights.medium}
          >
            {description}
          </Typography>
        </div>
      </div>

      {/* <div className={classes.botTabNavigation}>
        {navigations && selectedTab && (
          <TabSwitch
            value={selectedTab}
            onChange={(key: string) => {
              handleOnChangeTab(key);
            }}
            options={navigations}
          />
        )}
      </div> */}

      <div className={classes.botActionsContainer}>{children}</div>
    </div>
  );
};

export default BotHeader;
