import { makeStyles } from "@mui/styles";
import { type IMessageAction } from "types/ChatbotType";
import CarouselItem from "components/shared/CarouselItem";

const useStyles = makeStyles({
  "messages-carousel-container": {
    padding: "12px 12px",
    marginLeft: "24px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    columnGap: "16px",
    width: "1005",
    overflowX: "scroll",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
});

interface IMessageCarouselProps {
  content: any;
  onClickAction?: (action: IMessageAction) => void;
}

const MessageCarousel: React.FC<IMessageCarouselProps> = ({
  content,
  onClickAction,
}) => {
  const classes = useStyles();

  return (
    <div className={classes["messages-carousel-container"]}>
      {content?.map((item: any) => (
        <CarouselItem key={item.id} item={item} onClickAction={onClickAction} />
      ))}
    </div>
  );
};

export default MessageCarousel;
