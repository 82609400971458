import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "67%",
    height: "100%",
    display: "flex",
    gap: "32px",
  },
  image: {
    width: "120px",
    height: "120px",
    flexShrink: 0,
  },
  nameDescContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  titleTagContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
}));

export default useStyles;
