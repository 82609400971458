import type React from "react";
import useStyles from "./ConnectorActionPreview.styles";
import { ReactComponent as LogInIcon } from "assets/log-in-01.svg";
import { ReactComponent as LogOutIcon } from "assets/log-out-01.svg";
import { ReactComponent as PuzzleIcon } from "assets/puzzle-piece-02-black.svg";
import { ReactComponent as CrossIcon } from "assets/x-close.svg";
import {
  type ActionParam,
  type ConnectorActionsConfig,
} from "../../AgentActions.const";
import ActionParamTile from "./ActionParamTile";
import { IconButton, Popover } from "@mui/material";
import { useState } from "react";
import { isEqual } from "lodash";
import Button from "components/base/Button";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";

interface Props {
  onClose: () => void;
  action: ConnectorActionsConfig;
  openSelectAndConfigure: () => void;
}

const ConnectorActionPreview: React.FC<Props> = ({
  onClose,
  action,
  openSelectAndConfigure,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedParam, setSelectedParam] = useState<ActionParam | null>(null);
  const classes = useStyles({ width: anchorEl?.clientWidth });

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
    setSelectedParam(null);
  };

  const onClickParamItem = (
    param: ActionParam,
    event: React.MouseEvent<HTMLElement>
  ): void => {
    if (isEqual(param.id, selectedParam?.id)) {
      setSelectedParam(null);
      handlePopoverClose();
    } else {
      setAnchorEl(event.currentTarget);
      setSelectedParam(param);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.IconAndtitle}>
            <PuzzleIcon />
            <Typography
              variant={TypographyVariants.textXL}
              weight={TypographyWeights.bold}
            >
              {action.name}
            </Typography>
          </div>
          <CrossIcon onClick={onClose} />
        </div>
        <div className={classes.content}>
          <Typography
            variant={TypographyVariants.textLarge}
            weight={TypographyWeights.bold}
          >
            Description
          </Typography>
          <Typography
            variant={TypographyVariants.text}
            weight={TypographyWeights.medium}
          >
            {action.description}
          </Typography>
          {/* For Input Params */}
          <div className={classes.paramHead}>
            <IconButton>
              <LogInIcon />
            </IconButton>
            <Typography
              variant={TypographyVariants.textLarge}
              weight={TypographyWeights.bold}
              className="ml-2"
            >
              Input
            </Typography>
            <Typography
              variant={TypographyVariants.text}
              weight={TypographyWeights.medium}
              className={classes.paramCount}
            >
              {action?.input_params?.length}
            </Typography>
          </div>
          <div className={classes.tiles}>
            {action?.input_params?.map((param) => {
              return (
                <ActionParamTile
                  key={param.id}
                  param={param}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    onClickParamItem(param, event);
                  }}
                  isPopoverOpen={isEqual(selectedParam?.id, param.id)}
                  showOpenCloseState
                />
              );
            })}
          </div>
          {/* For Output params */}
          <div className={classes.paramHead}>
            <IconButton>
              <LogOutIcon />
            </IconButton>
            <Typography
              variant={TypographyVariants.textLarge}
              weight={TypographyWeights.bold}
              className="ml-2"
            >
              Output
            </Typography>
            <Typography
              variant={TypographyVariants.text}
              weight={TypographyWeights.medium}
              className={classes.paramCount}
            >
              {action.output_params.length}
            </Typography>
          </div>
          <div className={classes.tiles}>
            {action.output_params.map((param) => {
              return (
                <ActionParamTile
                  key={param?.id}
                  param={param}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    onClickParamItem(param, event);
                  }}
                  isPopoverOpen={isEqual(selectedParam?.id, param.id)}
                  showOpenCloseState
                />
              );
            })}
          </div>
        </div>
        <div className={classes.footer}>
          <Button
            color="primary"
            className={classes.configureBtn}
            onClick={openSelectAndConfigure}
          >
            Select & configure
          </Button>
        </div>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom", // Position it below the input
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.popover }}
      >
        <div>
          <Typography
            variant={TypographyVariants.textSmall}
            weight={TypographyWeights.bold}
          >
            How the departure date is sourced?
          </Typography>
          <Typography
            variant={TypographyVariants.textSmall}
            weight={TypographyWeights.medium}
          >
            Extracted from the user’s query
          </Typography>
        </div>
        <div>
          <Typography
            variant={TypographyVariants.textSmall}
            weight={TypographyWeights.bold}
          >
            Default value
          </Typography>
          <Typography
            variant={TypographyVariants.textSmall}
            weight={TypographyWeights.medium}
          >
            {selectedParam?.value ? selectedParam?.value : "Null"}
          </Typography>
        </div>
        <div>
          <Typography
            variant={TypographyVariants.textSmall}
            weight={TypographyWeights.bold}
          >
            Optional vs mandatory
          </Typography>
          <Typography
            variant={TypographyVariants.textSmall}
            weight={TypographyWeights.medium}
          >
            {selectedParam?.required ? "Mandatory" : "Optional"}
          </Typography>
        </div>
      </Popover>
    </>
  );
};

export default ConnectorActionPreview;
