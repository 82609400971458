import IntegrationHeader from "./Header/IntegrationHeader";
import IntegrationsContentContainer from "./Content/IntegrationContentContainer";
import { ReactComponent as IntegrationsIcon } from "assets/IntegrationsIcon.svg";
import { useStyles } from "./IntegrationsContainer.styles";

const IntegrationsContainer: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <IntegrationHeader title={"Integrations"} icon={<IntegrationsIcon />} />
      <IntegrationsContentContainer />
    </div>
  );
};

export default IntegrationsContainer;
