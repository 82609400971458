import GlobalSpinner from "components/shared/GlobalSpinner";
import useAuthorization from "hooks/useAuthorization";
import { useEffect } from "react";
import { useDispatch, useSelector } from "store";
import { LoginActions } from "store/reduxActions/authActions";

const NonAuthenticatedRoutes = ["webclient"];

interface Props {
  children: React.ReactNode;
}

const Auth: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const { authCheck } = useAuthorization();

  const { loggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      NonAuthenticatedRoutes.some((route) =>
        window.location.pathname.includes(route)
      )
    ) {
      dispatch({
        type: LoginActions.SET_LOGGED_IN,
        payload: { loggedIn: true },
      });
      return;
    }

    void authCheck();
  }, []);

  return loggedIn ? <>{children}</> : <GlobalSpinner />;
};

export default Auth;
