import type React from "react";
import { useFooterStyles } from "./StickeyFooter.styles";
import IconButton from "aether/IconButton";
import Button from "../Button";

interface FooterConfig {
  icon: React.ReactNode;
  message: string;
  buttonConfig?: {
    buttonLabel?: string;
    onClickFooterButton?: () => void;
    preFixIcon?: JSX.Element;
  };
}

const StickeyFooter: React.FC<FooterConfig> = ({
  icon,
  message,
  buttonConfig,
}) => {
  const classes = useFooterStyles();
  const { buttonLabel, onClickFooterButton, preFixIcon } = buttonConfig ?? {};

  return (
    <div className={classes.footer}>
      <IconButton size="xsmall" color="primary">
        {icon}
      </IconButton>
      <div className={classes.message}>{message}</div>
      <Button
        onClick={onClickFooterButton}
        className={classes.button}
        startIcon={preFixIcon}
        color="secondary"
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

export default StickeyFooter;
