import ReactJson from "react-json-view";
import useStyles from "./JSONSnippetRenderer.styles";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { format } from "date-fns";
import { isEmpty } from "lodash";

interface JSONSnippetRendererProps {
  data: string;
  lastRunDate?: string;
}

// Complete custom theme
const customTheme = {
  base00: "white",
  base01: "#f0f0f0",
  base02: "#d1d1d1",
  base03: "#b0b0b0",
  base04: "#8f8f8f",
  base05: "#4e4e4e",
  base06: "#2e2e2e",
  base07: "#3A91B4",
  base08: "black", // Color for strings
  base09: "black", // Color for numbers
  base0A: "red", // Color for keys
  base0B: "purple", // Color for booleans
  base0C: "gray", // Color for null values
  base0D: "black", // Color for object keys
  base0E: "black", // Color for array values
  base0F: "black", // Color for numbers
};

const JSONSnippetRenderer: React.FC<JSONSnippetRendererProps> = ({
  data,
  lastRunDate = "",
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography
          variant={TypographyVariants.textSmall}
          weight={TypographyWeights.medium}
          className={classes.headTypography}
        >
          JSON
        </Typography>
        <Typography
          variant={TypographyVariants.textSmall}
          weight={TypographyWeights.medium}
          className={classes.headTypography}
        >
          {!isEmpty(lastRunDate) &&
            format(new Date(lastRunDate), "MMMM dd, yyyy, hh:mm a 'UTC'")}
        </Typography>
      </div>
      <div className={classes.code}>
        <ReactJson
          src={JSON.parse(data)}
          name={null}
          collapsed={1}
          enableClipboard={false}
          theme={customTheme}
        />
      </div>
    </div>
  );
};

export default JSONSnippetRenderer;
