import { type Conversation, type Participant } from "@twilio/conversations";
import { type Dispatch } from "redux";
import { ReduxChatbotActions } from "store/reduxActions/ReduxChatbotActions";

export const initParticipantsListener = (
  conversation: Conversation,
  dispatch: Dispatch
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
  conversation.addListener(
    "participantJoined",
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    async (participant: Participant) => {
      const user = await participant.getUser();
      dispatch({
        type: ReduxChatbotActions.ACTION_ADD_PARTICIPANT,
        payload: {
          participant,
          user,
          message: {
            body: "AGENT_HANDOFF",
            attributes: { speaker: "bot" },
          },
        },
      });
    }
  );

  conversation.addListener("participantLeft", (participant: Participant) => {
    dispatch({
      type: ReduxChatbotActions.ACTION_REMOVE_PARTICIPANT,
      payload: { participant },
    });
  });

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const dispatchParticipantUpdate = (participant: Participant) => {
    dispatch({
      type: ReduxChatbotActions.ACTION_UPDATE_PARTICIPANT,
      payload: { participant },
    });
  };
  conversation.addListener("participantUpdated", ({ participant }) => {
    dispatchParticipantUpdate(participant);
  });
  conversation.addListener("typingStarted", dispatchParticipantUpdate);
  conversation.addListener("typingEnded", dispatchParticipantUpdate);
};
