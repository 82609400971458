import Modal from "components/base/Modal/Modal";
import { useSnackbar } from "notistack";
import { type IAssistant } from "pages/Assistant/types";
import { useState } from "react";
import { createAgent } from "api/assistant/agents";
import useRouteNavigator from "hooks/navigation/useRouteNavigator";
import Typography, { TypographyWeights } from "aether/Typography";
import { TextField, useTheme } from "@mui/material";
import { ReactComponent as ArrowRight } from "assets/arrow-right.svg";

const agent_name_label = "Skill name";
const agent_name_placeholder = "E.g. travel support voice skill";
const agent_description_label = "Short description for reference";
const agent_description_placeholder =
  "Eg. Help customer self serve booking related issues";

interface Props {
  assistant: IAssistant;
  open: boolean;
  // onConfirm: (agent: IAgent) => void;
  onClose: () => void;
}

const CreateAgentModal: React.FC<Props> = ({
  assistant,
  open,
  // onConfirm,
  onClose,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { gotoAgentBuilder } = useRouteNavigator();

  const { _id: assistantId, name: assistantName } = assistant || {};

  const [saving, setSaving] = useState(false);
  const [values, setValues] = useState({
    name: "",
    description: "",
  });

  const handleChange = (key: string, value: string): void => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleBlur = (key: string, value: string): void => {};

  const handleConfirm = async (): Promise<void> => {
    if (!assistantId) return;

    setSaving(true);
    createAgent({ bot_id: assistantId, ...values })
      .then((response) => {
        enqueueSnackbar(`Skill created successfully.`, {
          variant: "success",
        });
        gotoAgentBuilder(assistantId, response._id);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleClose = (): void => {
    onClose();
    setSaving(false);
  };

  return (
    <Modal
      open={open}
      title={`Create a skill for ${assistantName}`}
      confirmDisabled={!values?.name}
      confirmEndIcon={<ArrowRight />}
      showConfirmLoading={saving}
      onClose={handleClose}
      onConfirm={() => {
        void handleConfirm();
      }}
      onCancel={handleClose}
      borderColor={theme.palette.sky?.[700]}
    >
      <div className="flex-col row-gap-16 my-16">
        <div className="flex-col row-gap-4">
          <Typography weight={TypographyWeights.semiBold}>
            {agent_name_label}
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder={agent_name_placeholder}
            value={values.name}
            onChange={(event) => {
              handleChange("name", event.target.value);
            }}
            onBlur={(event) => {
              handleBlur("name", event.target.value);
            }}
          />
        </div>

        <div className="flex-col row-gap-4">
          <Typography weight={TypographyWeights.semiBold}>
            {agent_description_label}
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder={agent_description_placeholder}
            value={values.description}
            onChange={(event) => {
              handleChange("description", event.target.value);
            }}
            onBlur={(event) => {
              handleBlur("description", event.target.value);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateAgentModal;
