import type React from "react";
import {
  type ActionParam,
  type ConnectorActionsConfig,
} from "../../AgentActions.const";
import useStyles from "./ActionDetails.style";
import { IconButton } from "@mui/material";
import { ReactComponent as LogInIcon } from "assets/log-in-01.svg";
import { ReactComponent as LogOutIcon } from "assets/log-out-01.svg";
import ActionParamTile from "../../RightPanel/ActionPreview/ActionParamTile";
import { ActionBuilderActions } from "store/reduxActions/ActionBuilderActions";
import { useDispatch, useSelector } from "store";
import { isEqual } from "lodash";

interface Props {
  action: ConnectorActionsConfig;
}
const ActionDetails: React.FC<Props> = ({ action }) => {
  const classes = useStyles();
  const { selectedConfigurableParam } = useSelector(
    (state) => state.actionBuilder
  );
  const dispatch = useDispatch();
  const onClickParamItem = (param: ActionParam): void => {
    dispatch({
      type: ActionBuilderActions.SET_SELECTED_CONFIGURABLE_PARAM,
      payload: param,
    });
  };

  return (
    <div className={classes.container}>
      {/* For Input params */}
      <div className={classes.paramHead}>
        <IconButton>
          <LogInIcon />
        </IconButton>
        <div className={classes.paramHeadTitle}>Configure inputs</div>
        <div className={classes.paramCount}>{action.input_params.length}</div>
      </div>
      <div className={classes.tiles}>
        {action.input_params.map((param) => {
          return (
            <div key={param?.id} className={classes.tile}>
              <ActionParamTile
                key={param.id}
                param={param}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  onClickParamItem(param);
                }}
                showTileActive={isEqual(
                  selectedConfigurableParam?.id,
                  param.id
                )}
              />
            </div>
          );
        })}
      </div>
      {/* For Output params */}
      <div className={classes.paramHead}>
        <IconButton>
          <LogOutIcon />
        </IconButton>
        <div className={classes.paramHeadTitle}>Output</div>
        <div className={classes.paramCount}>{action.output_params.length}</div>
      </div>
      <div className={classes.tiles}>
        {action.output_params.map((param) => {
          return (
            <div key={param?.id} className={classes.tile}>
              <ActionParamTile
                param={param}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  onClickParamItem(param);
                }}
                showTileActive={isEqual(
                  selectedConfigurableParam?.id,
                  param.id
                )}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ActionDetails;
