import store from "store";
import { ReduxChatbotActions } from "store/reduxActions/ReduxChatbotActions";
import { ConnectionState } from "./types";

class WebSocketSingleton {
  // Private Properties
  private static instance: WebSocketSingleton | null = null;

  // Public Properties
  public socket: WebSocket | null = null;
  public url: string;
  public headers: Record<string, string>;

  private constructor(url: string, headers: Record<string, string>) {
    this.url = url;
    this.headers = headers;

    this.connect();
  }

  public static getInstance(
    url: string,
    headers: Record<string, string>
  ): WebSocketSingleton {
    if (WebSocketSingleton.instance == null) {
      WebSocketSingleton.instance = new WebSocketSingleton(url, headers);
    }

    return WebSocketSingleton.instance;
  }

  public getSocket(): WebSocket | null {
    return this.socket;
  }

  public connect(): void {
    const _headers = this.headers;
    const _url = this.url;

    const queryString = Object.keys(_headers)
      .filter((key: any) => _headers[key])
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(_headers[key])}`
      )
      .join("&");

    this.socket = new WebSocket(`${_url}?${queryString}`);

    this.socket.addEventListener("open", (event) => {
      store.dispatch({
        type: ReduxChatbotActions.ACTION_SET_CONNECTION_STATE,
        payload: { connectionState: ConnectionState.OPEN },
      });
    });

    this.socket.addEventListener("message", (event) => {
      store.dispatch({
        type: ReduxChatbotActions.ACTION_SET_CONNECTION_STATE,
        payload: { connectionState: ConnectionState.MESSAGE },
      });
    });

    this.socket.addEventListener("close", (event) => {
      store.dispatch({
        type: ReduxChatbotActions.ACTION_SET_CONNECTION_STATE,
        payload: { connectionState: ConnectionState.CLOSE },
      });
    });

    this.socket.addEventListener("error", (event) => {
      store.dispatch({
        type: ReduxChatbotActions.ACTION_SET_CONNECTION_STATE,
        payload: { connectionState: ConnectionState.ERROR },
      });
    });
  }

  public reconnect(): void {
    this.destroy();
    this.connect();
  }

  public destroy(): void {
    this.socket?.close();
    WebSocketSingleton.instance = null;
  }
}

export default WebSocketSingleton;
