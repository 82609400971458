import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  botDashboardContainer: {
    width: "100%",
    height: "calc(100vh)",
    overflowX: "auto",
  },
  botDashboardContent: {
    padding: "36px",
    maxWidth: "1376px",
    minWidth: "1200px",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifySelf: "center",

    "& .dashboard-header-container": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "24px",

      "& .dashboard-subtitle": {
        paddingTop: "12px",
        width: "736px",
      },
    },

    [theme.breakpoints.down("lg")]: {
      justifySelf: "flex-start",
    },
  },
  content: {
    // maxWidth: "1376px",
    // minWidth: "1200px",
    // height: "100%",
    // width: "100%",
    // display: "flex",
    // flexDirection: "column",
    // padding: "36px",
  },
}));
