import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: "16px",
    background: "#FFF",
    boxShadow:
      "0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    padding: "24px",
    marginTop: "32px",
    minHeight: "250px",
  },
  apiTable: {
    height: "100%",
    marginBottom: "16px",
  },
  actionsContainer: {
    display: "flex",
    gap: "16px",
  },
  linkToAPIPage: {
    display: "flex",
    gap: "4px",
  },
  linkText: {
    color: "var(--text-brand, #C9671D)",
    cursor: "pointer",
  },
}));

export default useStyles;
