import { Box, Link, Tooltip } from "@mui/material";
import { type BotMessageSource } from "./types";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { ReactComponent as OpenLinkIcon } from "assets/open-in-new.svg";
import { useCallback, useMemo } from "react";
import TenantLinkIcon from "components/shared/TenantLinkIcon";

const useStyles = makeStyles(() => ({
  sourceItem: {
    cursor: "pointer",
    background: "#e7e5e1",
  },
  tooltip: {
    background: "#FFF",
    color: "black",
    padding: "12px",
    maxWidth: "300px",
    filter:
      "drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.06)) drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.10))",
  },
  tooltipArrow: {
    color: "#FFF",
  },
}));

interface SourceBubbleProps {
  source: BotMessageSource;
}

const SourceBubble: React.FC<SourceBubbleProps> = ({ source }) => {
  const classes = useStyles();

  const handleSourceOnClick = useCallback((sourceLink: string): void => {
    if (sourceLink) {
      window.open(sourceLink, "_blank");
    }
  }, []);

  const tooltipContent = useMemo(() => {
    if (!source?.source) return null;
    let url = null;
    try {
      url = new URL(source?.source);
    } catch (e) {
      return null;
    }
    const domain = url?.hostname;

    return (
      <Box display="flex">
        <Box display="flex" justifyContent="center" pt={0.5}>
          <TenantLinkIcon />
        </Box>
        <Box ml={1.5}>
          <Link href={source.source} color="#000" target="_blank">
            <Typography maxLines={2} className="w-medium" variant="body1">
              {source.title}
            </Typography>
          </Link>
          <Box mt={0.5}>
            <Typography className="w-medium" variant="body1" color="#7C7972">
              {domain}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }, [source.source, source.title]);

  return (
    <Tooltip
      placement="top"
      title={tooltipContent}
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
      }}
    >
      <span>
        <Box
          className={classes.sourceItem}
          borderRadius={16}
          py={0.5}
          px={1}
          display="flex"
          onClick={() => {
            handleSourceOnClick(source?.source);
          }}
        >
          <Typography maxLines={1} className="w-semi-bold" variant="body1">
            {source.title}
          </Typography>
          <Box display="flex" alignItems="center" ml={0.5}>
            <OpenLinkIcon />
          </Box>
        </Box>
      </span>
    </Tooltip>
  );
};

export default SourceBubble;
