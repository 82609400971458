/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer } from "../util";
import { ReduxChatbotActions } from "store/reduxActions/ReduxChatbotActions";
import { type Message, type Participant } from "@twilio/conversations";
import { ConnectionState } from "pages/Bot/ChatBotContainer/ChatBot/types";
import { PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  conversationHandoff: false,
  conversationsClient: null,
  conversation: null,
  conversationState: null,
  users: null,
  participants: null,
  agent: null,
  messages: [],
  persona: "",
  botType: "default",
  connection: {
    state: ConnectionState.NOT_CONNECTED,
    reconnect: false,
  },
  widgetMounted: false,
};

const chatbotReducer = createReducer(initialState, {
  [ReduxChatbotActions.ACTION_SET_WIDGET_MOUNTED]: (
    state: any,
    action: PayloadAction<boolean>
  ) => {
    return {
      ...state,
      widgetMounted: action.payload,
    };
  },
  [ReduxChatbotActions.ACTION_START_SESSION]: (state: any, action: any) => {
    return {
      ...state,
      conversationHandoff: true,
      conversationsClient: action.payload.conversationsClient,
      conversation: action.payload.conversation,
      conversationState: action.payload.conversationState,
      users: action.payload.users,
      participants: action.payload.participants,
      messages: action.payload.messages,
    };
  },
  [ReduxChatbotActions.ACTION_SET_BOT_SESSION]: (state: any, action: any) => {
    return {
      ...state,
      conversationHandoff: false,
      conversationsClient: null,
      conversation: null,
      conversationState: null,
      users: null,
      participants: null,
      agent: null,
      messages: [],
      persona: "",
    };
  },
  [ReduxChatbotActions.ACTION_SET_RECONNECT]: (state: any, action: any) => {
    return {
      ...state,
      connection: {
        ...state.connection,
        reconnect: action.payload.reconnect,
      },
    };
  },
  [ReduxChatbotActions.ACTION_SET_CONNECTION_STATE]: (
    state: any,
    action: any
  ) => {
    return {
      ...state,
      connection: {
        ...state.connection,
        state: action.payload.connectionState,
      },
    };
  },
  [ReduxChatbotActions.ACTION_END_SESSION]: (state: any, action: any) => {
    return {
      ...state,
      messages: [...(state.messages || []), action.payload.message],
      conversationsClient: null,
      conversation: null,
    };
  },
  [ReduxChatbotActions.ACTION_END_HANDOFF]: (state: any, action: any) => {
    return {
      ...state,
      conversationHandoff: false,
    };
  },
  [ReduxChatbotActions.ACTION_UPDATE_CONVERSATION_STATE]: (
    state: any,
    action: any
  ) => {
    return {
      ...state,
      conversationsClient: action.payload.conversationsClient,
      conversation: action.payload.conversation,
      conversationState: action.payload.conversationState,
      users: action.payload.users,
      participants: action.payload.participants,
      messages: action.payload.messages,
    };
  },
  [ReduxChatbotActions.ACTION_ADD_PARTICIPANT]: (state: any, action: any) => {
    return {
      ...state,
      agent: action.payload.user,
      participants: [...(state.participants || []), action.payload.participant],
      users: [...(state.users || []), action.payload.user],
      messages: [...(state.messages || []), action.payload.message],
    };
  },
  [ReduxChatbotActions.ACTION_REMOVE_PARTICIPANT]: (
    state: any,
    action: any
  ) => {
    return {
      ...state,
      agentAvailableJoined: false,
      participants: [
        ...(state.participants || []).filter(
          (p: any) => p.sid !== action.payload.participant.sid
        ),
      ],
      users: [
        ...(state.users || []).filter(
          (u: any) => u.identity !== action.payload.participant.identity
        ),
      ],
    };
  },
  [ReduxChatbotActions.ACTION_UPDATE_PARTICIPANT]: (
    state: any,
    action: any
  ) => {
    return {
      ...state,
      participants: [
        ...(state.participants || []).reduce((acc: Participant[], p: any) => {
          if (p.sid === action.payload.participant.sid) {
            acc.push(action.payload.participant);
          } else {
            acc.push(p);
          }
          return acc;
        }, []),
      ],
    };
  },
  [ReduxChatbotActions.ACTION_ADD_MESSAGE]: (state: any, action: any) => {
    return {
      ...state,
      messages: [...(state.messages || []), action.payload.message],
    };
  },
  [ReduxChatbotActions.ACTION_REMOVE_MESSAGE]: (state: any, action: any) => {
    return {
      ...state,
      messages: [
        ...(state.messages || []).filter(
          (m: any) => m.sid !== action.payload.message.sid
        ),
      ],
    };
  },
  [ReduxChatbotActions.ACTION_UPDATE_MESSAGE]: (state: any, action: any) => {
    return {
      ...state,
      messages: [
        ...(state.messages || []).reduce((acc: Message[], m: any) => {
          if (m.sid === action.payload.message.sid) {
            acc.push(action.payload.message);
          } else {
            acc.push(m);
          }
          return acc;
        }, []),
      ],
    };
  },
  [ReduxChatbotActions.ACTION_SELECT_PERSONA]: (state: any, action: any) => {
    return {
      ...state,
      persona: action.payload.persona,
    };
  },
  [ReduxChatbotActions.ACTION_SET_BOT_TYPE]: (state: any, action: any) => {
    const isWorkflowBuilder =
      window.location.pathname.includes("workflow-builder");

    return {
      ...state,
      botType:
        action.payload?.botName?.toLowerCase()?.includes("workflow") ||
        isWorkflowBuilder
          ? "ws_workflow"
          : "default",
    };
  },
});

export default chatbotReducer;
