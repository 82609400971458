export const BotResponseType = {
  AUTH: "AUTH",
  TEXT: "text",
  SOURCE: "source",
  AGENT_HANDOFF_INIT: "agent_handoff_init",
  PERSONA_TAG: "persona_tags",
  MESSAGE: "message",
  CONTROL: "control",
  CAROUSEL: "carousel",
  KB_STREMMING: "kb_streaming",
};

export const AuthStatusType = {
  READY_FOR_AUTH_INFO: "READY_FOR_AUTH_INFO",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_FAILURE: "AUTH_FAILURE",
  AUTH_SKIPPED: "AUTH_SKIPPED",
};

export const ContentResponseType = {
  MESSAGE: "message",
  EOF: "EOF",
  SOURCE: "source",
  AGENT_HANDOFF_INIT: "agent_handoff_init",
};

export const SourceFileType = {
  DOC: "doc",
  DOCX: "docx",
  PDF: "pdf",
  HTML: "html",
  TEXT: "text",
  APPLICATION_DOC: "application/msword",
  APPLICATION_DOCX:
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  APPLICATION_PDF: "application/pdf",
  APPLICATION_TXT: "text/plain",
};
