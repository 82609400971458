import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  botHeaderContainer: {
    position: "fixed",
    display: "flex",
    width: "calc(100% - 72px)",
    padding: "16px 32px",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFF",
    boxShadow: "0px 1px 0px 0px #E6E6E6",
    zIndex: 1000,

    "& .back-button": {},

    "& .bot-details-container": {
      "& span": {
        lineHeight: "1",
      },
    },
  },
  botDetailsContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    columnGap: "24px",
  },
  botTabNavigation: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  botActionsContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default useStyles;
