import Chip, { ChipColors, ChipSizes } from "aether/Chip";
import { SourceStatus } from "pages/KnowledgeBase/types";

export const formatSourceStatus = (
  status: SourceStatus | boolean
): { label: string | null; color: ChipColors } => {
  if (typeof status === "boolean") {
    if (status) {
      return { label: "In Progress", color: ChipColors.default };
    } else {
      return { label: "Completed", color: ChipColors.success };
    }
  }

  switch (status) {
    case SourceStatus.PENDING:
      return { label: "Pending", color: ChipColors.warning };
    case SourceStatus.IN_PROGRESS:
      return { label: "In Progress", color: ChipColors.default };
    case SourceStatus.SUCCESS:
      return { label: "Completed", color: ChipColors.success };
    case SourceStatus.FAILED:
      return { label: "Failed", color: ChipColors.error };
    default:
      return { label: null, color: ChipColors.none };
  }
};

interface Props {
  status: SourceStatus | boolean;
}

const SourceStatusChip: React.FC<Props> = ({ status }) => {
  const { label, color } = formatSourceStatus(status);

  if (!label) return null;

  return <Chip label={label} color={color} size={ChipSizes.large} />;
};

export default SourceStatusChip;
