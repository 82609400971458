import { useNavigate } from "react-router-dom";

interface RouteNavigator {
  gotoChatbotDashboard: (botId: string) => void;
  gotoVoicebotDashboard: (botId: string) => void;
  gotoBotDashboard: () => void;
  gotoAgentBuilder: (assistantId: string, agentId: string) => void;
  gotoActionBuilder: (botId: string, agentId: string, toolId?: string) => void;
  gotoSiblingPath: (path: string) => void;
  gotoAbsolutePath: (path: string) => void;
  gotoAssistant: (assistantId: string) => void;
}

const useRouteNavigator = (): RouteNavigator => {
  const navigate = useNavigate();

  const gotoChatbotDashboard = (botId: string): void => {
    navigate(`/assistant/${botId}`);
  };

  const gotoVoicebotDashboard = (botId: string): void => {
    navigate(`/assistant/${botId}`);
  };

  const gotoBotDashboard = (): void => {
    navigate(`/assistant/`);
  };

  const gotoAgentBuilder = (assistantId: string, agentId: string): void => {
    navigate(`/assistant/${assistantId}/agent/${agentId}`);
  };

  const gotoActionBuilder = (
    botId: string,
    agentId: string,
    toolId: string = ""
  ): void => {
    navigate(`/bot/${botId}/action-builder/${agentId}/${toolId}`);
  };

  const gotoSiblingPath = (path: string): void => {
    navigate(`./${path}`);
  };

  const gotoAbsolutePath = (path: string): void => {
    navigate(path);
  };

  const gotoAssistant = (assistantId: string): void => {
    navigate(`/assistant/${assistantId}`);
  };

  return {
    gotoChatbotDashboard,
    gotoVoicebotDashboard,
    gotoBotDashboard,
    gotoAgentBuilder,
    gotoActionBuilder,
    gotoSiblingPath,
    gotoAbsolutePath,
    gotoAssistant,
  };
};

export default useRouteNavigator;
