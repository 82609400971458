import { AssistantRoute } from "pages/Assistant/types";
import { useNavigate } from "react-router-dom";

const useAssistantNavigation = (): any => {
  const navigate = useNavigate();

  const gotoAssistant = (assistantId: string): void => {
    navigate(`/assistant/${assistantId}`);
  };

  const gotoAssistantTab = (tab: AssistantRoute): void => {
    navigate(`./${tab}`);
  };

  return {
    gotoAssistant,
    gotoAssistantTab,
  };
};

export default useAssistantNavigation;
