import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useAssistantAgentsStyles = makeStyles((theme: Theme) => ({
  assistantAgentsContainer: {
    width: "100%",

    "& .agent-name-container": {
      "& .agent-name": {
        alignSelf: "flex-start",
        "&:hover": {
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
    },
  },
}));
