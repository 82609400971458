import React, { useEffect, useState, useRef, useContext } from "react";
import { makeStyles } from "@mui/styles";
import VisualizationContainer from "./VisualizationContainer";
import { VizLayout } from "pages/Analytics/types";
import Loading from "aether/Loading";
import ReactGridLayout from "react-grid-layout";
import { AnalyticsContext } from "pages/Analytics/Context/AnalyticsContext";

const DEFAULT_COLUMNS = 12;
const ROW_HEIGHT = 36;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%", // Ensure the container takes the full width
    position: "relative", // Ensures the grid layout is positioned relative to the container
    minHeight: "300px",
  },
}));

interface Props {
  layouts: VizLayout[];
}

const DashboardSectionGridLayout: React.FC<Props> = ({ layouts }) => {
  const classes = useStyles();
  const gridRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0); // Track the width of the container
  const { dashboard, loading } = useContext(AnalyticsContext);
  const visualizations = dashboard?.visualizations ?? [];

  // Effect to calculate the container width and set it on resize
  useEffect(() => {
    const updateContainerWidth = (): void => {
      if (gridRef.current) {
        setContainerWidth(gridRef.current.offsetWidth);
      }
    };

    updateContainerWidth(); // Initial calculation
    window.addEventListener("resize", updateContainerWidth); // Update on resize

    return () => {
      window.removeEventListener("resize", updateContainerWidth);
    };
  }, []);

  // Grid layout configuration
  const gridLayout = layouts.map((viz) => ({
    i: viz.vizId,
    static: true, // Make them static so they don't resize
    ...viz.layout,
  }));

  return (
    <div ref={gridRef} className={classes.container}>
      {containerWidth === 0 ? (
        <Loading />
      ) : (
        <ReactGridLayout
          className="layout"
          layout={gridLayout}
          cols={DEFAULT_COLUMNS}
          rowHeight={ROW_HEIGHT}
          width={containerWidth} // Use the responsive container width
          margin={[16, 16]} // Set the gap
          containerPadding={[0, 0]} // Ensure there is no padding inside the grid container
        >
          {layouts.map((vizLayout) => (
            <div
              key={vizLayout.vizId}
              data-grid={gridLayout.find((grid) => grid.i === vizLayout.vizId)}
            >
              <VisualizationContainer
                visualization={
                  visualizations.find((viz) => viz.vizId === vizLayout.vizId) ??
                  null
                }
                vizType={vizLayout.vizType}
                vizTitle={vizLayout.vizTitle}
                loading={loading}
              />
            </div>
          ))}
        </ReactGridLayout>
      )}
    </div>
  );
};

export default DashboardSectionGridLayout;
