import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  fieldWrapper: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",
  },
  actionButton: {
    marginTop: "8px",
  },
  description: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
  },
}));

export default useStyles;
