import { makeStyles } from "@mui/styles";
import EmbedCode from "./EmbedCode";
import Header from "../components/ContentHeader";

const useStyles = makeStyles((theme) => ({
  "knowledge-bot-container": {
    "& .content-wrapper": {
      display: "flex",
      flexDirection: "column",
      padding: "24px 32px",
      gap: "32px",

      "& .test": {
        backgroundColor: "black",
      },
    },
  },
}));

const EmbedChatbot: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes["knowledge-bot-container"]}>
      <div className="header">
        <Header
          title="Embed chatbot"
          subtitle="Get the code to integrate the chatbot into your website."
        />
      </div>

      <div className="content-wrapper">
        <EmbedCode />
      </div>
    </div>
  );
};

export default EmbedChatbot;
