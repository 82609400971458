import { TabNavigationCategory } from "../types";

export const ToolsNavigations: TabNavigationCategory[] = [
  {
    key: "tools-root",
    category: "",
    title: "",
    paths: [
      {
        key: "apis",
        path: "apis",
        title: "APIs",
        enabled: true,
      },
      // {
      //   key: "custom-flows",
      //   path: "custom-flows",
      //   title: "Custom Flows",
      //   enabled: false,
      // },
      // {
      //   key: "entities",
      //   path: "entities",
      //   title: "Entities",
      //   enabled: false,
      // },
      // {
      //   key: "agent-handoff",
      //   path: "agent-handoff",
      //   title: "Agent Handoff",
      //   enabled: false,
      // },
    ],
  },
];
