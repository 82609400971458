import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Outlet } from "react-router-dom";
import TabHeader from "../components/TabHeader";
import TabNavigationBar from "../components/TabNavigationBar";
import { ReactComponent as AssistantDeploymentIcon } from "assets/assistant-deployment-icon.svg";
import { DeploymentNavigations } from "./config";
import { useEffect } from "react";
import { SettingsActions } from "store/reduxActions/botActions/settingsSlice";
import { useDispatch } from "react-redux";

const deployment_header_title = "Deployment";
const deployment_header_description =
  "Skills may require APIs and custom flows to function effectively. You can integrate new APIs or create custom flows now, which can be utilized by skills later.";

const useStyles = makeStyles((theme: Theme) => ({
  deploymentContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: "24px",
  },
  deploymentContent: {
    display: "flex",
    flexDirection: "row",
    gap: "24px",
  },
  deploymentOutletContainer: {
    flex: 1,
    marginBottom: "24px",
  },
}));

const Deployment: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: SettingsActions.SET_USER_ON_SETTINGS_PAGE,
      payload: { userIsOnSettingsPage: true },
    });
    return () => {
      dispatch({
        type: SettingsActions.SET_USER_ON_SETTINGS_PAGE,
        payload: { userIsOnSettingsPage: false },
      });
    };
  }, []);

  return (
    <div className={classes.deploymentContainer}>
      <TabHeader
        icon={<AssistantDeploymentIcon />}
        title={deployment_header_title}
        description={deployment_header_description}
      />

      <div className={classes.deploymentContent}>
        <TabNavigationBar navigations={DeploymentNavigations} />

        <div className={classes.deploymentOutletContainer}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Deployment;
