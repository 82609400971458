import useStyles from "./styles";
import BotHeader from "pages/AgentBuilder/components/BotHeader";
import { AgentBuilderNavigations } from "./config";
import { isEmpty, isEqual } from "lodash";
import AgentComposition from "./AgentComposition";
import Button, { ButtonColors } from "aether/Button";
import { ReactComponent as ActivateIcon } from "assets/Activate.svg";
import { ReactComponent as AutoSaveIcon } from "assets/AutoSave.svg";
import { ReactComponent as DeactivateIcon } from "assets/deactivate.svg";
import { ReactComponent as SavingIcon } from "assets/saving.svg";
import { useContext, useState } from "react";
import AgentBuilderContext from "./AgentBuilderContext";
import { SkillStatus } from "./types";
import { useSnackbar } from "notistack";
import { patchAgent } from "api/bot/bot-agents";

const AgentBuilder: React.FC = () => {
  const classes = useStyles();
  const agentBuilderContext = useContext(AgentBuilderContext);
  const {
    agent: agentData,
    agentId,
    setAgent,
    isSavingInProgress,
    isEditingInProgress,
  } = agentBuilderContext;
  const [statusMutationInProgress, setStatusMutationInProgress] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleStatusChange = (
    newStatus: SkillStatus.ACTIVE | SkillStatus.INACTIVE
  ): void => {
    setStatusMutationInProgress(true);
    patchAgent({
      _id: agentId,
      status: newStatus,
    })
      .then((res) => {
        setAgent({ ...agentData, status: newStatus });
      })
      .catch((error) => {
        enqueueSnackbar(`Some error occurred ${error}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setStatusMutationInProgress(false);
      });
  };

  return (
    <div className={classes.agentBuilderContainer}>
      <BotHeader
        title={agentData?.name}
        description={agentData?.description}
        navigations={AgentBuilderNavigations}
      >
        <div className={classes.actionContainer}>
          {isSavingInProgress && (
            <div className={classes.autoSaving}>
              <SavingIcon />
              Saving changes...
            </div>
          )}
          {!isEditingInProgress && !isSavingInProgress && (
            <div className={classes.autoSave}>
              <AutoSaveIcon />
              Autosaved
            </div>
          )}
          {!isEqual(agentData?.status, SkillStatus.ACTIVE) ? (
            // we need to show Activate CTA on Draft and Inactive state
            <Button
              startIcon={<ActivateIcon />}
              onClick={() => {
                handleStatusChange(SkillStatus.ACTIVE);
              }}
              loading={statusMutationInProgress}
              disabled={
                isEmpty(agentData?.trigger) ||
                isEmpty(agentData?.general_prompt)
              }
              disabledTooltip={
                "This skill can’t be activated because some mandatory information is missing."
              }
            >
              Activate
            </Button>
          ) : (
            <Button
              startIcon={<DeactivateIcon />}
              color={ButtonColors.custom}
              customColors={{
                color: "var(--text-error, #B72D0E)",
                backgroundColor: "#ffff",
                borderColor: "var(--text-error, #B72D0E)",
              }}
              className={classes.deactivateBtn}
              onClick={() => {
                handleStatusChange(SkillStatus.INACTIVE);
              }}
              loading={statusMutationInProgress}
            >
              Deactivate
            </Button>
          )}
        </div>
      </BotHeader>

      <div className={classes.agentBuilderMainContainer}>
        <div className={classes.agentBuilderOutletContainer}>
          <AgentComposition />
        </div>
      </div>
    </div>
  );
};

export default AgentBuilder;
