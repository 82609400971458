import { makeStyles } from "@mui/styles";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles(() => ({
  textShimmerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
}));

const KPITileVisualizationShimmer: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.textShimmerContainer}>
      <Skeleton
        animation="wave"
        variant="rounded"
        width={220}
        height={40}
        key={"inSyncShimmer"}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        width={104}
        height={20}
        key={"inSyncShimmer"}
      />
    </div>
  );
};

export default KPITileVisualizationShimmer;
