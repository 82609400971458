import type React from "react";
import { useEffect, useState } from "react";
import { type ActionTileConfig, ActionType, Tool } from "./AgentActions.const";
import ActionTile from "./ActionTile";
import useStyles from "./ActionType.styles";
import { isEqual } from "lodash";
import ConnectorsAction from "./Connectors/ConnectorsAction";
import { useSnackbar } from "notistack";
import Loading from "aether/Loading";
import { getAllActionType, getTool } from "../Service/ActionBuilder.service";
import { getRequiredActionTilesConfig, getStepCount } from "./ActionType.util";
import ApiListContainer from "./Apis/ApiListContainer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { ActionBuilderActions } from "store/reduxActions/ActionBuilderActions";

const ActionTypeComponent: React.FC = () => {
  const { actionType } = useSelector((state) => state.actionBuilder);
  const dispatch = useDispatch();
  const [actionTypeConfigTiles, setActionTypeConfigTiles] = useState<
    ActionTileConfig[]
  >([]);
  const [tool, setTool] = useState<Tool | null>(null);
  const { botId, toolId } = useParams() || {};
  const [loading, setLoading] = useState<boolean>(true);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleActionTypeChange = (type: ActionType): void => {
    if (type) {
      dispatch({
        type: ActionBuilderActions.SET_ACTION_TYPE,
        payload: type,
      });
    }
  };

  useEffect(() => {
    // Start loading
    setLoading(true);

    const fetchData = async (): Promise<void> => {
      let modifiedActionType: ActionTileConfig[] = [];
      try {
        // Fetch all action types if botId is available
        if (botId) {
          const actionTypeResponse = await getAllActionType(botId);
          modifiedActionType = getRequiredActionTilesConfig(actionTypeResponse);
        }

        // Fetch tool details if toolId is available
        if (toolId) {
          const toolResponse = await getTool(toolId);
          setTool(toolResponse);
          // if we are going to update any tool then this tool action type should only be visible and selected.
          modifiedActionType = modifiedActionType.filter(
            (actionType) => actionType.type === toolResponse?.action_type
          );
          handleActionTypeChange(toolResponse?.action_type);
        }
        setActionTypeConfigTiles(modifiedActionType);
      } catch (err) {
        enqueueSnackbar("Something went wrong:", {
          variant: "error",
        });
      } finally {
        // Set loading to false once both calls are done (successful or error)
        setLoading(false);
      }
    };

    void fetchData();

    // Cleanup
    return () => {
      dispatch({
        type: ActionBuilderActions.CLEAR_SLICE,
      });
    };
  }, [botId, dispatch]);

  const getChildComponent = (
    type: ActionType | null,
    tool: Tool | null
  ): React.ReactNode => {
    switch (type) {
      case ActionType.CONNECTORS:
        return <ConnectorsAction tool={tool} />;
      case ActionType.API:
        return <ApiListContainer tool={tool} />;
      case ActionType.FLOWS:
        return null;
      default:
        return null;
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.step}>{`Step 1${getStepCount(
          actionType
        )}`}</div>
        <div className={classes.title}>Select Action type</div>
        {loading && <Loading />}
        <div className={classes.tiles}>
          {actionTypeConfigTiles.map((tile) => {
            return (
              <ActionTile
                key={tile.type}
                tileConfig={tile}
                onClick={() => {
                  handleActionTypeChange(tile.type);
                }}
                selected={isEqual(actionType, tile.type)}
                isFeaturePending={isEqual(tile.type, ActionType.FLOWS)}
              />
            );
          })}
        </div>
      </div>
      {!loading && getChildComponent(actionType, tool)}
    </>
  );
};

export default ActionTypeComponent;
