import type React from "react";
import { type ActionParam } from "../../AgentActions.const";
import useStyles from "./SelectedAPIAction.styles";
import { IconButton, TextField } from "@mui/material";
import { ReactComponent as LogInIcon } from "assets/log-in-01.svg";
import ActionParamTile from "../../RightPanel/ActionPreview/ActionParamTile";
import { ActionBuilderActions } from "store/reduxActions/ActionBuilderActions";
import { useDispatch, useSelector } from "store";
import { debounce, isEmpty, isEqual } from "lodash";
import { IAPI } from "types/APIIntegration";
import Typography, {
  TypographyColors,
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { ReactComponent as CodeIcon } from "assets/code-API.svg";
import Button from "components/base/Button";
import { ReactComponent as SuccessIcon } from "assets/check-circle-broken.svg";
import { ReactComponent as LinkExternal } from "assets/link-external-icon.svg";
import JSONSnippetRenderer from "./JSONSnippetRenderer";
import { useCallback } from "react";

interface Props {
  action: IAPI;
  onChange: () => void;
  onMoreInfoAndTest: () => void;
  updateActionDisplayName: (name: string) => void;
  updateActionInstructions: (instructions: string) => void;
  hideChangeButton?: boolean;
  initialName?: string;
  initialInstructions?: string;
}
const SelectedAPIAction: React.FC<Props> = ({
  action,
  onChange,
  onMoreInfoAndTest,
  updateActionDisplayName,
  updateActionInstructions,
  hideChangeButton = false,
  initialInstructions = "",
  initialName = "",
}) => {
  const classes = useStyles();
  const { selectedConfigurableParam } = useSelector(
    (state) => state.actionBuilder
  );
  const dispatch = useDispatch();
  const onClickParamItem = (param: ActionParam): void => {
    dispatch({
      type: ActionBuilderActions.SET_SELECTED_CONFIGURABLE_PARAM,
      payload: param,
    });
  };

  // Debounced functions for handling updates to name and instructions
  const debouncedUpdateDisplayName = useCallback(
    debounce((name: string) => {
      updateActionDisplayName(name);
    }, 500),
    [updateActionDisplayName]
  );

  const debouncedUpdateInstructions = useCallback(
    debounce((instructions: string) => {
      updateActionInstructions(instructions);
    }, 500),
    [updateActionInstructions]
  );

  // Handlers for changes in displayName and instructions
  const handleDisplayNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newDisplayName = event.target.value;
    debouncedUpdateDisplayName(newDisplayName); // Call the debounced function
  };

  const handleInstructionsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newInstructions = event.target.value;
    debouncedUpdateInstructions(newInstructions); // Call the debounced function
  };

  return (
    <div className={classes.container}>
      <div className={classes.selectedTableItem}>
        <div className={classes.iconTitleContainer}>
          <CodeIcon
            style={{
              width: "24px",
              height: "24px",
            }}
          />
          <div>
            <Typography
              weight={TypographyWeights.bold}
              variant={TypographyVariants.textLarge}
            >
              {action.name}
            </Typography>
            <Typography
              weight={TypographyWeights.medium}
              variant={TypographyVariants.text}
            >
              {action.description}
            </Typography>
          </div>
        </div>
        <div className={classes.actionsContainer}>
          <Button
            variant="text"
            color="secondary"
            size="medium"
            className={classes.previewButton}
            endIcon={<LinkExternal />}
            onClick={onMoreInfoAndTest}
          >
            More info & test
          </Button>
          {!hideChangeButton && (
            <Button
              variant="text"
              color="secondary"
              size="medium"
              className={classes.previewButton}
              onClick={onChange}
            >
              Change
            </Button>
          )}
          <Button
            className={classes.successBtn}
            variant="contained"
            color="primary"
            size="medium"
            iconBtn
            startIcon={<SuccessIcon />}
          >
            Selected
          </Button>
        </div>
      </div>
      {/* For Input params */}
      {!isEmpty(action?.extracted_variables) && (
        <div className={classes.paramHead}>
          <IconButton>
            <LogInIcon />
          </IconButton>
          <Typography
            weight={TypographyWeights.bold}
            variant={TypographyVariants.textLarge}
          >
            Configure inputs
          </Typography>
        </div>
      )}
      <div className={classes.tiles}>
        {action?.extracted_variables?.map((param) => {
          return (
            <div key={param?.id} className={classes.tile}>
              <ActionParamTile
                key={param.id}
                param={param}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  onClickParamItem(param);
                }}
                showTileActive={isEqual(
                  selectedConfigurableParam?.id,
                  param.id
                )}
              />
            </div>
          );
        })}
      </div>
      <Typography
        weight={TypographyWeights.bold}
        variant={TypographyVariants.textLarge}
        className="mt-4"
      >
        Example from last run
      </Typography>
      {!isEmpty(action?.last_run_response) ? (
        <JSONSnippetRenderer
          data={action?.last_run_response}
          lastRunDate={action?.last_run}
        />
      ) : (
        <Typography
          variant={TypographyVariants.text}
          weight={TypographyWeights.medium}
          color={TypographyColors.subtle}
        >
          We couldn’t find any history for the last run.
        </Typography>
      )}
      <div className="flex-col row-gap-8 mt-4">
        <Typography
          weight={TypographyWeights.semiBold}
          variant={TypographyVariants.text}
        >
          Action Display name (Optional)
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          placeholder={"Action Display name"}
          fullWidth
          onChange={handleDisplayNameChange}
          defaultValue={initialName}
        />
      </div>
      <div className="flex-col row-gap-8 mt-4">
        <Typography
          weight={TypographyWeights.semiBold}
          variant={TypographyVariants.text}
        >
          Add instructions (Optional)
        </Typography>
        <TextField
          defaultValue={initialInstructions}
          variant="outlined"
          size="small"
          placeholder={
            "E.g. Whenever this action is used exclude the 'delivery partner' from the output. The delivery status can be used as input for other "
          }
          fullWidth
          onChange={handleInstructionsChange}
          multiline
          minRows={3}
          maxRows={10}
        />
      </div>
    </div>
  );
};

export default SelectedAPIAction;
