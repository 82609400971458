import type React from "react";
import useStyles from "./ActionConfigure.style";
import { ReactComponent as CrossIcon } from "assets/x-close.svg";
import { ReactComponent as TrashIcon } from "assets/trash-01.svg";
import { ReactComponent as HelpCircleIcon } from "assets/help-circle.svg";
import { ReactComponent as SaveIcon } from "assets/save-01.svg";
import { ReactComponent as NoteIcon } from "assets/alert-square.svg";
import Button from "components/base/Button";
import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { type ActionParam } from "../../AgentActions.const";
import { useEffect, useState } from "react";
import Typography, {
  TypographyColors,
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";

interface Props {
  param: ActionParam;
  onClose: () => void;
  onSave: (value: string) => void;
}

const ActionParamConfigure: React.FC<Props> = ({ param, onClose, onSave }) => {
  const classes = useStyles();
  const [defaultValue, setDefaultValue] = useState(param.value ?? "");

  useEffect(() => {
    setDefaultValue(param.value ?? "");
  }, [param]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography
            variant={TypographyVariants.textXL}
            weight={TypographyWeights.bold}
          >
            {param.key}
          </Typography>
          <CrossIcon onClick={onClose} />
        </div>
        <div className={classes.content}>
          <div>
            <Typography
              variant={TypographyVariants.textLarge}
              weight={TypographyWeights.bold}
            >
              Description
            </Typography>
            <Typography
              variant={TypographyVariants.text}
              weight={TypographyWeights.medium}
            >
              {param.description ?? ""}
            </Typography>
            <Typography
              variant={TypographyVariants.text}
              weight={TypographyWeights.medium}
              color={TypographyColors.subtle}
            >
              {param.example ?? ""}
            </Typography>
          </div>
          {/* next row */}
          <div>
            <Typography
              variant={TypographyVariants.text}
              weight={TypographyWeights.semiBold}
            >
              Display name
            </Typography>
            <div className={classes.inputContainer}>
              <Typography
                variant={TypographyVariants.text}
                weight={TypographyWeights.medium}
                color={TypographyColors.subtle}
              >
                {param.key ?? ""}
              </Typography>
              <HelpCircleIcon />
            </div>
          </div>
          {/* next row */}
          <div>
            <Typography
              variant={TypographyVariants.text}
              weight={TypographyWeights.semiBold}
            >
              Provide a default value (optional)
            </Typography>
            <TextField
              type="text"
              className={classes.defaultInput}
              value={defaultValue}
              onChange={(e) => {
                setDefaultValue(e.target.value);
              }}
            />
            <div className={classes.noteContainer}>
              <div className={classes.noteIcon}>
                <NoteIcon />
              </div>
              <Typography
                variant={TypographyVariants.text}
                weight={TypographyWeights.medium}
                color={TypographyColors.subtle}
              >
                Default value will used incase user input is not available.
              </Typography>
            </div>
          </div>
          {/* next row */}
          <div>
            <Typography
              variant={TypographyVariants.text}
              weight={TypographyWeights.semiBold}
            >
              How the departure date is sourced?
            </Typography>
            <div className={classes.inputContainer}>
              <Typography
                variant={TypographyVariants.text}
                weight={TypographyWeights.medium}
                color={TypographyColors.subtle}
              >
                Extracted from the user’s query
              </Typography>
              <ExpandMore />
            </div>
          </div>
          {/* next row */}
          <div>
            <Typography
              variant={TypographyVariants.text}
              weight={TypographyWeights.semiBold}
            >
              Is this input required from the user to continue the conversation?
            </Typography>
            <RadioGroup row={false} className="px-1" value={param.required}>
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Yes, it's mandatory"
                disabled
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="No, it's optional"
                disabled
              />
            </RadioGroup>
          </div>
        </div>
        <div className={classes.footer}>
          <Button
            color="secondary"
            className={classes.configureBtn}
            onClick={onClose}
            iconBtn
            startIcon={<TrashIcon />}
          >
            Cancel changes
          </Button>
          <Button
            color="primary"
            className={classes.configureBtn}
            onClick={() => {
              onSave(defaultValue);
            }}
            iconBtn
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default ActionParamConfigure;
