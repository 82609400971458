import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputURLField from "./InputURLField";
import { useCallback, useMemo, useRef, useState } from "react";
import InputNameField from "./InputNameField";
import { ReactComponent as ClearIcon } from "assets/x-close.svg";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { useDispatch } from "store";
import { SourceActions } from "store/reduxActions/botActions";
import CenteredLoader from "components/shared/CenteredLoader";
import { useSnackbar } from "notistack";
import Button, { ButtonSizes, ButtonVariants } from "aether/Button";
import { postLinkSource } from "api/knowledge-base/source";

const useStyles = makeStyles((theme) => ({
  "source-link-container": {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
}));

const LinkSource: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [showURLError, setShowURLError] = useState(false);
  const [name, setName] = useState("");
  const [showNameError, setShowNameError] = useState(false);

  const areFieldValuesValid = useMemo(() => {
    return !(url === "" || name === "" || showURLError || showNameError);
  }, [name, showNameError, showURLError, url]);

  const confirmButtonWidthRef = useRef<number>();
  const confirmButtonHeightRef = useRef<number>();

  const dialogRef = useRef<any>();

  const onClickClear = useCallback(() => {
    setUrl("");
    setName("");
    setShowURLError(false);
    setShowNameError(false);
  }, []);

  const onClickAddSource = useCallback(() => {
    if (!areFieldValuesValid) {
      enqueueSnackbar("Please fill all the fields with valid values", {
        variant: "error",
      });
      return;
    }

    setIsLoading(true);
    postLinkSource({ url, name })
      .then((response) => {
        dispatch({
          type: SourceActions.POST_LINK_SOURCE_SUCCESS,
          payload: { link: response },
        });

        enqueueSnackbar("Source added", {
          variant: "success",
        });

        onClickClear();
      })
      .catch(() => {
        enqueueSnackbar("Some error occurred. Please try again", {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [areFieldValuesValid, dispatch, enqueueSnackbar, name, onClickClear, url]);

  return (
    <div className={classes["source-link-container"]}>
      <Box display="flex" flexDirection="column" mt={3}>
        <Box mb={0.75}>
          <div className="fs-14 fw-600">Enter a URL</div>
        </Box>
        <Box mb={3}>
          <InputURLField
            url={url}
            setUrl={setUrl}
            showURLError={showURLError}
            setShowURLError={setShowURLError}
          />
        </Box>
        <Box mb={0.75}>
          <div className="fs-14 fw-600">Give it a name</div>
        </Box>
        <Box mb={3}>
          <InputNameField
            name={name}
            setName={setName}
            showNameError={showNameError}
            setShowNameError={setShowNameError}
          />
        </Box>
        <Box display="flex">
          <Box mr={2}>
            {isLoading ? (
              <Box display="flex" alignItems="center">
                <CenteredLoader
                  width={`${confirmButtonWidthRef.current}px`}
                  height={`${confirmButtonHeightRef.current}px`}
                />
              </Box>
            ) : (
              <Box
                ref={(node) => {
                  dialogRef.current = node;
                  // Do your work requiring the node here, but make sure node isn't null.

                  if (dialogRef.current) {
                    const buttonEle = dialogRef.current.getElementsByClassName(
                      "AskConfirmation-ConfirmButton"
                    )[0];

                    if (buttonEle) {
                      const { width, height } =
                        buttonEle.getBoundingClientRect();

                      confirmButtonWidthRef.current = width;

                      confirmButtonHeightRef.current = height;
                    }
                  }
                }}
              >
                <Button
                  size={ButtonSizes.small}
                  className="AskConfirmation-ConfirmButton"
                  onClick={onClickAddSource}
                  startIcon={<PlusIcon />}
                >
                  Add source and train
                </Button>
              </Box>
            )}
          </Box>
          <Box>
            <Button
              variant={ButtonVariants.outlined}
              size={ButtonSizes.small}
              onClick={onClickClear}
              startIcon={<ClearIcon />}
            >
              Clear
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default LinkSource;
