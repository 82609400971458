export enum VizType {
  DONUT = "DONUT",
  BAR_CHART = "BAR_CHART",
  KPI = "KPI",
  BAR_AND_DONUT = "BAR_AND_DONUT",
}

export interface Layout {
  x: number;
  y: number;
  h: number;
  w: number;
}

// -------------------------------- Donut Chart --------------------------------

export interface CenterLabel {
  main: string;
  sub?: string;
}

export interface DonutChartSegment {
  name: string;
  value: number;
  color: string;
  hideLegend?: boolean;
  info?: string;
}

export interface DonutChartData {
  centerLabel?: CenterLabel;
  segments: DonutChartSegment[];
}

// pending check with arshdeep string or number works for resolutionRate below props
export interface DonutChartVizData {
  resolutionRate: string;
  percentageChange: number;
  isChangeTrendPositive: boolean;
  comparisonLabel: string;
  data: DonutChartData;
}

// -------------------------------- KPI Chart --------------------------------

export interface KPIChartVizData {
  primaryValue: string;
  percentageChange: number;
  isChangeTrendPositive: boolean;
  comparisonLabel: string;
}

// -------------------------------- Bar Chart --------------------------------

export interface BarChartCategory {
  id: string; // Unique identifier for the category (e.g., "conversations", "resolved")
  label: string;
  color: string; // Assigned color for the category
}

export interface BarChartValues {
  categoryId: string;
  value: number;
}

export interface BarChartEntry {
  attribute: string; // X-axis label (e.g., "Today", "Yesterday")
  values: BarChartValues[];
}

export interface BarChartData {
  categories: BarChartCategory[];
  data: BarChartEntry[];
  yAxisLabel: string | null;
}

// -------------------------------- Bar and Donut Chart --------------------------------
// pending null
export interface BarAndDonutVizData {
  donutChartdata: DonutChartData;
  barChartData: BarChartData;
}

// -------------------------------- Metadata Chart --------------------------------

export interface Visualization {
  vizId: string;
  vizTitle: string;
  info?: string; // need to show on hover
  vizType: VizType;
  vizData: DonutChartVizData | KPIChartVizData | BarAndDonutVizData;
}

export interface Filter {
  id: string;
  allValues: Array<{ id: string; label: string }>;
  currentFilterId: string;
}

export interface VizLayout {
  vizId: string;
  vizType: VizType;
  vizTitle: string;
  layout: Layout;
}

export interface SectionConfig {
  id: string;
  label: string;
  icon: string;
  layouts: VizLayout[];
}

export interface DashboardLayout {
  id: string;
  sections: SectionConfig[];
}

export interface AnalyticsDashboardData {
  id: string;
  insufficientData?: boolean;
  insuffecientFilteredData?: boolean;
  filters: Filter[];
  visualizations: Visualization[];
}
