import BotDescription from "../BotDescription";
import { ReactComponent as ChatBotDisabledIcon } from "./assets/chat-bot-disabled-icon.svg";

interface Props {
  message?: string;
}

const ChatBotDisabledState: React.FC<Props> = ({ message }) => {
  return (
    <BotDescription
      imageComponent={<ChatBotDisabledIcon />}
      heading={"It’s bit quiet here"}
      subHeading={
        message ??
        "Add sources to get Phi start providing answers to your's customer's questions 🤓"
      }
    />
  );
};

export default ChatBotDisabledState;
