import { Client } from "@twilio/conversations";
import { type Dispatch } from "redux";

import { initMessagesListener } from "./listeners/messagesListener";
import { initParticipantsListener } from "./listeners/participantsListener";
import { initConversationListener } from "./listeners/conversationListener";
import { ReduxChatbotActions } from "store/reduxActions/ReduxChatbotActions";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function initSession({
  token,
  conversationSid,
}: {
  token: string;
  conversationSid: string;
}) {
  return async (dispatch: Dispatch) => {
    let conversationsClient: Client;
    let conversation;
    let participants;
    let users;
    let messages;

    try {
      conversationsClient = new Client(token);
      try {
        conversation =
          await conversationsClient.getConversationBySid(conversationSid);
      } catch (e) {
        // !!! Add notification or error handle for this case
        // eslint-disable-next-line no-console
        console.log(e);
        return;
      }

      participants = await conversation.getParticipants();
      // eslint-disable-next-line @typescript-eslint/return-await
      users = await Promise.all(participants.map(async (p) => p.getUser()));
      messages = (await conversation.getMessages(20)).items;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      throw e;
    }

    dispatch({
      type: ReduxChatbotActions.ACTION_START_SESSION,
      payload: {
        token,
        conversationSid,
        conversationsClient,
        conversation,
        users,
        participants,
        messages,
        conversationState: conversation.state?.current,
        // currentPhase: EngagementPhase.MessagingCanvas,
      },
    });

    // initClientListeners(conversationsClient, dispatch);
    initConversationListener(conversation, dispatch);
    initMessagesListener(conversation, dispatch);
    initParticipantsListener(conversation, dispatch);
  };
}
