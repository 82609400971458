import {
  IChatbotSettings,
  type AssistantSettings,
} from "pages/Assistant/types";
import { botBEAxiosInstance } from "services/axios";

export const getAssistantSettings = async (
  assistantId: string
): Promise<AssistantSettings> => {
  return await botBEAxiosInstance({
    method: "GET",
    url: `/assistants/${assistantId}/settings`,
  });
};

// TODO: Update types of these appearance and all settings
export const postAssistantChatbotSettings = async (
  assistantId: string,
  values: IChatbotSettings
): Promise<AssistantSettings> => {
  return await botBEAxiosInstance({
    method: "PUT",
    url: `/assistants/${assistantId}/chatbot_settings`,
    data: values,
  });
};

export const updateAssistantChatbotSettings = async (
  assistantId: string,
  values: IChatbotSettings
): Promise<AssistantSettings> => {
  return await botBEAxiosInstance({
    method: "PUT",
    url: `/assistants/${assistantId}/chatbot_settings`,
    data: values,
  });
};

export const patchAssistantChatbotSettings = async (
  assistantId: string,
  values: Partial<IChatbotSettings>
): Promise<{ data: { settings: AssistantSettings } }> => {
  return await botBEAxiosInstance({
    method: "PATCH",
    url: `/assistants/${assistantId}/chatbot_settings`,
    data: values,
  });
};

export const uploadAssistantLogoFile = async (
  assistantId: string,
  data: any
): Promise<any> => {
  return await botBEAxiosInstance({
    method: "POST",
    url: `/assistants/${assistantId}/chatbot_settings/logos`,
    data,
  });
};
