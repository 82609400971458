import Typography from "components/base/Typography";
import { TextField, type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FieldArray, useFormikContext } from "formik";
import { Link } from "react-router-dom";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { type IIntegrationFormValues } from "types/APIIntegration";

const useStyles = makeStyles((theme: Theme) => ({
  "tab-params-container": {
    marginTop: "24px",

    "& .key-container, & .key-input-container": {
      width: "184px",
    },
    "& .value-container, & .value-input-container": {
      width: "184px",
    },
    "& .description-container, & .description-input-container": {
      width: "460px",
    },

    "& .add-param-btn": {
      "& .btn-text": {
        color: "#C9671D",
      },
      "& svg": {
        marginRight: "4px",

        "& path": {
          fill: "#C9671D",
        },
      },

      "&:hover": {
        "& .btn-text": {
          color: "#FF7B00",
        },
        "& svg": {
          "& path": {
            fill: "#FF7B00",
          },
        },
      },
    },
  },
}));

interface Props {
  disableAllFields?: boolean;
}

const TabParams: React.FC<Props> = ({ disableAllFields = false }) => {
  const classes = useStyles();

  const { values, handleBlur, handleChange } =
    useFormikContext<IIntegrationFormValues>();

  return (
    <div className={classes["tab-params-container"]}>
      <div className="params-header flex col-gap-24 mb-8">
        <div className="t-head key-container">
          <Typography className="w-semi-bold">Key</Typography>
        </div>
        <div className="t-head value-container">
          <Typography className="w-semi-bold">Value</Typography>
        </div>
        <div className="t-head description-container">
          <Typography className="w-semi-bold">Description</Typography>
        </div>
      </div>

      <div className="params-input-container">
        <FieldArray name="params">
          {({ insert, remove, push }) => (
            <div className="flex flex-col row-gap-16">
              {values.params.length > 0 &&
                values.params.map((param, index) => (
                  <div key={index} className="t-row flex col-gap-24">
                    <div className="key-input-container">
                      <TextField
                        name={`params.${index}.key`}
                        variant="outlined"
                        size="small"
                        placeholder=""
                        fullWidth
                        value={param.key}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disableAllFields}
                      />
                    </div>

                    <div className="value-input-container">
                      <TextField
                        name={`params.${index}.value`}
                        variant="outlined"
                        size="small"
                        placeholder=""
                        fullWidth
                        value={param.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disableAllFields}
                      />
                    </div>

                    <div className="description-input-container">
                      <TextField
                        name={`params.${index}.description`}
                        variant="outlined"
                        size="small"
                        placeholder=""
                        fullWidth
                        value={param.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disableAllFields}
                      />
                    </div>
                  </div>
                ))}

              {!disableAllFields && (
                <Link
                  className="add-param-btn no-decoration flex align-items-center"
                  to={""}
                  onClick={() => {
                    push({
                      key: "",
                      value: "",
                      description: "",
                    });
                  }}
                >
                  <PlusIcon />
                  <Typography variant="textSm" className="btn-text w-semi-bold">
                    Key value pair
                  </Typography>
                </Link>
              )}
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default TabParams;
