import { SourceActions } from "store/reduxActions/botActions";
import { createReducer } from "../util";

const initialState = {
  data: {
    link: [],
    file: [],
  },
  loading: false,
  error: false,
};

const sourceSlice = createReducer(initialState, {
  [SourceActions.FETCH_SOURCE_LIST_LOADING]: (state: any, action: any) => {
    return {
      ...state,
      loading: action.payload.loading,
    };
  },
  [SourceActions.FETCH_SOURCE_LIST_SUCCESS]: (state: any, action: any) => {
    console.log(action.payload);

    return {
      ...state,
      data: {
        link: action.payload.link,
        file: action.payload.file,
      },
      loading: false,
      error: false,
    };
  },
  [SourceActions.FETCH_SOURCE_LIST_ERROR]: (state: any, action: any) => {
    return {
      ...state,
      loading: false,
      error: true,
    };
  },
  [SourceActions.POST_LINK_SOURCE_SUCCESS]: (state: any, action: any) => {
    return {
      ...state,
      loading: false,
      data: {
        ...state.data,
        link: [...state.data.link, action.payload.link],
      },
    };
  },
  [SourceActions.POST_FILE_SOURCE_SUCCESS]: (state: any, action: any) => {
    return {
      ...state,
      loading: false,
      data: {
        ...state.data,
        file: [...state.data.file, ...action.payload.files],
      },
    };
  },
  [SourceActions.DELETE_LINK_SOURCE_SUCCESS]: (state: any, action: any) => {
    const itemIdToDelete = action.payload.id;
    const currActionList = [...state.data.link];

    const index = currActionList.findIndex(
      (currAction) => currAction.id === itemIdToDelete
    );

    return {
      ...state,
      loading: false,
      data: {
        ...state.data,
        link: currActionList
          .slice(0, index)
          .concat(currActionList.slice(index + 1)),
      },
    };
  },
  [SourceActions.DELETE_FILE_SOURCE_SUCCESS]: (state: any, action: any) => {
    const itemIdToDelete = action.payload.id;
    const currActionList = [...state.data.file];

    const index = currActionList.findIndex(
      (currAction) => currAction.id === itemIdToDelete
    );

    return {
      ...state,
      loading: false,
      data: {
        ...state.data,
        file: currActionList
          .slice(0, index)
          .concat(currActionList.slice(index + 1)),
      },
    };
  },
  [SourceActions.PUT_LINK_SOURCE_SUCCESS]: (state: any, action: any) => {
    const itemIdToUpdate = action.payload.id;
    const currActionList = [...state.data.link];

    const index = currActionList.findIndex(
      (currAction) => currAction.id === itemIdToUpdate
    );

    return {
      ...state,
      loading: false,
      data: {
        ...state.data,
        link: currActionList
          .slice(0, index)
          .concat([action.payload])
          .concat(currActionList.slice(index + 1)),
      },
    };
  },
  [SourceActions.PUT_FILE_SOURCE_SUCCESS]: (state: any, action: any) => {
    const currActionList = [...state.data.file];
    const itemId = action.payload.id;

    const itemToUpdate = currActionList?.find((el) => el.id === itemId);
    const updatedItem = {
      ...itemToUpdate,
      ...action.payload.response,
    };

    const index = currActionList.findIndex(
      (currAction) => currAction.id === itemId
    );

    return {
      ...state,
      loading: false,
      data: {
        ...state.data,
        file: currActionList
          .slice(0, index)
          .concat([updatedItem])
          .concat(currActionList.slice(index + 1)),
      },
    };
  },
});

export default sourceSlice;
