import type React from "react";
import useStyles from "./styles";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { type ReactNode } from "react";
import { noop } from "lodash";

interface Props {
  title?: string;
  onIconClick?: () => void;
  icon: ReactNode;
}

const IntegrationHeader: React.FC<Props> = ({
  title = "Integrations",
  onIconClick = noop,
  icon,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.integrationsIcon} onClick={onIconClick}>
        {icon}
      </div>
      <Typography
        variant={TypographyVariants.textXXL}
        weight={TypographyWeights.bold}
      >
        {title}
      </Typography>
    </div>
  );
};

export default IntegrationHeader;
