import { KnowledgeBase, KnowledgeBaseMetadata } from "pages/Assistant/types";

interface BeginMessageConfig {
  user_first: boolean;
  static_message: string | null;
  ai_prompt: string | null;
}

export interface Agent {
  name: string;
  description: string;
  knowledge_base: KnowledgeBase | null;
  kb_metadata: KnowledgeBaseMetadata;
  created: string;
  modified: string;
  company_info_text: string | null;
  goal: string | null;
  guidelines_and_rules: string | null;
  begin_message_config: BeginMessageConfig;
  trigger: string;
  general_prompt: string;
  _id: string;
  tenant: string;
  bot_id: string;
  voice_settings?: any;
  general_tools?: any;
  status: SkillStatus;
}

export enum SkillStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DRAFT = "DRAFT",
}
