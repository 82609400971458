import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "#FFF",
    width: "calc(33.33% - 16px)",
    overflow: "hidden",
    cursor: "pointer",
  },
  image: {
    width: "72px",
    height: "68px",
    flexShrink: 0,
  },
  titleTagContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: "8px",
    overflow: "hidden",
  },
  description: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
  },
}));

export default useStyles;
