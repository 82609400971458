import { Box } from "@mui/material";
import Typography from "components/base/Typography";
import Button from "components/base/Button";
import { makeStyles } from "@mui/styles";

interface Props {
  message: string;
  retryBtnText?: string;
  onRetry?: () => void;
  errorExtraAction?: () => void;
  errorExtraActionText?: string;
}

const useStyles = makeStyles(() => ({
  actions: {
    "& > *": {
      marginTop: "16px",
      marginRight: "16px",
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  retryButton: {
    color: "#d32f2f",
    borderColor: "#d32f2f",
    fontWeight: 400,
    padding: "4px",
    "&:hover": {
      borderColor: "#d32f2f",
    },
  },
}));

const ErrorSection: React.FC<Props> = ({
  message,
  retryBtnText,
  onRetry,
  errorExtraAction,
  errorExtraActionText,
}) => {
  const classes = useStyles();

  return (
    <Box textAlign="center" color="error.main">
      <Typography color="error" variant="textSm" className="w-semi-bold">
        {message}
      </Typography>
      {(onRetry ?? errorExtraAction) && (
        <>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={classes.actions}
          >
            {onRetry && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={onRetry}
                size="small"
                className={classes.retryButton}
              >
                {retryBtnText}
              </Button>
            )}
            {errorExtraAction && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={errorExtraAction}
                size="small"
              >
                {errorExtraActionText}
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

ErrorSection.defaultProps = {
  retryBtnText: undefined,
  onRetry: undefined,
};

export default ErrorSection;
