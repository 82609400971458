import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  paramHead: {
    display: "flex",
    marginTop: "24px",
    marginBottom: "16px",
  },
  tiles: {
    gap: "16px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tile: {
    cursor: "pointer",
    width: "49%",
  },
  selectedTableItem: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid var(--border-active, #F07400)",
    background: "#FFF",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    gap: "16px",
  },
  iconTitleContainer: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  previewButton: {
    marginRight: "16px",
    whiteSpace: "nowrap",
  },
  selectButton: {
    whiteSpace: "nowrap",
  },
  successBtn: {
    whiteSpace: "nowrap",
    color: "var(--text-Green-note, #308060)",
    background: "var(--colors-primary-lime-100, #E7FDF3)",
    "&:hover": {
      background: "var(--colors-primary-lime-100, #E7FDF3)",
    },
  },
  actionsContainer: {
    display: "flex",
  },
}));

export default useStyles;
