import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Header from "./components/Header";
import { Outlet } from "react-router-dom";

export const useStyles = makeStyles((theme: Theme) => ({
  knowledgeBaseContainer: {
    width: "calc(100vw - 72px)",
    height: "100vh",
    overflowX: "auto",
  },
  knowledgeBaseOutletContainer: {
    height: "calc(100vh - 80px)",
    width: "100%",
    minWidth: "1200px",
    position: "relative",
    top: "80px",
    padding: "16px 32px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [theme.breakpoints.down("lg")]: {
      alignItems: "flex-start",
    },
  },
}));

const KnowledgeBase: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.knowledgeBaseContainer}>
      <Header />

      <div className={classes.knowledgeBaseOutletContainer}>
        <Outlet />
      </div>
    </div>
  );
};

export default KnowledgeBase;
