// StickeyFooter.styles.ts
import { makeStyles } from "@mui/styles";

export const useFooterStyles = makeStyles(() => ({
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    position: "sticky",
    bottom: 0,
    background: "#FFF",
    boxShadow: "0px -2px 5px rgba(0,0,0,0.1)",
    zIndex: 1,
  },
  message: {
    flex: 1,
    fontSize: "14px",
    lineHeight: "20px",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    marginLeft: "16px",
    marginRight: "16px",
  },
  button: {
    textTransform: "none",
  },
}));
