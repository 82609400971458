import {
  type IContextVariableState,
  type ISessionContext,
} from "pages/Bot/WorkflowBuilder/types";
import { createReducer } from "../util";
import { SessionContextActions } from "store/reduxActions/botActions/contextSlice";
import { ContextVariableState } from "pages/Bot/WorkflowBuilder/config";
import { deleteNodeContext } from "pages/Bot/WorkflowBuilder/utils/workflow";

interface Props {
  loading: boolean;
  error: boolean;
  empty: boolean;
  session_context: ISessionContext;
}

const initialState: Props = {
  loading: true,
  error: false,
  empty: false,
  session_context: {
    [ContextVariableState.CONVERSATION]: {},
    [ContextVariableState.DIALOG]: {},
    [ContextVariableState.GLOBBAL]: {},
    [ContextVariableState.INTERNAL]: {},
    [ContextVariableState.USER]: {},
  },
};

const contextReducer = createReducer(initialState, {
  [SessionContextActions.SET_SESSION_CONTEXT_LOADING]: (
    state: Props,
    action: any
  ) => {
    return {
      ...state,
      loading: action.payload.loading,
    };
  },
  [SessionContextActions.SET_SESSION_CONTEXT_ERROR]: (
    state: Props,
    action: any
  ) => {
    return {
      ...state,
      loading: false,
      empty: false,
      error: true,
    };
  },
  [SessionContextActions.SET_SESSION_CONTEXT_DATA]: (
    state: Props,
    action: any
  ) => {
    return {
      loading: false,
      empty: false,
      error: false,
      session_context: action.payload.session_context,
    };
  },
  [SessionContextActions.DELETE_NODE_CONTEXT]: (state: Props, action: any) => {
    const {
      contextState,
      nodeId,
    }: { contextState: IContextVariableState; nodeId: string } = action.payload;
    const sessionContext = state.session_context;

    const updatedSessionContext = deleteNodeContext({
      nodeId,
      contextState,
      sessionContext,
    });

    return {
      ...state,
      session_context: updatedSessionContext,
    };
  },
});

export default contextReducer;
