import { isEqual } from "lodash";
import { type ConfigField } from "pages/Integrations/integrations.types";
import * as Yup from "yup";

export const MANUAL_ENTERED_NAME = "name";
const nameConfigField: ConfigField = {
  key: MANUAL_ENTERED_NAME,
  label: "Name for reference",
  capture_before_auth: true,
  ui_visible: true,
  description:
    "This is helpful if you want to have two connections with same app",
  protected: false,
  required: true,
};
const useFormikWrapper = (
  fields: ConfigField[],
  preConfiguredValues: Record<string, string>,
  connectionName: string = ""
): {
  UIVisibleFields: ConfigField[];
  createValidationSchema: (fields: ConfigField[]) => any;
  initialValues: Record<string, string>;
} => {
  const UIVisibleFields = fields.filter((field) => field?.ui_visible);
  // entering one more field apart from the configuration coming from backend to collect connection name
  UIVisibleFields.unshift(nameConfigField);

  const createValidationSchema = (fields: ConfigField[]): any => {
    const shape: Record<string, any> = {};

    fields.forEach((field) => {
      if (field.required) {
        shape[field.key] = Yup.string().required("This field is required");
      } else {
        shape[field.key] = Yup.string();
      }
    });
    return Yup.object().shape(shape);
  };

  // Formik initial values
  const initialValues = UIVisibleFields.reduce<Record<string, string>>(
    (acc, field) => {
      if (isEqual(field.key, MANUAL_ENTERED_NAME)) {
        acc[field.key] = connectionName;
      } else {
        acc[field.key] = preConfiguredValues?.[field.key] || "";
      }
      return acc;
    },
    {}
  );
  return { UIVisibleFields, createValidationSchema, initialValues };
};

export default useFormikWrapper;
