import Chip, { ChipColors } from "aether/Chip";
import { APIStatus } from "../../types";

export const formatSourceStatus = (
  status: APIStatus
): { label: string | null; color: ChipColors } => {
  switch (status) {
    case APIStatus.PENDING:
      return { label: "Pending", color: ChipColors.warning };
    case APIStatus.DRAFT:
      return { label: "Draft", color: ChipColors.default };
    case APIStatus.SUCCESS:
      return { label: "Success", color: ChipColors.success };
    case APIStatus.FAILED:
      return { label: "Failed", color: ChipColors.error };
  }
};

interface Props {
  status: APIStatus;
}

const APIStatusChip: React.FC<Props> = ({ status }) => {
  const { label, color } = formatSourceStatus(status) || {};

  if (!label) return null;

  return <Chip label={label} color={color} />;
};

export default APIStatusChip;
