export const SourceActions = {
  FETCH_SOURCE_LIST_SUCCESS: "SOURCE_LIST_SUCCESS",
  FETCH_SOURCE_LIST_ERROR: "SOURCE_LIST_ERROR",
  FETCH_SOURCE_LIST_LOADING: "SOURCE_LIST_LOADING",
  POST_LINK_SOURCE_SUCCESS: "POST_LINK_SOURCE_SUCCESS",
  POST_FILE_SOURCE_SUCCESS: "POST_FILE_SOURCE_SUCCESS",
  DELETE_LINK_SOURCE_SUCCESS: "DELETE_LINK_SOURCE_SUCCESS",
  DELETE_FILE_SOURCE_SUCCESS: "DELETE_FILE_SOURCE_SUCCESS",
  PUT_LINK_SOURCE_SUCCESS: "PUT_LINK_SOURCE_SUCCESS",
  PUT_FILE_SOURCE_SUCCESS: "PUT_FILE_SOURCE_SUCCESS",
};
