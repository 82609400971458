import { combineReducers } from "@reduxjs/toolkit";

import botReducer from "./botSlice";
import settingsReducer from "./settingsSlice";
import sourceReducer from "./sourcesSlice";
import workflowReducer from "./workflowSlice";
import contextReducer from "./contextSlice";
import apiIntegrationsReducer from "./apiIntegrationsSlice";

const reducer = combineReducers({
  api_integrations: apiIntegrationsReducer,
  meta: botReducer,
  settings: settingsReducer,
  sources: sourceReducer,
  workflow: workflowReducer,
  context: contextReducer,
});

export default reducer;
