import { MenuItem, type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { useFormikContext } from "formik";
import { TextField } from "@mui/material";
import { type IIntegrationFormValues } from "types/APIIntegration";
import { RequestType } from "pages/Bot/types";

const useStyles = makeStyles((theme: Theme) => ({
  "request-editor-wrapper": {
    display: "flex",
    columnGap: "16px",

    "& .http-method-container": {
      width: "94px",
    },
    "& .http-url-container": {
      flex: 1,
    },
  },
}));

interface RequestEditorProps {
  disabled?: boolean;
}

const RequestEditor: React.FC<RequestEditorProps> = ({ disabled = false }) => {
  const classes = useStyles();

  const { values, touched, errors, handleBlur, handleChange } =
    useFormikContext<IIntegrationFormValues>();

  return (
    <div className={classes["request-editor-wrapper"]}>
      <div className="form-input-container http-method-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">Method</Typography>
        <TextField
          name="http_method"
          variant="outlined"
          size="small"
          placeholder=""
          fullWidth
          select
          value={values.http_method}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.http_method && Boolean(errors.http_method)}
          helperText={touched.http_method && errors.http_method}
          disabled={disabled}
        >
          {Object.values(RequestType).map((method, index) => (
            <MenuItem key={method} value={method}>
              {method}
            </MenuItem>
          ))}
        </TextField>
      </div>

      <div className="form-input-container http-url-container flex flex-col row-gap-6">
        <Typography className="w-semi-bold">HTTPS URL</Typography>
        <TextField
          disabled={disabled}
          name="url"
          variant="outlined"
          size="small"
          fullWidth
          value={values.url}
          onChange={handleChange}
          onBlur={handleBlur}
          // error={touched.url && Boolean(errors.url)}
          // helperText={touched.url && errors.url}
        />
      </div>
    </div>
  );
};

export default RequestEditor;
