import type React from "react";
import { makeStyles } from "@mui/styles";
import { useState, useEffect } from "react";
import "./index.scss";
import Markdown from "react-markdown";

const useStyles = makeStyles({
  "markdown-container": {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",

    "& p": {
      margin: 0,
    },
    "& ol": {
      paddingLeft: "16px",
    },
    "& li": {
      margin: "8px 0px",
    },
    "& ul": {
      paddingLeft: "24px",
      margin: "8px 0px",
    },
  },
});

interface TypewriterProps {
  textStream: string;
  setIsTypingComplete: (val: boolean) => void;
  isMessageComplete: boolean;
}

const Typewriter: React.FC<TypewriterProps> = ({
  textStream,
  setIsTypingComplete,
  isMessageComplete,
}) => {
  const classes = useStyles();

  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const chatListContainer = document.getElementById("chat-list");

    if (chatListContainer) {
      chatListContainer.scrollTop =
        chatListContainer?.scrollHeight ?? chatListContainer.scrollTop;
    }
  }, [displayText]);

  useEffect(() => {
    if (currentIndex < textStream.length) {
      const timer = setTimeout(() => {
        setDisplayText(textStream.substring(0, currentIndex + 1));
        setCurrentIndex(currentIndex + 1);
      }, 5); // Adjust the typing speed as needed

      return () => {
        clearTimeout(timer);
      };
    } else if (isMessageComplete) {
      setIsTypingComplete(true);
    }
  }, [
    currentIndex,
    displayText,
    isMessageComplete,
    setIsTypingComplete,
    textStream,
  ]);

  return (
    <Markdown className={classes["markdown-container"]}>{displayText}</Markdown>
  );
};

export default Typewriter;
