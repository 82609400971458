import React from "react";
import ReactDOM from "react-dom/client";
import "style/main.scss";
import GlobalSpinner from "components/shared/GlobalSpinner";
import App from "./App";
import mixpanel from "mixpanel-browser";
import CONFIG from "config";

mixpanel.init(CONFIG.MIXPANEL, {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.Suspense fallback={<GlobalSpinner />}>
    <App />
  </React.Suspense>
);
