import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    gap: "24px",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  swagContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    width: "100%",
  },
  image: {
    width: "62px",
    height: "60px",
    flexShrink: 0,
  },
  actions: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",
    marginTop: "24px",
    flexShrink: 0,
  },
  actionButton: {
    width: "100% !important",
  },
  links: { display: "flex", gap: "4px", cursor: "pointer" },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  complianceContainer: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
  },
}));

export default useStyles;
