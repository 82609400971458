import { makeStyles } from "@mui/styles";

export const useAppearanceStyles = makeStyles((theme) => ({
  "appearance-container": {
    "& .content-wrapper": {
      display: "flex",
      flexDirection: "column",
      // padding: "24px 24px",
      gap: "32px",

      "& .test": {
        backgroundColor: "black",
      },
    },
  },
}));

export const useBasicSettingsStyles = makeStyles((theme) => ({
  "basic-settings-container": {
    display: "flex",
    flexDirection: "column",
    rowGap: "32px",

    "& .basic-settings-row": {
      "& .form-input-container": {
        width: "44%",
        display: "flex",
        flexDirection: "column",
        rowGap: "6px",
      },
    },
  },
}));

export const useChatCollapsedSettingsStyles = makeStyles((theme) => ({
  "chat-collapsed-settings-container": {
    display: "flex",
    flexDirection: "column",
    rowGap: "32px",

    "& .settings-row": {
      "& .form-input-container": {
        width: "44%",
        display: "flex",
        flexDirection: "column",
        rowGap: "6px",
      },
    },
  },
}));

export const useChatWidgetSettingsStyles = makeStyles((theme) => ({
  "chat-widget-settings-container": {
    display: "flex",
    flexDirection: "column",
    rowGap: "32px",

    "& .settings-row": {
      "& .form-input-container": {
        width: "44%",
        display: "flex",
        flexDirection: "column",
        rowGap: "6px",
      },

      "& .color-picker-wrapper": {
        borderRadius: "5px",
        border: "1px solid #E1DEDA",

        "& .color-picker": {
          display: "block",
          padding: "0px",
          height: "36px",
          width: "36px",
          cursor: "pointer",
          border: "none",

          "&::-webkit-color-swatch-wrapper": {
            padding: "0px",
            border: "none",
            borderRadius: "4px",
          },

          "&::-moz-color-swatch": {
            border: "none",
            borderRadius: "4px",
          },

          "&::-webkit-color-swatch": {
            border: "none",
            borderRadius: "4px",
          },
        },
      },

      "& .header-logo-container": {
        "& .header-logo": {
          display: "flex",
          width: "72px",
          height: "72px",
          padding: "8px 12px",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          borderRadius: "8px",
          border: "1px solid var(--input-border-default, #E1DEDA)",
          background: "var(--input-background-default, #FFF)",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

          "& .logo-content": {},
        },

        "& .header-logo-upload": {
          "& .header-upload-button": {
            "& svg": {
              width: "14px",
              height: "14px",

              "& path": {
                fill: "#C9671D",
              },
            },
          },
        },
      },

      "& .chat-bubble-logo-container": {
        "& .chat-bubble-logo": {
          display: "flex",
          width: "56px",
          height: "56px",
          padding: "16px",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          borderRadius: "8px",
          border: "1px solid var(--border-default, #E1DEDA)",
          background: "#FFF",

          "&.select-chat-bubble-logo": {
            border: "1px solid #F07400",
          },

          "& .logo-content": {
            height: "24px",
            width: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "16px",
            background: "var(--inactive-surfaces-disabled, #F3F2F0)",

            "& svg": {
              width: "16px",
              height: "16px",
            },
          },
        },

        "& .chat-bubble-logo-upload": {
          "& .chat-bubble-upload-button": {
            "& svg": {
              width: "14px",
              height: "14px",

              "& path": {
                fill: "#C9671D",
              },
            },
          },
        },
      },
    },
  },
}));
