import { makeStyles } from "@mui/styles";
import { type Theme } from "@mui/material/styles";
import { type ButtonCustomColorProps } from ".";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: "8px",
    textTransform: "none",

    "&.MuiButton-root": {
      "&.Mui-disabled": {
        backgroundColor: "#E1DEDA !important",
        color: "#B5B1AD !important",

        "& svg": {
          "& path": {
            fill: "#B5B1AD",
          },
        },
      },
    },
  },
  // Variants
  text: (props: { color: string; customColors: ButtonCustomColorProps }) => ({
    color: {
      primary: "#F07400", // TODO: define on theme
      secondary: "#E7FDF3",
      tertiary: "#fff3cd",
      warning: "#f8d7da",
      error: "#E53811",
      custom: props?.customColors?.backgroundColor,
    }[props.color],

    "&:hover": {
      color: {
        primary: "#FF7B00", // TODO: define on theme, that is strip of primary on scale light to dark
        secondary: "#E7FDF3",
        tertiary: "#fff3cd",
        warning: "#f8d7da",
        error: "#EF866F",
        custom: props?.customColors?.backgroundColor,
      }[props.color],
      backgroundColor: "transparent",
    },

    "& svg": {
      "& path": {
        fill: {
          primary: "#F07400", // TODO: define on theme
          secondary: "#E7FDF3",
          tertiary: "#fff3cd",
          warning: "#f8d7da",
          error: "#E53811",
          custom: props?.customColors?.backgroundColor,
        }[props.color],
      },
      "&:hover": {
        "& path": {
          primary: "#FF7B00", // TODO: define on theme, that is strip of primary on scale light to dark
          secondary: "#E7FDF3",
          tertiary: "#fff3cd",
          warning: "#f8d7da",
          error: "#EF866F",
          custom: props?.customColors?.backgroundColor,
        }[props.color],
      },
    },
  }),
  contained: (props: {
    color: string;
    customColors: ButtonCustomColorProps;
  }) => ({
    color: "#FFF",
    background: {
      primary: "#F07400",
      secondary: "#FFF",
      tertiary: "#fff3cd",
      warning: "#f8d7da",
      error: "#E53811",
      custom: props?.customColors?.backgroundColor,
    }[props.color],

    "& svg": {
      height: "16px",
      width: "16px",

      "& path": {
        fill: {
          primary: "#FFF",
          secondary: "#282624",
          tertiary: "#FFF",
          warning: "#FFF",
          error: "#FFF",
          custom: props?.customColors?.color,
        }[props.color],
      },
    },

    "&:hover": {
      background: {
        primary: "#FF7B00", // TODO: define on theme, that is strip of primary on scale light to dark
        secondary: "#FFF",
        tertiary: "#fff3cd",
        warning: "#f8d7da",
        error: "#EF866F",
        custom: props?.customColors?.backgroundColor,
      }[props.color],
    },
  }),
  outlined: (props: {
    color: string;
    customColors: ButtonCustomColorProps;
  }) => ({
    border: "1px solid",
    background: "#FFF",

    color: {
      primary: "#F07400",
      secondary: "#282624",
      tertiary: "#282624",
      warning: "#FFF",
      error: "#FFF",
      custom: props?.customColors?.borderColor,
    }[props.color],
    borderColor: {
      primary: "#F07400",
      secondary: "#E1DEDA",
      tertiary: "#282624",
      warning: "#FFF",
      error: "#FFF",
      custom: props?.customColors?.borderColor,
    }[props.color],
    "& svg": {
      height: "16px",
      width: "16px",

      "& path": {
        fill: {
          primary: "#F07400",
          secondary: "#282624",
          tertiary: "#282624",
          warning: "#FFF",
          error: "#FFF",
          custom: props?.customColors?.borderColor,
        }[props.color],
      },
    },

    "&:hover": {
      backgroundColor: "#F9F8F8",
    },
  }),

  // Size variants
  xsmall: {
    height: "32px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "0.14px",
  },
  small: {
    height: "40",
    padding: "8px 16px",
    fontSize: "16px",
    fontWeight: "700",
    letterSpacing: "0.16px",
  },
  medium: {
    height: "48px",
    padding: "12px 24px",
    fontSize: "16px",
    fontWeight: "700",
    letterSpacing: "0.16px",
  },
  large: {
    height: "56px",
    padding: "16px 32px",
    fontSize: "16px",
    fontWeight: "700",
    letterSpacing: "0.16px",
  },
}));

export default useStyles;
