import Typography, {
  TypographyWeights,
  TypographyVariants,
} from "aether/Typography";
import { ConnectorActionsConfig } from "../../AgentActions.const";
import useStyles from "./selectedConnectorAction.styles";
import Button from "components/base/Button";
import { ReactComponent as PuzzleIcon } from "assets/puzzle-piece-02-black.svg";
import { ReactComponent as SuccessIcon } from "assets/check-circle-broken.svg";
import ActionDetails from "./ActionDetails";

interface Props {
  action: ConnectorActionsConfig;
  onChange?: () => void;
  hideChangeButton?: boolean;
}
const SelectedConnectorAction: React.FC<Props> = ({
  action,
  onChange,
  hideChangeButton,
}) => {
  const classes = useStyles();
  if (!action) return null;
  return (
    <div className={classes.container}>
      <div className={classes.selectedTableItem}>
        <div className={classes.iconTitleContainer}>
          <PuzzleIcon
            style={{
              width: "24px",
              height: "24px",
            }}
          />
          <div>
            <Typography
              weight={TypographyWeights.bold}
              variant={TypographyVariants.textLarge}
            >
              {action?.name}
            </Typography>
            <Typography
              weight={TypographyWeights.medium}
              variant={TypographyVariants.text}
            >
              {action?.description}
            </Typography>
          </div>
        </div>
        <div className={classes.actionsContainer}>
          {!hideChangeButton && (
            <Button
              variant="text"
              color="secondary"
              size="medium"
              className={classes.previewButton}
              onClick={onChange}
            >
              Change
            </Button>
          )}
          <Button
            className={classes.successBtn}
            variant="contained"
            color="primary"
            size="medium"
            iconBtn
            startIcon={<SuccessIcon />}
          >
            Selected
          </Button>
        </div>
      </div>
      <ActionDetails action={action} />
    </div>
  );
};

export default SelectedConnectorAction;
