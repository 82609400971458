import { botBEAxiosInstance as botBEAxios } from "services/axios";
import { formatAgentGeneralInfoBody } from "./transformers";

export const createAgent = async (data: any): Promise<any> => {
  return await botBEAxios({
    method: "POST",
    url: `/agents/`,
    data,
  });
};

export const getAllAgents = async (assistantId: string): Promise<any> => {
  return await botBEAxios({
    method: "GET",
    url: `/agents/all/${assistantId}/`,
  });
};

export const getAgentData = async (agentId: string): Promise<any> => {
  return await botBEAxios({
    method: "GET",
    url: `/agents/${agentId}/`,
  });
};

export const deleteAgent = async (agentId: string): Promise<any> => {
  return await botBEAxios({
    method: "DELETE",
    url: `/agents/${agentId}/`,
  });
};

export const getAgentGeneralInfo = async (agentId: string): Promise<any> => {
  return await botBEAxios({
    method: "GET",
    url: `/agents/${agentId}/basic_info/`,
  });
};

export const patchAgent = async (data: any): Promise<any> => {
  return await botBEAxios({
    method: "PATCH",
    url: `/agents/`,
    data,
  });
};

export const updateAgentGeneralInfo = async (data: any): Promise<any> => {
  const formattedData = formatAgentGeneralInfoBody(data);
  return await patchAgent(formattedData);
};

// Script
export const getAgentScript = async (agentId: string): Promise<any> => {
  return await botBEAxios({
    method: "GET",
    url: `/agents/${agentId}/script/`,
  });
};

export const updateAgentScript = async (data: any): Promise<any> => {
  return await patchAgent(data);
};

// Tools
export const getToolList = async (agentId: string): Promise<any> => {
  return await botBEAxios({
    method: "GET",
    url: `/tools/${agentId}/`,
  });
};

export const postTool = async (data: any): Promise<any> => {
  return await botBEAxios({
    method: "POST",
    url: `/tools/`,
    data,
  });
};

export const updateTool = async (data: any): Promise<any> => {
  return await botBEAxios({
    method: "PATCH",
    url: `/tools/`,
    data,
  });
};

export const deleteTool = async (toolId: string): Promise<any> => {
  return await botBEAxios({
    method: "DELETE",
    url: `/tools/${toolId}/`,
  });
};
