import Modal from "components/base/Modal/Modal";
import { useState } from "react";
import Typography, { TypographyVariants } from "aether/Typography";
import IconButton from "aether/IconButton";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { useSnackbar } from "notistack";
import { deleteAPI } from "api/workflow/integration";

interface Props {
  id: string;
  onSuccessDeleteCb?: (data: any) => void;
}

const DeleteAPIModal: React.FC<Props> = ({ id, onSuccessDeleteCb }) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirmDelete = async (): Promise<void> => {
    setLoading(true);
    deleteAPI(id)
      .then(() => {
        enqueueSnackbar(`Deleted successfully`, {
          variant: "success",
        });
        if (onSuccessDeleteCb) {
          onSuccessDeleteCb(id);
        }
        setOpenModal(false);
      })
      .catch((error: any) => {
        enqueueSnackbar(`Something went wrong: ${error}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <IconButton
        size="xsmall"
        variant="outlined"
        color="error"
        onClick={(event) => {
          setOpenModal(true);
        }}
      >
        <DeleteIcon />
      </IconButton>

      <Modal
        variant={"error"}
        open={openModal}
        title={"Delete the API"}
        showConfirmLoading={loading}
        confirmText={"Delete"}
        cancelText={"Don't delete"}
        onClose={() => {
          setOpenModal(false);
        }}
        onConfirm={() => {
          void handleConfirmDelete();
        }}
        onCancel={() => {
          setOpenModal(false);
        }}
      >
        <div className="flex-col mb-16">
          <Typography variant={TypographyVariants.textLarge}>
            {
              "Are you sure you would like to delete the API. This action is non reversible."
            }
          </Typography>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteAPIModal;
