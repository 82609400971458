import { Connector } from "pages/Integrations/integrations.types";

export const enum ActionType {
  CONNECTORS = "CONNECTOR",
  FLOWS = "FLOWS",
  API = "API",
}

export enum VariableDataTypes {
  STRING = "String",
  NUMBER = "Number",
  BOOLEAN = "Boolean",
}

export interface ConnectorActionToolConfig {
  action_config_id: string;
  connection_id: string;
  action_config: ConnectorActionsConfig;
  input_params: ActionParam[];
  output_params: ActionParam[];
}

export interface APIActionToolConfig {
  api_integration_id: string;
  instructions: string;
  input_params: ActionParam[];
}

export interface Tool {
  _id: string;
  tenant: string;
  bot_id: string | null;
  agent_id: string;
  action_type: ActionType;
  api_integration_id: string | null;
  config: ConnectorActionToolConfig & APIActionToolConfig;
  name: string;
  description: string | null;
}

export interface ActionTileConfig {
  type: ActionType;
  name: string;
  description: string;
  actionCount: number;
  actionCountLabel: string;
  subActionCount: number;
  subActionLabel: string;
  icon: React.ReactNode;
}

export interface ConnectorConfig {
  _id: string;
  name: string;
  description: string;
  action_count: number;
  icon: string;
  connection_count: number;
  credential_type: string;
  label: string;
}

export interface ActionParam {
  id: number;
  key: string;
  description: string;
  data_type: VariableDataTypes;
  example: string;
  value?: string;
  required: boolean;
}

export interface ConnectorActionsConfig {
  _id: string;
  connector_id: string;
  connection_id: string;
  connector: Connector;
  name: string;
  description: string;
  label: string;
  input_params: ActionParam[];
  output_params: ActionParam[];
}
