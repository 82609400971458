import { AnalyticsDashboardData, DashboardLayout, Filter } from "../types";
import { botBEAxiosInstance as botBEAxios } from "services/axios";

export const getAnalyticsDashboard = async (
  data: Filter[] = []
): Promise<AnalyticsDashboardData> => {
  const res = await botBEAxios({
    method: "POST",
    url: `/analytics/dashboard/data/`,
    data,
  });
  return res?.data?.dashboard_data;
};

export const getAnalyticsDashboardLayout =
  async (): Promise<DashboardLayout> => {
    const res = await botBEAxios({
      method: "GET",
      url: `/analytics/dashboard/layout/`,
    });
    return res?.data?.dashboard_layout;
  };
