import { type AgentInfoInput, type AssistantType } from "pages/Assistant/types";

export const formatAssistantRequest = ({
  name,
  description,
  enabled_channels,
}: {
  name: string;
  description: string;
  enabled_channels?: [AssistantType];
}): any => {
  const formattedAssistant: any = {
    assistant: {
      name,
      description,
    },
  };

  if (enabled_channels) {
    formattedAssistant.enabled_channels = enabled_channels;
  }

  return formattedAssistant;
};

export const formatAgentGeneralInfoBody = (data: any): AgentInfoInput => {
  const body: AgentInfoInput = {
    ...data,
    begin_message_config: {
      user_first: true,
      static_message: null,
      ai_prompt: data.begin_message_ai_prompt,
    },
  };

  return body;
};
