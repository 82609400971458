export interface INavigationItem {
  key: string;
  path: string;
  title: string;
  enabled: boolean;
}

export interface INavigationCategory {
  category: string;
  key: string;
  title: string;
  paths: INavigationItem[];
}

export enum RequestBodyType {
  JSON = "JSON",
  FormData = "FormData",
}

export enum RequestType {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export interface IBotSettings {
  suggestions: string[];
  voice_settings: any | null;
  end_user_type: "CUSTOMER" | "AGENT" | string;
  enabled_channels: string[];
  bot_display_name: string;
  header_message: string;
  welcome_message: string;
  input_box_placeholder: string;
  bot_response_length: "short" | "medium" | "long" | string;
  show_source_of_info: "yes" | "no";
  request_feedback: "yes" | "no";
  display_nudge: "yes" | "no";
  nudge_message: string;
  default_widget_state: "open" | "closed" | string;
  no_bot_response: string;
  status_banner: "yes" | "no";
  end_conversation_msg: string;
  header_accent_color: string;
  header_text_color: string;
  header_logo_url: string | null;
  chat_bubble_url: string | null;
  chat_bubble_filename: string;
  header_logo_enabled: "yes" | "no";
  chat_bubble_enabled: "yes" | "no";
}

export interface BotConfig {
  id: string;
  enabled_channels: string[] | null;
  status: "NO_INDEX" | "INDEXED" | string;
  settings: IBotSettings;
  created: string; // ISO 8601 format date
  modified: string; // ISO 8601 format date
  name: string;
  description: string;
  enabled: boolean;
  deleted: boolean;
}
