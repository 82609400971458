// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const BOT_WS_BASE_URL = (endUserType: string, botType: string) => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const env: string = process.env.REACT_APP_ENVIRONMENT || "";

  let baseApiUrl = "";
  switch (env) {
    case "PRODUCTION":
      baseApiUrl = "prod";
      break;

    default:
      // staging for stagex, dev, local, preprod envs
      baseApiUrl = "staging";
      break;
  }

  if (window.location.pathname.includes("workflow-builder")) {
    return `wss://bot-${baseApiUrl}.thelevel.ai/ws_workflow_preview`;
  }

  if (endUserType === "AGENT") {
    return `wss://bot-${baseApiUrl}.thelevel.ai/ws-agent`;
  }

  if (botType === "ws_workflow") {
    return `wss://bot-${baseApiUrl}.thelevel.ai/ws_workflow`;
  }

  return `wss://bot-${baseApiUrl}.thelevel.ai/ws/v2`;
};
