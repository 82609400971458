import { Checkbox, TableBody, TableCell, TableRow } from "@mui/material";
import { TableRowSelectionProps, type TableColumnProps } from ".";
import {
  TableEmpty,
  TableError,
  TableLoader,
  type EmptyStateProps,
} from "aether/TableState";
import clsx from "clsx";
import useStyles from "./style";

interface TableBodyProps<RowData> {
  columns: Array<TableColumnProps<RowData>>;
  data: RowData[];
  loading: boolean;
  emptyState: EmptyStateProps;
  selectState?: TableRowSelectionProps;
  error: boolean;
  errorMessage: string;
  rowClassNames?: string;
}

const TableBodyContainer = <RowData,>(): React.FC<TableBodyProps<RowData>> => {
  const TableBodyContainerComponent: React.FC<TableBodyProps<RowData>> = ({
    data,
    columns,
    loading,
    selectState,
    emptyState,
    error,
    errorMessage,
    rowClassNames,
  }) => {
    const classes = useStyles();

    if (loading) {
      const totalSpan = selectState?.enableSelection
        ? columns.length + 1
        : columns.length;
      return <TableLoader colSpan={totalSpan} />;
    }

    if (emptyState.empty) {
      const totalSpan = selectState?.enableSelection
        ? columns.length + 1
        : columns.length;
      return <TableEmpty {...emptyState} colSpan={totalSpan} />;
    }

    if (error) {
      const totalSpan = selectState?.enableSelection
        ? columns.length + 1
        : columns.length;
      return <TableError colSpan={totalSpan} />;
    }

    return (
      <TableBody className={classes.tableBody}>
        {data?.map((row: any, rowIndex) => {
          const isSelected = selectState?.selected?.includes(row?.id);

          return (
            <TableRow
              hover
              tabIndex={-1}
              key={rowIndex}
              className={clsx(classes.rowContainer, rowClassNames)}
            >
              {selectState?.enableSelection && (
                <TableCell padding="checkbox">
                  <Checkbox
                    size="small"
                    color="primary"
                    onChange={(event) => {
                      selectState?.onSelect(event, row?.id);
                    }}
                    checked={isSelected}
                    inputProps={{
                      "aria-labelledby": `${rowIndex}`,
                    }}
                  />
                </TableCell>
              )}

              {columns.map((column, colIndex) => (
                <TableCell key={colIndex}>
                  {column?.render ? column.render(row) : "-"}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  TableBodyContainerComponent.displayName = "TableBodyContainer";

  return TableBodyContainerComponent;
};

export default TableBodyContainer;
