import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  iconWrapper: {
    width: "100px",
    height: "100px",
    position: "absolute",
    right: "0",
    bottom: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "transform 0.25s ease-in-out",
    "&:hover": { transform: "scale(1.1)" },
    "&.hide": {
      transform: "translateY(100%)",
      pointerEvents: "none",
    },
  },
  popUpContainer: {
    margin: "40px 20px 50px",
    padding: "24px",
    width: "500px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    bottom: "7px",
    backgroundColor: "#fff",
    borderRadius: "16px",
    boxShadow:
      "0px 0px 1px 0px rgba(38, 38, 38, 0.31), 0px 18px 28px 0px rgba(38, 38, 38, 0.15)",
    "&.show": { transform: "translateY(0%)", opacity: 1 },
    "&.hide": {
      display: "none",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    [theme.breakpoints.down("xl")]: {
      maxHeight: "calc(100% - 40px)",
      maxWidth: "calc(100% - 40px)",
      opacity: 0,
      transform: "translateY(150%)",
      transition: `transform 0.5s cubic-bezier(0.68, 0, 0.265, 1) 0.2s, opacity 0.5s linear 0.2s, height 0.5s cubic-bezier(0.68, 0, 0.265, 1) 0.2s`,
    },
  },
}));

export default useStyles;
