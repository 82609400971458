import { type ITabSwitchOption } from "components/base/TabSwitch";
import { ReactComponent as InfoSquareIcon } from "assets/info-square.svg";
import { ReactComponent as FileIcon } from "assets/file-icon.svg";

export const AgentBuilderNavigations: ITabSwitchOption[] = [
  {
    label: "General Info",
    key: "general-info",
    icon: InfoSquareIcon,
  },
  {
    label: "Script",
    key: "script",
    icon: FileIcon,
  },
];
