import { countryCodeMap } from "constant/CountryCodeMap";

export interface CountryFlagIconProps {
  countryCode: string;
}

const CountryFlagIcon: React.FC<CountryFlagIconProps> = ({ countryCode }) => {
  const isoCode = countryCodeMap[countryCode] || "";

  return (
    <span className={`fi fi-${isoCode}`} style={{ marginRight: "8px" }}></span>
  );
};

export default CountryFlagIcon;
