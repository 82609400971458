import Typography, { TypographyVariants } from "aether/Typography";
import { deleteAssistant } from "api/assistant/assistant";
import Modal from "components/base/Modal/Modal";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { type IAssistant } from "pages/Assistant/types";

const delete_assistant_modal_title = "Delete the assistant";
const delete_assistant_modal_text =
  "Are you sure you would like to delete the assistant. This action is non reversible.";
const delete_assistant_confirm_button = "Delete";
const delete_assistant_cancel_button = "Don't delete";

interface Props {
  open: boolean;
  assistant: IAssistant;
  onClose: () => void;
  onDeleteSuccessCb: (data: IAssistant) => void;
}

const DeleteAssistant: React.FC<Props> = ({
  open,
  assistant,
  onClose,
  onDeleteSuccessCb,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const handleConfirmDelete = async (): Promise<void> => {
    setLoading(true);

    deleteAssistant(assistant._id)
      .then((response) => {
        onClose();
        onDeleteSuccessCb(assistant);

        enqueueSnackbar(`Assistant deleted successfully.`, {
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(`Something went wrong, try again.`, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      variant="error"
      open={open}
      title={delete_assistant_modal_title}
      showConfirmLoading={loading}
      confirmText={delete_assistant_confirm_button}
      cancelText={delete_assistant_cancel_button}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() => {
        void handleConfirmDelete();
      }}
    >
      <div className="flex-col mb-16">
        <Typography variant={TypographyVariants.textLarge}>
          {delete_assistant_modal_text}
        </Typography>
      </div>
    </Modal>
  );
};

export default DeleteAssistant;
