import { Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import HeadedDialog from "components/shared/HeadedDialog";
import Typography from "components/base/Typography";
import Button from "components/base/Button";
import CommonAskConfirmationButton from "components/shared/CommonAskConfirmationButton";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { deleteFileSource, deleteLinkSource } from "api/knowledge-base/source";

const useStyles = makeStyles({
  "delete-source-container": {
    "& .delete-btn-container": {
      display: "flex",
      width: "32px",
      height: "32px",
      padding: "8px",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: 0,
      borderRadius: "8px",

      "& svg": {
        height: "18px",
        width: "18px",

        "& path": {
          fill: "#E53811",
        },
      },
    },
  },
  input: {
    fontSize: "16px",
  },
  notchedOutline: {
    borderRadius: "8px !important",
  },
  deleteButton: {
    background: "#E53811",
    "&:hover": {
      background: "#E53811",
    },
  },
});

interface Props {
  action: "file" | "link";
  id: string;
  onClose: () => void;
  onDeleteCb: (id: string) => void;
}

const SourceDelete: React.FC<Props> = ({ action, id, onClose, onDeleteCb }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [isDeleteCallUnderProcess, setIsDeleteCallUnderProcess] =
    useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose]);

  const handleClickDelete = useCallback(() => {
    setIsDeleteCallUnderProcess(true);

    if (action === "link") {
      deleteLinkSource(id)
        .then(() => {
          onDeleteCb(id);

          enqueueSnackbar("Source deleted", {
            variant: "success",
          });

          setOpen(false);
          onClose?.();
        })
        .catch(() => {
          enqueueSnackbar("Some error occurred. Please try again", {
            variant: "error",
          });
        })
        .finally(() => {
          setIsDeleteCallUnderProcess(false);
        });
    } else if (action === "file") {
      deleteFileSource(id)
        .then(() => {
          onDeleteCb(id);

          enqueueSnackbar("Source deleted", {
            variant: "success",
          });

          setOpen(false);
          onClose?.();
        })
        .catch(() => {
          enqueueSnackbar("Some error occurred. Please try again", {
            variant: "error",
          });
        })
        .finally(() => {
          setIsDeleteCallUnderProcess(false);
        });
    }
  }, [action, id, onDeleteCb, enqueueSnackbar, onClose]);

  return (
    <div className={classes["delete-source-container"]}>
      <div className="delete-btn-container">
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>

      <HeadedDialog
        open={open}
        onClose={handleClose}
        header={
          <Typography variant="textXl" className="w-semi-bold">
            Delete source
          </Typography>
        }
        width="474px"
        height="184px"
      >
        <Box pt={3}>
          <Box>
            <Typography variant="textSm">
              Are you sure you want to delete this source. This action will
              delete this source permanently
            </Typography>
          </Box>
          <Box display="flex" pt={3}>
            <Button color="secondary" size="large" onClick={handleClose}>
              Don&apos;t Delete
            </Button>
            <Box ml={3}>
              <CommonAskConfirmationButton isLoading={isDeleteCallUnderProcess}>
                <Button
                  color="primary"
                  size="large"
                  onClick={handleClickDelete}
                  className={classes.deleteButton}
                >
                  Delete
                </Button>
              </CommonAskConfirmationButton>
            </Box>
          </Box>
        </Box>
      </HeadedDialog>
    </div>
  );
};

export default SourceDelete;
