import { IconButton } from "@mui/material";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as BotIconHead } from "./assets/bot-icon-head.svg";
import { ReactComponent as LevelIconSmall } from "./assets/level-icon-small.svg";
import { ReactComponent as ChatIcon } from "./assets/chat-icon.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh-icon.svg";
import { ReactComponent as MinusIcon } from "assets/icons/minus-icon.svg";
import { useSelector } from "react-redux";
import Typography from "components/base/Typography";
import useStyles from "./styles";
import { useDispatch } from "store";
import { ReduxChatbotActions } from "store/reduxActions/ReduxChatbotActions";
import NudgePopup from "./NudgePopup";
import { isEmpty } from "lodash";
import { ConnectionState } from "../ChatBot/types";
import Image from "components/Image";

interface Props {
  botConfig?: any;
  children: React.ReactNode;
}

const ChatBotLayout: React.FC<Props> = ({ botConfig, children }) => {
  const dispatch = useDispatch();

  const { widgetMounted } = useSelector((state: any) => state.chatbot);
  const { settings } = useSelector((state: any) => state.bot);
  const { chatbot_settings, userIsOnSettingsPage } = settings || {};

  const {
    bot_display_name,
    // welcome_message,
    header_message,
    // chat_bubble_url,
    header_logo_enabled,
    header_logo_url,
    header_accent_color,
    header_text_color,
    default_widget_state,
    nudge_message,
  } = chatbot_settings || {};

  const classes = useStyles({
    accentColor: header_accent_color,
    color: header_text_color,
  });

  const isWidgetOpen = default_widget_state === "open";

  const [isChatOpen, setIsChatOpen] = useState(isWidgetOpen);
  const [isNudgeVisible, setIsNudgeVisible] = useState(false);
  const [showWidgetLogo, setShowWidgetLogo] = useState(true);

  const showNudgeMessage = useCallback((): boolean => {
    if (isEmpty(nudge_message?.trim()) || isChatOpen) {
      return false;
    }
    if (userIsOnSettingsPage) {
      return true;
    }
    return false;
  }, [isChatOpen, nudge_message, userIsOnSettingsPage]);

  useEffect(() => {
    // const setWidgetState = (): void => {
    //   const windowWidth = window.innerWidth;
    //   if (windowWidth > 1536) {
    //     setShowWidgetLogo(false);
    //     setIsChatOpen(true);
    //     setIsNudgeVisible(false);
    //   } else {
    //     setShowWidgetLogo(true);
    //   }
    // };
    // setWidgetState();
    // window.addEventListener("resize", setWidgetState);
    // return () => {
    //   window.removeEventListener("resize", setWidgetState);
    // };
    setShowWidgetLogo(true);
  }, []);

  useEffect(() => {
    if (showNudgeMessage()) {
      setIsNudgeVisible(true);
    } else {
      setIsNudgeVisible(false);
    }
  }, [isChatOpen, showNudgeMessage]);

  const resetChat = useCallback((): void => {
    dispatch({
      type: ReduxChatbotActions.ACTION_SET_CONNECTION_STATE,
      payload: { connectionState: ConnectionState.NOT_CONNECTED },
    });
    dispatch({
      type: ReduxChatbotActions.ACTION_SET_BOT_SESSION,
    });
    dispatch({
      type: ReduxChatbotActions.ACTION_SET_RECONNECT,
      payload: { reconnect: true },
    });
  }, []);

  const toggleChat = useCallback((): void => {
    if (!isChatOpen && !widgetMounted) {
      dispatch({
        type: ReduxChatbotActions.ACTION_SET_WIDGET_MOUNTED,
        payload: true,
      });
    }

    setIsChatOpen(!isChatOpen);
  }, [isChatOpen, widgetMounted, dispatch]);

  const handleLevelRedirection = (): void => {
    window.open("https://thelevel.ai/", "_blank");
  };

  return (
    <div className={classes["chat-bot-container"]}>
      <NudgePopup isOpen={isNudgeVisible} message={nudge_message} />
      {showWidgetLogo && (
        <div
          onClick={toggleChat}
          className={clsx(classes["chat-bot-icon-wrapper"], {
            "chat-bot-icon-hide": isChatOpen,
          })}
        >
          <ChatIcon />
        </div>
      )}
      <div
        className={clsx(classes["chat-container"], {
          "chat-container-show": isChatOpen,
          "chat-container-hide": !isChatOpen,
        })}
      >
        <div
          className={clsx(classes["chat-header"], "d-flex py-12 px-16")}
          style={{
            backgroundColor: header_accent_color ?? "#ff7d04",
            color: header_text_color ?? "#ffff",
          }}
        >
          <div className="flex">
            {header_logo_enabled === "yes" && (
              <div className="bot-logo-container flex align-items-end mr-12">
                {header_logo_url ? (
                  <Image url={header_logo_url} height="100%" width="100%" />
                ) : (
                  <BotIconHead />
                )}
              </div>
            )}

            <div className="flex-col">
              <Typography variant="textLg" className="w-bold">
                {bot_display_name}
              </Typography>
              <Typography variant="textSm" className="w-semi-bold">
                {header_message}
              </Typography>
            </div>
          </div>

          <div className="chatbot-action-container flex align-items-center col-gap-2">
            <div className="chat-reset-button">
              <IconButton color="inherit" size="small" onClick={resetChat}>
                <RefreshIcon />
              </IconButton>
            </div>

            <div
              className={clsx("minimize-icon-wrapper", {
                "show-icon": botConfig?.minimizeIcon,
              })}
            >
              <IconButton color="inherit" size="small" onClick={toggleChat}>
                <MinusIcon />
              </IconButton>
            </div>
          </div>
        </div>

        <div className={classes["chat-bot-content"]}>{children}</div>

        <div className="flex fs-10 fw-500 justify-content-center bottom-bar py-1">
          Powered By
          <span className="ml-6" onClick={handleLevelRedirection}>
            <LevelIconSmall />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChatBotLayout;
