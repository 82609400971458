import GlobalSpinner from "components/shared/GlobalSpinner";
import CONFIG from "config";
import {
  asyncWithLDProvider,
  basicLogger,
} from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { getTenantName } from "utils/helpers";

interface Props {
  children: React.ReactNode;
}

const LDProvider: React.FC<Props> = ({ children }) => {
  const [LDProviderWrapper, setLDProviderWrapper] = useState<any>(null);

  useEffect(() => {
    const initializeLDProvider = async (): Promise<any> => {
      const tenantName = getTenantName();

      const LDProviderComponent = await asyncWithLDProvider({
        clientSideID: CONFIG.LAUNCHDARKLY_TOKEN,
        context: {
          kind: "multi",
          tenant: {
            kind: "tenant",
            key: tenantName,
            name: tenantName,
          },
        },
        options: {
          baseUrl: "https://launchdarkly.thelevel.ai",
          streamUrl: "https://launchdarkly.thelevel.ai",
          logger: basicLogger({ level: "warn" }),
        },
      });

      setLDProviderWrapper(() => LDProviderComponent);
    };

    void initializeLDProvider();
  }, []);

  return LDProviderWrapper ? (
    <LDProviderWrapper>{children}</LDProviderWrapper>
  ) : (
    <GlobalSpinner />
  );
};

export default LDProvider;
