import type React from "react";
import { Field } from "formik";
import { TextField } from "@mui/material";
import { type ConfigField } from "pages/Integrations/integrations.types";
import Typography, {
  TypographyColors,
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import useStyles from "./ConfigureConnectionFields.style";

interface ConfigureConnectionFieldsProps {
  fields: ConfigField[];
  preConfiguredValues?: Record<string, any> | undefined;
  disabled: boolean;
}

const ConfigureConnectionFields: React.FC<ConfigureConnectionFieldsProps> = ({
  fields,
  preConfiguredValues,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.formContainer}>
      {fields.map((field) => (
        <div key={field.key} className={classes.fieldWrapper}>
          {/* Render Label if it exists */}
          {field.label && (
            <Typography
              variant={TypographyVariants.text}
              weight={TypographyWeights.semiBold}
            >
              {field.label}
            </Typography>
          )}

          {/* Render input field using Formik's Field component */}
          <Field name={field.key}>
            {({ field, meta }: any) => (
              <div>
                <TextField
                  {...field}
                  label={field.label || field.key} // Use field key as label if label is not provided
                  type={field.protected ? "password" : "text"} // Use password input for protected fields
                  required={field.required}
                  fullWidth
                  variant="outlined"
                  disabled={disabled} // Disable field based on the disabled prop
                  error={meta.touched && !!meta.error} // Show error if touched and there's an error
                  helperText={meta.touched && meta.error} // Display error message
                  defaultValue={preConfiguredValues?.[field.name] ?? ""} // Set default value from configValues
                />
              </div>
            )}
          </Field>

          {/* Render Description if it exists */}
          {field.description && (
            <Typography
              variant={TypographyVariants.textSmall}
              weight={TypographyWeights.medium}
              color={TypographyColors.subtle}
              className={classes.description}
            >
              {field.description}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
};

export default ConfigureConnectionFields;
