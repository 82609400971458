import { createBrowserRouter, redirect } from "react-router-dom";
import PageNotFound from "components/shared/PageNotFound";
import AppLayout from "layout";
import AssistantRoutes from "./AssistantRoutes";
import ChatbotWidget from "pages/ChatbotWidget";
import KnowledgeBaseRoutes from "./KnowledgeBaseRoutes";
import AnalyticsRoutes from "./AnalyticsRoutes";

const appRoutes = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [...AssistantRoutes, ...KnowledgeBaseRoutes, ...AnalyticsRoutes],
  },
  {
    path: "/webclient/:botId",
    element: <ChatbotWidget />,
  },
  {
    path: "/404",
    element: <PageNotFound />,
  },
  {
    path: "*",
    loader: () => {
      return redirect("/404");
    },
  },
]);

export default appRoutes;
