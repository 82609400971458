export const formatSourceFileRequest = (files: any): any => {
  if (!files) return;

  return files.map((file: any) => {
    const data = new FormData();
    data.append("resource_name", file.name);
    data.append("namespace_id", "GLOBAL");
    data.append("namespace_group", "GLOBAL");
    data.append("file", file);

    return data;
  });
};
