import { getTenantName } from "utils/helpers";
import { botBEaxiosInstance } from ".";
import { type IAgentHandoff } from "types/ChatbotType";

export const getAgentHandoff = async ({
  token,
  sessionId,
}: {
  token: string;
  sessionId: string;
}): Promise<IAgentHandoff> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "POST",
      url: `/bot_handover/initiate/`,
      withCredentials: true,
      data: {
        session: sessionId,
      },
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
