import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useAgentCompositionStyles = makeStyles((theme: Theme) => ({
  agentCompositionContainer: {
    width: "100%",
    display: "flex",
    rowGap: "32px",
    maxWidth: "1368px",
    minWidth: "1080px",
    marginTop: "16px",
    flexDirection: "column",
  },
}));

export const useActionsStyles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    "& .action-name-container": {
      display: "flex",
      flexDirection: "row",
      columnGap: "16px",

      "& .action-name": {
        alignSelf: "flex-start",
        cursor: "pointer",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
  },
}));
