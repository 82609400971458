export enum ActionType {
  Connector = "CONNECTOR",
  Api = "API",
  Workflow = "WORKFLOW",
}

export interface IActions {
  _id: string;
  name: string;
  api_integration_name: string;
  api_integration_id: string;
  api_integration_description: string;
  http_method: string;
}

export interface IAgentComposition {
  trigger: string;
  general_prompt: string;
  general_tools: IActions[];
}
