import AccordionCard, {
  type AccordionCardProps,
} from "components/base/AccordionCard";
import { ReactComponent as TriggerScopeIcon } from "assets/trigger-scope-icon.svg";
import { ReactComponent as InstructionsIcon } from "assets/instructions-icon.svg";
// import { ReactComponent as KnowledgeIcon } from "assets/knowledge-icon.svg";
import { ReactComponent as ActionsIcon } from "assets/actions-icon.svg";
import TriggerAndScope from "./TriggerAndScope";
import Instructions from "./Instructions";
import Actions from "./Actions";
import { useAgentCompositionStyles as useStyles } from "./style";
import Button from "components/base/Button";
import * as yup from "yup";
import { useContext } from "react";
import AgentBuilderContext from "../AgentBuilderContext";
import { type IAgentComposition } from "./types";
import { Formik } from "formik";
import useRouteNavigator from "hooks/navigation/useRouteNavigator";
// import AgentKnowledge from "../Knowledge";

const validationSchema = yup.object({
  trigger: yup.string(),
  general_prompt: yup.string(),
  general_tools: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      api_integration_name: yup.string(),
      description: yup.string(),
      api_integration_id: yup.string(),
      http_method: yup.string(),
    })
  ),
});

const FormContainer: React.FC = () => {
  const classes = useStyles();
  const { gotoActionBuilder } = useRouteNavigator();

  const agentBuilderContext = useContext(AgentBuilderContext);
  const { assistantId: botId, agentId, agent } = agentBuilderContext;

  const AgentCompositionConfiguration: AccordionCardProps[] = [
    {
      title: "Trigger",
      subtitle:
        "Defines the conditions that determine when this skill should be activated.",
      icon: <TriggerScopeIcon />,
      content: <TriggerAndScope />,
    },
    {
      title: "Instructions",
      subtitle: "Outlines the primary task(s) the skill will perform.",
      icon: <InstructionsIcon />,
      content: <Instructions />,
    },
    // {
    //   title: "Knowledge",
    //   subtitle:
    //     "Defines the primary tasks the bot will perform, ensuring clarity on its main functions and responsibilities.",
    //   icon: <KnowledgeIcon />,
    //   content: <Knowledge />,
    // },
    {
      title: "Actions",
      subtitle: `Your '${agent?.name}' toolkit for getting things done automatically.`,
      icon: <ActionsIcon />,
      content: <Actions />,
      headerButtons: (
        <Button
          color="secondary"
          size="x-small"
          onClick={() => {
            gotoActionBuilder(botId, agentId);
          }}
        >
          Add a new action
        </Button>
      ),
    },
  ];

  return (
    <div className={classes.agentCompositionContainer}>
      {AgentCompositionConfiguration.map((configuration, index) => (
        <AccordionCard defaultExpanded={true} key={index} {...configuration} />
      ))}
      {/* temperory change, since we don't want to show knowledge selection on Skill level hence removing this code */}
      {/* <AgentKnowledge /> */}
    </div>
  );
};

const AgentComposition: React.FC = () => {
  const agentBuilderContext = useContext(AgentBuilderContext);
  const { agent } = agentBuilderContext;

  const initialValues: IAgentComposition = {
    trigger: agent?.trigger ?? "",
    general_prompt: agent?.general_prompt ?? "",
    general_tools: agent?.general_tools ?? [],
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={() => {}}
      validationSchema={validationSchema}
    >
      <FormContainer />
    </Formik>
  );
};

export default AgentComposition;
