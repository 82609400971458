/**
 * This component is used to display an image.
 * It can display both SVG and PNG images from a URL
 *
 * @param url - The URL of the image.
 * @param height - The height of the image.
 * @param width - The width of the image.
 */

import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState, useCallback } from "react";

interface ImageProps {
  url: string;
  height?: string;
  width?: string;
}

const Image: React.FC<ImageProps> = ({
  url,
  height = "auto",
  width = "auto",
}) => {
  const [svgContent, setSvgContent] = useState<string | null>(null);
  const [progressSize, setProgressSize] = useState<number>(20);

  const containerRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      const { offsetWidth, offsetHeight } = node;
      const size = Math.min(
        Math.max(18, offsetWidth / 10, offsetHeight / 10),
        24
      );
      setProgressSize(size);
    }
  }, []);

  const isSvg = (url: string): boolean => {
    const urlWithoutQuery = url.split("?")[0];
    return urlWithoutQuery.endsWith(".svg");
  };

  useEffect(() => {
    if (isSvg(url)) {
      fetch(url)
        .then(async (response) => await response.text())
        .then((data) => {
          if (data.includes("<Error>")) {
            setSvgContent(null);
          } else {
            setSvgContent(data);
          }
        })
        .catch((error) => {
          console.error("Error fetching SVG:", error);
        });
    }
  }, [url]);

  return (
    <div ref={containerRef} className="flex center" style={{ height, width }}>
      {isSvg(url) ? (
        svgContent ? (
          <div
            className="flex center"
            dangerouslySetInnerHTML={{ __html: svgContent }}
          />
        ) : (
          <CircularProgress size={progressSize} />
        )
      ) : (
        <img src={url} alt="Image" style={{ height: "100%", width: "100%" }} />
      )}
    </div>
  );
};

export default Image;
