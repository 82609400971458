import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as LevelLogo } from "assets/level-logo.svg";
import NavigationItems from "./config";
import useStyles from "./style";
import Typography, {
  TypographyColors,
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { useEffect, useState } from "react";

const NavigationBar: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();

  const [activePath, setActivePath] = useState("");

  useEffect(() => {
    if (location?.pathname) {
      const _path = location.pathname.split("/")[1];
      setActivePath(_path);
    }
  }, [location]);

  return (
    <div className={classes["navbar-container"]}>
      <LevelLogo />

      <div className={classes["navbar-item-container"]}>
        {NavigationItems.map(
          ({ key, path, title, icon: NavigationIcon, enable }) => (
            <Link
              className={clsx("navbar-item", {
                active: path === activePath,
                hidden: !enable,
              })}
              key={key}
              to={path}
            >
              <div className="navbar-item-icon-container">
                <NavigationIcon className="navbar-item-icon" />
              </div>

              <Typography
                className="item-display-name"
                variant={TypographyVariants.textTiny}
                weight={
                  path === activePath
                    ? TypographyWeights.bold
                    : TypographyWeights.medium
                }
                color={
                  path === activePath
                    ? TypographyColors.white
                    : TypographyColors.placeholder
                }
              >
                {title}
              </Typography>
            </Link>
          )
        )}
      </div>
    </div>
  );
};

export default NavigationBar;
