import { TextField } from "@mui/material";
import Typography from "components/base/Typography";
import {
  AppearanceSettingsFields,
  type IAppearanceSettings,
} from "pages/Assistant/types";
import { type FormikProps, useFormikContext } from "formik";
import { useCallback, useContext, useRef } from "react";
import { useSnackbar } from "notistack";
import { patchAssistantChatbotSettings } from "api/assistant/deployment";
import AssistantContext from "pages/Assistant/AssistantContext";
import { useBasicSettingsStyles as useStyles } from "./styles";
import { useDispatch } from "store";
import { SettingsActions } from "store/reduxActions/botActions";

export interface BasicSettingsProps {
  [AppearanceSettingsFields.bot_display_name]: string;
  [AppearanceSettingsFields.header_message]: string;
  [AppearanceSettingsFields.welcome_message]: string;
  [AppearanceSettingsFields.input_box_placeholder]: string;
}

const BasicSettings: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const assistantContext = useContext(AssistantContext);
  const { assistantId } = assistantContext || {};

  const {
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    touched,
    errors,
  }: FormikProps<IAppearanceSettings> = useFormikContext();

  const {
    bot_display_name,
    header_message,
    welcome_message,
    input_box_placeholder,
  } = values ?? {};

  const currentBasicSettings = useRef<BasicSettingsProps>({
    bot_display_name,
    header_message,
    welcome_message,
    input_box_placeholder,
  });

  const handleOnBlur = useCallback(
    async (event: any, field: keyof BasicSettingsProps) => {
      handleBlur(event);

      if (currentBasicSettings.current[field] === values[field]) {
        return;
      }

      try {
        const response = await patchAssistantChatbotSettings(assistantId, {
          [field]: values[field],
        });
        const { data } = response;
        const { settings } = data;
        const { chatbot_settings } = settings;

        dispatch({
          type: SettingsActions.UPDATE_CHATBOT_SETTINGS,
          payload: { ...chatbot_settings },
        });

        currentBasicSettings.current[field] = values[field];
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while saving details ${error}`, {
          variant: "error",
        });
        void setFieldValue(field, currentBasicSettings.current[field]);
      }
    },
    [assistantId, enqueueSnackbar, handleBlur, setFieldValue, values, dispatch]
  );

  return (
    <div className={classes["basic-settings-container"]}>
      <div className="basic-settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">Chatbot Name</Typography>
          <TextField
            name={AppearanceSettingsFields.bot_display_name}
            variant="outlined"
            size="small"
            placeholder="Chatbot Name"
            fullWidth
            value={values.bot_display_name}
            onChange={handleChange}
            onBlur={(event) => {
              void handleOnBlur(
                event,
                AppearanceSettingsFields.bot_display_name
              );
            }}
            error={touched.bot_display_name && Boolean(errors.bot_display_name)}
            helperText={"Max 16 characters"}
          />
        </div>

        <div className="form-input-container">
          <Typography className="w-semi-bold">Header Message</Typography>
          <TextField
            name={AppearanceSettingsFields.header_message}
            variant="outlined"
            size="small"
            placeholder="Header Message"
            fullWidth
            value={values.header_message}
            onChange={handleChange}
            onBlur={(event) => {
              void handleOnBlur(event, AppearanceSettingsFields.header_message);
            }}
            error={touched.header_message && Boolean(errors.header_message)}
            helperText={"Max 50 characters"}
          />
        </div>
      </div>

      <div className="basic-settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">Welcome message</Typography>
          <TextField
            name={AppearanceSettingsFields.welcome_message}
            variant="outlined"
            size="small"
            placeholder="Welcome message"
            fullWidth
            multiline
            minRows={2}
            maxRows={2}
            value={values.welcome_message}
            onChange={handleChange}
            onBlur={(event) => {
              void handleOnBlur(
                event,
                AppearanceSettingsFields.welcome_message
              );
            }}
            error={touched.welcome_message && Boolean(errors.welcome_message)}
            helperText={touched.welcome_message && errors.welcome_message}
          />
        </div>

        <div className="form-input-container">
          <Typography className="w-semi-bold">Input Box Placeholder</Typography>
          <TextField
            name={AppearanceSettingsFields.input_box_placeholder}
            variant="outlined"
            size="small"
            placeholder="Chatbot Name"
            fullWidth
            value={values.input_box_placeholder}
            onChange={handleChange}
            onBlur={(event) => {
              void handleOnBlur(
                event,
                AppearanceSettingsFields.input_box_placeholder
              );
            }}
            error={
              touched.input_box_placeholder &&
              Boolean(errors.input_box_placeholder)
            }
            helperText={
              touched.input_box_placeholder && errors.input_box_placeholder
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BasicSettings;
