import { useContext } from "react";
import AssistantContext from "../AssistantContext";
import { useAssistantBuilderStyles as useStyles } from "./styles";
import { Formik } from "formik";
import * as yup from "yup";
import { AssistantBuilderFields, type IAssistantBuilder } from "../types";
import AssistantDetails from "../Details/";
import AssistantGuidelinesAndRules from "../GuidelinesAndRules/";
import AssistantAgents from "../Agents/";
import AssistantKnowledge from "../Knowledge/";

const validationSchema = yup.object({
  [AssistantBuilderFields.goal]: yup.string(),
  [AssistantBuilderFields.company_info_text]: yup.string(),
  [AssistantBuilderFields.guidelines_and_rules]: yup.string(),
  [AssistantBuilderFields.agents]: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      description: yup.string(),
    })
  ),
});

const FormContainer: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.assistantCompositionContainer}>
      <AssistantDetails />
      <AssistantAgents />
      <AssistantKnowledge />
      <AssistantGuidelinesAndRules />
    </div>
  );
};

const AssistantBuilder: React.FC = () => {
  const assistantContext = useContext(AssistantContext);
  const { assistant } = assistantContext || {};

  const initialValue: IAssistantBuilder = {
    [AssistantBuilderFields.goal]: assistant?.general_info?.goal ?? "",
    [AssistantBuilderFields.company_info_text]:
      assistant?.general_info?.company_info_text ?? "",
    [AssistantBuilderFields.agents]: [],
    [AssistantBuilderFields.guidelines_and_rules]:
      assistant?.general_info?.guidelines_and_rules ?? "",
  };

  return (
    <>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        <FormContainer />
      </Formik>
    </>
  );
};

export default AssistantBuilder;
