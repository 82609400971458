import { ReactNode, useEffect, useState } from "react";
import { AnalyticsContext } from "./AnalyticsContext";
import { AnalyticsDashboardData, DashboardLayout } from "../types";
import { useSnackbar } from "notistack";
import {
  getAnalyticsDashboard,
  getAnalyticsDashboardLayout,
} from "../Service/Analytics.service";

interface AnalyticsContextProviderProps {
  children: ReactNode;
}

const AnalyticsContextProvider: React.FC<AnalyticsContextProviderProps> = ({
  children,
}) => {
  const [dashboard, setDashboard] = useState<AnalyticsDashboardData | null>(
    null
  );
  const [dashboardLayout, setDashboardLayout] =
    useState<DashboardLayout | null>(null); // Adjust type as needed
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);

    // Make both API calls in parallel using Promise.all
    Promise.all([getAnalyticsDashboard(), getAnalyticsDashboardLayout()])
      .then(([dashboardData, layoutData]) => {
        // Set both the dashboard and layout data when both are successful
        setDashboard(dashboardData);
        setDashboardLayout(layoutData);
      })
      .catch((err) => {
        // If either of the API calls fails, show an error snackbar
        enqueueSnackbar(`Something went wrong: ${err}`, {
          variant: "error",
        });
        setError(err);
      })
      .finally(() => {
        setLoading(false); // Set loading to false once both API calls are done
      });
  }, []);

  const value = {
    dashboard,
    dashboardLayout,
    setDashboard,
    setDashboardLayout,
    loading,
    setLoading,
    errorInLoadingData: error,
    setErrorInLoadingData: setError,
  };

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContextProvider;
