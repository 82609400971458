import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "30%",
    height: "100%",
    background: "#FFF",
    marginBottom: "100px",
    boxShadow:
      "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
  },
}));

export default useStyles;
