/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReduxActionActions } from "store/reduxActions/ReduxActionActions";
import { createReducer } from "../util";

const initialState = {
  action_list: {
    data: null,
    isLoading: false,
    isError: false,
  },
};

const actionReducer = createReducer(initialState, {
  [ReduxActionActions.FETCH_ACTION_LIST_LOADING]: (state: any, action: any) => {
    return {
      ...state,
      action_list: {
        ...state.action_list,
        isLoading: true,
        isError: false,
      },
    };
  },
  [ReduxActionActions.FETCH_ACTION_LIST_SUCCESS]: (state: any, action: any) => {
    return {
      ...state,
      action_list: {
        ...state.action_list,
        isLoading: false,
        data: action.payload,
      },
    };
  },
  [ReduxActionActions.FETCH_ACTION_LIST_ERROR]: (state: any, action: any) => {
    return {
      ...state,
      action_list: {
        ...state.action_list,
        isLoading: false,
        isError: true,
      },
    };
  },
  [ReduxActionActions.POST_ACTION_SUCCESS]: (state: any, action: any) => {
    return {
      ...state,
      action_list: {
        ...state.action_list,
        isLoading: false,
        data: [...state.action_list.data, action.payload],
      },
    };
  },
  [ReduxActionActions.DELETE_ACTION_SUCCESS]: (state: any, action: any) => {
    const itemIdToDelete = action.payload.id;
    const currActionList = [...state.action_list.data];

    const index = currActionList.findIndex(
      (currAction) => currAction.id === itemIdToDelete
    );

    return {
      ...state,
      action_list: {
        ...state.action_list,
        isLoading: false,
        data: currActionList
          .slice(0, index)
          .concat(currActionList.slice(index + 1)),
      },
    };
  },
  [ReduxActionActions.PUT_ACTION_SUCCESS]: (state: any, action: any) => {
    const itemIdToUpdate = action.payload.id;

    const currActionList = [...state.action_list.data];

    const index = currActionList.findIndex(
      (currAction) => currAction.id === itemIdToUpdate
    );

    return {
      ...state,
      action_list: {
        ...state.action_list,
        isLoading: false,
        data: currActionList
          .slice(0, index)
          .concat([action.payload])
          .concat(currActionList.slice(index + 1)),
      },
    };
  },
});

export default actionReducer;
