import { BotActions } from "store/reduxActions/botActions";
import { createReducer } from "../util";

const initialState = {
  created: "",
  deleted: false,
  description: "",
  enabled: true,
  id: "",
  modified: "",
  name: "",
  status: "",
};

const botSlice = createReducer(initialState, {
  [BotActions.SET_BOT_META]: (state: any, action: any) => {
    const { settings, ...rest } = action.payload;

    return {
      ...state,
      ...rest,
    };
  },
});

export default botSlice;
