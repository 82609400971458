import { IconButton, Paper, TextField } from "@mui/material";
import Typography from "components/base/Typography";
import { makeStyles } from "@mui/styles";
import Button from "components/base/Button";
import { useCallback, useMemo, useState } from "react";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import TabSwitch, { type ITabSwitchOption } from "components/base/TabSwitch";
import CONFIG from "config";
import { useParams } from "react-router-dom";
import { ReactComponent as CopyIcon } from "assets/copy-icon.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/external-link-icon.svg";
import { getTenantName } from "utils/helpers";

const useStyles = makeStyles(() => ({
  "embed-chatbot-container": {
    "& .embed-code-container": {
      "& .code-snippet-container": {
        display: "flex",
        padding: "0px 24px 16px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        alignSelf: "stretch",
        borderRadius: "16px",
        border: "1px dashed var(--border-default, #E1DEDA)",
        background: "var(--inactive-surfaces-muted, #FCFCFB)",

        "& pre": {
          margin: "0px",
          whiteSpace: "pre-line",
        },
      },
    },
    "& .webclient-url-container": {
      "& .url-action-icon": {
        height: "36px",
        width: "36px",

        "& svg": {
          height: "20px",
          width: "20px",
        },
      },
    },
  },
}));

const options: ITabSwitchOption[] = [
  {
    key: "code",
    label: "Code",
  },
  // removing temperory, once we have embed support we enable this
  // {
  //   key: "iframe",
  //   label: "IFrame",
  //   disabled: true,
  // },
];

const EmbedCode: React.FC = () => {
  const classes = useStyles();
  const params = useParams();

  const { botId, assistantId } = params || {};
  const id = botId ?? assistantId;
  const { enqueueSnackbar } = useSnackbar();

  const webClientUrl = `${window.location.origin}/webclient/${id}`;

  const [embedType, setEmbedType] = useState("code");

  const configText = useMemo(() => {
    return JSON.stringify({
      botId: id,
      tenant: getTenantName(),
    });
  }, [id]);

  const text = useMemo(() => {
    return `
		  <script src="${CONFIG.SDK_URL}/level-chatbot-sdk.umd.cjs"></script> 
      <script>
        if (typeof define === "function" && define.amd) {
          require(["LevelChatbotSDK"], function (LevelChatbotSDK) {
            LevelChatbotSDK.showChatbotWidget(${configText});
          });
        } else if (typeof module !== "undefined" && module.exports) {
          const LevelChatbotSDK = require("LevelChatbotSDK");
          LevelChatbotSDK.showChatbotWidget(${configText});
        } else if (typeof LevelChatbotSDK !== "undefined") {
          LevelChatbotSDK.showChatbotWidget(${configText});
        } else {
          console.error("LevelChatbotSDK could not be loaded.");
        }
      </script>
		`;
  }, []);

  const handleOpenWebClient = useCallback(() => {
    window.open(webClientUrl, "_blank");
  }, [webClientUrl]);

  const handleCopyWebClientUrl = useCallback(() => {
    copy(webClientUrl);

    enqueueSnackbar(`Web Client URL copied`, {
      variant: "success",
    });
  }, [enqueueSnackbar, webClientUrl]);

  const handleCopySnippet = useCallback(() => {
    copy(text);

    enqueueSnackbar(`Code snippet copied`, {
      variant: "success",
    });
  }, [enqueueSnackbar, text]);

  return (
    <div className={classes["embed-chatbot-container"]}>
      <Paper elevation={1} className="p-24 flex flex-col row-gap-24">
        <div className="webclient-url-container flex flex-col row-gap-16">
          <div className="flex flex-col">
            <Typography variant="textLg" className="w-bold">
              URL
            </Typography>
            <Typography variant="textSm" sx={{ color: "#6D6D6D" }}>
              Share this URL with the users to interact with your virtual
              assistant.
            </Typography>
          </div>

          <div className="flex align-items-center col-gap-8">
            <TextField
              id="webclient-url"
              className="flex-grow"
              defaultValue={webClientUrl}
              InputProps={{
                readOnly: true,
              }}
              size="small"
            />
            <div className="url-action-icon center">
              <IconButton onClick={handleCopyWebClientUrl}>
                <CopyIcon />
              </IconButton>
            </div>
            <div className="url-action-icon center">
              <IconButton onClick={handleOpenWebClient}>
                <ExternalLinkIcon />
              </IconButton>
            </div>
          </div>
        </div>

        <div className="embed-code-container flex flex-col row-gap-16">
          <Typography variant="textLg" className="w-bold">
            Embed Code
          </Typography>

          <div className="flex flex-col justify-content-center align-items-start">
            <TabSwitch
              value={embedType}
              onChange={(type: string) => {
                setEmbedType(type);
              }}
              options={options}
            />
          </div>

          <div className="flex align-items-center">
            <Typography className="w-medium">
              Copy and paste the following HTML code before the end of the BODY
              tag on the application where you want to display the chat widget.
            </Typography>
          </div>

          {embedType === "code" && (
            <div className="code-snippet-container">
              <pre>
                <code>{text}</code>
              </pre>
            </div>
          )}

          <div className="flex" onClick={handleCopySnippet}>
            <Button color={"primary"} startIcon={<CopyIcon />}>
              Copy Code
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default EmbedCode;
