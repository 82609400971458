import { useEffect, useState } from "react";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useScript(src: string) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const onScriptLoad = () => {
      setLoaded(true);
    };
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const onScriptError = () => {
      setLoaded(false);
    };

    script.addEventListener("load", onScriptLoad);
    script.addEventListener("error", onScriptError);

    document.body.appendChild(script);

    return () => {
      script.removeEventListener("load", onScriptLoad);
      script.removeEventListener("error", onScriptError);
      document.body.removeChild(script);
    };
  }, [src]);

  return loaded;
}

export default useScript;
