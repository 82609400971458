import { type Connection } from "pages/Integrations/integrations.types";
import type React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ConfigurationLayoutContainer from "./ConfigurationLayoutContainer";
import useIntegrationsRouteNavigator from "hooks/navigation/useIntegrationsRouteNavigator";
import { ReactComponent as BackIcon } from "assets/arrow-left.svg";
import IntegrationHeader from "pages/Integrations/Header/IntegrationHeader";
import useStyles from "./connectionConnectorConfigureContainer.styles";
import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import {
  deleteConnection,
  getConnectionById,
} from "pages/Integrations/Services/integrations.service";

const ConnectionConfigureContainer: React.FC = () => {
  const { connectionId } = useParams();
  const [connection, setConnection] = useState<Connection | null>(null);
  const { gotoIntegrationsPage } = useIntegrationsRouteNavigator();
  const classes = useStyles();
  const handleBackNavigation = (): void => {
    gotoIntegrationsPage();
  };
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const handleUninstall = async (): Promise<any> => {
    if (connectionId) return await deleteConnection(connectionId);
  };
  useEffect(() => {
    if (!isNil(connectionId)) {
      setLoading(true);
      getConnectionById(connectionId)
        .then((res) => {
          setConnection(res);
        })
        .catch((err: any) => {
          enqueueSnackbar(`Something went wrong: ${err}`, {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [connectionId]);

  if (loading || isNil(connection)) {
    return (
      <div className={clsx(classes.loadingContainer, "center")}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <IntegrationHeader
        title={connection?.connector?.name}
        onIconClick={handleBackNavigation}
        icon={<BackIcon />}
      />
      <ConfigurationLayoutContainer
        connector={connection?.connector}
        integrated
        preConfiguredValues={connection?.config_values}
        title={connection?.name}
        handleUninstall={handleUninstall}
      />
    </div>
  );
};

export default ConnectionConfigureContainer;
