import type React from "react";
import useStyles from "./styles";
import { ReactComponent as LightningIcon } from "assets/Vector1220.svg";
import { ReactComponent as HelpCircle } from "assets/help-circle.svg";
import { ReactComponent as SaveIcon } from "assets/save-01.svg";
import { ReactComponent as CrossIcon } from "assets/x-close.svg";

import Button from "components/base/Button";
import IconButton from "aether/IconButton";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";

interface Props {
  onSave: () => void;
  onClickExit: () => void;
  disabledSave?: boolean;
  title?: string;
}

const ActionHeader: React.FC<Props> = ({
  onSave,
  disabledSave = false,
  onClickExit,
  title = "",
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.titleIconContainer}>
        <LightningIcon className={classes.lightningIcon} />
        <Typography
          variant={TypographyVariants.textXL}
          weight={TypographyWeights.bold}
        >
          {title}
        </Typography>
      </div>
      <div className={classes.actions}>
        <div className="p-8">
          <HelpCircle className={classes.helpIcon} />
        </div>
        <Button
          size="small"
          onClick={onSave}
          type="submit"
          color={"secondary"}
          disabled={false}
          startIcon={<SaveIcon />}
        >
          Save draft
        </Button>
        <IconButton color="secondary" variant="outlined" onClick={onClickExit}>
          <CrossIcon className={classes.crossIcon} />
        </IconButton>
      </div>
    </div>
  );
};

export default ActionHeader;
