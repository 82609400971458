import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ActionType } from "./types";
import { ReactComponent as WorkflowIcon } from "assets/workflow-icon.svg";
import { ReactComponent as ConnectorIcon } from "assets/connector-icon.svg";
import { ReactComponent as APIIcon } from "assets/api-icon.svg";

export const useStyles = makeStyles((theme: Theme) => ({
  actionIconContainer: {
    display: "flex",
    padding: "8px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    background: "#FFF5EB",

    "& svg": {
      width: "16px",
      height: "16px",
    },
  },
  connectorIconContainer: {},
}));

interface Props {
  actionType: ActionType;
  icon: React.ReactNode;
}

const ActionIconMap = {
  [ActionType.Workflow]: <WorkflowIcon />,
  [ActionType.Connector]: <ConnectorIcon />,
  [ActionType.Api]: <APIIcon />,
};

const ActionIcon: React.FC<Props> = ({ actionType, icon }) => {
  const classes = useStyles();

  if (!actionType) {
    return <></>;
  }

  if (icon) {
    <div className={classes.connectorIconContainer}></div>;
  }

  return (
    <div className={classes.actionIconContainer}>
      {ActionIconMap[actionType]}
    </div>
  );
};

export default ActionIcon;
