/* eslint-disable @typescript-eslint/no-explicit-any */
export const createReducer = (initialState: any, handlers: any) => {
	return function reducer(state = initialState, action: any) {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, no-prototype-builtins
		if (handlers.hasOwnProperty(action.type)) {
			return handlers[action.type](state, action);
		} else {
			return state;
		}
	};
};
