import type React from "react";
import theme from "./theme";
import { ThemeProvider as MuiThemeProvider, CssBaseline } from "@mui/material";

interface Props {
  children: React.ReactNode;
}

const ThemeProvider: React.FC<Props> = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
