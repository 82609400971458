import { VariableDataTypes } from "pages/ActionBuilder/components/AgentActions.const";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  type Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FieldArray, useFormikContext } from "formik";
import { type IIntegrationFormValues } from "types/APIIntegration";
import Typography, { TypographyWeights } from "aether/Typography";
import { ReactComponent as NumberIcon } from "assets/number.svg";
import { ReactComponent as StringIcon } from "assets/string.svg";
import { ReactComponent as BooleanIcon } from "assets/boolean.svg";

export const VariableTypeIconMap = {
  [VariableDataTypes.STRING]: <StringIcon />,
  [VariableDataTypes.NUMBER]: <NumberIcon />,
  [VariableDataTypes.BOOLEAN]: <BooleanIcon />,
};
interface Props {
  disableAllFields?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: "24px",
  },
  rowContainer: {
    display: "flex",
    columnGap: "24px",
    marginBottom: "8px",
  },
  smallColumns: {
    width: "20%",
  },
  mediumColumn: {
    width: "25%",
  },
  largeColumns: {
    width: "35%",
  },
  valuesContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  menuItemWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
}));

const TabVariables: React.FC<Props> = ({ disableAllFields = false }) => {
  const classes = useStyles();

  const { values, handleBlur, handleChange } =
    useFormikContext<IIntegrationFormValues>();

  return (
    <div className={classes.container}>
      <div className={classes.rowContainer}>
        <Typography
          weight={TypographyWeights.bold}
          className={classes.smallColumns}
        >
          Name
        </Typography>
        <Typography
          weight={TypographyWeights.bold}
          className={classes.smallColumns}
        >
          Data type
        </Typography>
        <Typography
          weight={TypographyWeights.bold}
          className={classes.mediumColumn}
        >
          Requirement type
        </Typography>

        <Typography
          weight={TypographyWeights.bold}
          className={classes.largeColumns}
        >
          Description (Optional)
        </Typography>
      </div>

      <div>
        <FieldArray name="extracted_variables">
          {({ insert, remove, push }) => (
            <div className={classes.valuesContainer}>
              {values.extracted_variables.length > 0 &&
                values.extracted_variables.map((extractedVar, index) => (
                  <div key={index} className={classes.rowContainer}>
                    <div className={classes.smallColumns}>
                      <TextField
                        name={`extracted_variables.${index}.key`}
                        variant="outlined"
                        size="small"
                        placeholder=""
                        fullWidth
                        value={extractedVar.key}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disableAllFields}
                      />
                    </div>

                    <div className={classes.smallColumns}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <Select
                          labelId="variable-type-label"
                          name={`extracted_variables.${index}.data_type`}
                          value={extractedVar.data_type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={disableAllFields}
                        >
                          {/* Enum values mapped to dropdown options */}
                          {Object.values(VariableDataTypes).map((type) => (
                            <MenuItem key={type} value={type}>
                              <div className={classes.menuItemWrapper}>
                                {VariableTypeIconMap[type]}
                                {type}
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className={classes.mediumColumn}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          name={`extracted_variables.${index}.required`}
                          value={extractedVar.required}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          row
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Mandatory"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Optional"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className={classes.largeColumns}>
                      <TextField
                        name={`extracted_variables.${index}.description`}
                        variant="outlined"
                        size="small"
                        placeholder=""
                        fullWidth
                        value={extractedVar.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disableAllFields}
                      />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default TabVariables;
