import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import { useCallback } from "react";

const useStyles = makeStyles(() => ({
  iceBreaker: {
    cursor: "pointer",
    border: "1px solid #E7E5E1",

    "&:hover": {
      background: "#F9F8F8",
    },
  },
}));

interface SourceBubbleProps {
  iceBreaker: string;
  onClickIceBreaker: (message: string) => void;
}

const IceBreaker: React.FC<SourceBubbleProps> = ({
  iceBreaker,
  onClickIceBreaker,
}) => {
  const classes = useStyles();

  const handleIceBreakerClick = useCallback((): void => {
    onClickIceBreaker?.(iceBreaker);
  }, [iceBreaker, onClickIceBreaker]);

  return (
    <Box
      className={classes.iceBreaker}
      borderRadius={16}
      py={0.5}
      px={1.5}
      display="flex"
      color="#282624"
      onClick={handleIceBreakerClick}
    >
      <Typography
        maxLines={1}
        className="w-medium"
        variant="textSm"
        color="inherit"
      >
        {iceBreaker}
      </Typography>
    </Box>
  );
};

export default IceBreaker;
