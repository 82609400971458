export interface BotMessageSource {
  source: string;
  title: string;
}
export interface BotMessage {
  type?: string;
  content?: any;
  id: string;
  text: string;
  created: string;
  speaker: string;
  sources: BotMessageSource[];
  isMessageComplete: boolean;
}

export interface BotMessageHandoff {
  content: string;
  session_id: string;
}

export interface IPersona {
  key: string;
  label: string;
}

export enum ConnectionState {
  NOT_CONNECTED = "NOT_CONNECTED",
  OPEN = "OPEN",
  MESSAGE = "MESSAGE",
  CLOSE = "CLOSE",
  ERROR = "ERROR",
}
