// NestedDropdown.styles.ts
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  preInputIcon: {
    marginRight: "8px",
    display: "flex",
    alignItems: "center",
    maxHeight: "704px",
    overflow: "auto",
  },
  inputBase: {
    width: "100%",
    height: "40px",
    padding: "8px 14px ",
    border: "1px solid #ccc",
    borderRadius: "8px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  popover: {
    width: (props: any) => `${props.width}px`,
    borderRadius: "8px",
    border: "1px solid var(--dropdown-border-primary-muted, #E1DEDA)",
    background: "var(--Base-White, #FFF)",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    maxHeight: "90vh", // 90% of the screen height
    display: "flex",
    flexDirection: "column",
  },
  dropdownContainer: {
    maxWidth: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  searchInputContainer: {
    padding: "16px 16px 0px 16px",
  },
  scrollableContent: {
    overflowY: "auto",
    flex: 1,
    padding: "0px 16px 16px 16px",
  },
  itemContainer: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  parentItem: {
    cursor: "pointer",
  },
  contentAndIconContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  contentContainer: {
    maxWidth: "calc(100% - 32px)",
  },
  titleAndCountContainer: {
    display: "flex",
    gap: "8px",
  },
  title: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  childItem: {
    paddingLeft: "24px",
  },
  subtitle: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "2",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    textWrap: "wrap",
    maxWidth: "100%",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    color: "var(--text-subtle, #7C7972)",
  },
  ellipsisAndCount: {
    display: "flex",
    alignItems: "center",
  },
  childCount: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#757575",
  },
  expandIconContainer: {
    display: "flex",
    height: "100 %",
    alignItems: "center",
  },
  childContent: {
    maxWidth: "calc(100% - 32px)",
  },
}));
