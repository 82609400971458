export interface FileResource {
  id: string;
  active: boolean;
  bot: null;
  created: string;
  deleted: boolean;
  document_id: string;
  download_url: string;
  file_path: string;
  file_type: string;
  integration_name: string;
  link: string;
  modified: string;
  namespace_group: string;
  namespace_id: string;
  pending_sync: boolean;
  preview_link: string;
  resource_name: string;
  title: string;
  user: string;
  resourceId: string;
  resourceType: ResourceType;
}

export interface LinkResource {
  id: string;
  name: string;
  url: string;
  status: string;
  resource_type: string;
  enabled: boolean;
  deleted: boolean;
  crawl_completed_on: string | null;
  index_completed_on: string | null;
  refresh_interval: number;
  namespace_id: string;
  namespace_group: string;
  bot_id: string;
  created: string;
  modified: string;
  render_status: SourceStatus;
  type: string;
  resourceId: string;
  resourceType: ResourceType;
}

export enum ResourceType {
  LINK = "link",
  FILE = "file",
}

export enum SourceStatus {
  PENDING = "Pending",
  IN_PROGRESS = "In Progress",
  FAILED = "Failed",
  ACTIVE = "Active",
  SUCCESS = "Success",
}

export interface SourcesTableData {
  id: string | number;
  resourceId: string;
  resourceType: ResourceType;
  type: string;
  name: string;
  uploadedOn: string;
  status: SourceStatus | boolean;
  actions: string;
}

export interface ISource {
  id: number;
  file_path: string;
  download_url: string;
  created: string;
  modified: string;
  document_id: string;
  user: string;
  file_type: string;
  resource_name: string;
  link: string;
  preview_link: string;
  integration_name: string;
  title: string;
  active: boolean;
  deleted: boolean;
  pending_sync: boolean;
  bot: string;
}

export default interface Source {
  id: string;
  render_status: SourceStatus;
  created: string;
  modified: string;
  name: string;
  url: string;
  status: string;
  resource_type: string;
  enabled: boolean;
  index_name: string | null;
  crawl_completed_on: string;
  index_completed_on: string | null;
}
