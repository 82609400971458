import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as EmptyStateIcon } from "assets/empty-state-icon.svg";
import Typography, { TypographyWeights } from "aether/Typography";
import { EmptyStateProps } from ".";

export const useStyles = makeStyles((theme: Theme) => ({
  tableEmptyContainer: {
    height: "30vh",

    "& .table-empty-logo": {
      "& svg": {},
    },
  },
}));

export interface Props extends EmptyStateProps {
  colSpan: number;
}

const TableEmpty: React.FC<Props> = ({
  colSpan,
  emptyLogo,
  message,
  description,
  button = null,
  ...props
}) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.tableEmptyContainer}>
      <TableCell colSpan={colSpan}>
        <div className="table-empty-logo flex-col center">
          {emptyLogo ?? <EmptyStateIcon />}

          <div className="flex-col row-gap-16 align-items-center">
            <div className="flex-col align-items-center">
              <Typography weight={TypographyWeights.bold}>{message}</Typography>
              {description && (
                <Typography weight={TypographyWeights.medium}>
                  {description}
                </Typography>
              )}
            </div>

            {button}
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TableEmpty;
