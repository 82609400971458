import { useParams } from "react-router-dom";
import APIBuilderContext from "../APIBuilderContext";
import Integration from "pages/Bot/APIIntegration/components/Integration";
import { useSnackbar } from "notistack";
import PageNotFound from "components/shared/PageNotFound";

const APIBuilderContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { assistantId, integrationId } = useParams() || {};
  if (!integrationId || !assistantId) {
    enqueueSnackbar("URL must include a valid Assistant and API ID.", {
      variant: "error",
    });
    return <PageNotFound />;
  }

  return (
    <APIBuilderContext.Provider value={{ assistantId, integrationId }}>
      <Integration />
    </APIBuilderContext.Provider>
  );
};

export default APIBuilderContainer;
