import BotDescription from "../BotDescription";
import { ReactComponent as ChatBotInProgressIcon } from "./assets/chat-bot-in-progress-icon.svg";

const ChatBotInProgressState: React.FC = () => {
	return (
		<BotDescription
			imageComponent={<ChatBotInProgressIcon />}
			heading={"Phi is training on  sources shared so far"}
			subHeading={"This usually takes 1-2 hours"}
		/>
	);
};

export default ChatBotInProgressState;
