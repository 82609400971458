import { useFormikContext } from "formik";
import { useEffect } from "react";
import { IIntegrationFormValues } from "types/APIIntegration";
import { getExtractedVariables } from "./util";
import { ActionParam } from "pages/ActionBuilder/components/AgentActions.const";

const GLOBAL_VALUE_MAP = new Map<string, ActionParam>();

const useVariableTabLogic = (): void => {
  const { values, setFieldValue } = useFormikContext<IIntegrationFormValues>();

  useEffect(() => {
    // extract all extracted variables and render the variable tab with updated values
    const extractedVariablesWithvalues =
      getExtractedVariables(values, GLOBAL_VALUE_MAP) ?? [];
    void setFieldValue("extracted_variables", extractedVariablesWithvalues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.body?.json,
    values.url,
    values.params,
    values.headers,
    values.body?.form_data,
  ]);
};

export default useVariableTabLogic;
