import Modal from "components/base/Modal/Modal";
import { useState } from "react";
import Typography from "components/base/Typography";
import IconButton from "aether/IconButton";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
// import { deleteAgent } from "api/assistant/agents";
import { FileResource, LinkResource } from "pages/KnowledgeBase/types";
import { patchAssistant } from "api/assistant/assistant";
import { useSnackbar } from "notistack";

const delete_knowledge_source_modal_title = "Delete the knowledge source";
const delete_knowledge_source_modal_text =
  "Are you sure you would like to delete the knowledge source. This action is non reversible.";
const delete_knowledge_source_confirm_button = "Delete";
const delete_knowledge_source_cancel_button = "Don't delete";

interface Props {
  assistantId: string;
  resources: Array<FileResource | LinkResource>;
  resourceId: string;
  onDeleteCb?: (resourceId: string) => void;
}

const DeleteSourceModal: React.FC<Props> = ({
  assistantId,
  resources,
  resourceId,
  onDeleteCb,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirmDelete = async (): Promise<void> => {
    setLoading(true);
    const updatedResources = resources.filter(
      (resource) => resource.resourceId !== resourceId
    );
    const updatedResourceIds = updatedResources.map(
      (resource) => resource.resourceId
    );

    const data = {
      knowledge_base: {
        resource_ids: updatedResourceIds,
      },
    };

    setLoading(true);

    patchAssistant(assistantId, data)
      .then((response) => {
        const knowledgeBase = response.data.assistant?.knowledge_base;
        if (knowledgeBase && !knowledgeBase.resource_ids.includes(resourceId)) {
          onDeleteCb?.(resourceId);
        }
        enqueueSnackbar("Knowledge source deleted", {
          variant: "success",
        });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Failed to delete knowledge source", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
        setOpenModal(false);
      });
  };

  return (
    <div className="delete-agent-modal-container">
      <IconButton
        size="xsmall"
        variant="outlined"
        color="error"
        onClick={(event) => {
          setOpenModal(true);
        }}
      >
        <DeleteIcon />
      </IconButton>

      <Modal
        variant={"error"}
        open={openModal}
        title={delete_knowledge_source_modal_title}
        onClose={() => {
          setOpenModal(false);
        }}
        onConfirm={() => {
          void handleConfirmDelete();
        }}
        onCancel={() => {
          setOpenModal(false);
        }}
        showConfirmLoading={loading}
        confirmText={delete_knowledge_source_confirm_button}
        cancelText={delete_knowledge_source_cancel_button}
      >
        <div className="flex-col mb-16">
          <Typography variant="textLg">
            {delete_knowledge_source_modal_text}
          </Typography>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteSourceModal;
