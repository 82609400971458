import type React from "react";
import { Box } from "@mui/material";
import useStyles from "./Banner.styles"; // Import styles
import { isEqual, isNil } from "lodash";
import IconButton from "aether/IconButton";

export enum BannerType {
  Info = "INFO",
  Success = "SUCCESS",
}

interface BannerProps {
  icon?: React.ReactNode; // Icon passed by the consumer
  gif?: string;
  title: string; // Title of the banner
  subtitle?: string; // Subtitle of the banner
  type: BannerType; // Type for determining background color
  className?: string; // Optional className for external styling
}

const Banner: React.FC<BannerProps> = ({
  icon,
  gif,
  title,
  subtitle,
  type,
  className,
}) => {
  const classes = useStyles();

  // Determine background class based on type
  const bannerClass = isEqual(type, BannerType.Info)
    ? classes.infoBanner
    : classes.successBanner;

  return (
    <Box className={`${classes.banner} ${bannerClass} ${className ?? ""}`}>
      <IconButton size="small" className={classes.gifContainer}>
        {isNil(icon) ? (
          <img
            src={gif}
            alt="gif-icon"
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          icon
        )}
      </IconButton>

      {/* Title and Subtitle */}
      <Box className={classes.textContainer}>
        <div className={classes.title}>{title}</div>
        <div className={classes.subtitle}>{subtitle}</div>
      </Box>

      {/* Static Progress Bar */}
      <Box className={classes.progressBarContainer}>
        <Box className={classes.progressBar} />
      </Box>
    </Box>
  );
};

export default Banner;
