import ErrorSection from "components/shared/ErrorSection";

export interface Props {
  message: string;
  onRetry?: () => void;
  errorExtraAction?: () => void;
  errorExtraActionText?: string;
}

const ApiError: React.FC<Props> = ({
  message,
  onRetry,
  errorExtraAction,
  errorExtraActionText,
}) => {
  return (
    <ErrorSection
      message={message}
      onRetry={onRetry}
      retryBtnText="Retry"
      errorExtraAction={errorExtraAction}
      errorExtraActionText={errorExtraActionText}
    />
  );
};

export default ApiError;
