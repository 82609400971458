import React from "react";
import { makeStyles } from "@mui/styles";
import {
  BarAndDonutVizData,
  DonutChartVizData,
  KPIChartVizData,
  Visualization,
  VizType,
} from "pages/Analytics/types";
import DonutTileVizualization from "../tiles/donutTile/DonutTileVizualization";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { ReactComponent as InfoIcon } from "assets/info-icon.svg";
import KPITileVisualization from "../tiles/KPITile/KPITileVisualization";
import BarAndDonutTileVizualization from "../tiles/BarAndDonutTile/BarAndDonutTileVizualization";
import Tooltip from "aether/Tooltip";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    borderRadius: "8px",
    border: "1px solid var(--colors-shades-stone-100, #F9F8F8)",
    background: "var(--Base-White, #FFF)",
    boxShadow: "0px 0px 8px -4px rgba(16, 24, 40, 0.10)",
    display: "flex",
    padding: "16px 24px",
    flexDirection: "column",
    alignItems: "flex-start",
    alignSelf: "stretch",
  },
  titleIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    marginBottom: "8px",
  },
}));

interface Props {
  visualization: Visualization | null;
  vizType: VizType;
  vizTitle: string;
  loading: boolean;
}

const VisualizationContainer: React.FC<Props> = ({
  visualization,
  vizType,
  vizTitle = "",
  loading = false,
}) => {
  const classes = useStyles();
  const { vizData } = visualization ?? {};
  let content = null;

  switch (vizType) {
    case VizType.DONUT:
      content = (
        <DonutTileVizualization
          vizData={vizData as DonutChartVizData}
          loading={loading}
        />
      );
      break;
    case VizType.KPI:
      content = (
        <KPITileVisualization
          vizData={vizData as KPIChartVizData}
          loading={loading}
        />
      );
      break;
    case VizType.BAR_AND_DONUT:
      content = (
        <BarAndDonutTileVizualization
          vizData={vizData as BarAndDonutVizData}
          loading={loading}
        />
      );
      break;
    default:
      content = null;
      // eslint-disable-next-line no-console
      console.error(
        "Unknown Visualization Type visualizationId: ",
        visualization?.vizId
      );
      break;
  }

  return (
    <div className={classes.container}>
      <div className={classes.titleIconContainer}>
        <Typography
          variant={TypographyVariants.textLarge}
          weight={TypographyWeights.bold}
          renderInLines={1}
        >
          {vizTitle}
        </Typography>
        {visualization?.info && (
          <Tooltip title={visualization?.info} className="ml-8">
            <InfoIcon style={{ width: 16, height: 16 }} />
          </Tooltip>
        )}
      </div>
      {content}
    </div>
  );
};

export default VisualizationContainer;
