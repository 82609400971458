import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    width: "100%",
    padding: "24px 32px",
    gap: "8px",
    background: "#FFF",
    boxShadow: "0px 1px 0px 0px #E6E6E6",
  },
  integrationsIcon: {
    width: "30px",
    height: "30px",
    flexShrink: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
}));

export default useStyles;
