import CONFIG from "config";
import useScript from "./useScript";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getTenantName } from "utils/helpers";
import "./style.scss"; // NOTE: Do not use useStyles, for merged react-app, prod random class-name issue

const ChatbotWidget: React.FC = () => {
  const params = useParams();

  const { botId } = params || {};

  const sdk = CONFIG.SDK_URL;
  const scriptURL = `${sdk}/level-chatbot-sdk.umd.cjs`;

  const sdkLoaded = useScript(scriptURL);

  useEffect(() => {
    if (sdkLoaded && botId) {
      // Ensure SDK is available
      if ((window as any)?.LevelChatbotSDK) {
        try {
          const config = {
            botId,
            tenant: getTenantName(),
            minimizeMode: false,
            parentId: "webclient-chatbot",
          };

          (window as any)?.LevelChatbotSDK.showChatbotWidget(config);
        } catch (error) {
          console.log("Error calling showChatWidget:", error);
        }
      } else {
        console.error("LevelChatbotSDK is not available on window object");
      }
    }
  }, [botId, sdkLoaded]);

  return (
    <div className="webclient-container">
      <div id="webclient-chatbot"></div>
    </div>
  );
};

export default ChatbotWidget;
