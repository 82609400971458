import Typography from "components/base/Typography";
import { FormControlLabel, TextField, type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormikContext } from "formik";
import { type IIntegrationFormValues } from "types/APIIntegration";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

const useStyles = makeStyles((theme: Theme) => ({
  "tab-authorization-container": {
    marginTop: "24px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",

    "& .auth-basic-container, & .auth-token-container": {
      width: "340px",
    },
  },
}));

interface Props {
  disableAllFields?: boolean;
}

const TabAuthorization: React.FC<Props> = ({ disableAllFields = false }) => {
  const classes = useStyles();

  const { values, handleBlur, handleChange } =
    useFormikContext<IIntegrationFormValues>();

  return (
    <div className={classes["tab-authorization-container"]}>
      <div className="auth-method-type flex flex-col col-gap-12 mb-8">
        <Typography className="w-semi-bold">Select Auth Method</Typography>

        <RadioGroup
          row
          className="px-1"
          aria-labelledby="auth-method"
          name={"authorization.auth_method"}
          value={values.authorization.auth_method}
          onChange={handleChange}
          hidden={disableAllFields}
        >
          <FormControlLabel
            value="basic"
            control={<Radio />}
            label="Basic auth"
          />
          <FormControlLabel
            value="token"
            control={<Radio />}
            label="Bearer token"
          />
        </RadioGroup>
      </div>

      <div className="auth-method-container">
        {values.authorization.auth_method === "basic" && (
          <div className="auth-basic-container flex flex-col justify-content-start align-items-stretch row-gap-24">
            <div className="form-input-container flex flex-col row-gap-6">
              <Typography className="w-semi-bold">Username</Typography>
              <TextField
                disabled={disableAllFields}
                name={`authorization.username`}
                variant="outlined"
                size="small"
                fullWidth
                value={values.authorization.username}
                onChange={handleChange}
                onBlur={handleBlur}
                //   error={touched.node_name && Boolean(errors.node_name)}
                //   helperText={touched.node_name && errors.node_name}
              />
            </div>

            <div className="form-input-container flex flex-col row-gap-6">
              <Typography className="w-semi-bold">Password</Typography>
              <TextField
                name={`authorization.password`}
                variant="outlined"
                size="small"
                fullWidth
                value={values.authorization.password}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disableAllFields}
                //   error={touched.node_name && Boolean(errors.node_name)}
                //   helperText={touched.node_name && errors.node_name}
              />
            </div>
          </div>
        )}

        {values.authorization.auth_method === "token" && (
          <div className="auth-token-container flex flex-col justify-content-start align-items-stretch row-gap-24">
            <div className="form-input-container flex flex-col row-gap-6">
              <Typography className="w-semi-bold">Token</Typography>
              <TextField
                name={`authorization.token`}
                variant="outlined"
                size="small"
                fullWidth
                value={values.authorization.token}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disableAllFields}
                //   error={touched.node_name && Boolean(errors.node_name)}
                //   helperText={touched.node_name && errors.node_name}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TabAuthorization;
