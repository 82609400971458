import AddSource from "./AddSource";
import SourceList from "./SourceList";
import { useSourceStyles as useStyles } from "./styles";

const Source = (): React.JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.sourceContainer}>
      <AddSource />
      <SourceList />
    </div>
  );
};

export default Source;
