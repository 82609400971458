import { SourceFileType } from "constant/BotConstant";
import { ReactComponent as DocumentIcon } from "assets/doc-icon.svg";
import { ReactComponent as PDFIcon } from "assets/pdf-icon.svg";
import { ReactComponent as TxtIcon } from "assets/txt-icon.svg";

const FileTypeIcon = ({ type }: { type: string }): JSX.Element => {
  switch (type) {
    case SourceFileType.DOC:
    case SourceFileType.DOCX:
    case SourceFileType.APPLICATION_DOC:
    case SourceFileType.APPLICATION_DOCX:
      return <DocumentIcon />;

    case SourceFileType.PDF:
    case SourceFileType.APPLICATION_PDF:
      return <PDFIcon />;

    case SourceFileType.TEXT:
    case SourceFileType.HTML:
    case SourceFileType.APPLICATION_TXT:
      return <TxtIcon />;
  }

  return <></>;
};

export default FileTypeIcon;
