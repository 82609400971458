import { type Connector } from "../integrations.types";
import useStyles from "./ConnectionsList.styles";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import ConnectorTile from "./ConnectorTile";
import Loading from "aether/Loading";

interface ConnectorListProps {
  connectors: Connector[];
  onClick: (item: Connector) => void;
  loading?: boolean;
}

const ConnectionsList: React.FC<ConnectorListProps> = ({
  connectors,
  onClick,
  loading = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography
        variant={TypographyVariants.textXL}
        weight={TypographyWeights.bold}
        className={classes.title}
      >
        Configure new integrations
      </Typography>
      {loading && <Loading />}
      <div className={classes.content}>
        {connectors.map((connector) => (
          <ConnectorTile
            key={connector._id}
            onClick={() => {
              onClick(connector);
            }}
            icon={connector?.icon}
            title={connector.name}
            description={connector?.description}
          />
        ))}
      </div>
    </div>
  );
};

export default ConnectionsList;
