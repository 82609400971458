import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Loading from "aether/Loading";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { type ConnectorActionsConfig } from "pages/ActionBuilder/components/AgentActions.const";
import type React from "react";
import ReactMarkdown from "react-markdown";
import useStyles from "./LeftOverview.styles";

interface LeftOverviewProps {
  markDownString: string;
  actions: ConnectorActionsConfig[];
  actionsLoading?: boolean;
}

const LeftOverview: React.FC<LeftOverviewProps> = ({
  markDownString = "",
  actions = [],
  actionsLoading = false,
}) => {
  const classes = useStyles();
  return (
    <div>
      <ReactMarkdown>{markDownString}</ReactMarkdown>
      <Typography
        variant={TypographyVariants.textLarge}
        weight={TypographyWeights.bold}
      >
        Actions
      </Typography>
      <Typography
        variant={TypographyVariants.text}
        weight={TypographyWeights.medium}
        style={{ marginBottom: "16px" }}
      >
        Our integration allow the assistant to the take following actions.
      </Typography>
      {actionsLoading ? (
        <Loading />
      ) : (
        <TableContainer>
          <Table>
            <TableBody>
              {actions?.map((action) => {
                return (
                  <TableRow key={action._id}>
                    <TableCell>
                      <Typography
                        variant={TypographyVariants.textLarge}
                        weight={TypographyWeights.bold}
                        className={classes.titleAndDescription}
                      >
                        {action.name}
                      </Typography>
                      <Typography
                        variant={TypographyVariants.text}
                        weight={TypographyWeights.medium}
                        className={classes.titleAndDescription}
                      >
                        {action.description}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default LeftOverview;
