import { type IWorkflowState } from "types/Workflow";
import { createReducer } from "../util";
import { WorkflowActions } from "store/reduxActions/botActions";
import {
  createBranchAddNodeAndEdge,
  // deleteBranchNode,
  deleteNode,
  updateNodeById,
} from "pages/Bot/WorkflowBuilder/utils/workflow";
// import { LogicNodeType } from "pages/Bot/WorkflowBuilder/config";

const initialState: IWorkflowState = {
  loading: true,
  error: false,
  empty: false,
  workflow: {
    pointer: 1,
    workflowId: "",
    title: "",
    description: "",
    bot_id: "",
    nodes: [],
    edges: [],
    lastAddedNodeId: null,
    selectNode: {
      id: null,
      anchor: null,
    },
    hoverNode: {
      id: null,
      anchor: null,
    },
    operDrawer: false,
    openCreateNodeDropdown: false,
  },
  connection: {
    connectionInProgress: false,
    connectionIsValid: false,
    connectionNodeId: null,
    connectionHandleId: null,
    connectionHandleType: "",
  },
};

const workflowReducer = createReducer(initialState, {
  [WorkflowActions.SET_WORKFLOW_LOADING]: (
    state: IWorkflowState,
    action: any
  ) => {
    return {
      ...state,
      loading: action.payload.loading,
    };
  },
  [WorkflowActions.SET_WORKFLOW_PAGE_ERROR]: (
    state: IWorkflowState,
    action: any
  ) => {
    return {
      ...state,
      empty: false,
      error: true,
    };
  },
  [WorkflowActions.SET_WORKFLOW_DATA]: (state: IWorkflowState, action: any) => {
    return {
      ...state,
      error: false,
      empty: false,
      workflow: {
        ...state.workflow,
        ...action.payload.workflow,
      },
    };
  },
  [WorkflowActions.SET_NEW_WORKFLOW]: (state: IWorkflowState, action: any) => {
    return {
      ...state,
      workflow: action.payload,
    };
  },
  [WorkflowActions.SET_WORKFLOW_NODES]: (
    state: IWorkflowState,
    action: any
  ) => {
    const payload: any = {
      nodes: action.payload.nodes,
    };

    if (action.payload?.pointer) {
      payload.pointer = action.payload.pointer;
    }

    return {
      ...state,
      workflow: {
        ...state.workflow,
        ...payload,
      },
    };
  },
  [WorkflowActions.SET_WORKFLOW_EDGES]: (
    state: IWorkflowState,
    action: any
  ) => {
    return {
      ...state,
      workflow: {
        ...state.workflow,
        edges: action.payload.edges,
      },
    };
  },
  [WorkflowActions.SET_WORKFLOW_LAYOUT]: (
    state: IWorkflowState,
    action: any
  ) => {
    return {
      ...state,
      workflow: {
        ...state.workflow,
        nodes: action.payload.nodes,
        edges: action.payload.edges,
      },
    };
  },
  [WorkflowActions.UPDATE_WORKFLOW_NODE]: (
    state: IWorkflowState,
    action: any
  ) => {
    const { id, config } = action.payload;
    const nodes = [...state.workflow.nodes];

    const updatedNodes = updateNodeById({ id, nodes, config });

    return {
      ...state,
      workflow: {
        ...state.workflow,
        nodes: updatedNodes,
      },
    };
  },
  [WorkflowActions.DELETE_NODE]: (state: IWorkflowState, action: any) => {
    const { node } = action.payload;
    const nodes = [...state.workflow.nodes];
    const edges = [...state.workflow.edges];

    const { nodes: updatedNodes, edges: updatedEdges } = deleteNode({
      node,
      nodes,
      edges,
    });

    return {
      ...state,
      workflow: {
        ...state.workflow,
        nodes: updatedNodes,
        edges: updatedEdges,
        selectNode: { id: null, anchor: null },
        hoverNode: { id: null, anchor: null },
        openDrawer: false,
        openCreateNodeDropdown: false,
      },
    };
  },
  [WorkflowActions.UPDATE_NODE_POSITION]: (
    state: IWorkflowState,
    action: any
  ) => {
    const { id, position } = action.payload;
    const nodes = [...state.workflow.nodes];

    const updatedNodes = nodes.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          position,
          data: {
            ...node.data,
            ui: {
              ...node.data.ui,
              dragged: true,
            },
          },
        };
      }

      return node;
    });

    return {
      ...state,
      workflow: {
        ...state.workflow,
        nodes: updatedNodes,
      },
    };
  },
  [WorkflowActions.DELETE_NODE_BRANCH]: (
    state: IWorkflowState,
    action: any
  ) => {
    const { rootNodeId } = action.payload;
    const nodes = [...state.workflow.nodes];

    // TO-DO: Delete rootNode if it is AddNode, otherwise let it be there
    return {
      ...state,
      workflow: {
        ...state.workflow,
        nodes: nodes.filter((node) => node.id !== rootNodeId),
      },
    };
  },
  [WorkflowActions.DELETE_CAROUSEL_BUTTON]: (
    state: IWorkflowState,
    action: any
  ) => {
    const { nodeId, deleteMap } = action.payload;
    const nodes = [...state.workflow.nodes];

    const carouselNode = nodes.find((node) => node.id === nodeId);
    const { actions_map } = carouselNode?.data;

    const deleteButtonId = actions_map[deleteMap];
    const updatedActionsMap = {
      ...actions_map,
    };
    updatedActionsMap[deleteMap] = null;
    // Remove Button2Map from Workflow
    let updatedNodes = nodes.filter((node) => node.id !== deleteButtonId);

    // Update Current Node Action Map
    updatedNodes = updateNodeById({
      id: nodeId,
      nodes: updatedNodes,
      config: {
        actions_map: {
          ...updatedActionsMap,
        },
      },
    });

    return {
      ...state,
      workflow: {
        ...state.workflow,
        nodes: updatedNodes,
      },
    };
  },
  [WorkflowActions.ADD_CAROUSEL_BUTTON]: (
    state: IWorkflowState,
    action: any
  ) => {
    const { nodeId, addMap } = action.payload;

    let updatedNodes = [...state.workflow.nodes];
    const updatedEdges = [...state.workflow.edges];

    const carouselNode = updatedNodes.find((node) => node.id === nodeId);
    const { actions_map } = carouselNode?.data;

    const { addNode: buttonNode, addNodeEdge: buttonEdge } =
      createBranchAddNodeAndEdge({
        nodeId,
        position: carouselNode.position,
        branchIndex: 0,
        branchId: 1,
      });

    const updatedActionsMap = {
      ...actions_map,
    };
    updatedActionsMap[addMap] = buttonNode?.id;

    // Update Current Node Action Map
    updatedNodes = updateNodeById({
      id: nodeId,
      nodes: updatedNodes,
      config: {
        actions_map: {
          ...updatedActionsMap,
        },
      },
    });

    return {
      ...state,
      workflow: {
        ...state.workflow,
        nodes: [...updatedNodes, buttonNode],
        edges: [...updatedEdges, buttonEdge],
      },
    };
  },
  [WorkflowActions.UPDATE_WORKFLOW_NODES]: (
    state: IWorkflowState,
    action: any
  ) => {
    const { nodes } = state.workflow;
    const updatedNodes = action.payload.updatedNodes;

    const _nodes = nodes.map((node) => {
      const updatedNode = updatedNodes?.find((d: any) => d.id === node.id);

      if (updatedNode) {
        return updatedNode;
      }

      return node;
    });

    return {
      ...state,
      workflow: {
        ...state.workflow,
        nodes: _nodes,
      },
    };
  },
  [WorkflowActions.UPDATE_LAST_ADDED_NODE]: (
    state: IWorkflowState,
    action: any
  ) => {
    return {
      ...state,
      workflow: {
        ...state.workflow,
        lastAddedNodeId: action.payload.nodeId,
      },
    };
  },
  [WorkflowActions.SET_SELECT_NODE]: (state: IWorkflowState, action: any) => {
    return {
      ...state,
      workflow: {
        ...state.workflow,
        selectNode: action.payload,
      },
    };
  },
  [WorkflowActions.SET_OPEN_DRAWER]: (state: IWorkflowState, action: any) => {
    return {
      ...state,
      workflow: {
        ...state.workflow,
        openDrawer: action.payload.openDrawer,
      },
    };
  },
  [WorkflowActions.SET_CREATE_NODE_DROPDOWN]: (
    state: IWorkflowState,
    action: any
  ) => {
    return {
      ...state,
      workflow: {
        ...state.workflow,
        openCreateNodeDropdown: action.payload.openCreateNodeDropdown,
      },
    };
  },
  [WorkflowActions.SET_HOVER_NODE]: (state: IWorkflowState, action: any) => {
    return {
      ...state,
      workflow: {
        ...state.workflow,
        hoverNode: action.payload,
      },
    };
  },
  [WorkflowActions.RESET_WORKFLOW_DATA]: (
    state: IWorkflowState,
    action: any
  ) => {
    return initialState;
  },
  [WorkflowActions.SET_CONNECTION_STATE]: (
    state: IWorkflowState,
    action: any
  ) => {
    return {
      ...state,
      connection: {
        ...state.connection,
        ...action.payload,
      },
    };
  },
});

export default workflowReducer;
