import type React from "react";
import useStyles from "./ConfigurationLayoutContainer.styles";
import ConfigureHeader from "./Header/ConfigureHeader";
import { type Connector } from "pages/Integrations/integrations.types";
import LeftOverview from "./LeftOverview/LeftOverview";
import { type ConnectorActionsConfig } from "pages/ActionBuilder/components/AgentActions.const";
import { getConnectorActions } from "pages/ActionBuilder/Service/ActionBuilder.service";
import { useState, useEffect } from "react";
import RightConfigure from "./RightConfigure/RightConfigure";
import { useSnackbar } from "notistack";

interface Props {
  connector: Connector;
  integrated?: boolean;
  preConfiguredValues?: Record<string, string> | undefined;
  title?: string;
  handleUninstall?: () => Promise<any>;
}
const ConfigurationLayoutContainer: React.FC<Props> = ({
  connector,
  integrated = false,
  preConfiguredValues = {},
  title = "",
  handleUninstall,
}) => {
  const classes = useStyles();
  const [connectorActions, setConnectorActions] = useState<
    ConnectorActionsConfig[]
  >([]);
  const [actionsLoading, setActionsLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setConnectorActions([]);
    setActionsLoading(true);
    if (connector?._id) {
      getConnectorActions(connector?._id)
        .then((res) => {
          setConnectorActions(res);
        })
        .catch((err) => {
          enqueueSnackbar(`Error in loading data: ${err}`, {
            variant: "error",
          });
        })
        .finally(() => {
          setActionsLoading(false);
        });
    }
  }, [connector?._id]);

  return (
    <div className={classes.parentContainer}>
      <div className={classes.container}>
        <div className={classes.header}>
          <ConfigureHeader
            icon={connector.icon}
            name={title}
            description={connector.description}
            showTag={integrated}
          />
        </div>
        <div className={classes.leftRightContainer}>
          <div className={classes.leftOverview}>
            <LeftOverview
              actions={connectorActions}
              actionsLoading={actionsLoading}
              markDownString={connector?.auth_instructions ?? ""}
            />
          </div>
          <div className={classes.rightConfigure}>
            <RightConfigure
              icon={connector?.icon}
              fields={
                connector.config_template_map?.direct_creds?.fields ||
                connector.config_template_map?.oauth_redirection?.fields
              }
              complianceDocs={connector.compliance_docs}
              preConfiguredValues={preConfiguredValues}
              integrated={integrated}
              authDocLink={connector?.auth_docs_link}
              connectorId={connector?._id}
              authType={connector?.supported_auth_types[0]}
              title={title}
              onUninstall={handleUninstall}
              redirectURL={connector?.redirect_url_template}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationLayoutContainer;
