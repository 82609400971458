/**
 * Utility Helper Functions
 */

import { TenantSchema } from "constant/TenantConfig";

export const getTenantName = (): string => {
  let tenantName = TenantSchema.level;
  const hostname = window.location.hostname;

  if (hostname.includes("-chatbot")) {
    tenantName = hostname.split("-chatbot")[0];

    if (tenantName === "vista") {
      tenantName = TenantSchema.vista;
    }
  }

  return tenantName;
};

export const redirectToLoginPage = (): void => {
  const ENV = process.env.REACT_APP_ENVIRONMENT ?? "";
  const tenant = getTenantName();

  const currentURL = window.location.href;
  let loginUrl = "";

  switch (ENV) {
    case "PRODUCTION":
      loginUrl = `${tenant}.thelevel.ai`;
      break;
    default:
      loginUrl = `stagex-${
        tenant === "level" ? "dashboards" : tenant
      }.thelevel.ai`;
      break;
  }

  if (loginUrl) {
    window.location.href = `https://${loginUrl}/login-redirect?redirectURL=${currentURL}`;
  }
};

export const getBotId = (): string => {
  const path = window.location.pathname;

  const botId = path.split("/")?.[2];

  return botId;
};

export const getWorkflowId = (): string | null | undefined => {
  return window.location.pathname.includes("workflow-builder")
    ? window.location.pathname.split("/").pop()
    : null;
};

export const getPageName = (): string => {
  const path = window.location.pathname;

  const pageName = path.split("/")?.[2];

  return pageName;
};
