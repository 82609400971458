import { Navigate } from "react-router-dom";
import Analytics from "pages/Analytics";

const AnalyticsRoutes = [
  {
    index: true,
    element: <Navigate to="analytics" />,
  },
  {
    path: "analytics",
    element: <Analytics />,
  },
];

export default AnalyticsRoutes;
