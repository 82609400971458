import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  parentContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "32px",
    height: "calc(100% - 80px)",
  },
  container: {
    width: "67%",
    height: "100%",
    borderRadius: "16px",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "#FFF",
    boxShadow:
      "0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    overflow: "hidden",
    [theme.breakpoints.down("xl")]: {
      width: "100%", // Width for screens larger than or equal to 1536px (xl breakpoint)
    },
  },

  header: {
    padding: "24px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "32px",
    alignSelf: "stretch",
    borderBottom: "1px solid var(--border-default, #E1DEDA)",
    background: "var(--inactive-surfaces-subtle, #F9F8F8)",
  },
  leftRightContainer: {
    display: "flex",
    overflow: "hidden",
    height: "calc(100% - 168px)",
  },
  leftOverview: {
    width: "63%",
    padding: "32px",
    overflow: "auto",
    height: "100%",
  },
  rightConfigure: {
    width: "37%",
    padding: "32px",
    alignSelf: "stretch",
    borderLeft: "1px solid var(--border-default, #E1DEDA)",
    height: "100%",
  },
}));

export default useStyles;
