import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  "tab-switch-container": {
    position: "relative",
    display: "flex",
    padding: "8px 16px",
    alignItems: "center",
    borderRadius: "8px",
    background: "#F9F8F8",

    "& .tab-switch-option-container": {
      display: "flex",
      height: "32px",
      padding: "8px",
      justifyContent: "center",
      alignItems: "center",

      "& .tab-switch-option-button": {
        display: "flex",
        height: "32px",
        padding: "8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        cursor: "pointer",
        zIndex: "1",

        "&.tab-disabled": {
          color: "#EDEBE9",
          backgroundColor: "#B5B1AD",
          cursor: "default",
          pointerEvents: "none",
          userSelect: "none",
        },

        "&  .tab-switch-option-icon": {
          width: 16,
          height: 16,

          "& svg": {
            width: "100%",
            height: "100%",
            "& path": {
              fill: "#35312D",
              transition: "fill 0.25s ease-in",
            },
          },
        },
        "& .tab-switch-option-label": {
          "& .label": {
            transition: "color 0.25s ease-in",
          },
        },
      },

      "&.active": {
        "& .tab-switch-option-button": {
          "& .tab-switch-option-icon": {
            "& svg": {
              "& path": {
                fill: "#FF7D04",
              },
            },
          },
          "& .tab-switch-option-label": {
            "& .label": {
              color: "#FF7D04",
            },
          },
        },

        "& ~ .glider-container": {
          transform: (props: any) => `translateX(${props.position * 100}%)`,
        },
      },

      "&.glider": {
        // transition: "0.25s ease-out",
        display: "flex",
        height: "32px",
        padding: "8px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        background: "#FFF",
      },
    },
  },
}));
