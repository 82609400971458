import { APIIntegrationsActions } from "store/reduxActions/botActions/apiIntegrationsSlice";
import { createReducer } from "../util";

interface Props {
  loading: boolean;
  error: boolean;
  empty: boolean;
  list: any[];
}

const initialState: Props = {
  loading: true,
  error: false,
  empty: false,
  list: [],
};

const apiIntegrationsReducer = createReducer(initialState, {
  [APIIntegrationsActions.SET_API_INTEGRATIONS_LOADING]: (
    state: Props,
    action: any
  ) => {
    return {
      ...state,
      loading: action.payload.loading,
    };
  },
  [APIIntegrationsActions.SET_API_INTEGRATIONS_ERROR]: (
    state: Props,
    action: any
  ) => {
    return {
      ...state,
      loading: false,
      empty: false,
      error: true,
    };
  },
  [APIIntegrationsActions.SET_API_INTEGRATIONS_LIST]: (
    state: Props,
    action: any
  ) => {
    return {
      loading: false,
      empty: false,
      error: false,
      list: action.payload.api_integrations,
    };
  },
});

export default apiIntegrationsReducer;
