import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { useKnowledgeBaseHeaderStyles as useStyles } from "./style";
import { ReactComponent as KnowledgeBaseIcon } from "assets/knowledge-base-icon.svg";

const Header: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.headerContainer}>
      <div className={classes.titleContainer}>
        <div className={classes.headerIconContainer}>
          <KnowledgeBaseIcon />
        </div>

        <Typography
          className="mb-2"
          variant={TypographyVariants.textXXL}
          weight={TypographyWeights.bold}
        >
          Knowledge Base
        </Typography>
      </div>
    </div>
  );
};

export default Header;
