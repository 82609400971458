import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "8px",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "var(--inactive-surfaces-muted, #FCFCFB)",
    marginTop: "12px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    borderBottom: "1px solid var(--border-default, #E1DEDA)",
    background: "var(--inactive-surfaces-subtle, #F9F8F8)",
  },
  headTypography: {
    fontFamily: "Source Code Pro",
  },
  code: {
    padding: "16px",
    overflow: "scroll",
    maxHeight: "400px",
    fontFamily: "Source Code Pro",

    color: "var(--text-Blue-notes, #3A91B4)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "32px",
  },
}));

export default useStyles;
