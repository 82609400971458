import actionReducer from "./actions/actionReducer";
import botReducer from "./botReducer";
import botListReducer from "./botListReducer";
import appUserReducer from "./login/userReducer";
import chatbotReducer from "./chatbot";
import botTreeReducer from "./botTree";
import ActionBuilderReducer from "./ActionBuilder/ActionBuilderReducer";
import loginReducer from "./authReducer/loginSlice";
import userReducer from "./authReducer/userSlice";

const appReducer = {
  auth: loginReducer,
  user: userReducer,
  app_user: appUserReducer,
  botList: botListReducer,
  bot: botReducer,
  actions: actionReducer,
  chatbot: chatbotReducer,
  botTree: botTreeReducer,
  actionBuilder: ActionBuilderReducer,
};

export default appReducer;
