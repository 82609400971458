import TextField from "@mui/material/TextField";
import { useCallback } from "react";

export interface InputNameFieldProps {
  name: string;
  setName: (value: string) => void;
  showNameError: boolean;
  setShowNameError: (value: boolean) => void;
}

const InputNameField: React.FC<InputNameFieldProps> = ({
  name,
  setName,
  showNameError,
  setShowNameError,
}) => {
  const handleNameChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: { target: { value: any } }) => {
      const value = event.target.value;
      setName(value);

      if (value === "") {
        setShowNameError(false);
        return;
      }

      if (value.length < 3) {
        setShowNameError(true);
      } else {
        setShowNameError(false);
      }
    },
    [setName, setShowNameError]
  );

  return (
    <TextField
      variant="outlined"
      className="fs-16 fw-400"
      size="small"
      placeholder="Eg. FAQs"
      fullWidth
      value={name}
      onChange={handleNameChange}
      error={showNameError}
      helperText={showNameError ? "Please enter at least 3 characters" : ""}
      InputProps={{
        classes: {
          input: "py-10",
        },
      }}
    />
  );
};

export default InputNameField;
