import { CircularProgress, type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getAssistant } from "api/assistant/assistant";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { AssistantType, type IAssistant } from "pages/Assistant/types";
import AssistantContext, { defaultAssistant } from "./AssistantContext";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import { useDispatch } from "store";
import { BotActions, SettingsActions } from "store/reduxActions/botActions";
import AssistantHeader from "./components/AssistantHeader";
import ChatBotContainer from "pages/Bot/ChatBotContainer";
import { useChatboWidgetStyles } from "./Overview/styles";
import isEqual from "lodash/isEqual";
import VoiceBotContainer from "pages/Bot/VoiceBotContainer/VoiceBotContainer";
import AssistantActionMenu from "pages/Dashboard/Assistants/AssistantCard/AssistantActionMenu";
import useRouteNavigator from "hooks/navigation/useRouteNavigator";

const useStyles = makeStyles((theme: Theme) => ({
  assistantContainer: {
    width: "calc(100vw - 72px)",
    height: "100vh",
    overflowX: "auto",
  },
  assistantOutletContainer: {
    height: "calc(100vh - 80px)",
    width: "100%",
    minWidth: "1200px",
    position: "relative",
    top: "80px",
    padding: "16px 32px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [theme.breakpoints.down("lg")]: {
      alignItems: "flex-start",
    },
  },
}));

const Assistant: React.FC = () => {
  const classes = useStyles();
  const chatBotClasses = useChatboWidgetStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { gotoBotDashboard } = useRouteNavigator();

  const { assistantId } = params || {};

  const [assistant, setAssistant] = useState<IAssistant>(defaultAssistant);
  const [assistantState, setAssistantState] = useState({
    loading: true,
    error: null,
  });

  const getAssistantData = useCallback(async (id: string) => {
    setAssistantState((prevState) => ({ ...prevState, loading: true }));
    getAssistant(id)
      .then((response) => {
        const { data } = response || {};

        dispatch({
          type: SettingsActions.FETCH_BOT_SETTINGS_SUCCESS,
          payload: data.assistant.settings,
        });
        dispatch({
          type: BotActions.SET_BOT_META,
          payload: data.assistant,
        });

        setAssistant(data.assistant);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(`Something went wrong, try again.`, {
          variant: "error",
        });
      })
      .finally(() => {
        setAssistantState((prevState) => ({ ...prevState, loading: false }));
      });
  }, []);

  useEffect(() => {
    if (!assistantId) {
      return;
    }

    void getAssistantData(assistantId);
  }, [assistantId, getAssistantData]);

  if (!assistantId || !assistant || assistantState.loading) {
    return (
      <div className={clsx(classes.assistantContainer, "center")}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.assistantContainer}>
      <AssistantContext.Provider
        value={{ assistantId: assistant._id, assistant, setAssistant }}
      >
        <AssistantHeader
          title={assistant.name}
          description={assistant.description}
          isVoiceAssistant={isEqual(
            assistant?.settings?.channel_settings?.enabled_channels?.[0],
            AssistantType.Voice
          )}
        >
          <div className="flex col-gap-12">
            {/* <Button size={ButtonSizes.small} endIcon={<UploadIcon />}>
              Publish
            </Button>
            <Button
              size={ButtonSizes.small}
              variant={ButtonVariants.outlined}
              endIcon={<TestIcon />}
            >
              Test
            </Button> */}
            <AssistantActionMenu
              assistant={assistant}
              onRemoveAssistant={gotoBotDashboard}
              onUpdateAssistant={(removeAssistant: IAssistant) => {
                setAssistant(removeAssistant);
              }}
            />
          </div>
          {null}
        </AssistantHeader>

        <div className={classes.assistantOutletContainer}>
          <Outlet />
        </div>
        <div className={clsx(chatBotClasses.WidgetContainer)}>
          {isEqual(
            assistant?.settings?.channel_settings?.enabled_channels?.[0],
            AssistantType.Voice
          ) ? (
            <VoiceBotContainer
              phoneNumber={
                assistant?.settings?.voice_settings?.associated_phone_number
              }
            />
          ) : (
            <ChatBotContainer
              botConfig={{ minimizeIcon: true, isChatOpen: false }}
              botData={assistant}
              isPlatformBot={true} // this will be true for Platform Chatbot
            />
          )}
        </div>
      </AssistantContext.Provider>
    </div>
  );
};

export default Assistant;
