import { LinkResource, FileResource } from "pages/KnowledgeBase/types";

// GLOBAL
export enum AssistantRoute {
  OVERVIEW = "overview",
  KNOWLEDGE = "knowledge",
  AGENTS = "agents",
  TOOLS = "tools",
  DEPLOYMENT = "deployment",
}

export interface IAssistantNavigation {
  label: string;
  key: AssistantRoute;
  icon?: React.ReactNode;
  hidden?: boolean;
}

// ASSISTANT
export enum AssistantType {
  Chat = "WEBCHAT_TEXT",
  Voice = "VOICE",
}

export enum ChatAssistantStatus {
  READY = "Ready",
  IN_PROGRESS = "InProgress",
  DRAFT = "Draft",
}

export enum AssistantResponseType {
  CONCISE = "concise",
  MEDIUM = "medium",
  VERBOSE = "verbose",
}

export enum RadioValueType {
  YES = "yes",
  NO = "no",
}

export enum WidgetStateType {
  OPEN = "open",
  CLOSED = "closed",
}

export enum ChatAssistantNoResponseType {
  END_CONVERSATION = "end_conversation",
  AGENT_HANDOFF = "agent_handoff",
  END_CONVERSATION_CREATE_TICKET = "end_conversation_create_ticket",
}

export interface IceBreakerAction {
  id: string;
  name: string;
}

export interface AssistantIceBreaker {
  id: number;
  action: IceBreakerAction | null;
  created: string;
  modified: string;
  title: string;
}

export interface IChannelSettins {
  enabled_channels: AssistantType[];
}

export interface IChatbotSettings {
  bot_display_name: string;
  enabled_channels: AssistantType[];
  header_message: string;
  welcome_message: string;
  input_box_placeholder: string;
  bot_response_length: AssistantResponseType;
  show_source_of_info: RadioValueType;
  request_feedback: RadioValueType;
  display_nudge: RadioValueType;
  nudge_message: string;
  default_widget_state: WidgetStateType;
  no_bot_response: ChatAssistantNoResponseType;
  status_banner: RadioValueType;
  end_conversation_msg: string;
  end_user_type: string;
  accent_color: string;
  suggestions: AssistantIceBreaker[];
}

export interface IVoicebotSettings {
  voice_id: string;
  language: string;
  associated_phone_number: string;
}

export interface AssistantSettings {
  _id: string;
  assistant_id: string | null;
  channel_settings: IChannelSettins;
  chatbot_settings: IChatbotSettings;
  voice_settings: IVoicebotSettings;
  created: string;
  modified: string;
}

export interface KnowledgeBase {
  resource_ids: string[];
  resources: Array<FileResource | LinkResource>;
  name: string;
  description: string;
  status: string;
  created_at: string;
  modified_at: string;
}

export interface KnowledgeBaseMetadata {
  crawl_resources_count: number;
  knowledge_units_count: number;
}

export interface IAssistant {
  _id: string;
  status?: ChatAssistantStatus;
  status_message?: string;
  created: string;
  modified: string;
  name: string;
  description: string;
  enabled: boolean;
  deleted: boolean;
  settings?: AssistantSettings;
  general_info?: {
    goal: string | null;
    guidelines_and_rules: string | null;
    company_info_text: string | null;
  };
  kb_metadata: KnowledgeBaseMetadata;
  knowledge_base?: KnowledgeBase;
}

export enum AssistantBuilderFields {
  call_type = "call_type",
  company_info_text = "company_info_text",
  goal = "goal",
  guidelines_and_rules = "guidelines_and_rules",
  begin_message_ai_prompt = "begin_message_ai_prompt",
  agents = "agents",
}

export interface IAgent {
  _id: string;
  name: string;
  description: string;
}

export interface IAssistantBuilder {
  [AssistantBuilderFields.goal]: string;
  [AssistantBuilderFields.company_info_text]: string;
  [AssistantBuilderFields.agents]: IAgent[];
  [AssistantBuilderFields.guidelines_and_rules]: string;
}

// AGENTS
export enum BoundType {
  Inbound = "INBOUND",
  OutBound = "OUTBOUND",
}

export enum StatusType {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  PendingAPIIntegration = "PENDING_API_INTEGRATION",
  ToolMissing = "TOOLMISSING",
}

export interface PhoneNumber {
  countryCode: string;
  phone: string;
}

export interface Agent {
  id: string;
  name: string;
  description?: string;
  boundType: BoundType;
  phoneNumber: PhoneNumber;
  lastEdited: string;
  status: StatusType;
}

export interface AgentInfo {
  callType: BoundType;
  companyInfo: string;
  goal: string;
  welcomeConfig: {
    userFirst?: boolean;
    staticMessage?: string;
    aiPrompt?: string;
  };
  companyInfoFromKnowledgeBase: boolean;
  guidelinesAndRules: string;
}

export interface AgentInfoInput {
  company_info_text?: string;
  goal?: string;
  guidelines_and_rules?: string;
  begin_message_config: {
    user_first?: boolean;
    static_message?: string;
    ai_prompt?: string;
  };
}

//
export interface TabNavigationItem {
  key: string;
  path: string;
  title: string;
  icon?: React.ReactNode;
  enabled: boolean;
}

export interface TabNavigationCategory {
  category: string;
  key: string;
  title: string | null;
  info?: string | React.ReactNode;
  paths: TabNavigationItem[];
}

// APPEARANCE
export enum AppearanceSettingsFields {
  bot_display_name = "bot_display_name",
  header_message = "header_message",
  welcome_message = "welcome_message",
  input_box_placeholder = "input_box_placeholder",
  header_logo_enabled = "header_logo_enabled",
  header_logo_url = "header_logo_url",
  header_accent_color = "header_accent_color",
  header_text_color = "header_text_color",
  chat_bubble_enabled = "chat_bubble_enabled",
  chat_bubble_url = "chat_bubble_url",
  display_nudge = "display_nudge",
  nudge_message = "nudge_message",
  default_widget_state = "default_widget_state",
}

export interface IAppearanceSettings {
  bot_display_name: string;
  header_message: string;
  welcome_message: string;
  input_box_placeholder: string;
  header_logo_enabled: RadioValueType;
  header_logo_url: string;
  header_accent_color: string;
  header_text_color: string;
  chat_bubble_enabled: RadioValueType;
  chat_bubble_url: string;
  display_nudge: RadioValueType;
  nudge_message: string;
  default_widget_state: RadioValueType;
}
