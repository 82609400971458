import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Typography from "components/base/Typography";
import TabSwitch, { type ITabSwitchOption } from "components/base/TabSwitch";
import { ReactComponent as LinkIcon } from "assets/link-icon.svg";
import { ReactComponent as FileIcon } from "assets/file-icon.svg";
import { useState } from "react";
import FileSource from "./FileSource";
import LinkSource from "./LinkSource";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "24px",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  },
}));

const options: ITabSwitchOption[] = [
  {
    key: "link",
    label: "Link",
    icon: LinkIcon,
  },
  {
    key: "file",
    label: "File",
    icon: FileIcon,
  },
];

const AddSourceComponent: React.FC = () => {
  const classes = useStyles();

  const [sourceType, setSourceType] = useState("link");

  return (
    <div
      className={clsx(
        classes.container,
        "flex flex-col justify-content-start align-items-stretch row-gap-16"
      )}
    >
      <div className="source-add-header">
        <Typography
          variant="textXl"
          className="w-semi-bold"
          sx={{ color: "#282624" }}
        >
          Add Source
        </Typography>
      </div>

      <div className="source-type-select flex justify-content-start">
        <TabSwitch
          value={sourceType}
          onChange={(type: string) => {
            setSourceType(type);
          }}
          options={options}
        />
      </div>

      <div>
        {sourceType === "link" && <LinkSource />}
        {sourceType === "file" && <FileSource />}
      </div>
    </div>
  );
};

export default AddSourceComponent;
