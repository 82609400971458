import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 16px;",
    borderRadius: "8px",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "#FFF",
    overflow: "hidden",
  },
  activeContainer: {
    border: "1px solid var(--border-default, #7C7972)",
  },
  content: { overflow: "hidden" },
  title: {
    color: "var(--text-default, #282624)",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
    whiteSpace: "nowrap", // Ensure title stays on a single line
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  description: {
    color: "var(--text-default, #282624)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitLineClamp: 2, // Limit text to 3 lines
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
  },
  example: {
    color: "var(--text-subtle, #7C7972)",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    whiteSpace: "nowrap", // Ensure title stays on a single line
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: "4px",
  },
  icon: {},
}));

export default useStyles;
