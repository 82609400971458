import { Navigate } from "react-router-dom";
import KnowledgeBase from "pages/KnowledgeBase";
import Source from "pages/KnowledgeBase/Source";

const routes = [
  {
    index: true,
    element: <Navigate to="knowledge-base" />,
  },
  {
    path: "knowledge-base",
    element: <KnowledgeBase />,
    children: [
      {
        index: true,
        element: <Source />,
      },
    ],
  },
];

export default routes;
