import { useEffect, useState } from "react";
import useStyles from "./ConnectorActionsList.styles";
import { ReactComponent as PuzzleIcon } from "assets/puzzle-piece-02-black.svg";

import { Tool, type ConnectorActionsConfig } from "../../AgentActions.const";
import Button from "components/base/Button";
import { isEmpty, isNil } from "lodash";
import clsx from "clsx";
import { getConnectorActions } from "pages/ActionBuilder/Service/ActionBuilder.service";
import { type Connection } from "pages/Integrations/integrations.types";
import useReducerUtilityActions from "../../useReducerUtilityActions/useReducerUtilityActions";
import Table, { TableColumnProps } from "aether/Table";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { ButtonSizes } from "aether/Button";
import SelectedConnectorAction from "./SelectedConnectorAction";

interface ConnectorActionsListProps {
  selectedConnection: Connection;
  tool: Tool | null;
}

const ConnectorActionsList: React.FC<ConnectorActionsListProps> = ({
  selectedConnection,
  tool,
}) => {
  const classes = useStyles();
  const [connectorActions, setConnectorActions] = useState<
    ConnectorActionsConfig[]
  >([]);

  const {
    selectedConfigurableAction,
    onActionSelection,
    onChangeSelectedAction,
    onPreview,
    clearSlice,
  } = useReducerUtilityActions();

  const selectedAction = selectedConfigurableAction as ConnectorActionsConfig;

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setConnectorActions([]);
    setLoading(true);
    if (selectedConnection?.connector_id) {
      getConnectorActions(selectedConnection?.connector_id)
        .then((res) => {
          if (tool?.config?.action_config?._id) {
            const toolAction = res.filter(
              (action) => action._id === tool?.config?.action_config?._id
            )?.[0];
            toolAction.input_params = tool?.config?.input_params ?? [];
            toolAction.output_params = tool?.config?.output_params ?? [];
            setConnectorActions(toolAction ? [toolAction] : []);
            onActionSelection({
              ...toolAction,
              connectionId: tool?.config?.connection_id,
            });
          } else {
            setConnectorActions(res);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // whenever connector changes, need to clear the Store
    return clearSlice;
  }, [selectedConnection?.connector_id]);

  const ConnectorActionTable = Table<ConnectorActionsConfig>();
  const columns: Array<TableColumnProps<ConnectorActionsConfig>> = [
    {
      id: "name",
      label: "Name and description",
      width: "70%",
      align: "left",
      render: (row: ConnectorActionsConfig) => (
        <div className="flex">
          <div className={classes.rowIcon}>
            <PuzzleIcon
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </div>
          <div>
            <Typography
              weight={TypographyWeights.bold}
              variant={TypographyVariants.textLarge}
              renderInLines={1}
            >
              {row?.name}
            </Typography>
            <Typography
              weight={TypographyWeights.medium}
              variant={TypographyVariants.text}
              renderInLines={1}
            >
              {row?.description}
            </Typography>
          </div>
        </div>
      ),
    },
    {
      id: "actions",
      label: "",
      width: "30%",
      align: "right",
      render: (row: ConnectorActionsConfig) => (
        <div className={clsx("row-action-container", classes.actionsContainer)}>
          <Button
            variant="text"
            color="secondary"
            size={ButtonSizes.small}
            onClick={() => {
              onPreview({
                ...row,
                connectionId: selectedConnection._id,
              });
            }}
          >
            Preview
          </Button>
          <Button
            className={classes.selectButton}
            variant="contained"
            color="primary"
            size={ButtonSizes.small}
            onClick={() => {
              onActionSelection({
                ...row,
                connectionId: selectedConnection._id,
              });
            }}
          >
            Select & configure
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <div className={classes.step}>Step 2/2</div>
        <div className={classes.title}>Select Action</div>
        {isNil(selectedAction) ? (
          <div>
            <ConnectorActionTable
              isHeaderHidden
              columns={columns}
              data={connectorActions}
              loading={loading}
              emptyState={{ empty: isEmpty(connectorActions) }}
            />
          </div>
        ) : (
          <SelectedConnectorAction
            action={selectedAction}
            onChange={onChangeSelectedAction}
            hideChangeButton={!isNil(tool)}
          />
        )}
      </div>
    </>
  );
};

export default ConnectorActionsList;
