import { useStyles } from "./styles";
import { Card } from "@mui/material";
import { Outlet } from "react-router-dom";

const APIs: React.FC = () => {
  const classes = useStyles();
  return (
    <Card className={classes.container}>
      <Outlet />
    </Card>
  );
};

export default APIs;
