import type React from "react";
import { type ActionParam } from "../../AgentActions.const";
import useStyles from "./ActionParamTile.styles";
import { ReactComponent as DownIcon } from "assets/chevron-down.svg";
import { ReactComponent as UpIcon } from "assets/chevron-up.svg";

import IconButton from "aether/IconButton";
import clsx from "clsx";

interface Props {
  param: ActionParam;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isPopoverOpen?: boolean;
  showOpenCloseState?: boolean;
  showTileActive?: boolean;
}

const ActionParamTile: React.FC<Props> = ({
  param,
  isPopoverOpen = false,
  onClick,
  showOpenCloseState = false,
  showTileActive = false,
}) => {
  const classes = useStyles();

  return (
    <>
      <div
        className={clsx(classes.container, {
          [classes.activeContainer]: showTileActive,
        })}
        onClick={onClick}
      >
        <div className={classes.content}>
          <div className={classes.title}>{param.key}</div>
          <div className={classes.description}>{param.description}</div>
          <div className={classes.example}>{`Example ${param.example}`}</div>
        </div>
        {showOpenCloseState && (
          <IconButton
            variant={"icon"}
            color="custom"
            customColors={{ backgroundColor: "#35312D" }}
          >
            {isPopoverOpen ? <UpIcon /> : <DownIcon />}
          </IconButton>
        )}
      </div>
    </>
  );
};

export default ActionParamTile;
