import { makeStyles } from "@mui/styles";
import { type Theme } from "@mui/material/styles";

const useStyles = makeStyles<Theme>((theme) => ({
  banner: {
    display: "flex",
    alignItems: "center",
    padding: "16px 24px",
    borderRadius: "12px",
    border: "1px solid var(--Gray-200, #EAECF0)",
    boxShadow:
      "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
    width: "100%",
  },
  infoBanner: {
    background: "var(--colors-secondary-yellow-50, #FFFCEF)",
  },
  successBanner: {
    backgroundColor: "#4CAF50",
  },
  gifContainer: {
    background: "var(--colors-secondary-yellow-300, #FFF0A1)",
    marginRight: "16px",
  },
  iconContainer: {
    marginRight: "16px",
  },
  textContainer: {
    flex: 1,
  },
  title: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "28px",
  },
  subtitle: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  progressBarContainer: {
    width: "120px",
    height: "8px",
    backgroundColor: "#E0E0E0",
    borderRadius: "4px",
    marginLeft: "16px",
  },
  progressBar: {
    width: "50%", // Static 50% filled progress bar
    height: "100%",
    backgroundColor: "#4CAF50", // Green for progress
    borderRadius: "4px",
  },
}));

export default useStyles;
