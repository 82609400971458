import { makeStyles } from "@mui/styles";
import { type Theme } from "@mui/material/styles";
import { type CustomColorProps } from ".";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "16px",
    mixBlendMode: "multiply",
    gap: "4px",
  },
  // Variant and Color
  contained: (props: { color: string; customColors: CustomColorProps }) => ({
    color: {
      default: "#344054",
      success: "#027A48",
      warning: "#856404",
      error: "#721c24",
      custom: props?.customColors?.color,
    }[props.color],
    background: {
      default: "#F2F4F7",
      success: "#E7FDF3",
      warning: "#fff3cd",
      error: "#f8d7da",
      custom: props?.customColors?.backgroundColor,
    }[props.color],
  }),
  outlined: (props: { color: string }) => ({
    border: "1.5px solid",
    color: {
      default: "#344054",
      success: "#027A48",
      warning: "#856404",
      error: "#721c24",
      custom: "#000000",
    }[props.color],
    borderColor: {
      default: "1.5px solid #475467",
      success: "1.5px solid #28a745",
      warning: "1.5px solid #ffc107",
      error: "1.5px solid #dc3545",
      custom: "1.5px solid #000000",
    }[props.color],
  }),
  // Size
  xsmall: {
    padding: "2px 8px",
  },
  small: {
    padding: "2px 8px",
  },
  medium: {
    padding: "2px 10px",
  },
  large: {
    padding: "2px 12px",
  },
}));

export default useStyles;
