// src/components/GenericModal/styles.ts
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  modalContainer: {
    "& .MuiPaper-root": {
      border: (props: any) => (props?.borderColor ? "2px solid" : "none"),
      borderColor: (props: any) =>
        props?.borderColor ? props.borderColor : "transparent",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  },
  button: {
    padding: "8px 16px",
    backgroundColor: "#F07400",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    "&:disabled": {
      backgroundColor: "#ddd",
    },
    "&:hover": {
      backgroundColor: "#d06500",
    },
  },
  closeButton: {
    padding: 0,
    display: "flex",
    alignItems: "center",
  },
  content: {
    marginTop: "16px",
  },
}));
