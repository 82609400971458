import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useSourceStyles = makeStyles((theme: Theme) => ({
  sourceContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
}));
