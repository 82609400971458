import { authorizeUser } from "api/auth";
import { useCallback } from "react";
import { setAxiosInstance } from "services/axios";
import { useDispatch, useSelector } from "store";
import { LoginActions, UserActions } from "store/reduxActions/authActions";
import { type IUser } from "types/auth.types";
import { redirectToLoginPage } from "utils/helpers";

const useAuthorization = (): any => {
  const dispatch = useDispatch();

  const { loggedIn } = useSelector((state) => state.auth.loggedIn);

  const handleAuthSuccess = ({
    token,
    user,
  }: {
    token: string;
    user: IUser;
  }): void => {
    dispatch({
      type: LoginActions.LOGIN_SUCCESS,
      payload: { token },
    });
    dispatch({
      type: UserActions.SET_APP_USER,
      payload: { user },
    });
  };

  const handleAuthFailure = (): void => {
    redirectToLoginPage();
  };

  const authCheck = useCallback(async () => {
    authorizeUser()
      .then((response: any) => {
        if (response?.data?.token) {
          const { token, ...user } = response.data;
          setAxiosInstance(token);
          handleAuthSuccess({ token, user });
        } else {
          handleAuthFailure();
        }
      })
      .catch((error) => {
        console.log(error);
        handleAuthFailure();
      });
  }, [loggedIn]);

  return { authCheck };
};

export default useAuthorization;
