import { createContext } from "react";
import { AnalyticsDashboardData, DashboardLayout } from "../types";
import noop from "lodash/noop";
interface AnalyticsContextType {
  loading: boolean;
  setLoading: (flag: boolean) => void;
  dashboard: AnalyticsDashboardData | null;
  setDashboard: (data: AnalyticsDashboardData) => void;
  dashboardLayout: DashboardLayout | null;
  setDashboardLayout: React.Dispatch<
    React.SetStateAction<DashboardLayout | null>
  >;
  setErrorInLoadingData: (error: string) => void;
  errorinLoadingData?: string | null;
}

const defaultContextValue: AnalyticsContextType = {
  loading: false,
  setLoading: noop,
  dashboard: null,
  setDashboard: noop,
  dashboardLayout: null,
  setDashboardLayout: noop,
  errorinLoadingData: null,
  setErrorInLoadingData: noop,
};

export const AnalyticsContext =
  createContext<AnalyticsContextType>(defaultContextValue);
