import { Box } from "@mui/material";
import CenteredLoader from "components/shared/CenteredLoader";
import { useRef } from "react";

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
  elementClassName?: string;
}

const CommonAskConfirmationButton: React.FC<Props> = ({
  isLoading,
  children,
  elementClassName,
}) => {
  const confirmButtonWidthRef = useRef<number>();
  const confirmButtonHeightRef = useRef<number>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dialogRef = useRef<any>();

  return (
    <Box>
      {isLoading ? (
        <Box display="flex" alignItems="center">
          <CenteredLoader
            width={`${confirmButtonWidthRef.current}px`}
            height={`${confirmButtonHeightRef.current}px`}
          />
        </Box>
      ) : (
        <Box
          ref={(node) => {
            dialogRef.current = node;
            // Do your work requiring the node here, but make sure node isn't null.

            if (dialogRef.current) {
              const buttonEle =
                dialogRef.current.getElementsByClassName(elementClassName)[0];

              if (buttonEle) {
                const { width, height } = buttonEle.getBoundingClientRect();

                confirmButtonWidthRef.current = width;

                confirmButtonHeightRef.current = height;
              }
            }
          }}
        >
          {/* // using 2 boxes so that it does not take entire line as space */}
          <Box display="flex">
            <Box className={elementClassName}>{children}</Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

CommonAskConfirmationButton.defaultProps = {
  elementClassName: "AskConfirmation-ConfirmButton",
};

export default CommonAskConfirmationButton;
