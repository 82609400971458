import {
  ResourceType,
  FileResource,
  LinkResource,
  SourcesTableData,
} from "pages/KnowledgeBase/types";

export const formatResourcesForTable = (
  resources: Array<FileResource | LinkResource>
): SourcesTableData[] => {
  return resources.map((resource) => {
    if ("document_id" in resource) {
      return {
        id: resource.id,
        resourceId: resource.document_id,
        resourceType: ResourceType.FILE,
        type: resource.file_type,
        name: resource.title,
        uploadedOn: resource.modified,
        status: resource.pending_sync,
        actions: "",
      };
    }

    return {
      id: resource.id,
      resourceId: resource.id,
      resourceType: ResourceType.LINK,
      type: ResourceType.LINK,
      name: resource.name,
      uploadedOn: resource.modified,
      status: resource.render_status,
      actions: "",
    };
  });
};

export const formatResources = (
  resources: Array<
    | Omit<FileResource, "resouceType" | "resourceId">
    | Omit<LinkResource, "resouceType" | "resourceId">
  >
): Array<FileResource | LinkResource> => {
  return resources.map((resource) => {
    if ("document_id" in resource) {
      return {
        ...resource,
        resourceId: resource.document_id,
        resourceType: ResourceType.FILE,
      };
    }

    return {
      ...resource,
      resourceId: resource.id,
      resourceType: ResourceType.LINK,
    };
  });
};
