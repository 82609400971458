import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    height: "32px",
    width: "32px",
    marginTop: "8px",
  },
  container: {
    cursor: "pointer",
    display: "flex",
    flex: "0 0 calc(33% - 8px)",
    width: "50%",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "#FFF",
    overflow: "hidden",
  },
  selectedContainer: { border: "1px solid var(--border-active, #F07400)" },
  content: {
    marginLeft: "16px",
    overflow: "hidden",
  },
  title: {
    color: "var(--fg-text-default, #282624)",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "1.5",
    whiteSpace: "nowrap", // Ensure title stays on a single line
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  description: {
    color: "var(--text-default, #282624)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "1.5",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitLineClamp: 3, // Limit text to 3 lines
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
  },
  icon: {},
  infoKeys: {
    display: "flex",
    marginTop: "8px",
  },
  info: {
    color: "var(--text-subtle, #7C7972)",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "20px",
  },
}));

export default useStyles;
