import type React from "react";
import useStyles from "./ConnectorTile.styles";
import clsx from "clsx";
import IconButton from "aether/IconButton";
import { ReactComponent as PuzzleIcon } from "assets/puzzle-piece-02-black.svg";
import { isEmpty } from "lodash";
import { type Connection } from "pages/Integrations/integrations.types";

interface Props {
  tileConfig: Connection;
  onClick: () => void;
  selected?: boolean;
}

const ConnectorTile: React.FC<Props> = ({
  tileConfig,
  onClick,
  selected = false,
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.container, {
        [classes.selectedContainer]: selected,
      })}
      onClick={onClick}
    >
      {!isEmpty(tileConfig?.connector?.icon) ? (
        <img
          src={tileConfig?.connector?.icon}
          alt={tileConfig?.name}
          className={classes.image}
        />
      ) : (
        <IconButton
          variant="contained"
          color="custom"
          customColors={{ backgroundColor: "#F3F2F0" }}
        >
          <PuzzleIcon />
        </IconButton>
      )}
      <div className={classes.content}>
        <div className={classes.title}>{tileConfig.name}</div>
        <div className={classes.description}>
          {tileConfig?.connector?.description}
        </div>
        <div className={classes.infoKeys}>
          <div
            className={classes.info}
          >{`${tileConfig?.connector?.action_count} predefined actions Action`}</div>
        </div>
      </div>
    </div>
  );
};

export default ConnectorTile;
