import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  confirmAddContainer: {
    display: "flex",
    marginTop: "32px",
    padding: "16px 24px",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "24px",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "var(--inactive-surfaces-Brand-highlight, #FFF5EB)",
    boxShadow:
      "0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  },
  confirmNote: {
    color: "var(--text-default, #282624)",
    textAlign: "center",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
});
export default useStyles;
