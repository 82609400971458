import { Navigate } from "react-router-dom";
import EmbedChatbot from "pages/Bot/EmbedChatbot";
import Dashboard from "pages/Dashboard";
import APIList from "pages/Bot/APIIntegration/components/APIList";
import AgentBuilderContainer from "pages/AgentBuilder";
import ActionBuilderContainer from "pages/ActionBuilder/components/ActionBuilderContainer";
import Assistant from "pages/Assistant";
import AssistantBuilder from "pages/Assistant/Overview";
import IntegrationsContainer from "pages/Integrations/IntegrationsContainer";
import ConnectionConfigureContainer from "pages/Integrations/Content/configuration/ConnectionConfigureContainer";
import GlobalIntegrationsContainer from "pages/Integrations/GlobalIntegrationsContainer";
import ConnectorConfigureContainer from "pages/Integrations/Content/configuration/ConnectorConfigureContainer";
import AssistantKnowledge from "pages/Assistant/Knowledge";
import AssistantAgents from "pages/Assistant/Agents";
import AssistantTools from "pages/Assistant/Tools";
import AssistantDeployment from "pages/Assistant/Deployment";
import APIs from "pages/Assistant/Tools/APIs";
import Appearance from "pages/Assistant/Deployment/Appearance";
import APIBuilderContainer from "pages/APIBuilder/Components/APIBuilder.container";

const routes = [
  {
    index: true,
    element: <Navigate to="assistant" />,
  },
  {
    path: "assistant",
    element: <Dashboard />,
  },
  {
    path: "assistant/:assistantId",
    element: <Assistant />,
    children: [
      {
        index: true,
        element: <Navigate to="overview" />,
      },
      {
        path: "overview",
        element: <AssistantBuilder />,
      },
      {
        path: "knowledge",
        element: <AssistantKnowledge />,
      },
      {
        path: "agents",
        element: <AssistantAgents />,
      },
      {
        path: "tools",
        element: <AssistantTools />,
        children: [
          {
            index: true,
            element: <Navigate to="apis" />,
          },
          {
            path: "apis",
            element: <APIs />,
            children: [
              {
                index: true,
                element: <APIList />,
              },
            ],
          },
        ],
      },
      {
        path: "deployment",
        element: <AssistantDeployment />,
        children: [
          {
            index: true,
            element: <Navigate to="appearance" />,
          },
          {
            path: "appearance",
            element: <Appearance />,
          },
          {
            path: "embed-chatbot",
            element: <EmbedChatbot />,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to="." />,
      },
    ],
  },
  {
    path: "assistant/:assistantId/api-builder/:integrationId",
    element: <APIBuilderContainer />,
  },
  {
    path: "assistant/:assistantId/agent/:agentId",
    element: <AgentBuilderContainer />,
  },
  {
    path: "bot/chat/*",
    element: <Navigate to="../assistant" />,
  },
  {
    path: "bot/voice/*",
    element: <Navigate to="../assistant" />,
  },
  {
    path: "bot/chat/:botId/workflow-builder/:workflowId?",
    element: <Navigate to="../assistant" />,
  },
  {
    path: "bot/:botId/agent-builder/:agentId",
    element: <Navigate to="../assistant" />,
  },
  {
    path: "bot/:botId/action-builder/:agentId/:toolId?",
    element: <ActionBuilderContainer />,
  },
  {
    path: "integrations",
    element: <GlobalIntegrationsContainer />,
    children: [
      {
        index: true,
        element: <IntegrationsContainer />,
      },
      {
        path: "connection/:connectionId",
        element: <ConnectionConfigureContainer />,
      },
      {
        path: "connector/:connectorId",
        element: <ConnectorConfigureContainer />,
      },
    ],
  },
];

export default routes;
