import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useKnowledgeSourceStyles = makeStyles((theme: Theme) => ({
  sourceListContainer: {
    width: "100%",
    height: "64vh",
    margin: "24px 0px",
  },
  linkToKnowledgeBasePage: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  linkText: {
    color: "var(--text-brand, #C9671D)",
    cursor: "pointer",
  },
}));

export const useSourceTableStyles = makeStyles((theme: Theme) => ({
  sourceTableContainer: {
    height: "100%",
    overflow: "auto",
  },
  tableContainer: {
    margin: "4px 0px",
  },
}));
