import { ReactComponent as EmptyListIcon } from "assets/images/empty-bot-list-icon.svg";
import Typography, { TypographyVariants } from "aether/Typography";

const EmptyList: React.FC = () => {
  return (
    <>
      <EmptyListIcon />
      <Typography variant={TypographyVariants.text}>
        Your bots will appear here
      </Typography>
      <div className="pt-2">
        <Typography variant={TypographyVariants.textSmall}>
          Get started by creating a bot.
        </Typography>
      </div>
    </>
  );
};

export default EmptyList;
