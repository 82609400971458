import React, { useEffect, useState } from "react";
import Typography, { TypographyWeights } from "aether/Typography";
import { useAssistantNavigationStyles as useStyles } from "../styles";
import clsx from "clsx";
import useAssistantNavigation from "hooks/navigation/useAssistantNavigation";
import { AssistantRoute } from "pages/Assistant/types";
import { AssistantNavigations } from "pages/Assistant/config";
import { isEqual } from "lodash";
import { useLocation } from "react-router-dom";

interface Props {
  hideDeploymentTab: boolean;
}

const AssistantNavigationBar: React.FC<Props> = ({ hideDeploymentTab }) => {
  const classes = useStyles();
  const location = useLocation();
  const { gotoAssistantTab } = useAssistantNavigation();

  const [currentTab, setCurrentTab] = useState<string>(AssistantRoute.OVERVIEW);

  useEffect(() => {
    if (location?.pathname) {
      const _path = location.pathname.split("/")[3];

      setCurrentTab(_path);
    }
  }, [location]);

  const handleSelectAssistantTab = (key: AssistantRoute): void => {
    setCurrentTab(key);
    gotoAssistantTab(key);
  };

  return (
    <div className={classes.assistantNavigationContainer}>
      {AssistantNavigations.map(({ label, key, icon: Icon }, index) => {
        if (hideDeploymentTab && isEqual(key, AssistantRoute.DEPLOYMENT)) {
          return null;
        }
        return (
          <React.Fragment key={key}>
            <div
              className={clsx(classes.navigationItemContainer, {
                active: key === currentTab,
              })}
              onClick={(event) => {
                handleSelectAssistantTab(key);
              }}
            >
              <div className="navigation-item-content">
                {Icon && (
                  <div className={classes.navigationItemIcon}>{Icon}</div>
                )}
                <div className={classes.navigationItemLabel}>
                  <Typography weight={TypographyWeights.semiBold}>
                    {label}
                  </Typography>
                </div>
              </div>
            </div>
            {index === 0 && <div className={classes.overviewSeparator}></div>}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default AssistantNavigationBar;
