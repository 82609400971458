import type React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ConfigurationLayoutContainer from "./ConfigurationLayoutContainer";
import { type Connector } from "pages/Integrations/integrations.types";
import useIntegrationsRouteNavigator from "hooks/navigation/useIntegrationsRouteNavigator";
import { ReactComponent as BackIcon } from "assets/arrow-left.svg";
import IntegrationHeader from "pages/Integrations/Header/IntegrationHeader";
import useStyles from "./connectionConnectorConfigureContainer.styles";
import { getConnectorById } from "pages/Integrations/Services/integrations.service";
import { useSnackbar } from "notistack";
import { isNil } from "lodash";
import { CircularProgress } from "@mui/material";
import clsx from "clsx";

const ConnectorConfigureContainer: React.FC = () => {
  const { connectorId } = useParams();
  const classes = useStyles();
  const [connector, setConnector] = useState<Connector | null>(null);
  const { gotoIntegrationsPage } = useIntegrationsRouteNavigator();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isNil(connectorId)) {
      setLoading(true);
      getConnectorById(connectorId)
        .then((res) => {
          setConnector(res);
        })
        .catch((err: any) => {
          enqueueSnackbar(`Something went wrong: ${err}`, {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [connectorId]);

  const handleBackNavigation = (): void => {
    gotoIntegrationsPage();
  };

  if (loading || isNil(connector)) {
    return (
      <div className={clsx(classes.loadingContainer, "center")}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <IntegrationHeader
        title={connector?.name}
        onIconClick={handleBackNavigation}
        icon={<BackIcon />}
      />
      <ConfigurationLayoutContainer
        connector={connector}
        title={connector?.name}
      />
    </div>
  );
};

export default ConnectorConfigureContainer;
