import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  type DialogProps,
  type DialogTitleProps as MuiDialogTitleProps,
  DialogTitle as MuiDialogTitle,
  IconButton,
  type ModalProps,
  Box,
  type BoxProps,
  DialogContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { type ReactNode } from "react";
import type React from "react";
import { useCallback } from "react";

const useStyles = makeStyles({
  zeroMarginPadding: {
    margin: 0,
    padding: 0,
  },
});

export interface HeadedDialogProps
  extends DialogProps,
    Pick<BoxProps, "height" | "width"> {
  header?: ReactNode;
  disableBackdropClose?: boolean;
}

interface DialogTitleProps extends MuiDialogTitleProps {
  header?: ReactNode;
  onClose?: ModalProps["onClose"];
}

const DialogTitle: React.FC<DialogTitleProps> = (props) => {
  const { header, onClose, ...other } = props;

  const classes = useStyles();

  const onClickClose = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      onClose?.(event, "escapeKeyDown");
    },
    [onClose]
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiDialogTitle
      className={classes.zeroMarginPadding}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {header && (
          <>
            <Box display="flex" flexGrow={1}>
              {header}
            </Box>
          </>
        )}
        {onClose && (
          <IconButton onClick={onClickClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </MuiDialogTitle>
  );
};

const HeadedDialog: React.FC<HeadedDialogProps> = ({
  children,
  disableBackdropClose,
  header,
  height,
  onClose,
  width,
  ...otherProps
}) => {
  const classes = useStyles();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Dialog
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      className={classes.zeroMarginPadding}
    >
      {(header ?? onClose) && <DialogTitle onClose={onClose} header={header} />}
      <DialogContent className={classes.zeroMarginPadding}>
        <Box
          flex={1}
          height={height}
          width={width}
          display="flex"
          flexDirection="column"
        >
          {children}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

HeadedDialog.defaultProps = {
  disableBackdropClose: false,
};

export default HeadedDialog;
