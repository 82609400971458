export const WorkflowActions = {
  SET_WORKFLOW_LOADING: "SET_WORKFLOW_LOADING",
  SET_WORKFLOW_PAGE_ERROR: "SET_WORKFLOW_PAGE_ERROR",
  SET_WORKFLOW_PAGE_EMPTY: "SET_WORKFLOW_PAGE_EMPTY",
  SET_WORKFLOW_DATA: "SET_WORKFLOW_DATA",
  SET_SELECT_NODE_ID: "SET_SELECT_NODE_ID",
  // SET_OPEN_NODE: "SET_OPEN_NODE",
  // SET_CLOSE_NODE: "SET_CLOSE_NODE",
  SET_NEW_WORKFLOW: "SET_NEW_WORKFLOW",
  SET_WORKFLOW_NODES: "SET_WORKFLOW_NODES",
  SET_WORKFLOW_EDGES: "SET_WORKFLOW_EDGES",
  UPDATE_WORKFLOW_NODE: "UPDATE_WORKFLOW_NODE",
  UPDATE_WORKFLOW_EDGE: "UPDATE_WORKFLOW_EDGE",
  DELETE_NODE: "DELETE_NODE",
  UPDATE_NODE_POSITION: "UPDATE_NODE_POSITION",
  DELETE_NODE_BRANCH: "DELETE_NODE_BRANCH",
  DELETE_CAROUSEL_BUTTON: "DELETE_CAROUSEL_BUTTON",
  ADD_CAROUSEL_BUTTON: "ADD_CAROUSEL_BUTTON",
  SET_WORKFLOW_LAYOUT: "SET_WORKFLOW_LAYOUT",
  UPDATE_WORKFLOW_NODES: "UPDATE_WORKFLOW_NODES",
  RESET_WORKFLOW_DATA: "RESET_WORKFLOW_DATA",
  UPDATE_LAST_ADDED_NODE: "UPDATE_LAST_ADDED_NODE",
  SET_CREATE_NODE_DROPDOWN: "SET_CREATE_NODE_DROPDOWN",
  SET_OPEN_DRAWER: "SET_OPEN_DRAWER",
  SET_SELECT_NODE: "SET_SELECT_NODE",
  SET_HOVER_NODE: "SET_HOVER_NODE",
  // CONNECTION STATE REDUX ACTIONS
  SET_CONNECTION_STATE: "SET_CONNECTION_STATE",
};
