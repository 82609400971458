import Axios from "axios";
import { getTenantName } from "utils/helpers";
import { BOT_BE_BASE_URL } from "services/base-url";
import type IBot from "types/BotType";
import type PaginatedResponse from "types/PaginatedResponse";
import { botAxiosInstance } from "services/axios";
import { type BotType } from "types/BotType";

export const botBEaxiosInstance = Axios.create({
  baseURL: BOT_BE_BASE_URL(),
  withCredentials: true,
});

// export const getBotStatus = async ({
//   token,
//   botId,
// }: {
//   token: string;
//   botId: string;
// }): Promise<IBot> => {
//   return await new Promise((resolve, reject) => {
//     botBEaxiosInstance({
//       method: "GET",
//       url: `/bot/${botId}/`,
//       withCredentials: true,
//       headers: {
//         "X-DTS-SCHEMA": getTenantName(),
//         Authorization: `Bearer ${token}`,
//       },
//     })
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const getBotData = async (botId: string): Promise<any> => {
  return await botAxiosInstance({
    method: "GET",
    url: `bot/${botId}/`,
  });
};

export const createbot = async (data: {
  name: string;
  description: string;
  enabled_channels: [BotType];
}): Promise<IBot> => {
  return await botAxiosInstance({
    method: "POST",
    url: `/bot/`,
    data,
  });
};

export const getBotList = async ({
  token,
  params,
}: {
  token: string;
  params: string;
}): Promise<PaginatedResponse<IBot>> => {
  // return await new Promise((resolve, reject) => {
  //   botBEaxiosInstance({
  //     method: "GET",
  //     url: `/bot/?${params}`,
  //     withCredentials: true,
  //     headers: {
  //       "X-DTS-SCHEMA": getTenantName(),
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((res) => {
  //       resolve(res.data);
  //     })
  //     .catch((err) => {
  //       reject(err);
  //     });
  // });

  return await botAxiosInstance({
    method: "GET",
    url: `/bot/?${params}`,
  });
};

export const putBotDetails = async (
  botId: string,
  name: string,
  description: string,
  token: string
): Promise<IBot> => {
  return await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "PUT",
      url: `/bot/${botId}/`,
      data: {
        name,
        description,
      },
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteBot = async (
  botId: string,
  token: string
): Promise<void> => {
  await new Promise((resolve, reject) => {
    botBEaxiosInstance({
      method: "DELETE",
      url: `/bot/${botId}/`,
      headers: {
        "X-DTS-SCHEMA": getTenantName(),
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
