export const SettingsActions = {
  FETCH_BOT_SETTINGS_SUCCESS: "FETCH_BOT_SETTINGS_SUCCESS",
  FETCH_BOT_SETTINGS_ERROR: "FETCH_BOT_SETTINGS_ERROR",
  FETCH_BOT_SETTINGS_LOADING: "FETCH_BOT_SETTINGS_LOADING",
  UPDATE_BOT_SETTINGS_SUCCESS: "UPDATE_BOT_SETTINGS_SUCCESS",
  UPDATE_BOT_SETTING_PROPERTY: "UPDATE_BOT_SETTING_PROPERTY",
  UPDATE_CHATBOT_SETTINGS: "UPDATE_CHATBOT_SETTINGS",
  UPDATE_CHATBOT_SETTINGS_FIELD: "UPDATE_CHATBOT_SETTINGS_FIELD",
  SET_USER_ON_SETTINGS_PAGE: "SET_USER_ON_SETTINGS_PAGE",
};
