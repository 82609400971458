import { makeStyles } from "@mui/styles";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { ReactComponent as EmtyStateIcon } from "assets/barChartEmptyState.svg";
const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface Props {
  title?: string;
  description?: string;
  icon?: React.ReactNode;
}

const BrokenVisualization: React.FC<Props> = ({
  title = "Sorry! there is something wrong at our end.",
  description = "Please check your internet connection, refresh your browser, or tryagain later.",
  icon = <EmtyStateIcon />,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {icon}
      <Typography
        weight={TypographyWeights.bold}
        variant={TypographyVariants.textLarge}
      >
        {title}
      </Typography>
      <Typography variant={TypographyVariants.text}>{description}</Typography>
    </div>
  );
};

export default BrokenVisualization;
