import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { ReactComponent as URLIcon } from "assets/link-icon.svg";
import { useCallback } from "react";
// import "./index.scss";
import urlRegex from "utils/urlRegex";

export interface InputURLFieldProps {
  url: string;
  setUrl: (value: string) => void;
  showURLError: boolean;
  setShowURLError: (value: boolean) => void;
}

const InputURLField: React.FC<InputURLFieldProps> = ({
  url,
  setUrl,
  showURLError,
  setShowURLError,
}) => {
  const handleUrlChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: { target: { value: any } }) => {
      const value = event.target.value;
      setUrl(value);

      if (value === "") {
        setShowURLError(false);
        return;
      }

      if (!value.match(urlRegex)) {
        setShowURLError(true);
      } else {
        setShowURLError(false);
      }
    },
    [setShowURLError, setUrl]
  );

  return (
    <TextField
      name="url"
      value={url}
      className="fs-16 fw-400"
      variant="outlined"
      size="small"
      placeholder={"https://www.example.com/support"}
      fullWidth
      helperText={
        showURLError
          ? "Please enter a valid url"
          : "This will crawl all the pages on the link provided"
      }
      onChange={handleUrlChange}
      error={showURLError}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <URLIcon className="placeholder-url-icon" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InputURLField;
