import { type BoxProps, CircularProgress } from "@mui/material";
import CenterContentBox from "components/shared/CenterContentBox";

export interface CenteredLoaderProps extends BoxProps {
  progressSize?: number;
}

const CenteredLoader: React.FC<CenteredLoaderProps> = ({
  progressSize,
  ...otherProps
}) => {
  return (
    <CenterContentBox
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      <CircularProgress style={{ color: "#282624" }} size={progressSize} />
    </CenterContentBox>
  );
};

CenteredLoader.defaultProps = {
  progressSize: 24,
};

export default CenteredLoader;
