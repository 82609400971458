import { BarAndDonutVizData } from "pages/Analytics/types";
import React from "react";
import DonutChart from "../../../Charts/DonutChart/DonutChart";
import { makeStyles } from "@mui/styles";
import Legends from "pages/Analytics/Charts/Legends/Legends";
import CustomBarChart from "pages/Analytics/Charts/BarChart/BarChart";
import BarAndDonutTileVisualizationShimmer from "./BarAndDonutTileVisualizationShimmer";
import BrokenVisualization from "../BrokenVisualization";

const useStyles = makeStyles(() => ({
  barAndDonutTileVizualizationContainer: {
    height: "calc(100% - 28px)",
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    gap: "38px",
    alignSelf: "stretch",
  },
  leftDonutContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  vizData: BarAndDonutVizData;
  loading: boolean;
}

const BarAndDonutTileVizualization: React.FC<Props> = ({
  vizData,
  loading,
}) => {
  const classes = useStyles();
  if (loading) {
    return <BarAndDonutTileVisualizationShimmer />;
  }

  if (!vizData) {
    return <BrokenVisualization />;
  }

  const { donutChartdata, barChartData } = vizData || {};

  return (
    <div className={classes.barAndDonutTileVizualizationContainer}>
      <div className={classes.leftDonutContainer}>
        <DonutChart
          segments={donutChartdata?.segments}
          centerLabel={donutChartdata?.centerLabel}
        />
        <Legends segments={donutChartdata.segments} />
      </div>
      <CustomBarChart vizData={barChartData} />
    </div>
  );
};

export default BarAndDonutTileVizualization;
