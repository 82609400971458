import { ReactComponent as VistaIcon } from "./imgs/vista.svg";
import { ReactComponent as AffirmIcon } from "./imgs/affirm.svg";
import { ReactComponent as CartaIcon } from "./imgs/carta.svg";
import { ReactComponent as EzcaterIcon } from "./imgs/ezcater.svg";
import { ReactComponent as LevelIcon } from "./imgs/level.svg";
import { ReactComponent as OpenLinkIcon } from "assets/open-in-new.svg";
import { getTenantName } from "utils/helpers";

const TenantLinkIcon: React.FC = () => {
  const tenant = getTenantName();

  if (tenant === "affirm") {
    return <AffirmIcon />;
  }

  if (tenant === "vista") {
    return <VistaIcon />;
  }

  if (tenant === "carta") {
    return <CartaIcon />;
  }
  if (tenant === "ezcater") {
    return <EzcaterIcon />;
  }

  if (tenant === ("level" || "demoecom")) {
    return <LevelIcon />;
  }

  return <OpenLinkIcon width="16px" height="16px" />;
};

export default TenantLinkIcon;
