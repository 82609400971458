import { botBEAxiosInstance as botBEAxios } from "services/axios";
import {
  ActionType,
  Tool,
  type ActionParam,
  type ConnectorActionsConfig,
} from "../components/AgentActions.const";

interface ConnectorActionToolConfigInput {
  action_config_id: string;
  connection_id: string;
  input_params: ActionParam[];
  output_params: ActionParam[];
}

interface APIActionToolConfigInput {
  api_integration_id: string;
  instructions: string;
  input_params: ActionParam[];
}

export interface AddToolInput {
  agent_id: string;
  name: string;
  action_type: ActionType;
  config: ConnectorActionToolConfigInput | APIActionToolConfigInput;
}

interface Metrics {
  count: number;
  sub_count: number;
}

export interface GetAllActionTypeOutput {
  type: ActionType;
  name: string;
  description: string;
  metrics: Metrics;
  icon: string | null;
}

export const getAllActionType = async (
  botId: string
): Promise<GetAllActionTypeOutput[]> => {
  const res = await botBEAxios({
    method: "GET",
    url: `/action-types/${botId}/`,
  });
  return res.data.action_types;
};

export const getConnectorActions = async (
  id: string
): Promise<ConnectorActionsConfig[]> => {
  const res = await botBEAxios({
    method: "GET",
    url: `/actions?connector_id=${id}`,
  });
  return res?.data?.action_configs;
};

export const addTool = async (data: AddToolInput): Promise<any> => {
  return await botBEAxios({
    method: "POST",
    url: `/tools/`,
    data,
  });
};

export const updateTool = async (
  toolId: string,
  data: AddToolInput
): Promise<void> => {
  await botBEAxios({
    method: "PATCH",
    url: `/tools/${toolId}`,
    data,
  });
};

export const getTool = async (actionId: string): Promise<Tool> => {
  return await botBEAxios({
    method: "GET",
    url: `/tool/${actionId}/`,
  });
};
