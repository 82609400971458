import Button from "components/base/Button";
import { isEqual, isNil } from "lodash";
import type React from "react";
import { ReactComponent as CheckIcon } from "assets/check.svg";
import useStyles from "./style";
import { useSelector } from "store";
import { type AddToolInput } from "pages/ActionBuilder/Service/ActionBuilder.service";
import { ActionType } from "../AgentActions.const";
import { useParams } from "react-router-dom";

interface Props {
  operation: string;
  onSubmit: (body: AddToolInput) => void;
}
const FinalSubmitAddAction: React.FC<Props> = ({ operation, onSubmit }) => {
  const classes = useStyles();
  const { agentId } = useParams();

  const { actionType, selectedConfigurableAction } = useSelector(
    (state) => state.actionBuilder
  );

  const addAction = (): void => {
    // add tool to the Agent
    if (agentId) {
      let body: AddToolInput = {} as any;
      if (isEqual(actionType, ActionType.CONNECTORS)) {
        body = {
          agent_id: agentId,
          name: selectedConfigurableAction?.name,
          action_type: actionType,
          config: {
            connection_id: selectedConfigurableAction?.connectionId,
            action_config_id: selectedConfigurableAction?._id,
            input_params: selectedConfigurableAction?.input_params,
            output_params: selectedConfigurableAction?.output_params,
          },
        };
      } else {
        body = {
          agent_id: agentId,
          name:
            selectedConfigurableAction?.actionDisplayName ||
            selectedConfigurableAction?.name,
          action_type: actionType,
          config: {
            instructions: selectedConfigurableAction?.actionInstructions,
            api_integration_id: selectedConfigurableAction?._id,
            input_params: selectedConfigurableAction?.extracted_variables,
          },
        };
      }
      onSubmit(body);
    }
  };
  return (
    <>
      {!isNil(selectedConfigurableAction) && (
        <div className={classes.confirmAddContainer}>
          <div className={classes.confirmNote}>
            {operation} <strong>{selectedConfigurableAction.name}</strong>
            {" action"}
          </div>
          <Button
            color="primary"
            onClick={addAction}
            iconBtn
            startIcon={<CheckIcon />}
          >
            {`${operation} action`}
          </Button>
        </div>
      )}
    </>
  );
};

export default FinalSubmitAddAction;
