import { TextField } from "@mui/material";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import Modal from "components/base/Modal/Modal";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import type React from "react";
import { useState } from "react";

interface ConfirmationUninstallModalProps {
  onCancel: () => void;
  onConfirm: () => Promise<any>;
  name?: string;
}

const ConfirmationUninstallModal: React.FC<ConfirmationUninstallModalProps> = ({
  onCancel,
  onConfirm,
  name = "",
}) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
  };

  const handleConfirm = (): void => {
    if (isEqual(inputValue.trim().toLowerCase(), "yes")) {
      setLoading(true);
      onConfirm()
        .then(() => {
          enqueueSnackbar("Successfully uninstalled", { variant: "success" });
        })
        .catch(() => {
          enqueueSnackbar("Something went wrong", { variant: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Modal
      open
      onClose={onCancel}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      title={`Uninstall '${name}'`}
      cancelText="Go back"
      confirmText="Uninstall"
      showConfirmLoading={loading}
    >
      <Typography
        variant={TypographyVariants.textLarge}
        weight={TypographyWeights.medium}
      >
        Be careful, Uninstalling <b>{name} </b>
        may significantly affect the skills of the assistants where it is used.
        This app will no longer have access to data or be able to take actions.
      </Typography>
      <Typography
        variant={TypographyVariants.textLarge}
        weight={TypographyWeights.medium}
        style={{ marginTop: "8px", marginBottom: "16px" }}
      >
        Please type YES below to confirm that you really want to remove
        <b>{name} </b>.
      </Typography>
      <TextField
        value={inputValue}
        onChange={handleInputChange}
        style={{ marginBottom: "24px" }}
      />
    </Modal>
  );
};

export default ConfirmationUninstallModal;
