import { type GetAllActionTypeOutput } from "../Service/ActionBuilder.service";
import { type ActionTileConfig, ActionType } from "./AgentActions.const";
import { ReactComponent as PuzzleIcon } from "assets/puzzle-piece-02.svg";
import { ReactComponent as DataFlowIcon } from "assets/dataflow-04.svg";
import { ReactComponent as CodeIcon } from "assets/code-01.svg";

export const getRequiredActionTilesConfig = (
  actions: GetAllActionTypeOutput[]
): ActionTileConfig[] => {
  return actions.map((action) => {
    let actionCountLabel = "";
    let subActionLabel = "";
    let icon = null;

    // Logic to override actionCountLabel, subActionLabel, and icon based on ActionType
    switch (action.type) {
      case ActionType.CONNECTORS:
        actionCountLabel = "connectors";
        subActionLabel = "predefined actions";
        icon = <PuzzleIcon />;
        break;
      case ActionType.FLOWS:
        actionCountLabel = "custom flows ready for use";
        subActionLabel = "created recently";
        icon = <DataFlowIcon />;
        break;
      case ActionType.API:
        actionCountLabel = "APIs ready for use";
        subActionLabel = "sample APIs for testing";
        icon = <CodeIcon />;
        break;
      default:
        // Handle default case if needed
        break;
    }

    // Return the transformed ActionTileConfig object
    return {
      type: action.type,
      name: action.name,
      description: action.description,
      actionCount: action.metrics.count,
      actionCountLabel,
      subActionCount: action.metrics.sub_count,
      subActionLabel,
      icon,
    };
  });
};

export const getStepCount = (actionType: ActionType | null): string => {
  switch (actionType) {
    case ActionType.CONNECTORS:
      return "/3";
    case ActionType.API:
      return "/2";
    case ActionType.FLOWS:
      return ""; // will update this
    default:
      return "";
  }
};
