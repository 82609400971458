import { createContext, Dispatch, SetStateAction } from "react";
import { type IAssistant } from "pages/Assistant/types";

export const defaultAssistant: IAssistant = {
  _id: "",
  created: "",
  modified: "",
  name: "",
  description: "",
  enabled: false,
  deleted: false,
  general_info: {
    goal: null,
    guidelines_and_rules: null,
    company_info_text: null,
  },
  kb_metadata: {
    crawl_resources_count: 0,
    knowledge_units_count: 0,
  },
  knowledge_base: undefined,
};

export interface AssistantContextProps {
  assistantId: string;
  assistant: IAssistant;
  setAssistant: Dispatch<SetStateAction<IAssistant>>;
}

const AssistantContext = createContext<AssistantContextProps>({
  assistantId: "",
  assistant: defaultAssistant,
  setAssistant: () => {},
});
export default AssistantContext;
