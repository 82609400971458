import { DonutChartSegment } from "pages/Analytics/types";
import React from "react";
import { makeStyles } from "@mui/styles";
import Typography, { TypographyWeights } from "aether/Typography";
import { ReactComponent as InfoIcon } from "assets/info-icon.svg";
import Tooltip from "aether/Tooltip";

const useStyles = makeStyles(() => ({
  legend: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  colorBox: {
    width: "16px",
    height: "16px",
    borderRadius: "2px",
    marginRight: "12px",
  },
}));

interface Props {
  segments: DonutChartSegment[];
}

const Legends: React.FC<Props> = ({ segments }) => {
  const classes = useStyles();

  return (
    <div>
      {segments.map((item, index) => {
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        if (item.hideLegend || item?.value === 0) return null;
        return (
          <div key={index} className={classes.legend}>
            <div
              className={classes.colorBox}
              style={{
                backgroundColor: item.color,
              }}
            ></div>
            <Typography
              weight={TypographyWeights.bold}
              className="mr-1"
              renderInLines={1}
            >
              {item.value.toFixed(0)}%
            </Typography>
            <Typography weight={TypographyWeights.medium} renderInLines={1}>
              {item.name}
            </Typography>
            {item?.info && (
              <Tooltip title={item?.info} placement="right" className="ml-8">
                <InfoIcon style={{ width: 16, height: 16 }} />
              </Tooltip>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Legends;
