import { Box, IconButton } from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import { makeStyles } from "@mui/styles";
import { useCallback, useState } from "react";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
	iconButtons: {
		padding: 0,
	},
	clickedFeedback: {
		color: "#FF7D04",
	},
}));

enum FeedbackResponse {
	POSITIVE = "POSITIVE",
	NEGATIVE = "NEGATIVE",
}

const FeedbackButtons: React.FC = () => {
	const classes = useStyles();

	const [messageFeedback, setMessageFeedback] =
		useState<FeedbackResponse | null>(null);

	const handleThumbsUpClick = useCallback(() => {
		if (messageFeedback !== FeedbackResponse.POSITIVE) {
			setMessageFeedback(FeedbackResponse.POSITIVE);
		} else {
			setMessageFeedback(null);
		}
	}, [messageFeedback]);

	const handleThumbsDownClick = useCallback(() => {
		if (messageFeedback !== FeedbackResponse.NEGATIVE) {
			setMessageFeedback(FeedbackResponse.NEGATIVE);
		} else {
			setMessageFeedback(null);
		}
	}, [messageFeedback]);

	return (
		<Box mt={1} display="flex" justifyContent="flex-end">
			<IconButton className={classes.iconButtons} onClick={handleThumbsUpClick}>
				<Box
					px={0.8}
					color="#171717"
					className={clsx({
						[classes.clickedFeedback]:
							messageFeedback === FeedbackResponse.POSITIVE,
					})}
				>
					<ThumbUpOutlinedIcon
						color="inherit"
						sx={{ width: "1rem", height: "1rem" }}
					/>
				</Box>
			</IconButton>

			<IconButton
				className={classes.iconButtons}
				onClick={handleThumbsDownClick}
			>
				<Box
					px={0.8}
					color="#171717"
					className={clsx({
						[classes.clickedFeedback]:
							messageFeedback === FeedbackResponse.NEGATIVE,
					})}
				>
					<ThumbDownOutlinedIcon
						color="inherit"
						sx={{ width: "1rem", height: "1rem" }}
					/>
				</Box>
			</IconButton>
		</Box>
	);
};

export default FeedbackButtons;
