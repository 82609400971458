import TextField from "@mui/material/TextField";
import Typography from "components/base/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { ReactComponent as UploadCloudIcon } from "assets/upload-cloud-icon.svg";
import { ReactComponent as LevelLogoLight } from "assets/level-logo-light.svg";
import { ReactComponent as PersonIcon } from "assets/person-icon.svg";
import { ReactComponent as BotIcon } from "assets/bot-icon.svg";
import { type FormikProps, useFormikContext } from "formik";
import {
  AppearanceSettingsFields,
  RadioValueType,
  type IAppearanceSettings,
} from "pages/Assistant/types";
import { useCallback, useContext, useRef, useState } from "react";
// import { uploadBotLogoFile } from "api/bot/appearance";
import LinearProgress from "@mui/material/LinearProgress";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import AssistantContext from "pages/Assistant/AssistantContext";
import { useChatWidgetSettingsStyles as useStyles } from "./styles";
import {
  uploadAssistantLogoFile,
  patchAssistantChatbotSettings,
} from "api/assistant/deployment";
import { useDispatch } from "store";
import { SettingsActions } from "store/reduxActions/botActions";
import Image from "components/Image";

interface ChatWidgetInputFields {
  [AppearanceSettingsFields.header_accent_color]: string;
  [AppearanceSettingsFields.header_text_color]: string;
}

interface ChatWidgetRadioFields {
  [AppearanceSettingsFields.header_logo_enabled]: RadioValueType;
  [AppearanceSettingsFields.chat_bubble_enabled]: RadioValueType;
}

export interface ChatWidgetSettingsProps
  extends ChatWidgetInputFields,
    ChatWidgetRadioFields {
  [AppearanceSettingsFields.header_logo_url]: string;
  [AppearanceSettingsFields.chat_bubble_url]: string;
}

interface ILogoState {
  file: File | null;
  preview: any | null;
  loading: boolean;
  url: string | null;
}

const ChatWidgetSettings: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const assistantContext = useContext(AssistantContext);
  const { assistantId } = assistantContext || {};

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
  }: FormikProps<IAppearanceSettings> = useFormikContext();

  const {
    header_logo_enabled,
    chat_bubble_enabled,
    header_logo_url,
    chat_bubble_url,
    header_accent_color,
    header_text_color,
  } = values ?? {};

  const [headerLogo, setHeaderLogo] = useState<ILogoState>({
    url: header_logo_url,
    file: null,
    preview: null,
    loading: false,
  });
  const [chatBubbleLogo, setChatBubbleLogo] = useState<ILogoState>({
    url: chat_bubble_url,
    file: null,
    preview: null,
    loading: false,
  });
  const [selectChatBubbleLogo, setSelectChatBubbleLogo] = useState(
    chat_bubble_url ? "custom" : "default"
  );
  const [headerAccentColor, setHeaderAccentColor] = useState(
    header_accent_color ?? "#FF7D04"
  );
  const [headerTextColor, setHeaderTextColor] = useState(
    header_text_color ?? "#FFFFFF"
  );

  const headerLogoRef = useRef<any>();
  const chatBubbleLogoRef = useRef<any>();

  const currentChatWidgetInputFields = useRef<ChatWidgetInputFields>({
    header_accent_color: headerAccentColor,
    header_text_color: headerTextColor,
  });
  const currentChatWidgetRadioFields = useRef<ChatWidgetRadioFields>({
    header_logo_enabled,
    chat_bubble_enabled,
  });

  const handleOnBlur = useCallback(
    async (event: any, field: keyof ChatWidgetInputFields) => {
      handleBlur(event);

      if (currentChatWidgetInputFields.current[field] === values[field]) {
        return;
      }

      try {
        const response = await patchAssistantChatbotSettings(assistantId, {
          [field]: values[field],
        });
        const { data } = response;
        const { settings } = data;
        const { chatbot_settings } = settings;

        dispatch({
          type: SettingsActions.UPDATE_CHATBOT_SETTINGS,
          payload: { ...chatbot_settings },
        });

        currentChatWidgetInputFields.current[field] = values[field];
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while saving details ${error}`, {
          variant: "error",
        });
        void setFieldValue(field, currentChatWidgetInputFields.current[field]);
      }
    },
    [handleBlur, values, assistantId, dispatch, enqueueSnackbar, setFieldValue]
  );

  const handleOnRadioChange = useCallback(
    async (event: any, field: keyof ChatWidgetRadioFields) => {
      handleChange(event);

      const updatedValue = event.target.value;

      if (currentChatWidgetRadioFields.current[field] === updatedValue) {
        return;
      }

      try {
        const response = await patchAssistantChatbotSettings(assistantId, {
          [field]: updatedValue,
        });
        const { data } = response;
        const { settings } = data;
        const { chatbot_settings } = settings;

        dispatch({
          type: SettingsActions.UPDATE_CHATBOT_SETTINGS,
          payload: { ...chatbot_settings },
        });

        currentChatWidgetRadioFields.current[field] = updatedValue;
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while saving details ${error}`, {
          variant: "error",
        });
        void setFieldValue(field, currentChatWidgetRadioFields.current[field]);
      }
    },
    [assistantId, enqueueSnackbar, handleChange, setFieldValue, dispatch]
  );

  const handleHeaderLogoChange = useCallback(
    (event: any) => {
      const file = event.target.files[0];

      if (!file) return;

      const formData = new FormData();
      formData.append("header_logo_url", file);

      setHeaderLogo((state) => ({ ...state, loading: true }));
      uploadAssistantLogoFile(assistantId, formData)
        .then(async (response) => {
          const { data } = response;
          const { settings } = data;
          const { chatbot_settings } = settings;

          setHeaderLogo((state) => ({
            ...state,
            loading: false,
            url: chatbot_settings.header_logo_url,
          }));
          await setFieldValue(
            "header_logo_url",
            chatbot_settings.header_logo_url
          );
          dispatch({
            type: SettingsActions.UPDATE_CHATBOT_SETTINGS,
            payload: { ...chatbot_settings },
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setHeaderLogo((state) => ({ ...state, loading: false }));
        });
    },
    [assistantId, dispatch, setFieldValue]
  );

  const handleChatBubbleLogoChange = useCallback(
    (event: any) => {
      const file = event.target.files[0];

      if (!file) return;

      const formData = new FormData();
      formData.append("chat_bubble_url", file);

      setChatBubbleLogo((state) => ({ ...state, loading: true }));
      uploadAssistantLogoFile(assistantId, formData)
        .then((response) => {
          setChatBubbleLogo((state) => ({
            ...state,
            loading: false,
            url: response.data.settings.chatbot_settings.chat_bubble_url,
          }));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setChatBubbleLogo((state) => ({ ...state, loading: false }));
        });
    },
    [assistantId]
  );

  const handleSelectChatBubbleLogo = useCallback(
    async (type: string) => {
      setSelectChatBubbleLogo(type);

      if (type === "default") {
        // DEFAULT
        await setFieldValue("chat_bubble_url", "default");
        dispatch({
          type: SettingsActions.UPDATE_CHATBOT_SETTINGS_FIELD,
          payload: {
            key: "chat_bubble_url",
            value: "default",
          },
        });
      }

      if (type === "custom") {
        // CUSTOM
        await setFieldValue("chat_bubble_url", chatBubbleLogo.url);
        dispatch({
          type: SettingsActions.UPDATE_CHATBOT_SETTINGS_FIELD,
          payload: {
            key: "chat_bubble_url",
            value: chatBubbleLogo.url,
          },
        });
      }
    },
    [setFieldValue, dispatch, chatBubbleLogo.url]
  );

  const handleChangeAccentColor = useCallback(async (event: any) => {
    setHeaderAccentColor(event.target.value);
    void setFieldValue("header_accent_color", event.target.value);
  }, []);

  const handleChangeHeaderTextColor = useCallback(async (event: any) => {
    setHeaderTextColor(event.target.value);
    await setFieldValue("header_text_color", event.target.value);
  }, []);

  return (
    <div className={classes["chat-widget-settings-container"]}>
      <div className="settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">
            Have an avatar/logo in header
          </Typography>
          <RadioGroup
            row
            aria-labelledby="header_logo_enabled"
            name="header_logo_enabled"
            value={header_logo_enabled}
            onChange={(event) => {
              void handleOnRadioChange(
                event,
                AppearanceSettingsFields.header_logo_enabled
              );
            }}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </div>

        <div className="form-input-container">
          <Typography className="w-semi-bold">Accent Color</Typography>
          <div className="flex justify-content-start align-items-center col-gap-8">
            <div className="color-picker-wrapper">
              <input
                type="color"
                className="color-picker"
                value={headerAccentColor}
                onChange={(event) => {
                  void handleChangeAccentColor(event);
                }}
                onBlur={(event) => {
                  void handleOnBlur(
                    event,
                    AppearanceSettingsFields.header_accent_color
                  );
                }}
              />
            </div>

            <TextField
              name="header_accent_color"
              variant="outlined"
              size="small"
              placeholder=""
              fullWidth
              value={headerAccentColor}
              onChange={(event) => {
                void handleChangeAccentColor(event);
              }}
              onBlur={(event) => {
                void handleOnBlur(
                  event,
                  AppearanceSettingsFields.header_accent_color
                );
              }}
              error={
                touched.header_accent_color &&
                Boolean(errors.header_accent_color)
              }
            />
          </div>
        </div>
      </div>

      <div className="settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">
            Avatar/logo in the header
          </Typography>

          <div className="header-logo-container flex justify-content-start align-items-center col-gap-24">
            <div className="header-logo">
              <div className="logo-content">
                {headerLogo?.url ? (
                  <Image url={headerLogo.url} height="40px" width="40px" />
                ) : (
                  <LevelLogoLight />
                )}
              </div>
            </div>

            <div className="header-logo-upload">
              {headerLogo?.loading ? (
                <LinearProgress sx={{ width: "100px" }} />
              ) : (
                <>
                  <div
                    className="header-upload-button flex justify-content-start align-items-center col-gap-4 cursor-pointer"
                    onClick={() => headerLogoRef.current.click()}
                  >
                    <input
                      type="file"
                      ref={headerLogoRef}
                      style={{ display: "none" }}
                      onChange={handleHeaderLogoChange}
                    />
                    <UploadCloudIcon />
                    <Typography className="w-bold" sx={{ color: "#C9671D" }}>
                      Upload
                    </Typography>
                  </div>
                  <Typography
                    variant="textSm"
                    className="w-medium"
                    sx={{ color: "#7C7972" }}
                  >
                    Max 2 MB, 40x40 px
                  </Typography>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="form-input-container">
          <Typography className="w-semi-bold">
            Color of text in header
          </Typography>
          <div className="flex justify-content-start align-items-center col-gap-8">
            <div className="color-picker-wrapper">
              <input
                type="color"
                className="color-picker"
                value={headerTextColor}
                onChange={(event) => {
                  void handleChangeHeaderTextColor(event);
                }}
              />
            </div>

            <TextField
              name="header_text_color"
              variant="outlined"
              size="small"
              placeholder=""
              fullWidth
              value={headerTextColor}
              onChange={(event) => {
                void handleChangeHeaderTextColor(event);
              }}
              onBlur={handleBlur}
              error={
                touched.header_text_color && Boolean(errors.header_text_color)
              }
            />
          </div>
        </div>
      </div>

      <div className="settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">
            Have an avatar/logo in-front of chat bubble
          </Typography>
          <RadioGroup
            row
            aria-labelledby="chat_bubble_enabled"
            name="chat_bubble_enabled"
            value={chat_bubble_enabled}
            onChange={(event) => {
              void handleOnRadioChange(
                event,
                AppearanceSettingsFields.chat_bubble_enabled
              );
            }}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </div>
      </div>

      <div className="settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">
            Select Avatar/logo in-front of chat bubble
          </Typography>

          <div className="chat-bubble-logo-container flex justify-content-start align-items-center col-gap-16">
            <div
              className={clsx("chat-bubble-logo default-logo cursor-pointer", {
                "select-chat-bubble-logo": selectChatBubbleLogo === "default",
              })}
              onClick={() => {
                void handleSelectChatBubbleLogo("default");
              }}
            >
              <div className="logo-content">
                <BotIcon />
              </div>
            </div>
            <div
              className={clsx("chat-bubble-logo custom-logo cursor-pointer", {
                "select-chat-bubble-logo": selectChatBubbleLogo === "custom",
              })}
              onClick={() => {
                void handleSelectChatBubbleLogo("custom");
              }}
            >
              <div className="logo-content">
                {chatBubbleLogo?.url ? (
                  <Image url={chatBubbleLogo.url} height="16px" width="16px" />
                ) : (
                  <PersonIcon />
                )}
              </div>
            </div>

            <div className="chat-bubble-logo-upload">
              {chatBubbleLogo?.loading ? (
                <LinearProgress sx={{ width: "100px" }} />
              ) : (
                <>
                  <div
                    className="chat-bubble-upload-button flex justify-content-start align-items-center col-gap-4 cursor-pointer"
                    onClick={() => chatBubbleLogoRef.current.click()}
                  >
                    <input
                      type="file"
                      ref={chatBubbleLogoRef}
                      style={{ display: "none" }}
                      onChange={handleChatBubbleLogoChange}
                    />
                    <UploadCloudIcon />
                    <Typography className="w-bold" sx={{ color: "#C9671D" }}>
                      Upload
                    </Typography>
                  </div>
                  <Typography
                    variant="textSm"
                    className="w-medium"
                    sx={{ color: "#7C7972" }}
                  >
                    Max 2 MB, 40x40 px
                  </Typography>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatWidgetSettings;
