import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: "16px",
    background: "#FFF",
    boxShadow:
      "0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    padding: "24px",
    minHeight: "200px",
  },
  step: {
    color: "var(--text-subtle, #7C7972)",
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0.2px",
  },
  title: {
    color: "var(--text-default, #282624)",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    letterSpacing: "-0.2px",
    marginBottom: "24px",
  },
  tiles: { display: "flex", flexWrap: "wrap", gap: "16px" },
}));

export default useStyles;
