import { type Conversation, type Message } from "@twilio/conversations";
import { type Dispatch } from "redux";
import { ReduxChatbotActions } from "store/reduxActions/ReduxChatbotActions";

export const initMessagesListener = (
  conversation: Conversation,
  dispatch: Dispatch
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
  conversation.addListener("messageAdded", (message: Message) => {
    dispatch({
      type: ReduxChatbotActions.ACTION_ADD_MESSAGE,
      payload: { message },
    });
  });
  conversation.addListener("messageRemoved", (message: Message) => {
    dispatch({
      type: ReduxChatbotActions.ACTION_REMOVE_MESSAGE,
      payload: { message },
    });
  });
  conversation.addListener("messageUpdated", ({ message }) => {
    dispatch({
      type: ReduxChatbotActions.ACTION_UPDATE_MESSAGE,
      payload: { message },
    });
  });
};
