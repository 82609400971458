import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useAssistantsStyles = makeStyles((theme: Theme) => ({
  assistantsContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "54px",

    "& .list-container": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingBottom: "28px",
    },
  },
}));

export const useAssistantListStyles = makeStyles((theme: Theme) => ({
  assistantListContainer: {
    marginTop: "54px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    columnGap: "32px",
    rowGap: "32px",
    width: "100%",
  },
  assistantCardWrapper: {
    width: "31.5%",
    height: "180px",
  },
}));

export const useAssistantCardStyles = makeStyles((theme: Theme) => ({
  assistantCardContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "24px",
    // boxShadow: theme.boxShadows.xxxl,
    boxShadow:
      "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
    borderRadius: "8px",
    backgroundColor: "#ffff",

    "& .card-header-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    "& .card-action-container": {
      display: "flex",
      flexDirection: "column",
      rowGap: "12px",
    },
  },
}));

export const useAssistantActionMenuStyles = makeStyles((theme: Theme) => ({
  assistantActionMenuContainer: {},
  assistantActionDropdownContainer: {
    "& .list-item-container": {
      display: "flex",
      alignItems: "center",
      columnGap: "8px",

      "& svg": {
        height: "16px",
        width: "16px",
      },

      "&.delete-assistant": {
        "& svg": {
          "& path": {
            fill: "#E53811",
          },
        },

        "& .list-item-title": {
          color: "#E53811",
        },
      },
    },
  },
}));
