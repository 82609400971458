import { isEmpty, isNil } from "lodash";
import {
  ActionParam,
  VariableDataTypes,
} from "pages/ActionBuilder/components/AgentActions.const";
import { IIntegrationFormValues } from "types/APIIntegration";

// Regular expression to extract variables in the form {{variableName}}
const EXTRACT_VARIABLES_PATTERN = /\{\{(\w+)\}\}/g;

/**
 * Utility function to extract variables enclosed in {{}} from various fields in IIntegrationFormValues.
 * It scans the URL, body, headers, query parameters, and form data for variables that match the pattern {{variableName}}.
 *
 * @param fieldValues - The IIntegrationFormValues object which contains API data from various sources (URL, body, headers, etc.).
 * @returns A list of ActionParam objects representing the extracted variables. Each ActionParam contains key, description, type, etc.
 */
export const extractVariablesAndDefaultValues = (
  fieldValues: IIntegrationFormValues | null
): ActionParam[] => {
  const extractedVariables: ActionParam[] = [];

  // If fieldValues is null, return an empty list
  if (!fieldValues) return extractedVariables;

  // Helper function to extract variables from a string (matches {{variableName}})
  const extractFromString = (str: string): ActionParam[] => {
    const matches = str.match(EXTRACT_VARIABLES_PATTERN);
    return matches
      ? matches.map((match, index) => ({
          id: index,
          key: match.replace(/[{}]/g, ""), // Remove curly braces to get the variable name
          description: "", // Default description, can be dynamically set later
          data_type: VariableDataTypes.STRING, // Default data type (can be modified)
          example: "", // Example can be added dynamically if needed
          required: true, // Default value, can be adjusted based on business logic
        }))
      : [];
  };

  // Extract variables from the URL field
  if (fieldValues.url) {
    extractedVariables.push(...extractFromString(fieldValues.url));
  }

  // Extract variables from query parameters (if available)
  if (fieldValues.params && Array.isArray(fieldValues.params)) {
    fieldValues.params.forEach((param) => {
      if (param.value) {
        extractedVariables.push(...extractFromString(param.value));
      }
    });
  }

  // Extract variables from headers (if available)
  if (fieldValues.headers && Array.isArray(fieldValues.headers)) {
    fieldValues.headers.forEach((header) => {
      if (header.value) {
        extractedVariables.push(...extractFromString(header.value));
      }
    });
  }

  // Extract variables from the JSON body, if it doesn't exist then check for form_data
  if (fieldValues.body?.json) {
    extractedVariables.push(...extractFromString(fieldValues.body.json));
  } else if (fieldValues.body?.form_data) {
    fieldValues.body.form_data.forEach((formParam) => {
      if (formParam.value) {
        extractedVariables.push(...extractFromString(formParam.value));
      }
    });
  }

  return extractedVariables;
};

/**
 * Updates the extracted variables by matching them with the values in the provided map.
 * If a variable exists in the map, it is updated with the latest value from the map.
 *
 * @param extractedVariablesWithKey - A list of ActionParam objects, each containing an extracted variable's key.
 * @param extractedVariablesValueMap - A Map that holds the latest values for variables (keyed by variable name).
 * @returns A new list of ActionParam objects, with updated values from the map.
 */
const extractVariablesWithUpdatedValues = (
  extractedVariablesWithKey: ActionParam[] | null,
  extractedVariablesValueMap: Map<string, ActionParam>
): ActionParam[] => {
  if (isEmpty(extractedVariablesWithKey) || isNil(extractedVariablesWithKey)) {
    return [];
  }

  return extractedVariablesWithKey.map((variable) => {
    // If a matching variable exists in the map, return the updated variable; otherwise, keep the original
    return extractedVariablesValueMap.get(variable.key) ?? variable;
  });
};

/**
 * Updates the extracted variables in the provided map with the latest values from the extracted variables list.
 * This function ensures that the map holds the most up-to-date values for all variables.
 *
 * @param extractedVariablesWithKey - A list of ActionParam objects containing extracted variables.
 * @param extractedVariablesValueMap - A Map where variables' latest values are stored.
 */
export const updateExtractedVariablesInMap = (
  extractedVariablesWithKey: ActionParam[] | null,
  extractedVariablesValueMap: Map<string, ActionParam>
): void => {
  if (isEmpty(extractedVariablesWithKey) || isNil(extractedVariablesWithKey)) {
    return;
  }

  // For each extracted variable, update its value in the map
  extractedVariablesWithKey.forEach((variable) => {
    extractedVariablesValueMap.set(variable.key, variable);
  });
};

/**
 * Main function to extract and update variables from the IIntegrationFormValues object.
 * It first updates the variable values in the map and then extracts all variables and their corresponding values.
 *
 * @param fieldValues - The IIntegrationFormValues containing the data to be processed.
 * @param extractedVariablesValueMap - The map containing the current values of extracted variables.
 * @returns A list of updated ActionParam objects containing variables along with their default and updated values.
 */
export const getExtractedVariables = (
  fieldValues: IIntegrationFormValues,
  extractedVariablesValueMap: Map<string, ActionParam>
): ActionParam[] => {
  // Update the variables in the map based on the extracted ones
  updateExtractedVariablesInMap(
    fieldValues.extracted_variables,
    extractedVariablesValueMap
  );

  // Extract the default variables from the fieldValues
  const extractedVariablesAndDefaultValues =
    extractVariablesAndDefaultValues(fieldValues);

  // Return the updated variables, combining both default values and updated ones from the map
  return extractVariablesWithUpdatedValues(
    extractedVariablesAndDefaultValues,
    extractedVariablesValueMap
  );
};
