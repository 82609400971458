export const ActionBuilderActions = {
  // Setting Action Type
  SET_ACTION_TYPE: "SET_ACTION_TYPE",

  // Setting and Clearing Action to Preview
  SET_PREVIEW_ACTION: "SET_PREVIEW_ACTION",
  CLEAR_PREVIEW_ACTION: "CLEAR_PREVIEW_ACTION",

  // Setting and Clearing Action to Configure
  SET_SELECTED_CONFIGURABLE_ACTION: "SET_SELECTED_CONFIGURABLE_ACTION",
  CLEAR_SELECTED_CONFIGURABLE_ACTION: "CLEAR_SELECTED_CONFIGURABLE_ACTION",

  // Setting and Clearing Param to Configure
  SET_SELECTED_CONFIGURABLE_PARAM: "SET_SELECTED_CONFIGURABLE_PARAM",
  CLEAR_SELECTED_CONFIGURABLE_PARAM: "CLEAR_SELECTED_CONFIGURABLE_PARAM",

  // clearing the slice except Action type
  CLEAR_ALL_ACTIONS_AND_PARAMS: "CLEAR_ALL_ACTIOINS_AND_PARAMS",

  // clearing the slice
  CLEAR_SLICE: "CLEAR_SLICE",
};
