import AccordionCard from "components/base/AccordionCard";
import { ReactComponent as AssistantDetailsIcon } from "assets/details-icon.svg";
import AssistantDetails from "./AssistantDetails";
import { useAssistantDetailsStyles as useStyles } from "./styles";

const details_title = "Details";
const details_subtitle =
  "Share key details about your agent's goal and company to align it with your brand identity";

const Details: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.assistantDetailsContainer}>
      <AccordionCard
        defaultExpanded={true}
        title={details_title}
        subtitle={details_subtitle}
        icon={<AssistantDetailsIcon />}
        content={<AssistantDetails />}
      />
    </div>
  );
};

export default Details;
