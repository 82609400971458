import { TabNavigationCategory } from "../types";

export const DeploymentNavigations: TabNavigationCategory[] = [
  // {
  //   key: "channels",
  //   category: "",
  //   title: "",
  //   paths: [
  //     {
  //       key: "channels",
  //       path: "channels",
  //       title: "Channels",
  //       enabled: true,
  //     },
  //     {
  //       key: "voice-settings",
  //       path: "",
  //       title: "Voice Settings",
  //       enabled: true,
  //     },
  //   ],
  // },
  {
    key: "settings",
    category: "settings",
    title: "Settings",
    paths: [
      {
        key: "appearance",
        path: "appearance",
        title: "Appearance",
        enabled: true,
      },
      {
        key: "embed-chatbot",
        path: "embed-chatbot",
        title: "Embed Chatbot",
        enabled: true,
      },
    ],
  },
];
