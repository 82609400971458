import store from "store";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { RouterProvider } from "react-router-dom";
import appRoutes from "routes";
import "flag-icons/css/flag-icons.min.css";
import Auth from "Auth";
import LDProvider from "LDProvider";
import ThemeProvider from "theme";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Auth>
          <LDProvider>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              autoHideDuration={2000}
            >
              <RouterProvider router={appRoutes} />
            </SnackbarProvider>
          </LDProvider>
        </Auth>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
