import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { getAgentData } from "api/bot/bot-agents";
import clsx from "clsx";
import AgentBuilderContext, { defaultAgent } from "./AgentBuilderContext";
import { isEqual } from "lodash";
import ChatBotContainer from "pages/Bot/ChatBotContainer";
import { getAssistant } from "api/assistant/assistant";
import { useDispatch } from "store";
import { BotActions, SettingsActions } from "store/reduxActions/botActions";
import { AssistantType } from "pages/Assistant/types";
import VoiceBotContainer from "pages/Bot/VoiceBotContainer/VoiceBotContainer";
import { Agent } from "./types";
import AgentBuilder from "./AgentBuilder";
import useStyles from "./styles";

const AgentBuilderContainer: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const { assistantId, agentId } = params || {};

  const [loading, setLoading] = useState(false);
  // Both these states are used to handle saving info on header
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);
  const [isEditingInProgress, setIsEditingInProgress] = useState(false);
  const [botData, setBotData] = useState<any>(null);
  const [agentData, setAgentData] = useState<Agent>(defaultAgent);

  useEffect(() => {
    if (!assistantId || !agentId) return;

    // TODO: Move this logic into Assistant, as we logically need to get the assistant data first
    setLoading(true);
    Promise.all([getAssistant(assistantId), getAgentData(agentId)])
      .then((results) => {
        const [_botData, _agentData] = results;

        dispatch({
          type: SettingsActions.FETCH_BOT_SETTINGS_SUCCESS,
          payload: _botData.data.assistant.settings,
        });
        dispatch({
          type: BotActions.SET_BOT_META,
          payload: _botData.data.assistant,
        });
        // dispatch({
        //   type: ReduxChatbotActions.ACTION_SET_BOT_TYPE,
        //   payload: { botName: _botData.data.assistant.name },
        // });

        setBotData(_botData.data.assistant);
        setAgentData(_agentData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (!agentId || !assistantId || loading) {
    return (
      <div className={clsx(classes.agentBuilderContainer, "center")}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <AgentBuilderContext.Provider
      value={{
        assistantId,
        agentId,
        bot: botData,
        agent: agentData,
        setAgent: setAgentData,
        isSavingInProgress,
        setIsSavingInProgress,
        isEditingInProgress,
        setIsEditingInProgress,
      }}
    >
      <AgentBuilder />
      {/* Below code should be removed and should have only one place to trigget the bot widget */}
      <div className={classes.chatbotWidgetContainer}>
        {isEqual(
          botData?.settings?.channel_settings?.enabled_channels?.[0],
          AssistantType.Voice
        ) ? (
          <VoiceBotContainer
            phoneNumber={
              botData?.settings?.voice_settings?.associated_phone_number
            }
          />
        ) : (
          <ChatBotContainer
            botConfig={{ minimizeIcon: true, isChatOpen: false }}
            botData={botData}
            isPlatformBot={true} // this will be true for Platform Chatbot
          />
        )}
      </div>
    </AgentBuilderContext.Provider>
  );
};

export default AgentBuilderContainer;
