import Typography from "components/base/Typography";
import { FormControlLabel, TextField, type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FieldArray, useFormikContext } from "formik";
import { Link } from "react-router-dom";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { type IIntegrationFormValues } from "types/APIIntegration";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

const useStyles = makeStyles((theme: Theme) => ({
  "tab-body-container": {
    marginTop: "24px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",

    "& .key-container, & .key-input-container": {
      width: "184px",
    },
    "& .value-container, & .value-input-container": {
      width: "184px",
    },
    "& .description-container, & .description-input-container": {
      width: "460px",
    },

    "& .add-param-btn": {
      "& .btn-text": {
        color: "#C9671D",
      },
      "& svg": {
        marginRight: "4px",

        "& path": {
          fill: "#C9671D",
        },
      },

      "&:hover": {
        "& .btn-text": {
          color: "#FF7B00",
        },
        "& svg": {
          "& path": {
            fill: "#FF7B00",
          },
        },
      },
    },
  },
}));

interface Props {
  disableAllFields?: boolean;
}

const TabBody: React.FC<Props> = ({ disableAllFields = false }) => {
  const classes = useStyles();

  const { values, handleBlur, handleChange } =
    useFormikContext<IIntegrationFormValues>();

  return (
    <div className={classes["tab-body-container"]}>
      <div className="body-method-type flex flex-col col-gap-12 mb-8">
        <RadioGroup
          row
          className="px-1"
          aria-labelledby="body-method"
          name={`body.body_method`}
          onChange={handleChange}
          value={values?.body?.body_method}
        >
          <FormControlLabel
            value={"form_data"}
            control={<Radio />}
            label="Form Data"
          />
          <FormControlLabel value={"JSON"} control={<Radio />} label="JSON" />
        </RadioGroup>
      </div>

      <div className="body-method-container flex flex-col align-items-stretch">
        {values?.body?.body_method === "form_data" && (
          <div className="form-data-container flex flex-col justify-content-start align-items-stretch">
            <div className="headers-header flex col-gap-24 mb-8">
              <div className="t-head key-container">
                <Typography className="w-semi-bold">Key</Typography>
              </div>
              <div className="t-head value-container">
                <Typography className="w-semi-bold">Value</Typography>
              </div>
              <div className="t-head description-container">
                <Typography className="w-semi-bold">Description</Typography>
              </div>
            </div>

            <div className="headers-input-container">
              <FieldArray name={`body.form_data`}>
                {({ insert, remove, push }) => (
                  <div className="flex flex-col row-gap-16">
                    {values?.body?.form_data &&
                      values.body.form_data.length > 0 &&
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      values.body.form_data.map((data, index) => (
                        <div key={index} className="t-row flex col-gap-24">
                          <div className="key-input-container">
                            <TextField
                              name={`body.form_data.${index}.key`}
                              variant="outlined"
                              size="small"
                              placeholder=""
                              fullWidth
                              value={data.key}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={disableAllFields}
                            />
                          </div>

                          <div className="value-input-container">
                            <TextField
                              name={`body.form_data.${index}.value`}
                              variant="outlined"
                              size="small"
                              placeholder=""
                              fullWidth
                              value={data.value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={disableAllFields}
                            />
                          </div>

                          <div className="description-input-container">
                            <TextField
                              name={`body.form_data.${index}.description`}
                              variant="outlined"
                              size="small"
                              placeholder=""
                              fullWidth
                              value={data.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={disableAllFields}
                            />
                          </div>
                        </div>
                      ))}

                    {!disableAllFields && (
                      <Link
                        className="add-param-btn no-decoration flex align-items-center"
                        to={""}
                        onClick={() => {
                          push({
                            key: "",
                            value: "",
                            description: "",
                          });
                        }}
                      >
                        <PlusIcon />
                        <Typography
                          variant="textSm"
                          className="btn-text w-semi-bold"
                        >
                          Key value pair
                        </Typography>
                      </Link>
                    )}
                  </div>
                )}
              </FieldArray>
            </div>
          </div>
        )}

        {values?.body?.body_method === "JSON" && (
          <div className="auth-token-container flex flex-col justify-content-start align-items-stretch row-gap-24">
            <div className="form-input-container flex flex-col row-gap-6">
              <TextField
                name={`body.json`}
                variant="outlined"
                size="small"
                multiline
                rows={8}
                fullWidth
                value={values?.body?.json}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disableAllFields}
                //   error={touched.node_name && Boolean(errors.node_name)}
                //   helperText={touched.node_name && errors.node_name}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TabBody;
