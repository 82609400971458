import { useCallback, useState } from "react";
import clsx from "clsx";
import { IconButton, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { ReactComponent as CloseIcon } from "assets/x-close.svg";
import { ReactComponent as ChatbotGradientIcon } from "assets/icons/chatbot-gradient-icon.svg";
import { ReactComponent as VoicebotGradientIcon } from "assets/icons/voicebot-gradient-icon.svg";
import { ReactComponent as ArrowRight } from "assets/arrow-right.svg";
import { useSelectBotModalStyles as useStyles } from "./style";
import Button, { ButtonSizes } from "aether/Button";
import { AssistantType } from "pages/Assistant/types";

const modal_title = "Select what kind of agent you would like to build";
const chatbot_text = {
  title: "Chat Agent",
  subtitle:
    "Build an AI-powered agent to manage complex workflows, automate tasks, and handle advanced use cases with copilot support.",
};
const voicebot_text = {
  title: "Voice Agent",
  subtitle:
    "Create a simple AI-powered voice agent using natural language to automate calls and manage straightforward tasks.",
};
const name_input = {
  label: "Name",
  [AssistantType.Chat]: "E.g. Travel operations chat agent",
  [AssistantType.Voice]: "E.g. Travel operations voice agent",
};
const description_input = {
  label: "Description",
  [AssistantType.Chat]: "Eg. Helps customer know flight details",
  [AssistantType.Voice]: "Eg. Helps customer know flight details",
};

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    fields: {
      name: string;
      description: string;
    },
    type: AssistantType
  ) => Promise<void>;
  loading?: boolean;
  initialTitle?: string;
  initialAssistantType?: AssistantType;
  hideTypeChange?: boolean; // when this is enabled we show only one assistantType and i.e initialAssistantType which can't be changed via UI
}

const SelectAssistantModal: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  loading = false,
  initialTitle = "",
  initialAssistantType = AssistantType.Chat,
  hideTypeChange = false,
}) => {
  const classes = useStyles();
  const [assistantType, setAssistantType] =
    useState<AssistantType>(initialAssistantType);
  const [botFields, setBotFields] = useState({
    name: initialTitle,
    description: "",
  });

  const handleSelectBotType = (type: AssistantType): void => {
    setAssistantType(type);
  };

  const resetBotFields = useCallback(() => {
    setBotFields({ name: "", description: "" });
  }, []);

  return (
    <Dialog
      className={classes.createAssistantModal}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <div className={classes.createAssistantContent}>
        <div className="flex justify-content-between align-items-center">
          <Typography
            variant={TypographyVariants.textXL}
            weight={TypographyWeights.semiBold}
          >
            {modal_title}
          </Typography>
          <IconButton className="close-create-bot-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="select-bot-type-container flex col-gap-24">
          {/* Render based on the hideTypeChange and initialAssistantType */}
          {(hideTypeChange
            ? [initialAssistantType]
            : [AssistantType.Chat, AssistantType.Voice]
          ).map((type) => (
            <div
              key={type}
              className={clsx("select-container", {
                selected: assistantType === type,
              })}
              onClick={() => {
                handleSelectBotType(type);
              }}
            >
              <div className={"bot-icon-container"}>
                {type === AssistantType.Chat ? (
                  <ChatbotGradientIcon />
                ) : (
                  <VoicebotGradientIcon />
                )}
              </div>
              <Typography
                variant={TypographyVariants.textLarge}
                weight={TypographyWeights.bold}
              >
                {type === AssistantType.Chat
                  ? chatbot_text.title
                  : voicebot_text.title}
              </Typography>
              <Typography
                variant={TypographyVariants.textSmall}
                weight={TypographyWeights.medium}
                className="text-center"
              >
                {type === AssistantType.Chat
                  ? chatbot_text.subtitle
                  : voicebot_text.subtitle}
              </Typography>
            </div>
          ))}
        </div>

        {assistantType && (
          <div className="flex flex-col row-gap-16">
            <div className="flex flex-col row-gap-4">
              <Typography weight={TypographyWeights.semiBold}>
                {name_input.label}
              </Typography>
              <TextField
                name="botName"
                value={botFields.name}
                variant="outlined"
                size="small"
                placeholder={name_input[assistantType]}
                fullWidth
                onChange={(event) => {
                  setBotFields((state) => ({
                    ...state,
                    name: event.target.value,
                  }));
                }}
              />
            </div>

            <div className="flex flex-col row-gap-4">
              <Typography weight={TypographyWeights.semiBold}>
                {description_input.label}
              </Typography>
              <TextField
                name="botDescription"
                value={botFields.description}
                variant="outlined"
                size="small"
                placeholder={description_input[assistantType]}
                fullWidth
                onChange={(event) => {
                  setBotFields((state) => ({
                    ...state,
                    description: event.target.value,
                  }));
                }}
              />
            </div>

            <Button
              size={ButtonSizes.small}
              className="align-self-start"
              endIcon={<ArrowRight />}
              loading={loading}
              disabled={!botFields.name}
              onClick={() => {
                void onSubmit(botFields, assistantType);
                resetBotFields();
              }}
            >
              Proceed
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default SelectAssistantModal;
