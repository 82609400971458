import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  paramHead: {
    display: "flex",
    marginTop: "24px",
    marginBottom: "16px",
  },
  paramHeadTitle: {
    color: "var(--text-default, #282624)",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "28px",
    marginLeft: "8px",
  },
  paramCount: {
    display: "flex",
    padding: "0px 12px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "12px",
    background: "var(--inactive-surfaces-disabled, #F3F2F0)",
    marginLeft: "8px",

    color: "var(--text-default, #282624)",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    alignSelf: "stretch",
    margin: "4px 0px",
  },
  tiles: {
    gap: "16px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tile: {
    cursor: "pointer",
    width: "49%",
  },
}));

export default useStyles;
