import {
  // type ICarousel,
  // type IFieldMap,
  // type IEdge,
  // type INode,
  type INodePosition,
} from "types/Workflow";
import {
  NodeGroupType,
  // ContextVariableConfig,
  // ContextVariable,
  EdgeType,
  BotPromptNodeType,
  FieldType,
  ResponseNodeType,
  // ServiceNodeType,
  ServiceNodeConfig,
  ResponseNodeConfig,
  BotPromptNodeConfig,
  LogicNodeType,
  CreateNodeType,
  // TriggerNodeType,
  LogicNodeConfig,
  OPERATOR_TYPE,
  TriggerNodeConfig,
  TriggerNodeType,
  // TriggerNodeConfig,
  // CreateNodeConfig,
} from "../config";
import {
  // getIncomers,
  type Edge,
  type Node,
  getOutgoers,
  // getConnectedEdges,
} from "reactflow";
import {
  getVariableFromSessionContext,
  getVariablePath,
} from "./session-context";
// import { type ISessionContext } from "types/PreviewSession";
import {
  type ISessionContext,
  type IEdge,
  type INode,
  type IContextVariableState,
} from "../types";
import { cloneDeep, omit } from "lodash";

export const uuid = (): string =>
  new Date().getTime().toString(36) + Math.random().toString(36).slice(2);

export const createNode = ({
  pointer,
  type,
  group,
  position,
}: {
  pointer: number;
  type: string;
  group: string;
  position: { x: number; y: number };
}): Omit<INode, "id"> => {
  const counter = pointer + 1;

  if (type === ResponseNodeType.TEXT) {
    return {
      type,
      position,
      width: 260,
      height: 70,
      dragHandle: ".node-drag-container",
      data: {
        name: `${type} node`,
        group,
        message: "",
        description: "",
        ui: {
          counter,
        },
      },
    };
  }

  if (group === NodeGroupType.SERVICE_NODE) {
    return {
      type,
      position,
      width: 260,
      height: 70,
      dragHandle: ".node-drag-container",
      data: {
        name: `${type} node`,
        group,
        ui: {
          counter,
        },
      },
    };
  }

  if (type === BotPromptNodeType.CAROUSEL) {
    return {
      type,
      position: { x: position.x, y: position.y - 50 },
      width: 260,
      height: 150,
      dragHandle: ".node-drag-container",
      data: {
        name: `Carousel node`,
        group,
        fields_map: [
          {
            key: "id",
            label: "Id",
            value: "",
            description: "",
            field_type: FieldType.TEMPLATE_SELECT,
            required: true,
          },
          {
            key: "image",
            label: "Image",
            value: "",
            description: "",
            field_type: FieldType.TEMPLATE_SELECT,
            required: false,
          },
          {
            key: "tag",
            label: "Tag",
            value: "",
            description: "",
            field_type: FieldType.TEMPLATE_SELECT,
            required: false,
          },
          {
            key: "subheading",
            label: "Sub-heading",
            value: "",
            description: "",
            field_type: FieldType.TEMPLATE_TEXTFIELD,
          },
          {
            key: "heading",
            label: "Heading",
            value: "",
            description: "",
            field_type: FieldType.TEMPLATE_TEXTFIELD,
            required: true,
          },
          {
            key: "body",
            label: "Body",
            value: "",
            description: "",
            field_type: FieldType.TEMPLATE_TEXTAREA,
            required: true,
          },
          {
            key: "button_1",
            label: "Button 1",
            value: "Button 1",
            description: "",
            field_type: FieldType.TEXT,
            required: true,
          },
          {
            key: "button_2",
            label: "Button 2",
            value: "Button 2",
            description: "",
            field_type: FieldType.TEXT,
            required: false,
          },
        ],
        ui: {
          counter,
        },
      },
    };
  }

  if (type === BotPromptNodeType.TEXT) {
    return {
      type,
      position,
      width: 260,
      height: 70,
      dragHandle: ".node-drag-container",
      data: {
        name: `Collect Data`,
        group,
        questions: [{ key: "q1", question: "", variables: [] }],
        ui: {
          counter,
        },
      },
    };
  }

  if (type === LogicNodeType.BRANCH) {
    return {
      type,
      position,
      width: 260,
      height: 150,
      dragHandle: ".node-drag-container",
      data: {
        name: `Branch Node`,
        description: "",
        group,
        ui: {
          counter,
        },
      },
    };
  }

  return {
    type,
    position,
    width: 260,
    height: 70,
    dragHandle: ".node-drag-container",
    data: {
      name: `${type} Node`,
      group,
      ui: {
        counter,
      },
    },
  };
};

export const createHorizontalNode = ({
  pointer,
  type,
  group,
  position,
  branchIndex,
  alignment = "LTR",
  isRootNode = false,
  rootNodeParent,
}: {
  pointer: number;
  type: string;
  group: string;
  position: { x: number; y: number };
  branchIndex: number;
  alignment?: string;
  isRootNode?: boolean;
  rootNodeParent?: Node | null;
}): any => {
  const newNode = createNode({ pointer, type, group, position });

  let positionX = position.x;
  let positionY = position.y;

  if (alignment === "LTR") {
    positionX += 300;
    positionY = getBranchNodePositionY({
      positionY,
      branchIndex,
      isRootNode,
      rootNodeParent,
    });
  }

  return {
    ...newNode,
    position: {
      x: positionX,
      y: positionY,
    },
  };
};

export const getBranchNodePositionY = ({
  positionY,
  branchIndex,
  isRootNode,
  rootNodeParent,
}: {
  positionY: number;
  branchIndex: number;
  isRootNode?: boolean;
  rootNodeParent?: Node | null;
}): number => {
  // Creating branch from root logical node
  if (isRootNode && rootNodeParent) {
    const parentNodePosition = rootNodeParent.position;

    const firstBranchChildPosition = parentNodePosition.y - 200;
    if (branchIndex === 0) {
      return firstBranchChildPosition;
    }

    return firstBranchChildPosition + branchIndex * 180;
  }

  // Creating branch node tree
  return positionY;
};

export const createPlaceholderNode = ({
  tNode,
  position,
  nodeId,
}: {
  tNode?: INode;
  nodeId?: string;
  position?: INodePosition;
}): INode => {
  let _position = {
    x: 0,
    y: 0,
  };

  if (tNode) {
    _position = {
      x: tNode.position.x + 320,
      y: tNode.position.y + 19.5,
    };
  }

  if (position) {
    _position = {
      x: position.x,
      y: position.y,
    };
  }

  const placeholderNode = {
    id: nodeId ?? uuid(),
    type: CreateNodeType.PLACEHOLDER_NODE,
    position: _position,
    width: 80,
    height: 31,
    data: {
      label: "Add",
      group: NodeGroupType.CREATE_NODE,
    },
  };

  return placeholderNode;
};

export const createPlaceholderEdge = ({
  sourceId,
  targetId,
  sourceHandle,
  targetHandle,
}: {
  sourceId: string;
  targetId: string;
  sourceHandle?: string;
  targetHandle?: string;
}): IEdge => {
  return {
    id: `${sourceId}=>${targetId}`,
    source: sourceId,
    target: targetId,
    sourceHandle: sourceHandle ?? `handle-source-${sourceId}-1`,
    targetHandle: targetHandle ?? `handle-target-${targetId}-3`,
    type: EdgeType.PLACEHOLDER_EDGE,
  };
};

export const createBranchAddNodeAndEdge = ({
  nodeId,
  branchIndex,
  branchId,
  position,
  absolutePosition,
}: {
  nodeId: string;
  branchIndex: number;
  branchId: string | number;
  position?: { x: number; y: number };
  absolutePosition?: { x: number; y: number };
}): any => {
  let targetPosition = {
    x: 0,
    y: 0,
  };

  if (position) {
    targetPosition = {
      x: position?.x + 320,
      y: position?.y + 66 + branchIndex * 44,
    };
  } else if (absolutePosition) {
    targetPosition = {
      x: absolutePosition.x,
      y: absolutePosition.y,
    };
  }

  const addNode = {
    id: uuid(),
    type: CreateNodeType.BRANCH_ADD_NODE,
    position: {
      x: targetPosition.x,
      y: targetPosition.y,
    },
    width: 80,
    height: 31,
    data: {
      label: `Add`,
      group: NodeGroupType.CREATE_NODE,
      branchIndex,
      alignment: "LTR",
      isRootNode: true,
      rootNodeParentId: nodeId,
    },
  };

  const addNodeEdge = {
    id: `${nodeId}=>${addNode.id}`,
    source: nodeId,
    target: addNode.id,
    sourceHandle: `handle-source-${nodeId}-${branchId}`,
    targetHandle: `handle-target-${addNode.id}-3`,
    type: EdgeType.PLACEHOLDER_EDGE,
  };

  return { addNode, addNodeEdge };
};

export const createNewWorkflowPlaceholder = (): any => {
  const startNode = {
    id: uuid(),
    type: NodeGroupType.TRIGGER_NODE,
    position: { x: 0, y: 0 },
    width: 260,
    height: 70,
    data: {
      group: NodeGroupType.TRIGGER_NODE,
      name: "Starter Node",
      dialog_prompt: "",
      ui: {
        counter: 1,
      },
    },
  };

  const placeholderNode = createPlaceholderNode({ tNode: startNode });
  const placeholderEdge = createPlaceholderEdge({
    sourceId: startNode.id,
    targetId: placeholderNode.id,
  });

  const defaultNodes: INode[] = [startNode, placeholderNode];
  const defaultEdges: Edge[] = [placeholderEdge];

  return {
    nodes: defaultNodes,
    edges: defaultEdges,
  };
};

export const updateNodeById = ({
  id,
  nodes,
  config,
  root,
}: {
  id: string;
  nodes: INode[];
  config: any;
  root?: any;
}): INode[] => {
  const updatedNodes = nodes.map((node: INode) => {
    if (node.id === id) {
      let updatedNode = {
        ...node,
        data: {
          ...node.data,
          ...config,
        },
      };

      if (root) {
        updatedNode = { ...updatedNode, ...root };
      }

      return updatedNode;
    }

    return node;
  });

  return updatedNodes;
};

export const updateNodePosition = ({
  id,
  nodes,
  position,
}: {
  id: string;
  nodes: INode[];
  position: { x: number; y: number };
}): any => {
  const updatedNodes = nodes.map((node: INode) => {
    if (node.id === id) {
      return { ...node, position };
    }
    return node;
  });

  return updatedNodes;
};

export const getActionMapNode = (
  edges: IEdge[],
  nodeId: string,
  handle: string
): any => {
  const selectedNodeEdges = edges.filter((ed) => ed.source === nodeId);

  if (handle.includes("0")) {
    const handleEdge = selectedNodeEdges.filter((ed) => !ed.sourceHandle);
    return handleEdge?.[0]?.target;
  }

  const handleEdge = selectedNodeEdges.filter(
    (ed) => ed.sourceHandle === handle
  );
  return handleEdge?.[0]?.target;
};

export const isTerminalNode = (node: INode, edges: IEdge[]): boolean => {
  const selectedNodeEdges = edges.filter((ed) => ed.source === node.id);

  return selectedNodeEdges.every((ed) => ed.type === EdgeType.PLACEHOLDER_EDGE);
};

// export const deleteNode = ({
//   node,
//   nodes,
//   edges,
// }: {
//   node: INode;
//   nodes: INode[];
//   edges: IEdge[];
// }): { nodes: INode[]; edges: IEdge[] } => {
//   let updatedNodes = nodes.filter((n) => n.id !== node.id);

//   let updatedEdges = [...edges];

//   if (
//     node.type === BotPromptNodeType.CAROUSEL &&
//     node.data?.actions_map?.button_2
//   ) {
//     // TODO: REMOVE BRANCHED WORKFLOW FROM BUTTON_2 IF ITS AVAILABLE
//     const branchNode = node.data?.actions_map?.button_2;
//     updatedEdges = updatedEdges.filter(
//       (edge: IEdge) => !(edge.source === node.id && edge.target === branchNode)
//     );
//     updatedNodes = updatedNodes.filter((n: INode) => n.id !== branchNode);
//   }

//   updatedEdges = [node].reduce((acc: any, node: any) => {
//     const connectedEdges = getConnectedEdges([node], edges);
//     const incomers = getIncomers(node, nodes, edges);
//     const outgoers = getOutgoers(node, nodes, edges);

//     const remainingEdges = acc.filter(
//       (edge: any) => !connectedEdges.includes(edge)
//     );

//     const createdEdges = incomers.flatMap(({ id: source }) =>
//       outgoers.map(({ id: target }) => ({
//         id: `${source}->${target}`,
//         source,
//         target,
//         sourceHandle: `handle-source-${source}-2`,
//       }))
//     );

//     return [...remainingEdges, ...createdEdges];
//   }, edges);

//   return { nodes: updatedNodes, edges: updatedEdges };
// };

export const checkConnectionValidity = ({
  connection,
  nodes,
  edges,
}: {
  connection: any;
  nodes: INode[];
  edges: IEdge[];
}): boolean => {
  console.log(connection);

  const { source, sourceHandle, target, targetHandle } = connection;
  const targetNode = nodes.find((node: INode) => node.id === target);

  if (targetNode?.type === TriggerNodeType.TRIGGER) {
    return false;
  }

  if (source === target || sourceHandle === targetHandle) {
    return false;
  }

  if (targetHandle.includes("source") || targetHandle.includes(source)) {
    return false;
  }

  return true;
};

export const isHandleConnected = ({
  type,
  nodeId,
  handleId,
  edges,
}: {
  type: "source" | "target";
  nodeId: string;
  handleId: string;
  edges: IEdge[];
}): boolean => {
  if (type === "source") {
    return edges.some(
      (edge: IEdge) => edge.source === nodeId && edge.sourceHandle === handleId
    );
  }

  return edges.some(
    (edge: IEdge) => edge.source === nodeId && edge.targetHandle === handleId
  );
};

export const getNodeConnectedEdges = (node: INode, edges: IEdge[]): IEdge[] => {
  if (!node || !edges?.length) return [];

  return edges.filter(
    (edge) => edge.source === node.id || edge.target === node.id
  );
};

export const deleteNode = ({
  node,
  nodes,
  edges,
}: {
  node: INode;
  nodes: INode[];
  edges: IEdge[];
}): { nodes: INode[]; edges: IEdge[] } => {
  const outgoers = getOutgoers(node, nodes, edges);
  const addNodes = outgoers.filter(
    (node: any) =>
      node.type === CreateNodeType.PLACEHOLDER_NODE ||
      node.type === CreateNodeType.BRANCH_ADD_NODE
  );
  const updatedNodes = nodes
    .filter((n) => n.id !== node.id)
    .filter((n) => !addNodes.some((_n) => _n.id === n.id));

  const updatedEdges = edges.filter(
    (edge) => edge.source !== node.id && edge.target !== node.id
  );

  return { nodes: updatedNodes, edges: updatedEdges };
};

export const deleteNodeContext = ({
  nodeId,
  contextState,
  sessionContext,
}: {
  nodeId: string;
  contextState: IContextVariableState;
  sessionContext: ISessionContext;
}): ISessionContext => {
  const _sessionContext = cloneDeep(sessionContext);
  const updatedSessionContextState = omit(_sessionContext[contextState], [
    nodeId,
  ]);

  return {
    ...sessionContext,
    [contextState]: updatedSessionContextState,
  };
};

export const deleteBranchNode = ({
  node,
  nodes,
  edges,
}: {
  node: INode;
  nodes: INode[];
  edges: IEdge[];
}): any => {
  const updatedNodes = nodes.filter((n) => n.id !== node.id);
  let updatedEdges = [...edges];

  updatedEdges = updatedEdges.filter(
    (edge: IEdge) => !(edge.source === node.id || edge.target === node.id)
  );

  return { nodes: updatedNodes, edges: updatedEdges };
};

export const getNodeIconAndColor = ({
  group,
  type,
}: {
  group: string;
  type: string;
}): { ICON: any | null; COLOR: string } => {
  let nodeConfig: { ICON: any | null; COLOR: string } = {
    ICON: null,
    COLOR: "",
  };

  if (!group || !type) return nodeConfig;

  switch (group) {
    case NodeGroupType.TRIGGER_NODE:
      nodeConfig = {
        ICON: TriggerNodeConfig[type].ICON,
        COLOR: TriggerNodeConfig[type].COLOR,
      };
      break;
    case NodeGroupType.RESPONSE_NODE:
      nodeConfig = {
        ICON: ResponseNodeConfig[type].ICON,
        COLOR: ResponseNodeConfig[type].COLOR,
      };
      break;
    case NodeGroupType.SERVICE_NODE:
      nodeConfig = {
        ICON: ServiceNodeConfig[type].ICON,
        COLOR: ServiceNodeConfig[type].COLOR,
      };
      break;
    case NodeGroupType.BOT_PROMPT_NODE:
      nodeConfig = {
        ICON: BotPromptNodeConfig[type].ICON,
        COLOR: BotPromptNodeConfig[type].COLOR,
      };
      break;
    case NodeGroupType.LOGIC_NODE:
      nodeConfig = {
        ICON: LogicNodeConfig[type].ICON,
        COLOR: LogicNodeConfig[type].COLOR,
      };
      break;
  }

  return nodeConfig;
};

// export const getNodeDimension = ({
//   group,
//   type,
// }: {
//   group: string | undefined;
//   type: string | undefined;
// }): any => {
//   let nodeDimension: any = [260, 68];

//   if (!group || !type) return nodeDimension;

//   switch (group) {
//     case NodeGroupType.TRIGGER_NODE:
//       nodeDimension = TriggerNodeConfig[type].DIMENSION;
//       break;
//     case NodeGroupType.CREATE_NODE:
//       nodeDimension = CreateNodeConfig[type].DIMENSION;
//       break;
//     case NodeGroupType.RESPONSE_NODE:
//       break;
//     case NodeGroupType.SERVICE_NODE:
//       nodeDimension = ServiceNodeConfig[type].DIMENSION;
//       break;
//       // nodeConfig = {
//       //   ICON: ServiceNodeConfig[type].ICON,
//       //   COLOR: ServiceNodeConfig[type].COLOR,
//       // };
//       break;
//     case NodeGroupType.BOT_PROMPT_NODE:
//       // nodeConfig = {
//       //   ICON: BotPromptNodeConfig[type].ICON,
//       //   COLOR: BotPromptNodeConfig[type].COLOR,
//       // };
//       break;
//     case NodeGroupType.LOGIC_NODE:
//       // nodeConfig = {
//       //   ICON: LogicNodeConfig[type].ICON,
//       //   COLOR: LogicNodeConfig[type].COLOR,
//       // };
//       break;
//   }

//   return { width: nodeDimension[0], height: nodeDimension[1] };
// };

// export const formatNodesDimension = (nodes: INode[]): any => {
//   return nodes?.map((node: INode) => {
//     if (node?.width && node?.height) {
//       return node;
//     }

//     const { type, data } = node;
//     const { group } = data;

//     const nodeDimension = getNodeDimension({ type, group });

//     return {
//       ...node,
//       ...nodeDimension,
//     };
//   });
// };

export const compareNodesChangeEvent = (event: any, nodes: any): any => {
  if (!event?.length || !nodes?.length) return [];

  const dimensions = event?.filter((e: any) => e.type === "dimensions");
  const updatedNodes: any = [];

  nodes.forEach((node: any) => {
    const nodeEvent = dimensions?.find((d: any) => d.id === node.id);

    if (nodeEvent) {
      if (!node?.width || !node?.height) {
        updatedNodes.push({
          ...node,
          width: nodeEvent.dimensions.width,
          height: nodeEvent.dimensions.height,
        });
      } else if (
        node?.width !== nodeEvent.dimensions.width ||
        node?.height !== nodeEvent.dimensions.height
      ) {
        updatedNodes.push({
          ...node,
          width: nodeEvent.dimensions.width,
          height: nodeEvent.dimensions.height,
        });
      }
    }
  });

  return updatedNodes;
};

export const moveObjectToEndOfArray = ({
  key,
  value,
  arr,
}: {
  key: string;
  value: any;
  arr: any;
}): any => {
  const objectToMove = arr.find((obj: any) => obj?.[key] === value);

  const index = arr.findIndex(
    (element: any) => element?.[key] === objectToMove?.[key]
  );

  if (index === -1 || index === arr.length - 1) {
    return arr;
  }

  return arr
    .filter((obj: any) => obj?.[key] !== objectToMove?.[key])
    .concat(objectToMove);
};

export const formatNodesLayout = (
  layoutNodes: INode[],
  nextNodes: INode[]
): INode[] => {
  // console.log(layoutNodes);
  // console.log(nextNodes);

  const updatedNodes = nextNodes.map((node) => {
    const layoutNode = layoutNodes?.find((n) => n?.id === node.id);

    if (layoutNode?.data?.ui?.dragged) {
      return layoutNode;
    }

    return node;
  });

  return updatedNodes;
};

export const getVariableType = (type: string): string => {
  switch (type) {
    case "String":
      return "STRING";
    case "Number":
      return "NUMBER";
    case "Boolean":
      return "BOOLEAN";
    default:
      return "STRING";
  }
};

export const getOperatorList = (
  operand: string | null,
  sessionContext: any
): any => {
  if (!operand) {
    return [];
  }

  const variablePath = getVariablePath(operand);
  const variable = getVariableFromSessionContext(variablePath, sessionContext);

  if (!variable?.meta?.type) {
    return [
      ...OPERATOR_TYPE?.STRING,
      ...OPERATOR_TYPE?.NUMBER,
      ...OPERATOR_TYPE?.BOOLEAN,
    ];
  }

  const operatorType = getVariableType(variable?.meta?.type);

  return OPERATOR_TYPE[operatorType];
};
