import { ActionBuilderState } from "store/reducers/ActionBuilder/ActionBuilderReducer";
import { ActionBuilderActions } from "store/reduxActions/ActionBuilderActions";
import { useDispatch, useSelector } from "store";

const useReducerUtilityActions = (): {
  selectedConfigurableAction: ActionBuilderState["selectedConfigurableAction"];
  onActionSelection: (
    payload: ActionBuilderState["selectedConfigurableAction"]
  ) => void;
  onChangeSelectedAction: () => void;
  onPreview: (payload: ActionBuilderState["actionForPreview"]) => void;
  clearSlice: () => void;
} => {
  const dispatch = useDispatch();
  const { selectedConfigurableAction } = useSelector(
    (state) => state.actionBuilder
  );
  const onActionSelection = (
    payload: ActionBuilderState["selectedConfigurableAction"]
  ): void => {
    dispatch({
      type: ActionBuilderActions.SET_SELECTED_CONFIGURABLE_ACTION,
      payload,
    });
    dispatch({
      type: ActionBuilderActions.CLEAR_PREVIEW_ACTION,
    });
  };

  const onChangeSelectedAction = (): void => {
    dispatch({
      type: ActionBuilderActions.CLEAR_SELECTED_CONFIGURABLE_ACTION,
    });
    dispatch({
      type: ActionBuilderActions.CLEAR_SELECTED_CONFIGURABLE_PARAM,
    });
  };

  const onPreview = (payload: ActionBuilderState["actionForPreview"]): void => {
    dispatch({
      type: ActionBuilderActions.SET_PREVIEW_ACTION,
      payload,
    });
  };

  // this will clear all the selected actions and Params, ActionType will still remain selected.
  const clearSlice = (): void => {
    dispatch({
      type: ActionBuilderActions.CLEAR_ALL_ACTIONS_AND_PARAMS,
    });
  };

  return {
    selectedConfigurableAction,
    onActionSelection,
    onChangeSelectedAction,
    onPreview,
    clearSlice,
  };
};

export default useReducerUtilityActions;
