import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavigationBar from "layout/components/NavigationBar";
// import Header from "layout/components/Header";
import { Outlet } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  appContainer: {},
  mainContainer: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    marginInlineStart: "72px",
    overflow: "hidden",
  },
  mainApp: {
    "&.main-header-enabled": {
      position: "relative",
      top: "40px",
    },
  },
}));

// const headerHiddenRoutes = [
//   "chat",
//   "voice",
//   "action-builder",
//   "workflow-builder",
//   "agent-builder",
//   "integrations",
// ];

const AppLayout: React.FC = () => {
  const classes = useStyles();

  // const pathname = location.pathname;
  // const isHeaderDisabled = pathname
  //   .split("/")
  //   .some((el) => headerHiddenRoutes.includes(el));

  return (
    <div className={classes.appContainer}>
      <NavigationBar />

      <div className={classes.mainContainer}>
        {/* <Header hideHeader={isHeaderDisabled} /> */}
        <div className={clsx(classes.mainApp)}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
