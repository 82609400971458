import { createContext } from "react";

export interface APIBuilderContextProps {
  assistantId: string;
  integrationId: string;
}

const APIBuilderContext = createContext<APIBuilderContextProps>({
  assistantId: "",
  integrationId: "",
});
export default APIBuilderContext;
