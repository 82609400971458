import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: {
    width: "100%",
  },
  tableContainer: {
    minHeight: "40vh",
    boxShadow: theme.boxShadows.sm,
    border: "none",
    backgroundColor: "#FFF",
  },
  tableHeadContainer: {
    backgroundColor: "#FCFCFB",
  },
  tableBody: {},
  rowContainer: {
    "&.MuiTableRow-root:hover": {
      backgroundColor: "#FCFCFB !important",
    },
    "& .row-action-container": {
      opacity: 0,
      visibility: "hidden",
      transition: "opacity 0.2s ease, visibility 0.2s ease",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      columnGap: "8px",
    },
    "&:hover": {
      "& .row-action-container": {
        opacity: 1,
        visibility: "visible",
      },
    },
  },
}));

export default useStyles;
