import { type Conversation } from "@twilio/conversations";
import { type Dispatch } from "redux";
import { ReduxChatbotActions } from "store/reduxActions/ReduxChatbotActions";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const initConversationListener = (
  conversation: Conversation,
  dispatch: Dispatch
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
  conversation.addListener(
    "updated",
    ({ conversation: updatedConversation, updateReasons }) => {
      // we are listening only to a subset of events.
      if (updateReasons?.includes("state")) {
        dispatch({
          type: ReduxChatbotActions.ACTION_UPDATE_CONVERSATION_STATE,
          payload: { conversationState: updatedConversation?.state?.current },
        });
      }

      if (updatedConversation?.state?.current?.toString() === "closed") {
        dispatch({
          type: ReduxChatbotActions.ACTION_END_SESSION,
          payload: {
            message: {
              body: "AGENT_HANDOFF_CLOSED",
              attributes: { speaker: "bot" },
            },
          },
        });
      }
    }
  );
};
