import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { makeStyles } from "@mui/styles";
import { BarChartData } from "pages/Analytics/types";

const useStyles = makeStyles(() => ({
  chartContainer: {
    width: "100%",
    height: "100%",
    padding: "16px",
    background: "white",
    borderRadius: "8px",
  },
  yAxisLabel: {
    fontSize: "12px",
    fontWeight: 600,
    fill: "#7C7972",
    textAnchor: "middle",
  },
}));

interface Props {
  vizData: BarChartData;
}

const CustomBarChart: React.FC<Props> = ({ vizData }) => {
  const classes = useStyles();
  const { data, yAxisLabel = "", categories } = vizData;

  const transformedData = data.map((entry) => {
    const transformedEntry: {
      attribute: string;
      [key: string]: number | string;
    } = {
      attribute: entry.attribute,
    };

    entry.values.forEach((value) => {
      transformedEntry[value.categoryId] = value.value;
    });

    return transformedEntry;
  });

  return (
    <div className={classes.chartContainer}>
      <ResponsiveContainer width="100%" height={"100%"}>
        <BarChart
          data={transformedData}
          margin={{ top: 20, right: 30, left: 10, bottom: 5 }}
          barCategoryGap={60} // Adjust gap between groups of bars
        >
          <XAxis
            dataKey="attribute"
            tick={{ fontSize: 12, fontWeight: 600, fill: "#000", dy: 8 }}
            tickLine={false}
          />
          <YAxis
            tick={{ fontSize: 12, fill: "#7C7972" }}
            tickCount={5}
            domain={[0, 100]}
            interval={0}
            axisLine={false}
            label={{
              value: yAxisLabel,
              angle: -90,
              position: "insideLeft",
              className: classes.yAxisLabel,
            }}
            tickLine={false}
          />
          <CartesianGrid stroke="#F3F2F0" vertical={false} />

          {categories.map((category) => (
            <Bar
              key={category.id}
              dataKey={category.id}
              fill={category.color}
              barSize={12}
              radius={[4, 4, 0, 0]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomBarChart;
