import type React from "react";
import ActionBuilder from "./ActionBuilder";
import useStyles from "./ActionBuilder.style";
import ActionHeader from "./Header/ActionHeader";
import RightPanelContainer from "./RightPanel/RightPanel.container";
import FinalSubmitAddAction from "./FooterFinalSubmit/FinalSubmitAddAction";
import { useParams } from "react-router";
import { AddToolInput } from "../Service/ActionBuilder.service";
import { useSnackbar } from "notistack";
import {
  addTool,
  updateTool,
} from "pages/ActionBuilder/Service/ActionBuilder.service";
import useRouteNavigator from "hooks/navigation/useRouteNavigator";

const ActionBuilderContainer: React.FC = () => {
  const classes = useStyles();
  const { gotoAgentBuilder } = useRouteNavigator();
  const { botId, agentId, toolId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const onSaveDraft = (): void => {
    // not implemented yet
    //  make an API call to save draft
  };

  const handleAddAction = (data: AddToolInput): void => {
    addTool(data)
      .then(() => {
        enqueueSnackbar("Successfully added", { variant: "success" });
        // redirect to the parent page back
        if (botId && agentId) {
          gotoAgentBuilder(botId, agentId);
        }
      })
      .catch((err) => {
        enqueueSnackbar(`Something went wrong: ${err}`, { variant: "error" });
      });
  };

  const handleUpdateAction = (data: AddToolInput): void => {
    if (toolId)
      updateTool(toolId, data)
        .then(() => {
          enqueueSnackbar("Successfully added", { variant: "success" });
          // redirect to the parent page back
          if (botId && agentId) {
            gotoAgentBuilder(botId, agentId);
          }
        })
        .catch((err) => {
          enqueueSnackbar(`Something went wrong: ${err}`, { variant: "error" });
        });
  };

  return (
    <div className={classes.container}>
      <ActionHeader
        onSave={onSaveDraft}
        onClickExit={() => {
          if (botId && agentId) {
            gotoAgentBuilder(botId, agentId);
          }
        }}
        title={toolId ? "Update action" : "Add a new action"}
      />
      <div className={classes.ContentAndRightPanelContainer}>
        <div className={classes.contentContainer}>
          <ActionBuilder />
          <FinalSubmitAddAction
            operation={toolId ? "Update" : "Add"}
            onSubmit={toolId ? handleUpdateAction : handleAddAction}
          />
        </div>
        <RightPanelContainer />
      </div>
    </div>
  );
};

export default ActionBuilderContainer;
