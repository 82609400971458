/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer } from "../util";
import { ReduxBotTreeActions } from "store/reduxActions/ReduxBotTreeActions";
import { type IBotTreeState } from "types/BotTree";

const initialState: IBotTreeState = {
  loading: true,
  error: false,
  empty: false,
  filters: {
    dateRange: "",
    sortMetric: "",
  },
  node: null,
  nodes: [],
  edges: [],
};

const botTreeReducer = createReducer(initialState, {
  [ReduxBotTreeActions.SET_BOT_TREE_LOADING]: (state: any, action: any) => {
    return {
      ...state,
      node: null,
      loading: action.payload.loading,
    };
  },
  [ReduxBotTreeActions.SET_BOT_TREE_PAGE_ERROR]: (state: any, action: any) => {
    return {
      ...state,
      empty: false,
      error: action.payload.error,
    };
  },
  [ReduxBotTreeActions.SET_BOT_TREE_DATA]: (state: any, action: any) => {
    return {
      ...state,
      nodes: action.payload.nodes,
      edges: action.payload.edges,
      error: false,
      empty: !action.payload.nodes?.length,
    };
  },
  [ReduxBotTreeActions.SET_FILTER]: (state: any, action: any) => {
    return {
      ...state,
      filters: {
        dateRange: action.payload.dateRange,
        sortMetric: action.payload.sortMetric,
      },
    };
  },
  [ReduxBotTreeActions.SET_OPEN_NODE]: (state: any, action: any) => {
    return {
      ...state,
      node: action.payload.node,
    };
  },
  [ReduxBotTreeActions.SET_CLOSE_NODE]: (state: any, action: any) => {
    return {
      ...state,
      node: null,
    };
  },
});

export default botTreeReducer;
