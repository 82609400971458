import {
  Button as MuiButton,
  type ButtonProps as MuiButtonProps,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles({
  root: {
    fontSize: "1rem",
    lineHeight: "150%",
    letterSpacing: "0.16px",
    fontWeight: 700,
    fontStyle: "normal",
    borderRadius: 8,
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "& .MuiButton-startIcon, .MuiButton-endIcon": {
      height: "16px",
      width: "16px",

      "& svg": {
        height: "100%",
        width: "100%",
      },
    },

    "&.Mui-disabled": {
      background: "#EDEBE9",
      color: "#B5B1AD",

      "& .MuiButton-startIcon, .MuiButton-endIcon": {
        "& svg": {
          "& path": {
            fill: "#B5B1AD",
          },
        },
      },
    },
    "& .MuiButton-startIcon": {
      "& svg": {
        "& path": {
          fill: "currentColor",
        },
      },
    },
  },
  iconButton: {
    padding: "8px !important",
  },
  sizeLarge: {
    padding: "16px 32px",
  },
  sizeMedium: {
    padding: "12px 24px",
  },
  sizeSmall: {
    padding: "8px 16px",
  },
  sizeXSmall: {
    fontSize: "14px",
    padding: "8px 16px",
    height: "32px",
  },
  primary: {
    color: "#FFF",
    background: "#F07400",
    "&:hover": {
      background: "#FF9736",
    },
  },
  secondary: {
    background: "#FFF",
    color: "#282624",
    border: "1px solid #E1DEDA",
    "&:hover": {
      background: "#F9F8F8",
    },
  },
  tertiary: {
    background: "#282624",
    "&:hover": {
      background: "#524D42",
    },
  },
  error: {
    background: "#E53811",
    "&:hover": {
      background: "#F5AE9D",
    },
  },
});

type Color = "primary" | "secondary" | "tertiary" | "error";
type Size = "x-small" | "small" | "medium" | "large";

interface Props extends Omit<MuiButtonProps, "color" | "size"> {
  size?: Size;
  color: Color;
  iconBtn?: boolean;
  loading?: boolean;
}

const Button: React.FC<Props> = ({
  className,
  color,
  size,
  disabled,
  iconBtn = false,
  children,
  loading = false,
  startIcon,
  endIcon,
  ...otherProps
}) => {
  const classes = useStyles();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiButton
      className={clsx(className, classes.root, {
        [classes.iconButton]: iconBtn,
        [classes.sizeLarge]: size === "large",
        [classes.sizeMedium]: size === "medium",
        [classes.sizeSmall]: size === "small" || !size,
        [classes.sizeXSmall]: size === "x-small",
        [classes.primary]: color === "primary",
        [classes.secondary]: color === "secondary",
        [classes.tertiary]: color === "tertiary",
        [classes.error]: color === "error",
      })}
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      disabled={disabled || loading}
      variant="contained"
      {...otherProps}
      startIcon={
        loading ? (
          <CircularProgress size={"18px"} sx={{ color: "#B5B1AD" }} />
        ) : (
          startIcon
        )
      }
      endIcon={endIcon}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
