import AnalyticsContentContainer from "./AnalyticsContent.container";
import AnalyticsHeader from "./AnalyticsHeader";
import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100vh",
    overflow: "auto",
  },
}));

const AnalyticsLayout: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <AnalyticsHeader />
      <AnalyticsContentContainer />
    </div>
  );
};

export default AnalyticsLayout;
