import * as yup from "yup";
import { AppearanceSettingsFields } from "pages/Assistant/types";

export const AppearanceValidationSchema = yup.object({
  [AppearanceSettingsFields.bot_display_name]: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(16, "Maximum 16 characters allowed")
    .required("This is a required field"),
  [AppearanceSettingsFields.header_message]: yup
    .string()
    .min(3, "Please enter at least 3 characters")
    .max(50, "Maximum 50 characters allowed")
    .required("This is a required field"),
  [AppearanceSettingsFields.welcome_message]: yup
    .string()
    .required("This is a required field"),
  [AppearanceSettingsFields.input_box_placeholder]: yup
    .string()
    .required("This is a required field"),
  [AppearanceSettingsFields.header_logo_enabled]: yup
    .string()
    .required("This is a required field"),
  [AppearanceSettingsFields.header_logo_url]: yup.string(),
  [AppearanceSettingsFields.header_accent_color]: yup.string(),
  [AppearanceSettingsFields.header_text_color]: yup.string(),
  [AppearanceSettingsFields.chat_bubble_enabled]: yup
    .string()
    .required("This is a required field"),
  [AppearanceSettingsFields.chat_bubble_url]: yup.string(),
  [AppearanceSettingsFields.display_nudge]: yup.string(),
  [AppearanceSettingsFields.nudge_message]: yup
    .string()
    .max(100, "Maximum 100 characters allowed"),
  [AppearanceSettingsFields.default_widget_state]: yup.string(),
});
