import { baseAxiosInstance, botAxiosInstance } from "services/axios";

export const authorizeUser = async (): Promise<any> => {
  return await baseAxiosInstance({
    method: "GET",
    url: `/accounts/me/`,
    withCredentials: true,
  });
};

export const logoutUserApi = async (): Promise<any> => {
  return await botAxiosInstance({
    method: "POST",
    url: `/accounts/logout/`,
  });
};
