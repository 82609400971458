import { type AuthType } from "pages/Integrations/integrations.types";
import { MANUAL_ENTERED_NAME } from "./useFormikWrapper";
import { type CreateConnectionInput } from "pages/Integrations/Services/integrations.service";

const redirectURLPlaceholder = "{{redirect_url}}";
export const getModifiedPayload = (
  formValues: Record<string, string>,
  connectorId: string,
  authType: AuthType
): CreateConnectionInput => {
  const name = formValues[MANUAL_ENTERED_NAME];
  const configValues = Object.keys(formValues).reduce<Record<string, string>>(
    (acc, key) => {
      if (key !== MANUAL_ENTERED_NAME) {
        acc[key] = formValues[key];
      }
      return acc;
    },
    {}
  );

  return {
    name,
    config_values: configValues,
    connector_id: connectorId,
    auth_type: authType,
  };
};

export const getModifiedURL = (
  url: string | null,
  backRedirectURL: string,
  collectedValues: Record<string, string>
): string | null => {
  if (!url) return null; // Early return if URL is null or undefined

  // Replace the redirect URL placeholder first
  if (url.includes(redirectURLPlaceholder)) {
    url = url.replace(redirectURLPlaceholder, backRedirectURL);
  }

  // Now, replace other placeholders in the URL using collectedValues
  const regex = /{{(.*?)}}/g; // This regex matches {{key}} pattern
  return url.replace(regex, (match, key) => {
    // Trim key to handle any extra spaces around the placeholders
    const trimmedKey = key.trim();
    // Check if the collectedValues has the key
    if (Object.prototype.hasOwnProperty.call(collectedValues, trimmedKey)) {
      return collectedValues[trimmedKey]; // Replace with the value from collectedValues
    }
    // If key not found in collectedValues, return the original placeholder
    return match;
  });
};
