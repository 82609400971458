/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer } from "../util";
import { ReduxAuthActions } from "store/reduxActions/ReduxAuthActions";

const initialState = {
  loading: true,
  first_name: "",
  last_name: "",
  groups: [],
  id: "",
  email: "",
  channel: "",
  token: "",
  is_active: undefined,
  is_superuser: undefined,
  is_super_admin: undefined,
  is_agent: undefined,
  is_qa_auditor: undefined,
  is_admin: undefined,
  schema_name: "",
  notificationCount: 0,
  business_type: undefined,
  workspace: [],
  selectedWorkspace: "",
  redirectUrl: "/organizations",
  userSettings: {
    emailDigestSubscription: {
      isEnabled: false,
      isEnabledForRole: false,
      isDataFetched: false,
    },
  },
};

const appUserReducer = createReducer(initialState, {
  [ReduxAuthActions.SET_GLOABL_LOADING]: (state: any, action: any) => {
    return {
      ...state,
      loading: action.payload,
    };
  },
  [ReduxAuthActions.LOGIN_USER]: (state: any, action: any) => {
    return {
      ...state,
      isLoading: true,
      first_name: "",
      last_name: "",
      groups: [],
      id: "",
      email: "",
      channel: "",
      token: "",
      is_active: undefined,
      is_superuser: undefined,
      is_admin: undefined,
      is_agent: undefined,
      is_levelai: undefined,
      is_qa_auditor: undefined,
      schema_name: "",
      notificationCount: 0,
      business_type: undefined,
      workspace: [],
      selectedWorkspace: "",
    };
  },
  [ReduxAuthActions.STORE_USER_DETAILS]: (state: any, action: any) => {
    return {
      ...state,
      isLoading: false,
      ...action.payload.userDetails,
      selectedWorkspace: action.payload.selectedWorkspace,
    };
  },
  [ReduxAuthActions.LOGIN_END]: (state: any, action: any) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  [ReduxAuthActions.LOGOUT_USER]: (state: any, action: any) => {
    return {
      ...state,
      first_name: "",
      last_name: "",
      groups: [],
      id: "",
      email: "",
      channel: "",
      token: "",
      is_active: undefined,
      is_superuser: undefined,
      is_admin: undefined,
      is_agent: undefined,
      is_qa_auditor: undefined,
      schema_name: "",
      isLoading: false,
      business_type: undefined,
      workspace: [],
      selectedWorkspace: "",
      userSettings: {
        emailDigestSubscription: {
          isEnabled: false,
          isEnabledForRole: false,
        },
      },
    };
  },
});

export default appUserReducer;
