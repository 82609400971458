// key for Stored data in session storage while authentication
export const GLOBAL_INTEGRATION_SESSION_KEY = "global-integration-user-input";

// Enum for AuthType
export enum AuthType {
  API_TOKEN = "direct_creds",
  OAUTH = "oauth_redirection",
}

// Enum for ConnectionStatus
export enum ConnectionStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

// Interface for ConfigField
export interface ConfigField {
  key: string;
  label?: string;
  capture_before_auth?: boolean;
  ui_visible: boolean;
  description?: string;
  protected: boolean;
  required?: boolean;
}

// Interface for ConfigTemplate
export interface ConfigTemplate {
  fields: ConfigField[];
}

// Interface for ComplianceDoc
export interface ComplianceDoc {
  label: string; // e.g., Privacy policy, Terms of Service
  doc_link: string;
  description?: string;
}

// Interface for Connector
export interface Connector {
  _id: string;
  name: string;
  label: string;
  supported_auth_types: AuthType[]; // List of AuthTypes
  config_template_map: Record<AuthType, ConfigTemplate>; // Mapping of AuthType to ConfigTemplate
  description: string;
  redirect_url_template?: string;
  icon: string;
  compliance_docs: ComplianceDoc[];
  auth_instructions?: string;
  auth_docs_link?: string;
  action_count: number;
}

// Interface for Connection
export interface Connection {
  _id: string;
  user_id?: string;
  connector_id: string;
  connector: Connector;
  name: string;
  auth_type: AuthType;
  config_values?: Record<string, string>; // Config values based on the connector's config_template
  status: ConnectionStatus; // Status of the connection
}
