import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100vh",
  },
  loadingContainer: {
    height: "100vh",
  },
}));

export default useStyles;
