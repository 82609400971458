import { type Connection } from "../integrations.types";
import useStyles from "./ConnectionsList.styles";
import Typography, {
  TypographyColors,
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import ConnectorTile from "./ConnectorTile";
import Loading from "aether/Loading";
import { isEmpty } from "lodash";

interface ConnectionsListProps {
  connections: Connection[];
  onClick: (item: Connection) => void;
  loading?: boolean;
}

const ConnectionsList: React.FC<ConnectionsListProps> = ({
  connections,
  onClick,
  loading = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography
        variant={TypographyVariants.textXL}
        weight={TypographyWeights.bold}
        className={classes.title}
      >
        Integrated
      </Typography>
      {loading && <Loading />}
      {isEmpty(connections) && (
        <Typography
          variant={TypographyVariants.textXL}
          weight={TypographyWeights.bold}
          color={TypographyColors.subtle}
          className={classes.emptyContainer}
        >
          No Connections yet
        </Typography>
      )}
      <div className={classes.content}>
        {connections.map((connection) => (
          <ConnectorTile
            key={connection._id}
            onClick={() => {
              onClick(connection);
            }}
            icon={connection?.connector?.icon}
            title={connection.name}
            description={connection?.connector?.description}
            showTag
          />
        ))}
      </div>
    </div>
  );
};

export default ConnectionsList;
