import { useState, useEffect, useCallback, useMemo } from "react";
import WebSocketSingleton from "./WebSocketSingleton";
import { v4 as uuidv4 } from "uuid";
import {
  getBotId,
  getPageName,
  getTenantName,
  getWorkflowId,
} from "utils/helpers";
import {
  type BotMessage,
  type BotMessageSource,
  type BotMessageHandoff,
  type IPersona,
  ConnectionState,
} from "./types";
import {
  AuthStatusType,
  BotResponseType,
  ContentResponseType,
} from "constant/BotConstant";
import { getAgentHandoff } from "api/bot/bot-handoff";
import { useSelector } from "react-redux";
import { initSession } from "store/reducers/chatbot/agentSession";
import { ReduxChatbotActions } from "store/reduxActions/ReduxChatbotActions";
import { type RootState, type AppDispatch } from "store";
import { type IMessageAction, type IAgentHandoff } from "types/ChatbotType";

const useWebSocketHook = (
  url: string,
  dispatch: AppDispatch,
  setEnableMessageInput: (state: boolean) => void,
  isPlatformBot?: boolean
): [
  BotMessage[],
  boolean,
  (message: BotMessage) => void,
  (messageAction: IMessageAction) => void,
] => {
  const token = useSelector((state: RootState) => state.app_user.token);

  const {
    conversationHandoff,
    conversation,
    messages: agentMessages,
    botType,
    connection,
    widgetMounted,
  } = useSelector((state: RootState) => state.chatbot);
  const { state: connectionState, reconnect: connectionReconnect } = connection;

  const [socket, setSocket] = useState<WebSocket | null>();
  const [messages, setMessages] = useState<BotMessage[]>([]);
  const [showTypingIndicator, setShowTypingIndicator] = useState(true);

  const currentTenant = useMemo(() => getTenantName(), []);
  const currentBotId = useMemo(() => getBotId(), []);
  const workflowId = useMemo(() => getWorkflowId(), []);

  const wsBotType = useMemo(() => {
    const pageName = getPageName();

    if (pageName === "actions") {
      return "actions";
    }

    return "knowledge";
  }, []);

  const socketInstance = useMemo(() => {
    if (!widgetMounted) {
      return null;
    }

    const _socketInstance = WebSocketSingleton.getInstance(url, {
      tenant: currentTenant || "level",
      assistant_id: currentBotId,
      isPlatformBot: isPlatformBot ? "true" : "false",
      // bot_type: wsBotType,
      // workflowId: workflowId ?? "",
    });

    return _socketInstance;
  }, [url, currentTenant, currentBotId, wsBotType, workflowId, widgetMounted]);

  const createNewMessage = useCallback(
    ({
      id = uuidv4(),
      text = "",
      speaker = "bot",
      isMessageComplete = false,
      type = BotResponseType.TEXT,
      content = [{ key: "", label: "" }],
    }) => {
      const newMessageObj: BotMessage = {
        type,
        text,
        id,
        created: "",
        speaker,
        sources: [],
        isMessageComplete,
        content,
      };
      setMessages((prevMessages) => [...prevMessages, newMessageObj]);
    },
    []
  );

  const handleAddSource = useCallback((botMessageSource: BotMessageSource) => {
    setMessages((prevMessages) => {
      const newPrevMessages = [...prevMessages];
      if (newPrevMessages.length > 1) {
        const lastMessage = newPrevMessages[newPrevMessages.length - 1];
        const newSources = [...(lastMessage.sources || [])];
        newSources.push(botMessageSource);
        newPrevMessages[newPrevMessages.length - 1] = {
          ...lastMessage,
          sources: newSources,
        };
      }
      return newPrevMessages;
    });
  }, []);

  const toggleMessageComplete = useCallback(() => {
    setMessages((prevMessages) => {
      const newPrevMessages = [...prevMessages];
      if (newPrevMessages.length > 1) {
        const lastMessage = newPrevMessages[newPrevMessages.length - 1];

        newPrevMessages[newPrevMessages.length - 1] = {
          ...lastMessage,
          isMessageComplete: true,
        };
      }
      return newPrevMessages;
    });
  }, []);

  const pushMessageToCurrentActiveMessage = useCallback(
    (incomingMsg: string) => {
      setMessages((prevMessages) => {
        const newPrevMessages = [...prevMessages];

        let newMergedText = "";
        if (newPrevMessages.length) {
          const lastMessage = newPrevMessages[newPrevMessages.length - 1];

          newMergedText = `${lastMessage.text}${incomingMsg}`;
          newPrevMessages[newPrevMessages.length - 1] = {
            ...lastMessage,
            text: newMergedText,
          };
        } else {
          newMergedText = incomingMsg;
          newPrevMessages.push({
            id: uuidv4(),
            created: "",
            speaker: "Bot",
            sources: [],
            text: newMergedText,
            isMessageComplete: false,
          });
        }

        return newPrevMessages;
      });
    },
    []
  );

  const handleAgentHandoff = useCallback(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ({ content, session_id: sessionId }: BotMessageHandoff) => {
      createNewMessage({ text: content, isMessageComplete: true });
      getAgentHandoff({ token, sessionId })
        .then(async (response: IAgentHandoff) => {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { conversation_id, handover_detail } = response || {};

          await dispatch(
            initSession({
              token: handover_detail?.token,
              conversationSid: conversation_id,
            })
          );
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    [createNewMessage, dispatch, token]
  );

  const handleAddPersonaTag = useCallback(
    ({ content, title }: { content: IPersona[]; title: string }) => {
      createNewMessage({
        text: title,
        isMessageComplete: true,
        type: BotResponseType.PERSONA_TAG,
        content,
      });
    },
    [createNewMessage]
  );

  const sendMessage = useCallback(
    async (message: BotMessage) => {
      if (conversation) {
        let preparedMessage = conversation.prepareMessage();
        preparedMessage = preparedMessage
          .setBody(message.text)
          .setAttributes({ ...message });
        await preparedMessage.build().send();
      } else if (
        socket &&
        socket.readyState === WebSocket.OPEN &&
        botType === "default"
      ) {
        setShowTypingIndicator(true);
        socket.send(
          JSON.stringify({
            text: message.text,
          })
        );
      } else if (
        socket &&
        socket.readyState === WebSocket.OPEN &&
        botType === "ws_workflow"
      ) {
        setShowTypingIndicator(true);
        socket.send(message.text);
        setEnableMessageInput(false);
      }

      setMessages((prevMessages) => [...prevMessages, message]);
      // createNewMessage({ ...message });
      createNewMessage({});
    },
    [conversation, createNewMessage, socket]
  );

  const sendAction = useCallback((messageAction: IMessageAction) => {
    if (
      socket &&
      socket.readyState === WebSocket.OPEN &&
      botType === "ws_workflow"
    ) {
      socket.send(JSON.stringify(messageAction));
    }
  }, []);

  const messageHandler = (event: any): void => {
    if (botType === "default") {
      handleBotMessageHandler(event);
    } else if (botType === "ws_workflow") {
      handleWorkflowBotMessageHandler(event);
    }
  };

  const sendAuthMessage = (): void => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const authMessage = {
        type: BotResponseType.AUTH,
        content: {
          auth_required: false,
          auth_fields: {},
          user_info_fields: {},
        },
      };
      socket.send(JSON.stringify(authMessage));
    }
  };

  const handleChatbotAuthentication = (status: string): void => {
    if (status === AuthStatusType.READY_FOR_AUTH_INFO) {
      sendAuthMessage();
    }
    // else if (
    //   status === AuthStatusType.AUTH_SUCCESS ||
    //   status === AuthStatusType.AUTH_SKIPPED
    // ) {
    // } else if (status === AuthStatusType.AUTH_FAILURE) {
    // }
  };

  const handleBotMessageHandler = (event: any): void => {
    setShowTypingIndicator(false);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { type, content, status, source, title, session_id } =
      JSON.parse(event.data) || {};

    if (type === BotResponseType.AUTH) {
      handleChatbotAuthentication(status);
    } else if (type === BotResponseType.TEXT) {
      pushMessageToCurrentActiveMessage(content);
    } else if (type === BotResponseType.SOURCE) {
      handleAddSource({ source, title });
    } else if (type === BotResponseType.AGENT_HANDOFF_INIT) {
      handleAgentHandoff({ content, session_id });
    } else if (type === BotResponseType.PERSONA_TAG) {
      handleAddPersonaTag({ content, title });
    } else {
      toggleMessageComplete();
    }
  };

  const handleWorkflowBotMessageHandler = (event: any): void => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { type, content } = JSON.parse(event.data) || {};

    if (type === BotResponseType.MESSAGE) {
      createNewMessage({
        text: content,
        type: BotResponseType.TEXT,
        isMessageComplete: true,
      });
    } else if (type === BotResponseType.CAROUSEL) {
      createNewMessage({
        text: "carousel",
        content,
        type: BotResponseType.CAROUSEL,
        isMessageComplete: true,
      });
    } else if (type === BotResponseType.KB_STREMMING) {
      // eslint-disable-next-line
      const { content_type, message, title, source, session_id } = content;

      if (content_type === ContentResponseType.MESSAGE) {
        pushMessageToCurrentActiveMessage(message);
      } else if (content_type === ContentResponseType.SOURCE) {
        handleAddSource({ source, title });
      } else if (content_type === ContentResponseType.AGENT_HANDOFF_INIT) {
        handleAgentHandoff({ content: message, session_id });
      } else {
        toggleMessageComplete();
      }
    } else if (
      type === BotResponseType.CONTROL &&
      content === "READY_TO_RECEIVE"
    ) {
      setEnableMessageInput(true);
    } else {
      toggleMessageComplete();
    }

    setShowTypingIndicator(false);
  };

  const agentHandoffHandler = (): void => {
    const content = agentMessages?.pop();
    if (content && content?.attributes?.speaker !== "agent") {
      createNewMessage({ text: content?.body, isMessageComplete: true });
    } else if (content?.body === "AGENT_HANDOFF_CLOSED") {
      createNewMessage({ text: content?.body, isMessageComplete: true });
      dispatch({
        type: ReduxChatbotActions.ACTION_END_HANDOFF,
      });
    }
  };

  const handleReconnect = (): void => {
    setMessages([]);
    setShowTypingIndicator(true);

    socketInstance?.reconnect();
  };

  const handleConnectionOnOpen = useCallback((): void => {
    console.log("Connection On Open");
    setSocket(socketInstance?.getSocket());

    // Set Reconnect false if it was set true somewhere
    dispatch({
      type: ReduxChatbotActions.ACTION_SET_RECONNECT,
      payload: { reconnect: false },
    });
  }, [connectionState]);

  const handleConnectionOnClose = useCallback((): void => {
    console.log("Connection On Close");
  }, [connectionState]);

  const handleConnectionOnMessage = useCallback((): void => {
    console.log("Connection On Message");
  }, [connectionState]);

  const handleConnectionOnError = useCallback((): void => {
    console.log("Connection On Error");
  }, [connectionState]);

  useEffect(() => {
    return () => {
      if (socketInstance && socket) {
        console.log("Socket Destroyed");
        socket.removeEventListener("message", messageHandler);
        socketInstance?.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (socket != null && !conversationHandoff) {
      socket.addEventListener("message", messageHandler);
    }

    if (conversationHandoff) {
      agentHandoffHandler();
    }
  }, [socket, socketInstance, conversationHandoff, agentMessages, botType]);

  useEffect(() => {
    if (connectionReconnect) {
      handleReconnect();
    }
  }, [connectionReconnect]);

  useEffect(() => {
    if (connectionState === ConnectionState.OPEN) {
      handleConnectionOnOpen();
    } else if (connectionState === ConnectionState.CLOSE) {
      handleConnectionOnClose();
    } else if (connectionState === ConnectionState.MESSAGE) {
      handleConnectionOnMessage();
    } else if (connectionState === ConnectionState.ERROR) {
      handleConnectionOnError();
    }
  }, [
    connectionState,
    handleConnectionOnClose,
    handleConnectionOnError,
    handleConnectionOnMessage,
    handleConnectionOnOpen,
  ]);

  return useMemo(
    () => [
      messages.filter((m) => !!m && !!m.text),
      showTypingIndicator,
      sendMessage,
      sendAction,
    ],
    [messages, sendMessage, showTypingIndicator]
  );
};

export default useWebSocketHook;
