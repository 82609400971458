import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as BrokenDataIcon } from "assets/broken-data.svg";

const useStyles = makeStyles((theme: Theme) => ({
  tableErrorContainer: {
    height: "40vh",

    "& .table-error-logo": {
      "& svg": {
        height: "200px",
      },
    },
  },
}));

interface Props {
  colSpan: number;
}

const TableError: React.FC<Props> = ({ colSpan }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.tableErrorContainer}>
      <TableCell colSpan={colSpan}>
        <div className="table-error-logo center">
          <BrokenDataIcon />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TableError;
