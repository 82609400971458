import React from "react";
import useStyles from "./style";
import clsx from "clsx";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";

export enum ChipVariants {
  // text = "text",
  contained = "contained",
  outlined = "outlined",
}

export enum ChipColors {
  default = "default",
  success = "success",
  warning = "warning",
  error = "error",
  none = "none",
  custom = "custom",
}

export enum ChipSizes {
  xsmall = "xsmall",
  small = "small",
  medium = "medium",
  large = "large",
}

type ChipVariant = ChipVariants;
type ChipColor = ChipColors;
type ChipSize = ChipSizes;

export interface CustomColorProps {
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
}

export interface ChipProps {
  label: string;
  variant?: ChipVariant;
  color?: ChipColor;
  customColors?: CustomColorProps;
  size?: ChipSize;
  className?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      variant = ChipVariants.contained,
      color = ChipColors.default,
      size = ChipSizes.small,
      label,
      className,
      customColors = { color: "#000000", backgroundColor: "#ffffff" },
      startIcon,
    },
    ref
  ) => {
    const classes = useStyles({ color, customColors });

    const chipClasses = clsx(
      classes.root,
      classes[variant],
      classes[size],
      className
    );

    return (
      <div ref={ref} className={chipClasses}>
        {startIcon && startIcon}
        {size === ChipSizes.xsmall ? (
          <Typography
            variant={TypographyVariants.textTiny}
            weight={TypographyWeights.bold}
          >
            {label}
          </Typography>
        ) : (
          <Typography weight={TypographyWeights.semiBold}>{label}</Typography>
        )}
      </div>
    );
  }
);

Chip.displayName = "Chip";

export default Chip;
