import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    borderRadius: "16px",
    background: "#FFF",
    boxShadow:
      "0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    padding: "24px",
    marginTop: "32px",
    minHeight: "250px",
  },
  step: {
    color: "var(--text-subtle, #7C7972)",
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0.2px",
  },
  title: {
    color: "var(--text-default, #282624)",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    letterSpacing: "-0.2px",
    marginBottom: "24px",
  },
  rowIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "16px",
  },
  selectButton: {
    whiteSpace: "nowrap",
  },
  actionsContainer: {
    display: "flex",
  },
});
export default useStyles;
