import { Box } from "@mui/material";
import Typography from "components/base/Typography";

export interface BotDescriptionProps {
  imageComponent: React.ReactNode;
  heading: string;
  subHeading: string;
}

const BotDescription: React.FC<BotDescriptionProps> = ({
  imageComponent,
  heading,
  subHeading,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mx={4}
      height="100%"
    >
      <Box mb={3}>{imageComponent}</Box>
      <Box mb={1} className="text-center">
        <Typography variant="h6" className="w-bold">
          {heading}
        </Typography>
      </Box>
      <Box className="text-center">
        <Typography variant="textMd" className="w-regular">
          {subHeading}
        </Typography>
      </Box>
    </Box>
  );
};

export default BotDescription;
