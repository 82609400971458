import type React from "react";
import useStyles from "./APIActionPreview.styles";
import { ReactComponent as LogInIcon } from "assets/log-in-01.svg";
import { ReactComponent as CrossIcon } from "assets/x-close.svg";
import { type ActionParam } from "../../AgentActions.const";
import ActionParamTile from "./ActionParamTile";
import { Popover } from "@mui/material";
import { useState } from "react";
import { isEmpty, isEqual, isNil } from "lodash";
import Button from "components/base/Button";
import { IAPI } from "types/APIIntegration";
import { ReactComponent as CodeIcon } from "assets/code-API.svg";
import JSONSnippetRenderer from "../../Apis/selectedAPI/JSONSnippetRenderer";
import { ReactComponent as LinkExternal } from "assets/link-external-icon.svg";
import { useParams } from "react-router-dom";
import useAPIIntegrationsRouteNavigator from "hooks/navigation/useAPIIntegrationRouteNavigator";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";

interface Props {
  onClose: () => void;
  action: IAPI;
  openSelectAndConfigure: () => void;
}

const APIActionPreview: React.FC<Props> = ({
  onClose,
  action,
  openSelectAndConfigure,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedParam, setSelectedParam] = useState<ActionParam | null>(null);
  const classes = useStyles({ width: anchorEl?.clientWidth });
  const { botId } = useParams();
  const handlePopoverClose = (): void => {
    setAnchorEl(null);
    setSelectedParam(null);
  };
  const { gotoAPI } = useAPIIntegrationsRouteNavigator();

  const onClickParamItem = (
    param: ActionParam,
    event: React.MouseEvent<HTMLElement>
  ): void => {
    if (isEqual(param.id, selectedParam?.id)) {
      setSelectedParam(null);
      handlePopoverClose();
    } else {
      setAnchorEl(event.currentTarget);
      setSelectedParam(param);
    }
  };

  const onMoreInfoAndTest = (): void => {
    if (!isNil(botId) && !isNil(action?._id)) {
      gotoAPI(botId, action?._id ?? "");
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.IconAndtitle}>
            <CodeIcon />
            <Typography
              variant={TypographyVariants.textXL}
              weight={TypographyWeights.bold}
            >
              {action.name}
            </Typography>
          </div>
          <CrossIcon onClick={onClose} />
        </div>
        <div className={classes.content}>
          {action?.description && (
            <>
              <Typography
                variant={TypographyVariants.textLarge}
                weight={TypographyWeights.bold}
              >
                Description
              </Typography>
              <Typography
                variant={TypographyVariants.text}
                weight={TypographyWeights.medium}
              >
                {action.description}
              </Typography>
            </>
          )}
          <div className={classes.infoBoxContainer}>
            <div className={classes.row}>
              <Typography
                variant={TypographyVariants.textLarge}
                weight={TypographyWeights.bold}
                className={classes.infoMethod}
              >
                Method
              </Typography>
              <Typography
                variant={TypographyVariants.textLarge}
                weight={TypographyWeights.bold}
                className={classes.infoEndpoint}
              >
                Endpoint
              </Typography>
            </div>
            <div className={classes.row}>
              <Typography
                variant={TypographyVariants.text}
                weight={TypographyWeights.medium}
                className={classes.infoMethodValue}
              >
                {action.http_method}
              </Typography>
              <Typography
                variant={TypographyVariants.text}
                weight={TypographyWeights.medium}
                className={classes.infoEndpointValue}
              >
                {action.url}
              </Typography>
            </div>
          </div>
          {/* For Input Params */}
          <div className={classes.paramHead}>
            <div className={classes.iconDiv}>
              <LogInIcon />
            </div>
            <Typography
              variant={TypographyVariants.textLarge}
              weight={TypographyWeights.bold}
              className="ml-2"
            >
              Input
            </Typography>
            <Typography
              variant={TypographyVariants.text}
              weight={TypographyWeights.medium}
              className={classes.paramCount}
            >
              {action?.extracted_variables?.length}
            </Typography>
          </div>
          <div className={classes.tiles}>
            {action?.extracted_variables?.map((param) => {
              return (
                <ActionParamTile
                  key={param.id}
                  param={param}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    onClickParamItem(param, event);
                  }}
                  isPopoverOpen={isEqual(selectedParam?.id, param.id)}
                  showOpenCloseState
                />
              );
            })}
          </div>
          {!isEmpty(action?.last_run_response) && (
            <>
              <Typography
                variant={TypographyVariants.textLarge}
                weight={TypographyWeights.bold}
                className={"mt-4"}
              >
                Example from last run
              </Typography>

              <JSONSnippetRenderer
                data={action?.last_run_response}
                lastRunDate={action?.last_run}
              />
            </>
          )}
        </div>
        <div className={classes.footer}>
          <Button
            variant="text"
            color="secondary"
            className={classes.redirectBtn}
            endIcon={<LinkExternal />}
            onClick={onMoreInfoAndTest}
          >
            More info & test
          </Button>
          <Button
            color="primary"
            className={classes.configureBtn}
            onClick={openSelectAndConfigure}
          >
            Select & configure
          </Button>
        </div>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom", // Position it below the input
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.popover }}
      >
        <div>
          <Typography
            variant={TypographyVariants.textSmall}
            weight={TypographyWeights.bold}
          >
            How the departure date is sourced?
          </Typography>
          <Typography
            variant={TypographyVariants.textSmall}
            weight={TypographyWeights.medium}
          >
            Extracted from the user’s query
          </Typography>
        </div>
        <div>
          <Typography
            variant={TypographyVariants.textSmall}
            weight={TypographyWeights.bold}
          >
            Default value
          </Typography>
          <Typography
            variant={TypographyVariants.textSmall}
            weight={TypographyWeights.medium}
          >
            {selectedParam?.value ? selectedParam?.value : "Null"}
          </Typography>
        </div>
        <div>
          <Typography
            variant={TypographyVariants.textSmall}
            weight={TypographyWeights.bold}
          >
            Optional vs mandatory
          </Typography>
          <Typography
            variant={TypographyVariants.textSmall}
            weight={TypographyWeights.medium}
          >
            {selectedParam?.required ? "Mandatory" : "Optional"}
          </Typography>
        </div>
      </Popover>
    </>
  );
};

export default APIActionPreview;
