import { useMemo } from "react";
import ChatBotLayout from "./ChatBotLayout";
import ChatBot from "./ChatBot";
import ChatBotDisabledState from "./ChatBotDisabledState";
import ChatBotInProgressState from "./ChatBotInProgressState";
import { ChatAssistantStatus, IAssistant } from "pages/Assistant/types";

interface ChatBotContainerProps {
  botConfig?: any;
  botData: IAssistant;
  isPlatformBot?: boolean;
}

const ChatBotContainer: React.FC<ChatBotContainerProps> = ({
  botConfig,
  botData,
  isPlatformBot,
}) => {
  const botStatus = useMemo(() => botData?.status, [botData?.status]);

  const renderComponent = useMemo(() => {
    if (botStatus === ChatAssistantStatus.DRAFT) {
      return <ChatBotDisabledState message={botData?.status_message} />;
    }

    if (botStatus === ChatAssistantStatus.IN_PROGRESS) {
      return <ChatBotInProgressState />;
    }

    return <ChatBot isPlatformBot={isPlatformBot} />;
  }, [botStatus]);

  return <ChatBotLayout botConfig={botConfig}>{renderComponent}</ChatBotLayout>;
};

export default ChatBotContainer;
