import { useStyles } from "./style";
import { Typography } from "@mui/material";
import clsx from "clsx";

export interface ITabSwitchOption {
  label: string;
  key: string;
  icon?: any;
  disabled?: boolean;
}

export interface ITabSwitch {
  value: string | number;
  onChange: (value: string) => void;
  options: ITabSwitchOption[];
}

const TabSwitch: React.FC<ITabSwitch> = ({ value, onChange, options }) => {
  const position = options.findIndex((o) => o.key === value);
  const classes = useStyles({ position });

  return (
    <div className={classes["tab-switch-container"]}>
      {options?.map(
        (
          { key, label, icon: Icon, disabled }: ITabSwitchOption,
          index: number
        ) => (
          <div
            key={index}
            className={clsx("tab-switch-option-container", {
              active: value === key,
              glider: value === key,
            })}
          >
            <div
              key={key}
              className={clsx("tab-switch-option-button", {
                "tab-disabled": disabled,
              })}
              onClick={() => {
                onChange(key);
              }}
            >
              {Icon && (
                <div className="tab-switch-option-icon">
                  <Icon />
                </div>
              )}
              <div className="tab-switch-option-label">
                <Typography className="label w-bold">{label}</Typography>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default TabSwitch;
