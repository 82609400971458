import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    width: "100%",
    padding: "12px 32px",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFF",
    boxShadow: "0px 1px 0px 0px #E6E6E6",
  },
  titleIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
  },
  actions: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
  },
  description: {
    color: "var(--text-default, #282624)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  lightningIcon: {
    width: "30px",
    height: "30px",
    flexShrink: 0,
  },
  helpIcon: {
    height: "24px",
    width: "24px",
  },
  crossIcon: {
    fill: "black",
  },
}));

export default useStyles;
