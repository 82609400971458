import type React from "react";
import useStyles from "./SampleAPIData.styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@mui/material";
import { ReactComponent as InfoIcon } from "assets/info-icon.svg";
import { type MockAPIData, type MockAPIEntity } from "types/APIIntegration";

interface SampleAPIDataProps {
  entityHeader?: MockAPIEntity[];
  entityValues?: MockAPIData[];
}

const SampleAPIData: React.FC<SampleAPIDataProps> = ({
  entityHeader = [],
  entityValues = [],
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>Sample data</div>
        <div className={classes.iconContainer}>
          <InfoIcon />
        </div>
      </div>
      <div className={classes.subTitle}>
        This dataset lets you quickly test workflow or a voice agent to ensure
        it functions as expected.
      </div>

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className={classes.tableHeaderRow}>
              {entityHeader.map((column) => (
                <TableCell key={column.name} className={classes.tableHeader}>
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {/* Data Rows */}
            {entityValues.map((row, index) => (
              <TableRow key={index}>
                {entityHeader.map((column) => (
                  <TableCell key={column.name} className={classes.tableCell}>
                    {row?.[column.name as keyof MockAPIData]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SampleAPIData;
