import AnalyticsLayout from "./components/AnalyticsLayout";
import AnalyticsContextProvider from "./Context/AnalyticsContextProvider";

const Analytics: React.FC = () => {
  return (
    <AnalyticsContextProvider>
      <AnalyticsLayout />
    </AnalyticsContextProvider>
  );
};

export default Analytics;
