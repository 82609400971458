import { makeStyles } from "@mui/styles";
import Typography, { TypographyVariants } from "aether/Typography";
import { ReactComponent as EmtyStateIcon } from "assets/vizEmptyState.svg";
const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    marginTop: "8px",
  },
}));

const KPITileVisualizationEmptyState: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <EmtyStateIcon />
      <Typography
        variant={TypographyVariants.textSmall}
        style={{ width: "222px" }}
      >
        Sorry! there is something wrong at our end, please try again later
      </Typography>
    </div>
  );
};

export default KPITileVisualizationEmptyState;
