import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Outlet } from "react-router-dom";
import TabHeader from "../components/TabHeader";
import TabNavigationBar from "../components/TabNavigationBar";
import { ReactComponent as AssistantToolsIcon } from "assets/assistant-tools-icon.svg";
import { ToolsNavigations } from "./config";

const tools_header_title = "Tools";
const tools_header_description =
  "Skills may require APIs and custom flows to function effectively. You can integrate new APIs or create custom flows now, which can be utilized by skills later.";

const useStyles = makeStyles((theme: Theme) => ({
  toolsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: "24px",
  },
  toolContent: {
    display: "flex",
    flexDirection: "row",
    gap: "24px",
  },
  toolOutletContainer: {
    flex: 1,
  },
}));

const Tools: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.toolsContainer}>
      <TabHeader
        icon={<AssistantToolsIcon />}
        title={tools_header_title}
        description={tools_header_description}
      />

      <div className={classes.toolContent}>
        <TabNavigationBar navigations={ToolsNavigations} />

        <div className={classes.toolOutletContainer}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Tools;
