import { useSnackbar } from "notistack";
import { useState } from "react";
import { AssistantType, type IAssistant } from "pages/Assistant/types";
import SelectAssistantModal from "pages/Dashboard/CreateAssistantModal/SelectAssistantModal";
import { cloneAssistant } from "api/assistant/assistant";
import useRouteNavigator from "hooks/navigation/useRouteNavigator";

interface Props {
  open: boolean;
  assistant: IAssistant;
  onClose: () => void;
}

const CloneAssistant: React.FC<Props> = ({ open, assistant, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { gotoAssistant } = useRouteNavigator();
  const handleCloneAssistant = async (
    fields: {
      name: string;
      description: string;
    },
    type: AssistantType
  ): Promise<void> => {
    setLoading(true);
    const data: Record<string, string> = { name: fields.name };
    if (fields?.description) {
      data.description = fields.description;
    }

    cloneAssistant(assistant._id, data)
      .then((response) => {
        onClose();
        gotoAssistant(response?._id);
        enqueueSnackbar(`Assistant Cloned successfully.`, {
          variant: "success",
        });
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar(`Something went wrong, try again.`, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <SelectAssistantModal
      open={open}
      onClose={onClose}
      initialTitle={`Copy_${assistant?.name}`}
      onSubmit={handleCloneAssistant}
      loading={loading}
      initialAssistantType={
        assistant?.settings?.channel_settings?.enabled_channels?.[0]
      }
      hideTypeChange
    />
  );
};

export default CloneAssistant;
