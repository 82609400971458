import { makeStyles } from "@mui/styles";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles(() => ({
  donutTileShimmerContainer: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    gap: "48px",
  },
  leftParent: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  topLeft: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  bottomLeft: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
}));

const DonutTileVisualizationShimmer: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.donutTileShimmerContainer}>
      {/* Left side with text shimmers */}
      <div className={classes.leftParent}>
        <div className={classes.topLeft}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={171}
            height={40}
            key={"inSyncShimmer"}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            width={104}
            height={20}
            key={"inSyncShimmer"}
          />
        </div>
        <div className={classes.bottomLeft}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={204}
            height={24}
            key={"inSyncShimmer"}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            width={204}
            height={24}
            key={"inSyncShimmer"}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            width={204}
            height={24}
            key={"inSyncShimmer"}
          />
        </div>
      </div>

      {/* Right side with donut shimmer */}
      <Skeleton
        variant="circular"
        animation="wave"
        width={180}
        height={180}
        sx={{ marginLeft: 2 }}
        key={"inSyncShimmer"}
      />
    </div>
  );
};

export default DonutTileVisualizationShimmer;
