import { makeStyles } from "@mui/styles";
import { Drawer, TextField, CircularProgress, IconButton } from "@mui/material";
import Button from "components/base/Button";
import CloseIcon from "@mui/icons-material/Close";
import { testAPIIntegration } from "api/workflow/integration";
import { useEffect, useState } from "react";
import Typography from "components/base/Typography";
import ReactJson from "react-json-view";
import { noop } from "lodash";

const useStyles = makeStyles((theme) => ({
  "response-viewer-container": {
    padding: "16px 24px",
    width: "600px",

    "& .title-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    "& .response-container": {
      marginTop: "16px",

      "& .response-loader": {
        width: "100%",
        height: "20rem",
      },
    },

    "& .api-response-container": {
      display: "flex",
      padding: "16px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "12px",
      alignSelf: "stretch",
      borderRadius: "8px",
      background: "var(--bg-disabled, #F9F8F8)",
      wordBreak: "break-all",
    },
  },
}));

interface Props {
  integratonId: string;
  extractedVariables: any;
  onTestAPIIntegrationSuccessCb?: () => void;
  disableTest?: boolean;
}

const APIResponseViewer: React.FC<Props> = ({
  integratonId,
  extractedVariables,
  onTestAPIIntegrationSuccessCb = noop,
  disableTest = false,
}) => {
  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [extractedVariablesMap, setExtractedVariablesMap] = useState<any>(
    extractedVariables?.map((variable: any) => ({ ...variable, value: "" }))
  );
  const [apiResponse, setApiResponse] = useState<any>(null);

  useEffect(() => {
    if (!openDrawer) {
      return;
    }

    if (!extractedVariables?.length) {
      handleTestAPIIntegration();
    } else {
      setLoading(false);
    }
  }, [openDrawer]);

  const handleTestAPIIntegration = (): void => {
    setLoading(true);

    const body: Record<string, string> = {};

    for (const variable of extractedVariablesMap ?? []) {
      body[variable.key] = variable.value;
    }

    testAPIIntegration(integratonId, body)
      .then((response) => {
        const data = JSON.parse(response.body);
        setApiResponse(data);
        onTestAPIIntegrationSuccessCb();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleVariableMapChange = (key: string, value: any): void => {
    const updatedVariablesMap = extractedVariablesMap.map((variable: any) =>
      variable.key === key ? { ...variable, value } : variable
    );

    setExtractedVariablesMap(updatedVariablesMap);
  };

  const enableRun = extractedVariablesMap?.every(
    (variable: any) => variable.value
  );

  return (
    <>
      <Button
        color={"secondary"}
        onClick={() => {
          setOpenDrawer(true);
        }}
        disabled={disableTest}
      >
        Test
      </Button>

      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        ModalProps={{
          sx: {
            ".MuiPaper-root": {
              borderRadius: "0px",
              "&::-webkit-scrollbar": { width: "0px" },
              "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "6px",
              },
              "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
            },
          },
        }}
      >
        <div className={classes["response-viewer-container"]}>
          <div className="title-container">
            <Typography variant="textXl" className="w-semi-bold">
              Response Viewer
            </Typography>

            <IconButton
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>

          {extractedVariablesMap?.length ? (
            <div className="api-variable-editor flex flex-col row-gap-16">
              {extractedVariablesMap.map((variable: any, index: number) => (
                <div
                  key={index}
                  className="flex flex-col align-items-stretch row-gap-8"
                >
                  <div className="flex flex-col">
                    <Typography>{variable.key}</Typography>
                    <Typography variant="textSm" sx={{ color: "#7C7972" }}>
                      {variable.description}
                    </Typography>
                  </div>

                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Enter value"
                    fullWidth
                    value={variable?.value || ""}
                    onChange={(event) => {
                      handleVariableMapChange(variable.key, event.target.value);
                    }}
                  />
                </div>
              ))}

              <Button
                sx={{ width: 120 }}
                size="x-small"
                color={"secondary"}
                disabled={!enableRun}
                onClick={handleTestAPIIntegration}
                loading={loading}
              >
                Run
              </Button>
            </div>
          ) : null}

          <div className="response-container">
            {loading && (
              <div className="response-loader center">
                <CircularProgress size={28} sx={{ margin: "auto" }} />
              </div>
            )}
            {!loading && apiResponse && (
              <div className="api-response-container">
                <ReactJson
                  src={apiResponse}
                  name={null}
                  collapsed={1}
                  enableClipboard={false}
                />
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default APIResponseViewer;
