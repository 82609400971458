import type React from "react";
import useStyles from "./RightPanel.container.styles";
import { ActionBuilderActions } from "store/reduxActions/ActionBuilderActions";
import { useDispatch, useSelector } from "store";
import { isEqual } from "lodash";
import { ActionType, type ActionParam } from "../AgentActions.const";
import ConnectorActionPreview from "./ActionPreview/ConnectorActionPreview";
import APIActionPreview from "./ActionPreview/APIActionPreview";
import ActionParamConfigure from "./ActiionConfigure/ActionParamConfigure";

const RightPanelContainer: React.FC = () => {
  const classes = useStyles();
  const {
    actionType,
    actionForPreview,
    selectedConfigurableParam,
    selectedConfigurableAction,
  } = useSelector((state) => state.actionBuilder);
  const dispatch = useDispatch();

  const openSelectAndConfigure = (): void => {
    dispatch({
      type: ActionBuilderActions.SET_SELECTED_CONFIGURABLE_ACTION,
      payload: actionForPreview,
    });
    dispatch({ type: ActionBuilderActions.CLEAR_PREVIEW_ACTION });
  };

  const onSaveParamChanges = (value: string): void => {
    let updatedAction = {};

    if (isEqual(actionType, ActionType.API)) {
      // API action
      const updatedParams =
        selectedConfigurableAction?.extracted_variables?.map(
          (param: ActionParam) => {
            if (isEqual(param.id, selectedConfigurableParam.id)) {
              return { ...param, value }; // Update the default field
            }
            return param; // Return original if no match
          }
        );
      updatedAction = {
        ...selectedConfigurableAction,
        extracted_variables: updatedParams,
      };
    } else {
      // connector action
      // Update input_params (create new array with updated default value)
      const updatedInputParams = selectedConfigurableAction.input_params.map(
        (param: ActionParam) => {
          if (isEqual(param.id, selectedConfigurableParam.id)) {
            return { ...param, value }; // Update the default field
          }
          return param; // Return original if no match
        }
      );

      // Update output_params (create new array with updated default value)
      const updatedOutputParams = selectedConfigurableAction.output_params.map(
        (param: ActionParam) => {
          if (isEqual(param.id, selectedConfigurableParam.id)) {
            return { ...param, value }; // Update the default field
          }
          return param; // Return original if no match
        }
      );

      updatedAction = {
        ...selectedConfigurableAction,
        input_params: updatedInputParams,
        output_params: updatedOutputParams,
      };
    }
    dispatch({
      type: ActionBuilderActions.SET_SELECTED_CONFIGURABLE_ACTION,
      payload: updatedAction,
    });
    // close right panel
    dispatch({
      type: ActionBuilderActions.CLEAR_SELECTED_CONFIGURABLE_PARAM,
    });
  };

  const getChildComponent = (type: ActionType | null): React.ReactNode => {
    switch (type) {
      case ActionType.CONNECTORS:
        return (
          <ConnectorActionPreview
            onClose={() =>
              dispatch({
                type: ActionBuilderActions.CLEAR_PREVIEW_ACTION,
              })
            }
            action={actionForPreview}
            openSelectAndConfigure={openSelectAndConfigure}
          />
        );
      case ActionType.API:
        return (
          <APIActionPreview
            onClose={() =>
              dispatch({
                type: ActionBuilderActions.CLEAR_PREVIEW_ACTION,
              })
            }
            action={actionForPreview}
            openSelectAndConfigure={openSelectAndConfigure}
          />
        );
      case ActionType.FLOWS:
        return <div>Not imoplemented yet</div>;
      default:
        return null;
    }
  };

  return (
    (actionForPreview || selectedConfigurableParam) && (
      <div className={classes.container}>
        {actionForPreview && getChildComponent(actionType)}
        {selectedConfigurableParam && (
          <ActionParamConfigure
            param={selectedConfigurableParam}
            onClose={() => {
              dispatch({
                type: ActionBuilderActions.CLEAR_SELECTED_CONFIGURABLE_PARAM,
              });
            }}
            onSave={onSaveParamChanges}
          />
        )}
      </div>
    )
  );
};

export default RightPanelContainer;
