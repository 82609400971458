import { KPIChartVizData } from "pages/Analytics/types";
import React from "react";
import { makeStyles } from "@mui/styles";
import Typography, {
  TypographyColors,
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import Chip, { ChipColors } from "aether/Chip";
import { ReactComponent as ArrowUpPositiveIcon } from "assets/arrowUpPositive.svg";
import { ReactComponent as ArrowUpNegativeIcon } from "assets/arrowUpNegative.svg";
import KPITileVisualizationShimmer from "./KPITileVisualizationShimmer";
import KPITileVisualizationEmptyState from "./KPITileVisualizationEmptyState";
import { ReactComponent as ArrowDownPositiveIcon } from "assets/arrowDownPositive.svg";
import { ReactComponent as ArrowDownNegativeIcon } from "assets/arrowDownNegative.svg";

const useStyles = makeStyles(() => ({
  donutTileVizualizationContainer: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    gap: "48px",
  },
  resolutionRateContainer: {
    display: "flex",
    gap: "8px",
    marginBottom: "8px",
    alignItems: "center",
  },
  resolutionRate: {
    fontSize: "32px",
    fontWeight: 600,
    lineHeight: "40px",
    letterSpacing: "-0.5px",
  },
  chip: {
    height: "28px",
  },
}));

interface Props {
  vizData: KPIChartVizData;
  loading: boolean;
}

const KPITileVisualization: React.FC<Props> = ({ vizData, loading }) => {
  const classes = useStyles();
  if (loading || !vizData) {
    return <KPITileVisualizationShimmer />;
  }

  if (!vizData) {
    return <KPITileVisualizationEmptyState />;
  }

  const {
    primaryValue,
    percentageChange,
    comparisonLabel,
    isChangeTrendPositive = true,
  } = vizData || {};

  const getChipStartIcon = (): JSX.Element => {
    if (isChangeTrendPositive) {
      return percentageChange >= 0 ? (
        <ArrowUpPositiveIcon />
      ) : (
        <ArrowDownPositiveIcon />
      );
    } else {
      return percentageChange >= 0 ? (
        <ArrowUpNegativeIcon />
      ) : (
        <ArrowDownNegativeIcon />
      );
    }
  };

  return (
    <div className={classes.donutTileVizualizationContainer}>
      <div>
        <div className={classes.resolutionRateContainer}>
          <Typography
            className={classes.resolutionRate}
            weight={TypographyWeights.semiBold}
          >
            {primaryValue}
          </Typography>
          <Chip
            className={classes.chip}
            color={
              isChangeTrendPositive ? ChipColors.success : ChipColors.error
            }
            label={`${percentageChange.toFixed(2)}%`}
            startIcon={getChipStartIcon()}
          />
        </div>
        <Typography
          variant={TypographyVariants.textSmall}
          weight={TypographyWeights.medium}
          color={TypographyColors.subtle}
          renderInLines={1}
        >
          {comparisonLabel}
        </Typography>
      </div>
    </div>
  );
};

export default KPITileVisualization;
