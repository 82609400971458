import { CenterLabel, DonutChartSegment } from "pages/Analytics/types";
import React from "react";
import { PieChart, Pie, Cell, Tooltip, Label } from "recharts";
import { Paper } from "@mui/material";

interface Props {
  segments: DonutChartSegment[];
  centerLabel?: CenterLabel;
}

// Custom Tooltip Component
const CustomTooltip = ({ active, payload }: any): JSX.Element | null => {
  if (active && payload?.length) {
    return (
      <Paper
        elevation={3}
        sx={{
          padding: "4px 12px",
          borderRadius: "22px",
          border: "1px solid var(--border-default, #E1DEDA)",
          background: "#FFF",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
          fontWeight: 500,
          color: "#282624",
        }}
      >
        {`${payload[0].name} : ${payload[0].value.toFixed(2)}%`}
      </Paper>
    );
  }
  return null;
};

// Custom Label on Pie Segments
const RenderLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: any): JSX.Element | null => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  if (percent === 0) return null;
  return (
    <foreignObject
      x={x - 28}
      y={y - 14}
      width="56px"
      height="28px"
      style={{ pointerEvents: "none" }}
    >
      <div
        style={{
          width: "56px",
          height: "28px",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "3px",
          borderRadius: "22px",
          border: "1px solid var(--border-default, #E1DEDA)",
          background: "var(--Base-White, #FFF)",
        }}
      >
        <div
          style={{
            color: "var(--text-default, #282624)",
            textAlign: "center",
            fontFamily: "Manrope",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >{`${(percent * 100).toFixed(0)}%`}</div>
      </div>
    </foreignObject>
  );
};

const DonutChart: React.FC<Props> = ({ segments, centerLabel }) => {
  return (
    <PieChart width={250} height={250}>
      <Pie
        data={segments}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        innerRadius={68}
        outerRadius={95}
        labelLine={false}
        label={RenderLabel}
        cornerRadius={2}
      >
        <Label
          content={() => (
            <>
              <text
                x="50%"
                y="45%"
                textAnchor="middle"
                dominantBaseline="central"
                fontSize={20}
                fontWeight="bold"
                fill="black"
              >
                {centerLabel?.main}
              </text>
              <text
                x="50%"
                y="45%"
                dy={20}
                textAnchor="middle"
                dominantBaseline="central"
                fontSize={12}
                fill="gray"
              >
                {centerLabel?.sub}
              </text>
            </>
          )}
          position="center"
        />

        {segments.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={entry.color}
            stroke="white"
            strokeWidth={4}
            style={{ outline: "none" }}
          />
        ))}
      </Pie>

      {/* Using Custom Tooltip */}
      <Tooltip content={<CustomTooltip />} />
    </PieChart>
  );
};

export default DonutChart;
