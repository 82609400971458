import type React from "react";
import useStyles from "./ConnectorsAction.styles";
import ConnectorTile from "./ConnectorTile";
import { useEffect, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import Loading from "aether/Loading";
import { useSnackbar } from "notistack";
import { getAllConnections } from "pages/Integrations/Services/integrations.service";
import { type Connection } from "pages/Integrations/integrations.types";
import { Tool } from "../AgentActions.const";
import ConnectorActionsList from "./SelectAction/ConnectorActionsList";

interface Props {
  tool: Tool | null;
}
const ConnectorsAction: React.FC<Props> = ({ tool = null }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [selectedConnection, setSelectedConnection] =
    useState<Connection | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    getAllConnections()
      .then((res) => {
        if (tool?.config?.connection_id) {
          const toolConnection = res.filter(
            (connection) => connection._id === tool?.config?.connection_id
          );
          setConnections(toolConnection ?? []);
          setSelectedConnection(toolConnection?.[0]);
        } else {
          setConnections(res);
        }
      })
      .catch((err) => {
        enqueueSnackbar(`Something went wrong: ${err}`, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.step}>Step 1/2</div>
        <div className={classes.title}>Select a connector</div>
        {loading && <Loading />}
        <div className={classes.tiles}>
          {connections.map((tile) => {
            return (
              // They are actually rendering all connections not connectors
              <ConnectorTile
                key={tile._id}
                tileConfig={tile}
                onClick={() => {
                  setSelectedConnection(tile);
                }}
                selected={isEqual(selectedConnection?._id, tile._id)}
              />
            );
          })}
        </div>
      </div>
      {!isEmpty(selectedConnection) && (
        <ConnectorActionsList
          selectedConnection={selectedConnection}
          tool={tool}
        />
      )}
    </>
  );
};

export default ConnectorsAction;
