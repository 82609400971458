import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    cursor: "pointer",
    flex: "0 0 calc(50% - 8px)",
    width: "50%",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "#FFF",
    overflow: "hidden",
  },
  selectedContainer: { border: "1px solid var(--border-active, #F07400)" },
  content: {
    overflow: "hidden",
    marginRight: "16px",
  },
  title: {
    color: "var(--fg-text-default, #282624)",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "28px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  description: {
    color: "var(--text-default, #282624)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
  },
  icon: {},
  infoKeys: {
    display: "flex",
    marginTop: "8px",
  },
  pendingFeatureContainer: {
    marginTop: "16px",
    width: "152px",
    display: "flex",
    padding: "4px 12px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "8px",
    background: "var(--colors-primary-sky-100, #EAF9FE)",
    whiteSpace: "nowrap",
  },
  comingSoonText: {
    color: "var(--text-Blue-notes, #3A91B4)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
  },
  info: {
    color: "var(--text-subtle, #7C7972)",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "20px",
  },
}));

export default useStyles;
